import React from 'react';
import styled, { css } from 'styled-components';
import { ArrayPropType } from '../../util/PropTypeValues';

const separator = css`
  content: ' ';
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #c30144;
  border-radius: 50%;
  margin: 0 0.6rem;
  vertical-align: middle;
`;
const DivBreadcrumbs = styled.div`
  margin-bottom: 2rem;

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #32546d;
    text-decoration: none;

    :nth-of-type(even) {
      ::before {
        ${separator}
      }

      :not(:last-of-type)::after {
        ${separator}
      }
    }

    :hover {
      text-decoration: underline;
    }
  }
  button {
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #32546d;
    text-decoration: none;
    :nth-of-type(even) {
      ::before {
        ${separator}
      }
      :not(:last-of-type)::after {
        ${separator}
      }
    }
    :hover {
      text-decoration: underline;
    }
  }
`;

export const Breadcrumbs = ({ children }) => <DivBreadcrumbs>{children}</DivBreadcrumbs>;

Breadcrumbs.defaultProps = {
  children: []
};

Breadcrumbs.propTypes = {
  children: ArrayPropType
};

export default Breadcrumbs;
