import {
  JOB_ERROR,
  JOB_LOADING,
  JOB_RESET,
  JOB_SUCCESS,
  JOB_UNAVAILABLE,
  RELEVANT_JOBS,
  RELEVANT_JOBS_ERROR,
  RELEVANT_JOBS_SUCCESS,
  SERVICE_FAVORITE,
  SERVICE_FAVORITE_ERROR,
  SERVICE_FAVORITE_SUCCESS,
  SERVICE_FILTER_UPDATE,
  SERVICE_TRASH,
  SERVICE_TRASH_ERROR,
  SERVICE_TRASH_SUCCESS,
  SERVICE_UPDATE_CART_ID,
  SERVICE_UPDATE_ID,
  SERVICE_USE_FREE_LEAD,
  SERVICE_USE_FREE_LEAD_ERROR,
  SERVICE_USE_FREE_LEAD_SUCCESS
} from './actionTypes';

import JobService from '../../../lib/services/jobService';
import TraderService from '../../../lib/services/traderService';

const jobService = new JobService();
const traderService = new TraderService();

export function addServiceFavorite(payload) {
  return async dispatch => {
    dispatch({
      type: SERVICE_FAVORITE,
      payload
    });
    try {
      const data = await jobService.toggleFavorite(payload);
      data.sr_status = data.status;
      dispatch({
        type: SERVICE_FAVORITE_SUCCESS,
        payload: data
      });
      return data;
    } catch (error) {
      dispatch({
        type: SERVICE_FAVORITE_ERROR,
        payload: error.message
      });
      return error;
    }
  };
}

export function serviceNotInterested(payload) {
  return async dispatch => {
    dispatch({
      type: SERVICE_TRASH,
      payload
    });
    try {
      const data = await jobService.trash(payload);
      dispatch({
        type: SERVICE_TRASH_SUCCESS,
        payload: data
      });
      return data;
    } catch (error) {
      dispatch({
        type: SERVICE_TRASH_ERROR,
        payload: error.message
      });
      return error;
    }
  };
}

export function serviceFilter(payload) {
  return {
    type: SERVICE_FILTER_UPDATE,
    payload: {
      ...payload,
      pagination: {
        total: 0,
        perPage: 12,
        page: 1
      }
    }
  };
}

export function serviceUpdateId(payload) {
  return {
    type: SERVICE_UPDATE_ID,
    payload
  };
}
export function serviceUpdateCartId(payload) {
  return {
    type: SERVICE_UPDATE_CART_ID,
    payload
  };
}

export function serviceUseFreeLead(payload) {
  return async dispatch => {
    dispatch({
      type: SERVICE_USE_FREE_LEAD,
      payload
    });
    try {
      const data = await jobService.useFreeLeadOnId(payload);
      dispatch({
        type: SERVICE_USE_FREE_LEAD_SUCCESS,
        payload: { id: payload, data }
      });
    } catch (error) {
      dispatch({
        type: SERVICE_USE_FREE_LEAD_ERROR,
        payload: { id: payload, error }
      });
    }
  };
}

export function getRelevantJobs() {
  return async dispatch => {
    dispatch({
      type: RELEVANT_JOBS
    });
    try {
      const data = await traderService.relevantJobs();
      dispatch({
        type: RELEVANT_JOBS_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: RELEVANT_JOBS_ERROR,
        payload: error.message
      });
    }
  };
}

function loadingJob() {
  return {
    type: JOB_LOADING
  };
}

function successJob(payload) {
  return {
    type: JOB_SUCCESS,
    payload
  };
}
function unavailableJob(payload) {
  return {
    type: JOB_UNAVAILABLE,
    payload
  };
}
function errorJob(payload) {
  return {
    type: JOB_ERROR,
    payload
  };
}
export function resetJob() {
  return async dispatch => {
    dispatch({
      type: JOB_RESET
    });
  };
}
export function requestJobById({ id, isGuest = false }) {
  return async dispatch => {
    try {
      dispatch(loadingJob());
      const result = await jobService.getById(id, isGuest);
      const data = await result.json();
      if (result.ok) {
        dispatch(successJob(data));
        return data;
      }
      if (result.status === 410 || result.status === 404 || result.status === 400) {
        dispatch(unavailableJob(data));

        return {
          redirect: true
        };
      }
      dispatch(errorJob(data));
    } catch (error) {
      dispatch(errorJob(error));
    }
  };
}
