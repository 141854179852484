import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';

import { ButtonLogin } from '../LoginForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import TextField from '../TextField';
import styled from 'styled-components';

const DivContainer = styled.div`
  margin: 4rem;
  max-width: 468px;

  @media (min-width: 768px) {
    margin: 4rem auto;
  }
`;
const DivForm = styled.div`
  max-width: 468px;
  margin: 0 auto 2rem auto;

  > div {
    margin-top: 1rem;
  }
`;
const H2Title = styled.h2`
  color: #32546d;
  text-align: center;
  font-family: 'Lato', sans-serif;
`;
const DivError = styled.div`
  color: red;
  transition: all 2s ease-in-out;
`;
const Anchor = styled(Link)`
  display: inline-block;
  cursor: pointer;
  color: #32546d;
  margin-top: 0.5rem;

  span {
    font-size: 10pt;
    font-weight: bold;
    text-decoration: underline;
    font-family: 'Lato', sans-serif;
    font-style: normal;
  }
`;

const Message = styled.p`
  font-family: Lato, sans-serif;
  color: #32546d;
  span {
    font-size: 12pt;
    font-weight: bold;
  }
`;

/**
 *
 * @param { onSubmit(username, password) } param0
 */
export const ForgotPasswordForm = ({ onSubmit, isLoading, unauthorized, message }) => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(null);
  const onClickSubmit = () => {
    if (email) {
      onSubmit(email);
    } else {
      setHasError({
        required: {
          email: !email
        }
      });
    }
  };
  const validate = field => {
    if (hasError && hasError.required) {
      return hasError.required[field];
    }

    return false;
  };
  const onChange = (field, value) => {
    if (field === 'email') {
      setEmail(value);
    }

    if (hasError && hasError.required && hasError.required.hasOwnProperty(field)) {
      const { required } = hasError;
      delete required[field];

      setHasError({
        required
      });
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const forgotEmail = sessionStorage.getItem('forgotEmail');
      setEmail(forgotEmail);
    }
  }, []);

  return (
    <DivContainer>
      <H2Title>
        <FormattedMessage tagName="span" id="forgot_password.title" />
      </H2Title>
      <DivForm>
        <TextField
          id="email"
          value={email}
          onChange={e => onChange('email', e.target.value)}
          label={intl.messages['forgot_password.form.email']}
          type="email"
          error={validate('email')}
          fullWidth
          disabled={Boolean(message)}
        />
      </DivForm>
      {unauthorized || hasError ? (
        <DivError data-cy-div="forgot-password-error">
          {hasError && Object.values(hasError.required).includes(true) && (
            <FormattedMessage tagName="span" id="forgot_password.form.error.required" />
          )}
          {unauthorized && (
            <FormattedMessage tagName="span" id="forgot_password.form.error.unauthorized" />
          )}
        </DivError>
      ) : (
        message && (
          <Message data-cy-message="email-sent-forgot-password">
            <FormattedMessage
              id="reset_password.form.success"
              values={{ email: <span>{email}</span> }}
            />
          </Message>
        )
      )}
      {!message && (
        <ButtonLogin onClick={onClickSubmit} disabled={isLoading} type="submit" fullWidth>
          {isLoading && <CircularProgress color="inherit" disableShrink />}
          {!isLoading && <FormattedMessage tagName="span" id="forgot_password.form.submit" />}
        </ButtonLogin>
      )}
      <Anchor to={`${ROUTES.LOGIN.handler()}`} data-cy-link="login">
        <FormattedMessage tagName="span" id="forgot_password.back_to_login" />
      </Anchor>
    </DivContainer>
  );
};

export default ForgotPasswordForm;
