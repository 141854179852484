import React, { useMemo, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import isNil from 'lodash/isNil';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';
import { RADIUS, RADIUS_LOCAL } from '../../constants/radius';
import { Subscriptions } from '../../constants/subscriptions';
import Select from '../Select';
import SubscriptionPopover from '../subscription/Popover';
import ValidatePermission from '../subscription/ValidatePermission';

const tagManagerArgs = {
  dataLayer: {
    event: 'Plan Upgrade Requested'
  },
  dataLayerName: 'ProDataLayer'
};
const useStylesMenuItemDisabled = makeStyles({
  root: {
    opacity: '0.5',
    cursor: 'default'
  }
});
const RadiusSelect = ({ limit, onChange, userSubscription, ...props }) => {
  const menuItemDisabled = useStylesMenuItemDisabled();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = event => {
    if (isNil(event) || isNil(event.target) || isNil(event.target.value)) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = (event, value, disabled) => {
    if (disabled) {
      return setAnchorEl(event.currentTarget);
    }
    onChange({ value, target: { value } });
    handleClose();
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = anchorEl !== null;

  const radiusToUse = useMemo(() => {
    if (
      userSubscription &&
      userSubscription.length > 0 &&
      userSubscription[0].sub_type !== Subscriptions.Local
    ) {
      return RADIUS;
    }
    return RADIUS_LOCAL;
  }, [userSubscription]);

  return (
    <>
      <SubscriptionPopover open={openPopover} anchorEl={anchorEl} onClose={handleClosePopover}>
        <FormattedMessage
          tagName="p"
          id="profile.form.radius.limit.title"
          values={{ count: limit }}
        />
        <FormattedMessage
          tagName="span"
          id="profile.form.radius.limit.content.start"
          values={{ count: limit }}
        />
        <div
          style={{ display: 'inline-block', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => {
            TagManager.dataLayer(tagManagerArgs);
            setTimeout(() => window.location.reload(), 500);
          }}
        >
          <FormattedMessage tagName="span" id="profile.form.radius.limit.event" />
        </div>
        <FormattedMessage tagName="span" id="profile.form.radius.limit.content.end" />
      </SubscriptionPopover>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        name="service_radius"
        {...props}
      >
        {radiusToUse.map(radius => {
          let disabled = false;
          if (limit) {
            disabled = radius.value > limit;
          }
          return (
            <MenuItem
              onClick={event => handleClick(event, radius.value, disabled)}
              classes={{ root: disabled && menuItemDisabled.root }}
              data-cy-menuitem={radius.value}
              value={radius.value}
              key={radius.value}
            >
              {radius.label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
const RadiusSelectSubscription = props => (
  <ValidatePermission permission="service_radius_limit">
    <RadiusSelect {...props} />
  </ValidatePermission>
);
export default RadiusSelectSubscription;
