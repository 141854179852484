import React, { useEffect } from 'react';

import { ReactComponent as ChecklistSVG } from '../../assets/images/warning-icon.svg';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const AnchorDarkRed = styled.a`
  margin-top: 2rem !important;
  color: #af1e3c;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #af1e3c;
  border-radius: 3px;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  width: auto !important;
  padding: 0.8rem 1.8rem;
  cursor: pointer;
`;
const DivIcon = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  margin: auto;
  border: 2.4px solid #405a6b;
  border-radius: 100%;
`;
const DivLoading = styled.div`
  margin: 5rem auto;
  > div {
    height: 4rem !important;
    width: 4rem !important;
  }
`;

const StepSomethingWrong = ({ user, refreshUser, vatValidating }) => {
  const intl = useIntl();
  useEffect(() => {
    const timer = setInterval(() => refreshUser(user.id), 6000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {vatValidating && (
        <>
          <DivLoading>
            <CircularProgress />
          </DivLoading>
          <h3 data-cy="wait">{intl.messages['register.step.wait.wait']}</h3>
          <p>{intl.messages['register.step.wait.validating_vat']}</p>
          <p>{intl.messages['register.step.wait.contact']}</p>
        </>
      )}
      {!vatValidating && (
        <>
          <DivIcon>
            <ChecklistSVG />
          </DivIcon>
          <h3>{intl.messages['register.step.wait.wrong']}</h3>
          <p>{intl.messages['register.step.wait.contact']}</p>
        </>
      )}
      <AnchorDarkRed href={`tel:${intl.messages['footer.phone']}`}>
        {intl.messages['footer.phone']}
      </AnchorDarkRed>
      <p>{intl.messages['register.step.wait.hours']}</p>
    </>
  );
};

export default StepSomethingWrong;
