import {
  BoolPropType,
  FuncPropType,
  ObjectPropType,
  StringPropType
} from '../../util/PropTypeValues';
import React, { useEffect, useState } from 'react';

import CategoriesTreeSelect from '../../components/CategoriesTreeSelect';
import { ERROR_VAT_ALREADY_USED } from '../../util/serviceHelpers';
import { ErrorMessage } from '../../components/common/Error';
import JaimyCheckbox from '../../components/Checkbox';
import JaimyTooltip from '../../components/Tooltip';
import { connect } from 'react-redux';
import { requestCategories } from '../../store/reducers/categories/actions';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ButtonDarkRed } from '../../components/common/Button';

const DivUseCommercialName = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem !important;
  display: flex;
  align-items: center;
`;
const DivTreeSelect = styled.div`
  display: contents;
  > div > div > div {
    ${props => !props.valid && 'border-color: #c30b30;'};
  }
`;
const LabelVAT = styled.label`
  display: flex;
  height: 40px;
  > span {
    color: #32546d;
    border: 1px solid #d8dae0;
    border-right: none;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: white;
    outline: none;
    height: 40px;
    line-height: 40px;
    width: 12%;
    text-align: center;
    ${props => props.valid === false && 'border-color: #c30b30;'}
  }
  > input {
    width: 90%;
    color: #32546d;
    border: 1px solid #d8dae0;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f3f4f6;
    outline: none;
    ${props => props.valid === false && 'border-color: #c30b30;'}
    ${props => props.valid === false && 'border-left-color: #D8DAE0;'}
  }
`;
const InputText = styled.input`
  color: #32546d;
  border: 1px solid #d8dae0;
  font-size: 18px;
  padding-left: 0.8rem;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  background-color: #f3f4f6;
  outline: none;
  height: 40px;
  &:required,
  &:focus,
  &:invalid {
    border-color: #c30b3064;
  }
  ${props => props.valid === false && 'border-color: #c30b30;'}
  &.noMarginBotton {
    margin-bottom: 0.3rem !important;
  }
`;
const DivTreeWrapper = styled.div`
  width: 100%;
  display: flex;
  > div > div {
    width: 80%;
    margin: auto;
  }
  margin: 0.4rem auto 1rem !important;
`;
const SpanVisibleByUsers = styled.span`
  font-family: Lato;
  font-size: 12px;
  width: 80%;
`;
const SpanTooltip = styled.span`
  display: flex;
  align-items: center;
  color: #af1e3c;
`;

const StepCompanyDetails = ({
  onClickContinue,
  categories,
  catLoading,
  catError,
  getCategories,
  locale,
  userError
}) => {
  const [categoriesValue, setCategoriesValue] = useState({ value: [], touched: false });
  const [VAT, setVAT] = useState({ value: '', touched: false });
  const [companyName, setCompanyName] = useState({ value: '', touched: false });
  const [useCommercialName, setUseCommercialName] = useState(false);
  const [commercialName, setCommercialName] = useState({ value: '', touched: false });

  const intl = useIntl();

  useEffect(() => {
    if (!catError && !catLoading) {
      getCategories(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const isCategoriesValid = !!categoriesValue.value && categoriesValue.value.length > 0;
  const noSeperatorsVat = VAT.value.replace(/(\.|-| )/g, '');
  const isVATValid =
    !!noSeperatorsVat && /^[0-9]{10}$/.test(noSeperatorsVat) && noSeperatorsVat !== '0123456789';
  const isCompanyNameValid = !!companyName.value && companyName.value !== '';
  const isCommercialNameValid =
    !useCommercialName || (!!commercialName.value && commercialName.value !== '');

  const validateAndContinue = () => {
    if (isCategoriesValid && isVATValid && isCompanyNameValid && isCommercialNameValid) {
      onClickContinue({
        treetrades: categoriesValue.value,
        vat_number: 'BE' + noSeperatorsVat,
        companyname: useCommercialName ? commercialName.value : companyName.value,
        vat_companyname: companyName.value
      });
    } else {
      setCategoriesValue({ value: categoriesValue.value, touched: true });
      setVAT({ value: VAT.value, touched: true });
      setCompanyName({ value: companyName.value, touched: true });
      setCommercialName({ value: commercialName.value, touched: true });
    }
  };

  return (
    <>
      <h2 data-cy="page-details">{intl.messages['register.step.details.title']}</h2>
      <span className="subtitle">{intl.messages['register.step.details.expertise']}</span>
      <DivTreeWrapper>
        <DivTreeSelect valid={isCategoriesValid || !categoriesValue.touched} data-cy="treeselect">
          <CategoriesTreeSelect
            showCompetenceDomain
            id="treetrades"
            name="treetrades"
            categories={categories}
            locale={locale}
            onSelectTrade={val => setCategoriesValue({ value: val, touched: true })}
            required
            primary
          />
        </DivTreeSelect>
      </DivTreeWrapper>
      <span className="subtitle">{intl.messages['register.step.details.VAT']}</span>
      <LabelVAT htmlFor="vatField" valid={isVATValid || !VAT.touched}>
        <span>BE</span>
        <input
          id="vatField"
          type="text"
          value={VAT.value || ''}
          onChange={e => setVAT({ value: e.target.value, touched: true })}
          data-cy="vat"
        />
      </LabelVAT>
      <span className="subtitle">{intl.messages['register.step.details.companyname']}</span>
      <InputText
        type="text"
        value={companyName.value || ''}
        onChange={e => setCompanyName({ value: e.target.value, touched: true })}
        valid={isCompanyNameValid || !companyName.touched}
        data-cy="companyname"
      />
      <DivUseCommercialName>
        <label htmlFor="useCommercial">
          <JaimyCheckbox
            id="useCommercial"
            type="checkbox"
            checked={useCommercialName}
            onChange={e => setUseCommercialName(e.target.checked)}
            data-cy="usecommercialname"
          />
          {intl.messages['register.step.details.use_commercial_name']}
        </label>
        <JaimyTooltip
          placement="top"
          title={
            <>
              <p>
                <strong>{intl.messages['register.step.details.tooltip_1']}</strong>
                {intl.messages['register.step.details.tooltip_2']}
              </p>
              <p>
                <strong>{intl.messages['register.step.details.tooltip_3']}</strong>
                {intl.messages['register.step.details.tooltip_4']}
              </p>
            </>
          }
        >
          <SpanTooltip> {intl.messages['register.step.details.tooltip']}</SpanTooltip>
        </JaimyTooltip>
      </DivUseCommercialName>
      {useCommercialName && (
        <>
          <span className="subtitle">{intl.messages['register.step.details.commercialname']}</span>
          <InputText
            type="text"
            className="noMarginBotton"
            value={commercialName.value || ''}
            onChange={e => setCommercialName({ value: e.target.value, touched: true })}
            valid={isCommercialNameValid || !commercialName.touched}
            data-cy="commercialname"
          />
          <SpanVisibleByUsers>
            {intl.messages['register.step.details.visible_by_users']}
          </SpanVisibleByUsers>
        </>
      )}
      {userError === ERROR_VAT_ALREADY_USED && (
        <ErrorMessage>
          {intl.messages['register.step.wait.wrong']} {intl.messages['register.step.wait.contact']}
        </ErrorMessage>
      )}
      {!isVATValid && VAT.touched && (
        <ErrorMessage>{intl.messages['missing-properties.errors.vat']}</ErrorMessage>
      )}
      <ButtonDarkRed onClick={validateAndContinue} data-cy="submit">
        {intl.messages['register.steps.continue']}
      </ButtonDarkRed>
    </>
  );
};

StepCompanyDetails.defaultProps = { onClickContinue: {}, catError: null };
StepCompanyDetails.propTypes = {
  onClickContinue: FuncPropType,
  getCategories: FuncPropType.isRequired,
  categories: ObjectPropType.isRequired,
  catLoading: BoolPropType.isRequired,
  catError: BoolPropType,
  locale: StringPropType
};

const mapStateToProps = ({ user: { user, error }, categories }) => ({
  categories: categories.tree,
  catLoading: categories.loading,
  catError: categories.error,
  locale: user.locale,
  userError: error
});
const mapDispatchToProps = dispatch => ({
  getCategories: payload => dispatch(requestCategories(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(StepCompanyDetails);
