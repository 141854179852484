import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addServiceToCart, removeFromCart } from '../../store/reducers/cart/actions';
import {
  addServiceFavorite,
  serviceNotInterested,
  serviceUseFreeLead
} from '../../store/reducers/services/actions';
import { formatSubtitle, formatTitle, showSubtitle } from '../../util/cardHelpers';

import querystring from 'query-string';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import CardSkeleton from '../../components/CardSkeleton';
import FreeLeadModal from '../../components/FreeLeadModal';
import ServiceCard from '../../components/ServiceCard';
import ServiceFilter from '../../components/ServiceFilter';
import CheckedAlert from '../../components/alerts/CheckedAlert';
import FreeLeadAlert from '../../components/alerts/FreeLeadAlert';
import WaitingForInfoAlert from '../../components/alerts/WaitingForInfo';
import JobService from '../../lib/services/jobService';
import TraderService from '../../lib/services/traderService';
import { ROUTES } from '../../routes';
import { countCurrentIndex } from '../../util/helpers';
import { centsToEuro } from '../../util/priceCents';
const traderService = new TraderService();
const ButtonLoadMore = styled.button`
  visibility: hidden;
`;
const DivFlexContainer = styled.div`
  display: flex;
`;
const DivNoJobs = styled.div`
  margin: 1rem auto;
  background-color: #fff;
  font-size: 14pt;
  padding: 1.6rem;
  color: #32546d;
  font-family: Lato, sans-serif;
  border-radius: 4px;
  box-shadow: 1px 5px 5px #d7d7d7;
  text-align: center;
`;

const NoJobsSubtitle = styled.p`
  max-width: 400px;
  display: inline-flex;
  font-size: 10pt;
  text-align: justify;
`;

const ListDiv = styled.div`
  padding: 0 1rem;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;
const ListLoadingDiv = styled(ListDiv)`
  padding: 0 1.2rem;
  margin-top: ${props => (props.infiniteScroll ? '-1.2rem' : 'inherit')};
`;
const updateJob = (job, data) => {
  const newData = { ...data };
  for (let page of newData?.pages) {
    if (job && job.service_request_id) {
      const index = page?.data.findIndex(j => j.id === job.service_request_id);
      if (index !== -1) {
        page.data[index] = { ...page.data[index], ...job, id: job.service_request_id };
        break;
      }
    }
  }
  return newData;
};
const jobService = new JobService();
const Services = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const query = querystring.parse(location.search);

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const filters = useSelector(state => state.services.filters);
  const freeLeads = useSelector(state => state.user.free_leads_remaining ?? 0);
  const isCoupon = useSelector(state => state.user.isCoupon);
  const cartIsLoading = useSelector(state => state.cart.isLoading);

  const [freeLeadSrId, setFreeLeadSrId] = useState(null);

  const { code } = query;
  const metaTitle = intl.messages['jobspage.title'];
  const { data, fetchNextPage, isFetchingNextPage, isLoading, hasNextPage, isError } =
    useInfiniteQuery(
      ['services', filters],
      async ({ pageParam = 1 }) => {
        let { pagination, ...updatedFilters } = filters;

        updatedFilters.pagination = { page: pageParam };

        const {
          items,
          pagination: { total }
        } = await jobService.getFilteredList(updatedFilters);
        return {
          data: items,
          pageParam: pageParam + 1,
          total: Number.isNaN(total) ? items.length : total
        };
      },
      {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        // cacheTime: 0,
        retry: 0,
        getNextPageParam: (lastPage, allPages) => {
          const currentTotal = countCurrentIndex(allPages);
          return currentTotal < lastPage.total ? lastPage.pageParam : undefined;
        }
      }
    );
  const isEmpty = useMemo(() => (data ? countCurrentIndex(data?.pages) === 0 : true), [data]);

  const [loadMoreButtonRef, inView] = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    const updateTeamleaderCode = async () => {
      if (code) {
        await traderService.update({ teamleader_code: code });
      }
    };
    updateTeamleaderCode();
  }, [code]);

  const useFreeLeadHandler = payload => {
    applyFreeLead(payload);
    history.push(ROUTES.PURCHASED_ID.handler(payload));
  };
  const updateCache = job => {
    const cachedData = queryClient.getQueryData(['services', filters]);
    const newCache = updateJob(job, cachedData);
    queryClient.setQueryData(['services', filters], newCache);
    queryClient.invalidateQueries('services', { refetchInactive: true });
  };

  const addFavorite = useCallback(
    async payload => {
      const job = await dispatch(addServiceFavorite(payload));
      updateCache(job);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const notInterested = useCallback(
    async payload => {
      const job = await dispatch(serviceNotInterested(payload));
      updateCache(job);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addToCart = useCallback(async payload => {
    const job = await dispatch(addServiceToCart(payload));
    updateCache(job);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeJobFromCart = useCallback(async payload => {
    const job = await dispatch(removeFromCart(payload));
    updateCache(job);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFreeLead = useCallback(
    async payload => {
      await dispatch(serviceUseFreeLead(payload));
    },
    [dispatch]
  );

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <FreeLeadModal
        open={freeLeadSrId !== null}
        closeFreeLeadModal={() => setFreeLeadSrId(null)}
        freeLeadsRemaining={freeLeads}
        onBuyItem={() => applyFreeLead(freeLeadSrId)}
        id={freeLeadSrId}
        isCoupon={isCoupon}
      />
      <ServiceFilter
        hasFreelead={freeLeads}
        total={data?.pages[0]?.total ?? 0}
        isLoading={isLoading}
      />

      <FreeLeadAlert />
      <CheckedAlert />
      <WaitingForInfoAlert />

      {data?.pages?.map((page, index) => (
        <ListDiv key={index}>
          {page?.data.map(e => (
            <React.Fragment key={e.id}>
              <ServiceCard
                {...e}
                key={e.id}
                id={e.id}
                location={e.postcode}
                serviceRequestId={e.service_request_id ? e.service_request_id : null}
                files={e.photos ? e.photos.length : 0}
                price={centsToEuro(e.price_cents)}
                originalPrice={centsToEuro(e.original_price)}
                title={formatTitle(e, user.locale)}
                subtitle={formatSubtitle(e, user.locale)}
                showSubtitle={showSubtitle(e)}
                isFavorite={e.sr_status === 'favorite'}
                isNotInterested={e.sr_status === 'trashed'}
                onFavorite={addFavorite}
                onNotInterested={notInterested}
                town={e.townNames && (user.locale === 'fr' ? e.townNames.fr : e.townNames.nl)}
                isMobile={isMobile}
                addToCart={() => addToCart(e)}
                removeFromCart={removeJobFromCart}
                cartId={e.cart_id}
                isNew={!e.more_than_48_hours}
                isLoading={isLoading}
                cartIsLoading={cartIsLoading}
                isOnSale={e.is_on_sale}
                description={e.description || e.additionalInfo}
                hasFreelead={freeLeads}
                applyFreeLead={useFreeLeadHandler}
                setFreeLeadSrId={id => setFreeLeadSrId(id)}
                page="jobs"
              />
            </React.Fragment>
          ))}
        </ListDiv>
      ))}
      {(isFetchingNextPage || isLoading) && !isError && (
        <ListLoadingDiv>
          <CardSkeleton key="0" />
          <CardSkeleton key="1" />
          <CardSkeleton key="2" />
          <CardSkeleton key="3" />
        </ListLoadingDiv>
      )}
      <ButtonLoadMore
        ref={loadMoreButtonRef}
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        Load More
      </ButtonLoadMore>

      {isError && !isLoading && (
        <DivFlexContainer>
          <DivNoJobs>
            <FormattedMessage tagName="p" id="general.error.message" />
          </DivNoJobs>
        </DivFlexContainer>
      )}
      {isEmpty && !isLoading && !isError && (
        <DivFlexContainer>
          <DivNoJobs>
            {(filters?.service_view === 'simplified' || filters?.service_view === 'unseen') && (
              <>
                <FormattedMessage tagName="p" id="servicespage.no_jobs_found.title.overview" />
                <NoJobsSubtitle>
                  <FormattedMessage
                    tagName="span"
                    id="servicespage.no_jobs_found.subtitle.overview"
                  />
                </NoJobsSubtitle>
              </>
            )}
            {filters?.service_view === 'trash' && (
              <>
                <FormattedMessage tagName="p" id="servicespage.no_jobs_found.title.trash" />
                <NoJobsSubtitle>
                  <FormattedMessage tagName="span" id="servicespage.no_jobs_found.subtitle.trash" />
                </NoJobsSubtitle>
              </>
            )}
            {filters?.service_view === 'favorites' && (
              <>
                <FormattedMessage tagName="p" id="servicespage.no_jobs_found.title.favorites" />
                <NoJobsSubtitle>
                  <FormattedMessage
                    tagName="span"
                    id="servicespage.no_jobs_found.subtitle.favorites"
                  />
                </NoJobsSubtitle>
              </>
            )}
          </DivNoJobs>
        </DivFlexContainer>
      )}
    </>
  );
};
export default Services;
