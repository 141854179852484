import styled, { css } from 'styled-components';
import theme from '../../../../theme';

interface IWeekDayButtonProps {
  selected: boolean;
}

export const WeekDayButton = styled.button<IWeekDayButtonProps>`
  border-radius: 1.25rem;
  border: 0;
  background-color: '#cecece';
  color: ${theme.colors.secondary.base};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;

  height: 1.925rem;
  width: 1.925rem;

  transition: all 0.2s ease;

  &:hover {
    background-color: ${theme.colors.secondary.base};
    color: white;
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${theme.colors.secondary.base};
      color: white;
    `}

  @media screen and (min-width: 767px) {
    height: 2.5rem;
    width: 2.5rem;
  }
`;
