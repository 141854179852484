import { FormattedMessage } from 'react-intl'
import theme from '../../../../theme'
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading'
import { Button } from '@mui/material';
import JaimyBox from '../../../../theme/components/layout/Box';


const SatisfiedTraderWithSubscription = () => {

  const handleReviewButtonClick = () => {
    window.open('https://g.page/r/CWnGajXHCBApEBM/review', '_blank')
  }

  return (

    <JaimyFlex gap={"1rem"} flexDirection="column">
      <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
        <FormattedMessage id="communication.form.satisfied.sub.title" />
      </JaimyHeading.H1>
      <JaimyHeading.H2 color={theme.colors.secondary.base}>
        <FormattedMessage id="communication.form.satisfied.sub.subTitle" />
      </JaimyHeading.H2>
      <JaimyHeading.H4 color={theme.colors.secondary.base} >
        <FormattedMessage id="communication.form.satisfied.sub.google.review.title" />
      </JaimyHeading.H4>
      <JaimyHeading.H4 color={theme.colors.secondary.base} >
        <FormattedMessage id="communication.form.satisfied.sub.google.review.subtitle" />
      </JaimyHeading.H4>
      <JaimyBox>
        <Button variant="contained" onClick={handleReviewButtonClick} style={{ textTransform: 'uppercase', backgroundColor: theme.colors.blue.lightBlue }} >
          <FormattedMessage
            tagName="span"
            id="communication.form.satisfied.sub.google.review.cta"
            defaultMessage="Post in google review"
          />
        </Button>
      </JaimyBox>
    </JaimyFlex >
  )
}

export default SatisfiedTraderWithSubscription
