export const GET_ALL_REQUEST = 'GET_ALL_REQUEST';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_ERROR = 'GET_ALL_ERROR';

export const GET_ALL_WARNINGS_REQUEST = 'GET_ALL_WARNINGS_REQUEST';
export const GET_ALL_WARNINGS_SUCCESS = 'GET_ALL_WARNINGS_SUCCESS';
export const GET_ALL_WARNINGS_ERROR = 'GET_ALL_WARNINGS_ERROR';

export const GET_ALL_OF_CALENDAR_REQUEST = 'GET_ALL_OF_CALENDAR_REQUEST';
export const GET_ALL_OF_CALENDAR_SUCCESS = 'GET_ALL_OF_CALENDAR_SUCCESS';
export const GET_ALL_OF_CALENDAR_ERROR = 'GET_ALL_OF_CALENDAR_ERROR';

export const GET_ALL_OF_PREBOOK_REQUEST = 'GET_ALL_OF_PREBOOK_REQUEST';
export const GET_ALL_OF_PREBOOK_SUCCESS = 'GET_ALL_OF_PREBOOK_SUCCESS';
export const GET_ALL_OF_PREBOOK_ERROR = 'GET_ALL_OF_PREBOOK_ERROR';

export const CREATE_PLANNING_REQUEST = 'CREATE_PLANNING_REQUEST';
export const CREATE_PLANNING_SUCCESS = 'CREATE_PLANNING_SUCCESS';
export const CREATE_PLANNING_ERROR = 'CREATE_PLANNING_ERROR';

export const UPDATE_PLANNING_REQUEST = 'UPDATE_PLANNING_REQUEST';
export const UPDATE_PLANNING_SUCCESS = 'UPDATE_PLANNING_SUCCESS';
export const UPDATE_PLANNING_ERROR = 'UPDATE_PLANNING_ERROR';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_ERROR = 'DELETE_ERROR';