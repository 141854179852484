import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as BasketIcon } from '../../assets/images/basket-icon.svg';
import CardContent from './CardContent';
import CardHeader from './CardHeader';

const DivCard = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #d8dae0;
  border-left: 1px solid #d8dae0;
  border-right: 1px solid #d8dae0;
  border-radius: 8px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-basis: 48%;
  }
`;

const DivAddedBy = styled.div`
  position: relative;
  font-family: 'Lato', sans-serif;
  color: #fff;
  background-color: #c30144;
  padding: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  > svg {
    margin-right: 0.5rem;
  }
`;

const ServiceCard = ({
  id,
  serviceRequestId,
  isMobile,
  onFavorite,
  onNotInterested,
  onArchive,
  onUnarchive,
  addedBy,
  title,
  subtitle,
  price,
  originalPrice,
  location,
  files,
  description,
  isFavorite,
  isNotInterested,
  addToCart,
  removeFromCart,
  cartId,
  cartIsLoading,
  showSubtitle,
  town,
  customer,
  isOnSale,
  isNew,
  hasFreelead,
  isLoading,
  setFreeLeadSrId,
  page,
  status,
  isLoadingActions
}) => {
  const handleAddToCart = e => {
    if (cartId) {
      removeFromCart(cartId);
    } else {
      addToCart(e);
    }
  };

  return (
    <DivCard>
      <CardHeader
        id={id}
        isNew={isNew}
        isOnSale={isOnSale}
        hasFreelead={hasFreelead}
        page={page}
        status={status}
        title={title}
        subtitle={subtitle}
        showSubtitle={showSubtitle}
        isNotInterested={isNotInterested}
        isFavorite={isFavorite}
        onFavorite={onFavorite}
        onNotInterested={onNotInterested}
        onArchive={onArchive}
        onUnarchive={onUnarchive}
        isPurchased={Boolean(customer && customer.name)}
        isLoadingActions={isLoadingActions}
      />
      {addedBy && (
        <DivAddedBy>
          <BasketIcon fill="white" />
          <FormattedMessage
            tagName="span"
            id="servicecardlist.added_to_cart_by"
            values={{ user: addedBy }}
          />
        </DivAddedBy>
      )}
      <CardContent
        id={id}
        serviceRequestId={serviceRequestId}
        cartIsLoading={cartIsLoading}
        setFreeLeadSrId={setFreeLeadSrId}
        isLoading={isLoading}
        cartId={cartId}
        handleAddToCart={handleAddToCart}
        isMobile={isMobile}
        hasFreelead={hasFreelead}
        price={price}
        originalPrice={originalPrice}
        isOnSale={isOnSale}
        location={location}
        files={files}
        town={town}
        description={description}
        hasActions={page === 'jobs'}
        customer={customer}
        isPurchased={Boolean(customer && customer.name)}
        page={page}
      />
    </DivCard>
  );
};

ServiceCard.defaultProps = {
  id: '',
  serviceRequestId: '',
  isMobile: false,
  onArchive: null,
  onUnarchive: null,
  onFavorite: null,
  onNotInterested: null,
  title: '',
  subtitle: '',
  showSubtitle: true,
  price: 0,
  originalPrice: 0,
  location: '',
  files: 0,
  description: '',
  isFavorite: false,
  isNotInterested: false,
  addToCart: null,
  removeFromCart: null,
  cartId: 0,
  cartIsLoading: false,
  addedBy: '',
  town: '',
  customer: {
    name: '',
    phone: '',
    email: '',
    postcode: '',
    street: '',
    number: ''
  },
  isOnSale: false,
  isNew: false,
  hasFreelead: 0,
  isLoading: false,
  setFreeLeadSrId: null,
  page: 'jobs',
  status: null,
  isLoadingActions: false
};
ServiceCard.propTypes = {
  id: PropTypes.number,
  serviceRequestId: PropTypes.number,
  isMobile: PropTypes.bool,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  onFavorite: PropTypes.func,
  onNotInterested: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
  price: PropTypes.number,
  originalPrice: PropTypes.number,
  location: PropTypes.string,
  files: PropTypes.number,
  description: PropTypes.string,
  isFavorite: PropTypes.bool,
  isNotInterested: PropTypes.bool,
  addToCart: PropTypes.func,
  removeFromCart: PropTypes.func,
  cartId: PropTypes.number,
  cartIsLoading: PropTypes.bool,
  addedBy: PropTypes.string,
  town: PropTypes.string,
  customer: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    postcode: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string
  }),
  isOnSale: PropTypes.bool,
  isNew: PropTypes.bool,
  hasFreelead: PropTypes.number,
  isLoading: PropTypes.bool,
  setFreeLeadSrId: PropTypes.func,
  page: PropTypes.string,
  status: PropTypes.string,
  isLoadingActions: PropTypes.bool
};

export default ServiceCard;
