import { Dialog } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EVerifiedStatus } from '../../constants/verifiedStatus';
import { ROUTES } from '../../routes';
import theme from '../../theme';
import JaimyText from '../../theme/components/Typography/Text';
import JaimyFlex from '../../theme/components/layout/Flex';
import ValidatePermission from '../subscription/ValidatePermission';
import { ComponentIsVisible } from '../utils/isVisible';

const ButtonBuy = styled.button`
  background-color: #fff;
  border: 2px solid #c30144;
  border-radius: 5px;
  color: #c30144;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin: 0.6rem auto;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  justify-content: center;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  width: ${props => (props.isMobile ? '100%' : 'fit-content')};
  &:hover {
    color: #fff;
    background-color: #c30144;
  }
  &:disabled {
    background-color: #d8dae0;
    border-color: #d8dae0;
    color: #867a7a;
  }
`;
const ButtonInvoice = styled.button`
  background-color: #c30144;
  border: 1px solid #c30b30;
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin: 0.6rem auto;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  column-gap: 0.5rem;
  justify-content: center;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  width: ${props => (props.isMobile ? '100%' : 'fit-content')};
  &:hover {
    background-color: #c30144e0;
  }
  &:disabled {
    background-color: #d8dae0;
    border-color: #d8dae0;
    color: #867a7a;
  }
`;
const DivDialogHeader = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile && 'column-reverse'};
  margin: 1rem;
  button {
    flex-direction: ${props => props.isMobile && 'column-reverse'};
    margin: ${props => (props.isMobile ? '0 0 0 auto' : 'auto 0')};
    padding: ${props => props.isMobile && '0'};
  }
`;
const DivDialogButtons = styled.div`
  display: flex;
  margin: ${props => (props.isMobile ? '0 1rem 1rem 1rem' : '1rem .5rem')};
  justify-content: space-evenly;
  flex-direction: ${props => props.isMobile && 'column-reverse'};
`;
const DivDialogTitle = styled.div`
  margin: ${props => (props.isMobile ? '0' : '1rem auto')};
  font-family: 'BelfiusNormal', sans-serif;
  font-weight: bold;
  font-size: 26px;
`;

const BlockedMessage = styled.small`
  font-style: italic;
  display: block;
  text-align: center;
  color: ${theme.colors.primary.base};
  width: fit-content;
  grid-column: 1 / 3;
  margin: 0.25rem auto 0;
`;

const LinkDialog = styled(Link)`
  color: ${theme.colors.primary.base};
`;

const CheckoutDialog = ({
  open,
  handleClose,
  payWithCard,
  invoiceLater,
  isLoading,
  userVerifiedStatus
}) => {
  const isBlockedInvoicePayment = useMemo(() => {
    const isRestrictedLatePayment = () => {
      if (userVerifiedStatus === EVerifiedStatus.RestrictedLatePayment45) {
        return true;
      }
      if (userVerifiedStatus === EVerifiedStatus.RestrictedLatePayment60) {
        return true;
      } else return false;
    };

    return isRestrictedLatePayment();
  }, [userVerifiedStatus]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DivDialogHeader isMobile={isMobile}>
        <DivDialogTitle isMobile={isMobile}>
          <FormattedMessage id="checkout.dialog.title" />
        </DivDialogTitle>
        <IconButton edge="start" aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DivDialogHeader>
      <DivDialogButtons isMobile={isMobile}>
        <ValidatePermission permission="show_invoice_later">
          <ButtonInvoice
            isMobile={isMobile}
            disabled={isLoading || isBlockedInvoicePayment}
            onClick={invoiceLater}
          >
            <ReceiptIcon style={{ marginRight: '.5rem' }} />
            <JaimyFlex flexDirection="column">
              <FormattedMessage id="checkout.dialog.button.invoice" />
              <ComponentIsVisible when={isBlockedInvoicePayment}>
                <BlockedMessage>
                  <FormattedMessage id="restriction.late45.unavailable" />
                </BlockedMessage>
              </ComponentIsVisible>
            </JaimyFlex>
          </ButtonInvoice>
        </ValidatePermission>

        <ButtonBuy isMobile={isMobile} disabled={isLoading} onClick={payWithCard}>
          <CreditCardIcon style={{ marginRight: '.5rem' }} />
          <FormattedMessage id="checkout.dialog.button.online" />
        </ButtonBuy>
      </DivDialogButtons>

      <ComponentIsVisible when={isBlockedInvoicePayment}>
        <JaimyText
          padding={isMobile ? '.5rem 2rem 1.5rem' : '.5rem 1rem 1.5rem 3rem'}
          fontSize={theme.typography.fontSizes.sm}
          fontStyle="italic"
        >
          <FormattedMessage id="restriction.late45.message" />
          <LinkDialog to={ROUTES.INVOICES.handler()}>
            <FormattedMessage id="restriction.late45.invoicesLink" />
          </LinkDialog>
        </JaimyText>
      </ComponentIsVisible>
    </Dialog>
  );
};
CheckoutDialog.defaultProps = {
  open: false,
  handleClose: null,
  payWithCard: null,
  invoiceLater: null,
  isLoading: false,
  userVerifiedStatus: ''
};
CheckoutDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  payWithCard: PropTypes.func,
  invoiceLater: PropTypes.func,
  isLoading: PropTypes.bool,
  userVerifiedStatus: PropTypes.string
};
export default CheckoutDialog;
