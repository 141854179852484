import styled from 'styled-components';

export const DivHeader = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d8dae0;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

export const SectionTopHeader = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 1.2rem;
  }
`;

// export const H1ProfileTitle = styled.h1`
//   color: #c30144;
//   font-family: 'BelfiusNormal', sans-serif;
//   font-size: 28px;
//   line-height: 28px;
//   padding: 0 1rem;
//   text-align: center;
//   margin: 0 auto 1rem;

//   @media (min-width: 1200px) {
//     max-width: 550px;
//     font-size: 32px;
//     line-height: 32px;
//   }
// `;

export const H4Title = styled.h4`
  color: #637780;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  padding: 0 1rem;
`;

export const DivTitleWrapper = styled.div`
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.2rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    h4,
    h1 {
      flex-basis: 100%;
    }

    h4,
    h2,
    h1 {
      padding: 0;
    }
  }
`;

export const H1ProfileTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: center;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 350px;
    font-size: 32px;
    line-height: 32px;
  }
`;

export const H2Subtitle = styled.h2`
  color: ${props => (props.isFreeLead ? '#86bcd3' : '#c30144')};
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  line-height: 21px;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;
