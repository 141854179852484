export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_ERROR = 'GET_APPOINTMENTS_ERROR';

export const GET_ONLY_JAIMY_APPOINTMENTS_REQUEST = 'GET_ONLY_JAIMY_APPOINTMENTS_REQUEST';
export const GET_ONLY_JAIMY_APPOINTMENTS_SUCCESS = 'GET_ONLY_JAIMY_APPOINTMENTS_SUCCESS';
export const GET_ONLY_JAIMY_APPOINTMENTS_ERROR = 'GET_ONLY_JAIMY_APPOINTMENTS_ERROR';

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR';

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_ERROR = 'UPDATE_APPOINTMENT_ERROR';

export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_ERROR = 'DELETE_APPOINTMENT_ERROR';

export const CREATE_MANAGE_CALENDAR = 'CREATE_MANAGE_CALENDAR';
export const CREATE_MANAGE_CALENDAR_SUCCESS = 'CREATE_MANAGE_CALENDAR_SUCCESS';
export const CREATE_MANAGE_CALENDAR_ERROR = 'CREATE_MANAGE_CALENDAR_ERROR';

export const UPDATE_MANAGE_CALENDAR = 'UPDATE_MANAGE_CALENDAR';
export const UPDATE_MANAGE_CALENDAR_SUCCESS = 'UPDATE_MANAGE_CALENDAR_SUCCESS';
export const UPDATE_MANAGE_CALENDAR_ERROR = 'UPDATE_MANAGE_CALENDAR_ERROR';

export const SET_FIRST_DAY_OF_SELECTED_MONTH = 'SET_FIRST_DAY_OF_SELECTED_MONTH';
