import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

const DivSkeleton = styled.div`
  background-color: #fff;
  margin: 1rem 0;

  @media (min-width: 768px) {
    flex-basis: 48%;
  }
`;
const DivHeader = styled.div`
  height: 112px;
  background-color: #d5d2d2;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  animation: ${loading} 1.5s ease-in-out infinite;
`;
const DivItem = styled.div`
  margin-top: 0.8em;
  border-radius: 4px;
  margin-bottom: 0.8em;
  width: ${props => `${props.width}%`};
  height: 1.2em;
  background-color: #d5d2d2;
  animation: ${loading} 1.5s ease-in-out infinite;
  margin-left: 1rem;
`;
const DivDescription = styled.div``;
const DivContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;
const DivBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 233px;
`;
const DivAction = styled.div`
  animation: ${loading} 1.5s ease-in-out infinite;
  border-radius: 4px;
  background-color: #d5d2d2;
  margin-top: 0.8em;
  width: ${props => `${props.width}%`};
  height: 50px;
  padding: 1rem;
`;

const CardSkeleton = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <DivSkeleton>
    <DivHeader />
    <DivBody>
      <DivDescription>
        <DivItem width={80} />
        <DivItem width={80} />
        <DivItem width={60} />
      </DivDescription>
      <DivContent>
        <DivAction width={30} />
        <DivAction width={68} />
      </DivContent>
    </DivBody>
  </DivSkeleton>
);

export default CardSkeleton;
