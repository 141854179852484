export const arraysEqual = (_arr1, _arr2) => {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
    return false;
  }

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const countCurrentIndex = data => {
  if (data) {
    const currentTotal = data.reduce((accum, page) => {
      accum += page.data.length;
      return accum;
    }, 0);
    return currentTotal;
  }
  return 0;
};
