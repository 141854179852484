import WarningOutlined from '@material-ui/icons/WarningOutlined';
import { FormattedMessage } from 'react-intl';
import { EVerifiedStatus } from '../../../../constants/verifiedStatus';
import { ROUTES } from '../../../../routes';
import theme from '../../../../theme';
import JaimyButton from '../../../../theme/components/Button';
import JaimyText from '../../../../theme/components/Typography/Text';
import JaimyGrid from '../../../../theme/components/layout/Grid';
import { IUser } from '../../../../types/User';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { PaymentLink } from './styles';

interface IBannerRestrictionPaymentProps {
  user: IUser;
}

export function BannerRestrictionPayment({ user }: IBannerRestrictionPaymentProps) {
  return (
    <ComponentIsVisible
      when={
        user.verified_status === EVerifiedStatus.RestrictedLatePayment45 ||
        user.verified_status === EVerifiedStatus.RestrictedLatePayment60
      }
    >
      <JaimyGrid backgroundColor={theme.colors.primary.dark}>
        <JaimyGrid
          alignItems="center"
          columns="3rem 1fr 12rem"
          padding=".75rem"
          margin="0 auto"
          columnGap=".5rem"
          maxWidth="1200px"
        >
          <WarningOutlined htmlColor={theme.colors.white} fontSize="large" />

          <JaimyText color={theme.colors.white}>
            <ComponentIsVisible
              when={user.verified_status === EVerifiedStatus.RestrictedLatePayment45}
            >
              <FormattedMessage id="mainheader.banner.latePayment45" />
            </ComponentIsVisible>
            <ComponentIsVisible
              when={user.verified_status === EVerifiedStatus.RestrictedLatePayment60}
            >
              <FormattedMessage id="mainheader.banner.latePayment60" />
            </ComponentIsVisible>
          </JaimyText>

          <PaymentLink to={ROUTES.INVOICES.handler()}>
            <JaimyButton.Base backgroundColor={theme.colors.secondary.base}>
              <FormattedMessage id="mainheader.banner.paymentButton" />
            </JaimyButton.Base>
          </PaymentLink>
        </JaimyGrid>
      </JaimyGrid>
    </ComponentIsVisible>
  );
}
