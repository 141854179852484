import { format, startOfToday } from 'date-fns';
import parse from 'date-fns/parse';
import { AnyAction } from 'redux';
import { IEvent, IJaimyAppointment } from '../../../types/Calendar';
import * as actionTypes from './actionTypes';

interface ICalendarState {
  firstDayOfSelectedMonth: Date;

  appointments: IEvent[];
  appointmentsLoading: boolean;
  appointmentsError: null;

  jaimyAppointments: IJaimyAppointment[];
  jaimyAppointmentsLoading: false;
  jaimyAppointmentsError: null;

  createAppointmentLoading: boolean;
  createAppointmentError: null;

  updateAppointmentLoading: boolean;
  updateAppointmentError: null;

  deleteAppointmentLoading: boolean;
  deleteAppointmentError: null;

  createManageCalendarLoading: boolean;
  createManageCalendarError: null;

  updateManageCalendarLoading: boolean;
  updateManageCalendarError: null;
}

const today = startOfToday();

const initialState: ICalendarState = {
  firstDayOfSelectedMonth: parse(format(today, 'MMM-yyyy'), 'MMM-yyyy', new Date()),

  appointments: [],
  appointmentsLoading: false,
  appointmentsError: null,

  jaimyAppointments: [],
  jaimyAppointmentsLoading: false,
  jaimyAppointmentsError: null,

  createAppointmentLoading: false,
  createAppointmentError: null,

  updateAppointmentLoading: false,
  updateAppointmentError: null,

  deleteAppointmentLoading: false,
  deleteAppointmentError: null,

  createManageCalendarLoading: false,
  createManageCalendarError: null,

  updateManageCalendarLoading: false,
  updateManageCalendarError: null
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_FIRST_DAY_OF_SELECTED_MONTH:
      return {
        ...state,
        firstDayOfSelectedMonth: parse(action.payload, 'MMM-yyyy', new Date())
      };

    case actionTypes.GET_APPOINTMENTS:
      return { ...state, appointmentsLoading: true };

    case actionTypes.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
        appointmentsLoading: false,
        appointmentsError: false
      };
    case actionTypes.GET_APPOINTMENTS_ERROR:
      return {
        ...state,
        appointmentsLoading: false,
        appointmentsError: action.error
      };

    case actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_REQUEST:
      return {
        ...state,
        jaimyAppointmentsLoading: true
      };
    case actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        jaimyAppointments: action.payload,
        jaimyAppointmentsLoading: false,
        jaimyAppointmentsError: false
      };
    case actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_ERROR:
      return {
        ...state,
        jaimyAppointmentsLoading: false,
        jaimyAppointmentsError: action.error
      };

    case actionTypes.CREATE_APPOINTMENT:
      return { ...state, createAppointmentLoading: true };

    case actionTypes.CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
        createAppointmentLoading: false,
        createAppointmentError: false
      };
    case actionTypes.CREATE_APPOINTMENT_ERROR:
      return {
        ...state,
        createAppointmentLoading: false,
        createAppointmentError: action.error
      };

    case actionTypes.UPDATE_APPOINTMENT:
      return { ...state, updateAppointmentLoading: true };

    case actionTypes.UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        updateAppointmentLoading: false,
        updateAppointmentError: false
      };
    case actionTypes.UPDATE_APPOINTMENT_ERROR:
      return {
        ...state,
        updateAppointmentLoading: false,
        updateAppointmentError: action.error
      };

    case actionTypes.DELETE_APPOINTMENT:
      return { ...state, deleteAppointmentLoading: true };

    case actionTypes.DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        deleteAppointmentLoading: false,
        deleteAppointmentError: false
      };
    case actionTypes.DELETE_APPOINTMENT_ERROR:
      return {
        ...state,
        deleteAppointmentLoading: false,
        deleteAppointmentError: action.error
      };

    case actionTypes.CREATE_MANAGE_CALENDAR:
      return { ...state, createManageCalendarLoading: true };

    case actionTypes.CREATE_MANAGE_CALENDAR_SUCCESS:
      return {
        ...state,
        createManageCalendarLoading: false,
        createManageCalendarError: false
      };

    case actionTypes.CREATE_MANAGE_CALENDAR_ERROR:
      return {
        ...state,
        createManageCalendarLoading: false,
        createManageCalendarError: action.error
      };

    case actionTypes.UPDATE_MANAGE_CALENDAR:
      return { ...state, updateManageCalendarLoading: true };

    case actionTypes.UPDATE_MANAGE_CALENDAR_SUCCESS:
      return {
        ...state,
        updateManageCalendarLoading: false,
        updateManageCalendarError: false
      };
    case actionTypes.UPDATE_MANAGE_CALENDAR_ERROR:
      return {
        ...state,
        updateManageCalendarLoading: false,
        updateManageCalendarError: action.error
      };

    default:
      return state;
  }
};
