import querystring from 'query-string';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ButtonPrimary } from '../../../../components/common/Button';
import { ComponentIsVisible } from '../../../../components/utils/isVisible';
import TraderService from '../../../../lib/services/traderService';
import theme from '../../../../theme';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import JaimyText from '../../../../theme/components/Typography/Text';
import JaimyFlex from '../../../../theme/components/layout/Flex';

const traderService = new TraderService();

const SatisfiedTraderWithoutSubscription = ({ grade }: { grade: number }) => {
  const location = useLocation();
  const query = querystring.parse(location.search);

  const [showNextStep, setShowNextStep] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    if (showNextStep) return;

    const body = {
      token: query.token,
      grade: grade,
      reason: 'contact_trader_with_interest_in_subscription',
    };
    try {
      await traderService.postSrGradeByTrader(body);
      setShowNextStep(true);
    } catch (error) {
      console.log('error', error);
    }
  }, [grade, query.token, showNextStep]);

  return (
    <>
      <ComponentIsVisible when={!showNextStep}>
        <JaimyFlex gap={'1rem'} flexDirection="column">
          <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
            <FormattedMessage id="communication.form.satisfied.sub.title" />
          </JaimyHeading.H1>
          <JaimyHeading.H2 color={theme.colors.secondary.base}>
            <FormattedMessage id="communication.form.satisfied.sub.subTitle" />
          </JaimyHeading.H2>
        </JaimyFlex>
        <JaimyFlex flexDirection="column" alignItems="center" gap={'1rem'} margin="20px 0px">
          <JaimyHeading.H2
            color={theme.colors.secondary.base}
            fontWeight="bold"
            style={{ textDecoration: 'underline', fontStyle: 'italic' }}
          >
            <FormattedMessage id="communication.form.satisfied.noSub.did_you_know" />
          </JaimyHeading.H2>
          <JaimyText color={theme.colors.secondary.base} textAlign="start">
            <FormattedMessage id="communication.form.satisfied.noSub.sub_proposal" />
          </JaimyText>
          <ul>
            <JaimyText color={theme.colors.secondary.base}>
              <li>
                <FormattedMessage id="communication.form.satisfied.noSub.bullet_1" />
              </li>
            </JaimyText>
            <JaimyText color={theme.colors.secondary.base}>
              <li>
                <FormattedMessage id="communication.form.satisfied.noSub.bullet_2" />
              </li>
            </JaimyText>
            <JaimyText color={theme.colors.secondary.base}>
              <li>
                <FormattedMessage id="communication.form.satisfied.noSub.bullet_3" />
              </li>
            </JaimyText>
          </ul>
          <ButtonPrimary onClick={() => handleOnSubmit()}>
            <FormattedMessage
              tagName="span"
              id="communication.form.satisfied.noSub.interested"
              defaultMessage="Interested"
            />
          </ButtonPrimary>
        </JaimyFlex>
      </ComponentIsVisible>
      <ComponentIsVisible when={showNextStep}>
        <JaimyFlex gap={'1rem'} flexDirection="column">
          <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
            <FormattedMessage id="communication.form.satisfied.noSub.request_received" />
          </JaimyHeading.H1>
          <JaimyHeading.H2 color={theme.colors.secondary.base}>
            <FormattedMessage id="communication.form.satisfied.noSub.get_in_contact" />
          </JaimyHeading.H2>
        </JaimyFlex>
      </ComponentIsVisible>
    </>
  );
};

export default SatisfiedTraderWithoutSubscription;
