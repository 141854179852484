import { FuncPropType } from '../../util/PropTypeValues';
import JaimyTooltip from '../../components/Tooltip';
import React from 'react';
import { ReactComponent as RocketSVG } from '../../assets/images/rocket-icon.svg';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ButtonDarkRed } from '../../components/common/Button';

const DivIcon = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  margin: auto auto 2rem !important;
  border: 2.4px solid #405a6b;
  border-radius: 100%;
  > svg {
    transform: rotate(25deg);
  }
`;
const DivMargin = styled.div`
  margin: 2rem auto 0 !important;
`;

const StepComplete = ({ onClickContinue }) => {
  const intl = useIntl();
  return (
    <>
      <DivIcon>
        <RocketSVG />
      </DivIcon>
      <h3>{intl.messages['register.step.complete.awesome']}</h3>
      <p>{intl.messages['register.step.complete.subscription']}</p>
      <h3>{intl.messages['register.step.complete.started']}</h3>
      <p>
        {intl.messages['register.step.complete.leads_1']}
        <strong>{intl.messages['register.step.complete.leads_2']}</strong>
        {intl.messages['register.step.complete.leads_3']}
      </p>
      <DivMargin>
        <JaimyTooltip
          placement="top"
          title={
            <>
              <p>{intl.messages['register.step.complete.limit_1']}</p>
              <br />
              <p>{intl.messages['register.step.complete.limit_2']}</p>
            </>
          }
        >
          <span className="smallRed">{intl.messages['register.step.complete.limit']}</span>
        </JaimyTooltip>
      </DivMargin>
      <ButtonDarkRed data-cy="continue" onClick={onClickContinue} fullWidth>
        <span>{intl.messages['register.step.complete.use']}</span>
      </ButtonDarkRed>
    </>
  );
};

StepComplete.defaultProps = { onClickContinue: {} };
StepComplete.propTypes = { onClickContinue: FuncPropType };

export default StepComplete;
