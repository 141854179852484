import * as Sentry from '@sentry/react';

import React, { useEffect, useMemo, useState } from 'react';
import { orderParser, raisePageEvent } from '../../../logging/analytics';

import CircularProgress from '@material-ui/core/CircularProgress';
import querystring from 'query-string';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CupIcon } from '../../../assets/images/cup-icon.svg';
import { CardTitle } from '../../../components/ContactCard';
import { AnchorButton } from '../../../components/common/Link';
import useInterval from '../../../hooks/useInterval';
import { ROUTES } from '../../../routes';
import { requestPaymentStatus } from '../../../store/reducers/cart/actions';
import { requestJobById } from '../../../store/reducers/services/actions';
import { requestUser } from '../../../store/reducers/user/actions';
import { calculateJobPrice } from '../../../util/priceCents';

const DivSuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  figure {
    display: flex;
    flex-direction: column;

    svg {
      margin: 0 auto;
    }

    figcaption {
      color: #c30b30;
      font-size: 40px;
      line-height: 48px;
      margin-top: 2rem;
    }
  }

  p {
    color: #32546d;
    font-size: 18px;
    font-family: 'Lato', sans-serif;
    line-height: 28px;
    text-align: center;
  }

  > div {
    margin: 4rem auto;
  }
`;

const DivErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 5rem;
`;
const DivErrorBackLink = styled.div`
  margin-top: 2rem;
`;
const Success = () => (
  <DivSuccessWrapper>
    <figure>
      <CupIcon />
      <figcaption>
        <FormattedMessage tagName="span" id="checkout.success.title" />
      </figcaption>
    </figure>
    <p>
      <FormattedMessage tagName="span" id="checkout.success.subtitle" />
    </p>
    <CardTitle title={<FormattedMessage tagName="span" id="checkout.success.tip.title" />}>
      <FormattedMessage tagName="span" id="checkout.success.tip" />
    </CardTitle>
    <p>
      <FormattedMessage tagName="span" id="checkout.success.redirect" />
    </p>
  </DivSuccessWrapper>
);

const ErrorMsg = ({ status }) => (
  <DivErrorWrapper>
    <CardTitle title={<span>{status}</span>}>
      <FormattedMessage tagName="span" id="checkout.error.message" />
    </CardTitle>
    <DivErrorBackLink>
      <AnchorButton to={`${ROUTES.CART.handler()}`}>
        <FormattedMessage id="checkout.error.back" />
      </AnchorButton>
    </DivErrorBackLink>
  </DivErrorWrapper>
);

const DivLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 1rem;

  h2 {
    color: #32546d;
    margin-bottom: 2rem;
  }
`;

const Loading = () => (
  <DivLoadingWrapper>
    <h2>
      <FormattedMessage tagName="span" id="checkout.loading.message" />
    </h2>
    <CircularProgress color="inherit" />
  </DivLoadingWrapper>
);

const DivCheckoutWrapper = styled.div`
  padding: 1rem;
`;
const delay = 2500;
// 24 requests === 1 minute
const retries = 24;
const metaTitle = 'jaimy.be - checkout';
const Checkout = ({
  user,
  paymentStatus,
  paymentStatusLoading,
  paymentStatusError,

  requestPaymentStatus,
  requestJobById,
  requestUser
}) => {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const isSuccess = useMemo(() => paymentStatus === 'succeeded', [paymentStatus]);
  const isRunning = useMemo(
    () => (paymentStatus === 'pending' && count < retries ? true : null),
    [count, paymentStatus]
  );
  useInterval(
    () => {
      requestPaymentStatus();
      setCount(count + 1);
    },
    isRunning ? delay : null
  );
  //Mount
  useEffect(() => {
    raisePageEvent({ title: metaTitle });
    requestPaymentStatus();
    return () => {
      setCount(retries);
    };
  }, [requestPaymentStatus]);
  //Unmount
  useEffect(() => {
    if (isSuccess) {
      unmount('Order Completed');
    }
    if (paymentStatusError) {
      unmount('Order Cancelled');
    }
    if (count === retries) {
      unmount('Order Cancelled');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusError, isSuccess, isRunning]);

  const unmount = async name => {
    try {
      // .../cart/checkout?credits=100&discount_of=10&ids=[37921]&timestamp=1602248114795
      if (history.location.search) {
        const query = querystring.parse(history.location.search);
        let { credits, discount_of, ids, timestamp } = query;
        credits = parseInt(credits, 10) || 0;
        discount_of = parseInt(discount_of, 10) || 0;
        const idList = Array.isArray(ids) ? ids : [ids];
        const jobs = await Promise.all(idList.map(id => requestJobById({ id })));

        const total = jobs.reduce((accum, cur) => {
          if (cur && cur.price_cents) {
            return (accum += cur.price_cents);
          }
          return accum;
        }, 0);
        const { totalPaymentExclVat, totalPaymentInclVat } = calculateJobPrice(
          total,
          credits,
          discount_of
        );
        TagManager.dataLayer({
          dataLayer: {
            event: name,
            data: orderParser({
              products: jobs,
              totalPaymentInclVat,
              totalPaymentExclVat,
              discount: credits,
              order_id: parseInt(timestamp, 10)
            })
          },
          dataLayerName: 'ProDataLayer'
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      requestUser(user.id);
      history.push(ROUTES.PURCHASED.handler());
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivCheckoutWrapper>
        {isSuccess && <Success />}
        {paymentStatusLoading && <Loading />}
        {paymentStatusError && <ErrorMsg status={paymentStatus} />}
      </DivCheckoutWrapper>
    </>
  );
};

const mapStateToProps = ({
  user: { user },
  cart: { paymentStatus, paymentStatusLoading, paymentStatusError }
}) => ({
  user,
  paymentStatus,
  paymentStatusLoading,
  paymentStatusError
});
const mapDispatchToProps = {
  requestPaymentStatus,
  requestJobById,
  requestUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
