import { FuncPropType } from '../../util/PropTypeValues';
import React from 'react';
import { ReactComponent as CheckSVG } from '../../assets/images/check-icon.svg';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ButtonDarkRed } from '../../components/common/Button';

const DivIcon = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  margin: auto auto 2rem !important;
  border: 2.4px solid #405a6b;
  border-radius: 100%;
  position: relative;

  svg {
    width: 50px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #405a6b;
  }
`;

const StepVatValidated = ({ onClickContinue }) => {
  const intl = useIntl();

  return (
    <>
      <DivIcon>
        <CheckSVG />
      </DivIcon>
      <h3 style={{ marginBottom: 10 }}>{intl.messages['register.step.password.title']}</h3>
      <p>{intl.messages[`register.step.vatValidated.p1`]}</p>
      <p>{intl.messages[`register.step.vatValidated.p2`]}</p>
      <p>{intl.messages[`register.step.vatValidated.p3`]}</p>
      <ButtonDarkRed data-cy="continue" onClick={onClickContinue} fullWidth>
        <span>{intl.messages[`register.step.vatValidated.next`]}</span>
      </ButtonDarkRed>
    </>
  );
};

StepVatValidated.defaultProps = { onClickContinue: {} };
StepVatValidated.propTypes = { onClickContinue: FuncPropType };

export default StepVatValidated;
