import { AnchorButton } from '../components/common/Link';
import { CardTitle } from '../components/ContactCard';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ROUTES } from '../routes';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { raisePageEvent } from '../logging/analytics';

const DivErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 1rem;
  padding-top: 5rem;
`;
const DivErrorBackLink = styled.div`
  margin: 2rem auto;
`;

export const Error = () => {
  const metaTitle = 'jaimy.be - error';
  useEffect(() => {
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <DivErrorWrapper>
        <CardTitle title={<span>Error</span>}>
          <FormattedMessage id="general.error.message" />
        </CardTitle>
        <DivErrorBackLink>
          <AnchorButton to={`${ROUTES.JOBS.handler()}`}>
            <FormattedMessage id="servicespage.back_to_overview" />
          </AnchorButton>
        </DivErrorBackLink>
      </DivErrorWrapper>
    </>
  );
};

export default Error;
