import { FormattedMessage } from 'react-intl';
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/person-icon.svg';
import { ROUTES } from '../../../routes';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import theme from '../../../theme';
import JaimyText from '../../../theme/components/Typography/Text';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyButton from '../../../theme/components/Button';
import JaimyLink from '../../../theme/components/Typography/Link';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export type ICustomer = {
  name: string,
  email: string,
  phone: string
}

interface ICardContentPurchasedProps {
  compensated: boolean;
  id?: string;
  serviceRequestId: string;
  location: string;
  customer: ICustomer;
  town: string;
}

const CustomerItems = (location: string, town: string, name: string, email: string, phone: string, width: number, compensated: boolean) => (
  <JaimyBox padding="10px">
    {!compensated &&
      <JaimyFlex
        padding="16px 0px 0px 16px"
        alignItems="center"
        flexDirection={width > 768 ? 'row' : 'column'}
        gap={width <= 768 ? '10px' : '0px'}
      >
        <JaimyFlex alignItems="center">
          <PersonIcon height={12} fill="#ACB7BB" />
          <JaimyFlex flexDirection="column" marginLeft="7px">
            <JaimyHeading.H6 color={theme.colors.primary.base}>
              <FormattedMessage tagName="span" id="servicecardlist.card.name" />
            </JaimyHeading.H6>
            <JaimyText>{name}</JaimyText>
          </JaimyFlex>
        </JaimyFlex>
        <JaimyFlex alignItems="center">
          <MailIcon height={12} fill="#ACB7BB" />
          <JaimyFlex flexDirection="column" marginLeft="7px">
            <JaimyHeading.H6 color={theme.colors.primary.base}>
              <FormattedMessage tagName="span" id="servicecardlist.card.email" />
            </JaimyHeading.H6>
            <JaimyText>{email}</JaimyText>
          </JaimyFlex>
        </JaimyFlex>
      </JaimyFlex>
    }
    <JaimyFlex
      padding="16px 0px 0px 16px"
      alignItems="center"
      flexDirection={width > 768 ? 'row' : 'column'}
      gap={width <= 768 ? '10px' : "0px"}
    >
      <JaimyFlex alignItems="center">
        <LocationIcon />
        <JaimyFlex flexDirection="column" marginLeft="7px">
          <JaimyHeading.H6 color={theme.colors.primary.base}>
            <FormattedMessage tagName="span" id="servicecardlist.card.location" />
          </JaimyHeading.H6>
          <JaimyText>{`${location} - ${town}`}</JaimyText>
        </JaimyFlex>
      </JaimyFlex>
      {!compensated ?
        <JaimyFlex alignItems="center">
          <PhoneIcon height={12} fill="#ACB7BB" />
          <JaimyFlex flexDirection="column" marginLeft="7px">
            <JaimyHeading.H6 color={theme.colors.primary.base}>
              <FormattedMessage tagName="span" id="servicecardlist.card.phone" />
            </JaimyHeading.H6>
            <JaimyText> {phone}</JaimyText>
          </JaimyFlex>
        </JaimyFlex>
        :
        <JaimyFlex>
          <JaimyText fontStyle='italic' color={theme.colors.gray}>
            <FormattedMessage tagName="span" id="servicecardlist.card.compensated.text" />
          </JaimyText>
        </JaimyFlex>}
    </JaimyFlex>
  </JaimyBox>
);

const CardContent = ({ serviceRequestId, location, customer, town, compensated }: ICardContentPurchasedProps) => {
  const { width } = useWindowDimensions();
  return (
    <JaimyBox>
      {CustomerItems(location, town, customer.name, customer.email, customer.phone, width, compensated)}
      <JaimyBox padding="20px" marginLeft="50%">
        <JaimyLink
          to={`${ROUTES.PURCHASED_ID.handler(serviceRequestId)}`}
          color={theme.colors.secondary.base}
          style={{ pointerEvents: compensated ? "none" : "auto" }}
        >
          <JaimyButton.BaseSecondary disabled={compensated}>
            <FormattedMessage tagName="span" id="servicecardlist.read_more" />
          </JaimyButton.BaseSecondary>
        </JaimyLink>
      </JaimyBox>
    </JaimyBox>
  );
};

export default CardContent;
