import { Divider } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as AgendaIcon } from '../../../assets/images/agenda.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check-icon.svg';
import { ReactComponent as PurchasedIcon } from '../../../assets/images/purchased-archive.svg';
import { ROUTES } from '../../../routes';
import theme from '../../../theme';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import { centsToEuro } from '../../../util/priceCents';
export interface PrebookType {
  frequency?: string;
  max_quantity?: number;
  name?: string;
  id?: number;
  prebook_sold_count_in_frequency?: number;
  spent_during_frequency?: number;
  email?: string;
  email_choice?: string;
  zip?: string | number;
  radius?: string | number;
  matching_with_radius?: boolean;
  accepted_postcodes?: string[];
  rejected_postcodes?: string[];
  pause?: boolean;
  locale: string | string[];
  start_date_pause?: Date;
  end_date_pause?: Date;
  no_end_date?: boolean;
}

interface IPreBookCard {
  prebook: PrebookType;
}

const PrebookCard = ({ prebook }: IPreBookCard) => {
  const {
    frequency,
    max_quantity,
    name,
    prebook_sold_count_in_frequency,
    spent_during_frequency,
    id,
    pause
  } = prebook;

  return (
    <StyledCalendarCard>
      <JaimyFlex justifyContent="space-between">
        <JaimyFlex alignItems="center" justifyContent="start">
          <JaimyHeading.H2>{name ? name : 'Prebook'}</JaimyHeading.H2>
        </JaimyFlex>
        <JaimyBox>
          <JaimyFlex flexDirection="column" alignItems="center" justifyContent="center">
            <JaimyHeading.H4 style={{ marginTop: '5px' }}>
              <FormattedMessage
                tagName="span"
                id={`prebook.form.status.${!pause ? 'active' : 'paused'}`}
                defaultMessage="Status"
              />
            </JaimyHeading.H4>
            {pause ? (
              <PauseIcon style={{ color: !pause ? 'green' : 'red' }} />
            ) : (
              <PlayArrowIcon style={{ color: !pause ? 'green' : 'red' }} />
            )}
          </JaimyFlex>
        </JaimyBox>
      </JaimyFlex>
      <Divider />
      <JaimyFlex>
        <JaimyFlex flex={'60%'} flexDirection="column" gap="1rem">
          <JaimyFlex>
            <JaimyBox padding="0px 10px 0px 5px">
              <AgendaIcon width={16} height={16} fill={theme.colors.secondary.base} />
            </JaimyBox>
            <JaimyHeading.H4>
              <FormattedMessage id="prebook.frequency" />
            </JaimyHeading.H4>
          </JaimyFlex>
          <JaimyFlex>
            <JaimyBox padding="0px 10px 0px 5px">
              <CheckIcon width={16} height={16} fill={theme.colors.secondary.base} />
            </JaimyBox>
            <JaimyHeading.H4>
              <FormattedMessage id="prebook.received" />
            </JaimyHeading.H4>
          </JaimyFlex>
          <JaimyFlex>
            <JaimyBox padding="0px 10px 0px 5px">
              <PurchasedIcon width={16} height={16} fill={theme.colors.secondary.base} />
            </JaimyBox>
            <JaimyHeading.H4>
              <FormattedMessage id="prebook.cost" />
            </JaimyHeading.H4>
          </JaimyFlex>
        </JaimyFlex>
        <Divider orientation="vertical" flexItem />

        <JaimyFlex flex={'40%'} flexDirection="column" gap="1rem" margin="0px 0px 0px 10px">
          <JaimyHeading.H4>
            <FormattedMessage id={`prebook.form.frequency.${frequency}`} />
          </JaimyHeading.H4>
          <JaimyHeading.H4>
            {prebook_sold_count_in_frequency}/{max_quantity}
          </JaimyHeading.H4>
          <JaimyHeading.H4>{centsToEuro(spent_during_frequency)}€</JaimyHeading.H4>
        </JaimyFlex>
      </JaimyFlex>
      <PrebookButton to={{ pathname: ROUTES.PREBOOK_ID.handler(id), state: id }}>
        <FormattedMessage id="calendar.manage" />
      </PrebookButton>
    </StyledCalendarCard>
  );
};

export default PrebookCard;

// Styles

const StyledCalendarCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 5px 5px #d7d7d7;
  text-align: start;
  min-width: 350px;

  h2 {
    color: #c30144;
    font-weight: normal;
    text-transform: uppercase;
  }

  p {
    padding: 5px;
    color: #32546d;
    font-weight: normal;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PrebookButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  background-color: ${theme.colors.secondary.base};
  font-size: 16px;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${theme.colors.secondary.light};
  }
`;
