import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DivHeader, H1ProfileTitle, SectionTopHeader } from '../components/common/Header';
import { ComponentIsVisible } from '../components/utils/isVisible';
import { Breadcrumbs } from '@mui/material';
import { ROUTES } from '../routes';
import { AnchorBack } from '../components/common/Link';
import JaimyFlex from '../theme/components/layout/Flex';
import JaimyBox from '../theme/components/layout/Box';
import theme from '../theme';
import JaimyHeading from '../theme/components/Typography/Heading';

const spanMessage = {
  span: (msg: any) => {
    return <span style={{ color: theme.colors.primary.base }}>{msg}</span>;
  }
};

const ConfirmationPageGuest = () => {
  return (
    <>
      <DivHeader>
        <SectionTopHeader>
          <ComponentIsVisible when={!isMobile}>
            <Breadcrumbs>
              <></>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
            </Breadcrumbs>
          </ComponentIsVisible>
          <ComponentIsVisible when={isMobile}>
            <AnchorBack to={`${ROUTES.HOME.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          </ComponentIsVisible>
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="communication.header" />
        </H1ProfileTitle>
      </DivHeader>
      <JaimyFlex
        flexDirection="column"
        minHeight="70vh"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <JaimyFlex
          padding={'2rem'}
          zIndex={2}
          backgroundColor={theme.colors.whiteOpacity}
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <JaimyFlex gap={"1rem"} flexDirection="column">
            <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
              <FormattedMessage id="confirmation" />
            </JaimyHeading.H1>
            <JaimyHeading.H2 color={theme.colors.secondary.base}>
              {/* @ts-ignore */}
              <FormattedMessage id="confirmation.contact" values={spanMessage} />
            </JaimyHeading.H2>
          </JaimyFlex>
        </JaimyFlex>
        <JaimyBox width="100%" height="100%" position="absolute">
          <Image
            alt="Jaimy confirmation"
            src="https://cdn.jaimy.be/images/Depositphotos_14042979_xl-2015.jpg"
          />
        </JaimyBox>
      </JaimyFlex>
    </>
  );
};

export default ConfirmationPageGuest;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
`;
