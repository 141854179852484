import * as Sentry from '@sentry/react';

// import * as serviceWorker from './serviceWorker';

// Material-UI: the createMuiTheme function was renamed to createTheme.
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import { PrismicProvider } from '@prismicio/react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import ReactIntlProvider from './components/IntlProvider';
import { client } from './lib/services/prismicService';
import Routes from './routes';
import configureStore from './store/configureStore';
import { default as jaimyTheme } from './theme';
import GlobalStyle from './theme/global';
const queryClient = new QueryClient();

const environment = process.env.REACT_APP_ENV;

Sentry.init({
  enabled: environment !== 'development',
  dsn: process.env.REACT_APP_SENTRY_KEY,
  release: process.env.REACT_APP_RELEASE_NAME,
  integrations: [new Sentry.BrowserTracing()],

  tracesSampleRate: 0,

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,

  environment
});

if (environment && environment !== 'development') {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
    dataLayerName: 'ProDataLayer',
    events: {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    }
  };

  TagManager.initialize(tagManagerArgs);
}

const store = configureStore();

const materialTheme = createTheme({
  palette: {
    primary: {
      light: '#cf3369',
      main: '#C30144',
      dark: '#88002f',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#32546D',
        fontFamily: "'Lato', sans-serif",
        fontWeight: 'bold',
        fontSize: '16px',
        '&$focused': {
          color: '#32546D'
        }
      }
    }
  }
});
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={jaimyTheme}>
        <MuiThemeProvider theme={materialTheme}>
          <CssBaseline />
          <ReactIntlProvider>
            <PersistGate persistor={store.__persistor} loading={null}>
              <PrismicProvider client={client}>
                <QueryClientProvider client={queryClient}>
                  <Routes />
                  <ReactQueryDevtools initialIsOpen />
                  <noscript>
                    <iframe
                      title="GTM No Script"
                      src={`"https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_GTM}"`}
                      height="0"
                      width="0"
                      style={{ display: 'none', visibility: 'hidden' }}
                    />
                  </noscript>
                </QueryClientProvider>
              </PrismicProvider>
            </PersistGate>
          </ReactIntlProvider>
        </MuiThemeProvider>
        <GlobalStyle />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
