import {
  ICalendarVacationsPayloadData,
  IDeleteVacationPayloadData,
  IPrebookVacationsPayloadData,
  IVacationPlanningPayloadData,
  IVacationWarningsPayloadData
} from '../../types/Vacation';
import { handleResult } from '../../util/serviceHelpers';
import { getToken } from '../../util/tokenProvider';
import { API_URL } from '../../util/url';

class VacationService {
  async getAll(traderId: number) {
    const response = await fetch(`${API_URL}/api/traders/v1/traders/${traderId}/pauses`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);

    return response;
  }

  async getAllWarnings(payload: IVacationWarningsPayloadData) {
    const params = new URLSearchParams();

    params.append('start_date', payload.startDate);
    params.append('end_date', payload.endDate);
    if (payload.pauseCommunication) {
      params.append('pause_communication', 'true');
    }
    if (payload.pauseCalendar) {
      params.append('pause_calendar', 'true');
    }
    if (payload.pausePreebook) {
      params.append('pause_preebook', 'true');
    }
    if (payload.calendarIds) {
      payload.calendarIds.forEach(calendarId =>
        params.append('calendar_ids[]', calendarId.toString())
      );
    }
    if (payload.prebookIds) {
      payload.prebookIds.forEach(prebookId => params.append('prebook_ids[]', prebookId.toString()));
    }

    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.traderId}/warning_pause?${params}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        }
      }
    ).then(handleResult);

    return response;
  }

  async getAllOfCalendar(payload: ICalendarVacationsPayloadData) {
    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.traderId}/pauses/${payload.calendarId}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        }
      }
    ).then(handleResult);

    return response;
  }

  async getAllOfPrebook(payload: IPrebookVacationsPayloadData) {
    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.traderId}/pauses/prebook/${payload.prebookId}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        }
      }
    ).then(handleResult);

    return response;
  }

  async create(payload: IVacationPlanningPayloadData) {
    const requestBody = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      pause_communication: payload.pauseCommunication,
      pause_calendar: payload.pauseCalendar,
      pause_preebook: payload.pausePreebook,
      calendar_ids: payload.calendarIds,
      prebook_ids: payload.prebookIds
    };

    return fetch(`${API_URL}/api/traders/v1/traders/${payload.traderId}/pause`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }

  async update(payload: IVacationPlanningPayloadData) {
    const requestBody = {
      start_date: payload.startDate,
      end_date: payload.endDate,
      pause_communication: payload.pauseCommunication,
      pause_calendar: payload.pauseCalendar,
      pause_preebook: payload.pausePreebook,
      calendar_ids: payload.calendarIds,
      prebook_ids: payload.prebookIds
    };

    return fetch(`${API_URL}/api/traders/v1/traders/${payload.traderId}/pause`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }

  async delete(payload: IDeleteVacationPayloadData) {
    const requestBody = {
      status: payload.status,
      pause_id: payload.pauseId,
      calendar_ids: payload.calendarId,
      prebook_ids: payload.prebookId
    };

    return fetch(`${API_URL}/api/traders/v1/traders/${payload.traderId}/pause/${payload.pauseId}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }
}

export default VacationService;
