import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CalendarCard from '../../components/Calendar/CalendarCard';
import JaimyFlex from '../../theme/components/layout/Flex';
import { FixMeLater } from '../../types/FixMeLater';

import { DivHeader, SectionTopHeader, H1ProfileTitle } from '../../components/common/Header';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ROUTES } from '../../routes';
import { AnchorBack } from '../../components/common/Link';
import JaimyHeading from '../../theme/components/Typography/Heading';
import JaimyText from '../../theme/components/Typography/Text';
import theme from '../../theme';
import { ComponentIsVisible } from '../../components/utils/isVisible';
import { IInterventionPartnership, IUser } from '../../types/User';
import { proOrPremiumSub, Subscriptions } from '../../constants/subscriptions';

interface ICalendarPage {
  user: IUser;
}

const Calendar = ({ user }: ICalendarPage): JSX.Element => {
  const sub_type =
    user.subscription && user.subscription.length > 0 ? user.subscription[0].sub_type : 'local';
  const history = useHistory();

  useEffect(() => {
    if (!user) return;
    if (!sub_type) return;
    if (!proOrPremiumSub.includes(sub_type as Subscriptions)) {
      history.push(ROUTES.DASHBOARD.handler());
    }
  }, [history, sub_type, user]);

  return (
    <>
      <DivHeader>
        <SectionTopHeader>
          <ComponentIsVisible when={!isMobile}>
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.CALENDAR.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.calendar" />
              </Link>
            </Breadcrumbs>
          </ComponentIsVisible>
          <ComponentIsVisible when={isMobile}>
            <AnchorBack to={`${ROUTES.HOME.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          </ComponentIsVisible>
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="calendar.pageTitle" />
        </H1ProfileTitle>
      </DivHeader>

      <JaimyFlex flexWrap="wrap" flexMd="row" gap="16px" justifyContent="center" my={1}>
        {user.intervention_partnerships.map((interventionPartnership: IInterventionPartnership) => (
          <CalendarCard key={interventionPartnership.id} calendar={interventionPartnership} />
        ))}
      </JaimyFlex>
      <JaimyFlex
        flexDirection="column"
        color={theme.colors.secondary.base}
        margin="2rem auto"
        width="fit-content"
        textAlign="center"
      >
        <JaimyHeading.H2>
          <FormattedMessage id="calendar.newCalendar" />
        </JaimyHeading.H2>
        <JaimyText>
          <FormattedMessage id="calendar.contact" />
        </JaimyText>
      </JaimyFlex>
    </>
  );
};

const mapStateToProps = ({ user: { user } }: { user: FixMeLater }) => ({
  user
});

export default connect(mapStateToProps, null)(Calendar);
