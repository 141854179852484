import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CartSVG } from '../assets/images/cart-icon.svg';
import { ReactComponent as DarkLogo } from '../assets/images/jaimy-logo.svg';
import { PremiumSub, proOrPremiumSub } from '../constants/subscriptions';
import {
  allowedToAccessHistory,
  allowedToAccessInvoice,
  allowedToAccessMyCalendar,
  allowedToAccessMyPrebook,
  allowedToAccessMyProfile,
  allowedToSeeJobList,
  EVerifiedStatus,
  isShowingBanner
} from '../constants/verifiedStatus';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { ROUTES } from '../routes';
import { centsToEuro } from '../util/priceCents';
import { UserPropType } from '../util/PropTypeValues';
import { logout } from '../util/tokenProvider';
import { BannerRestrictionBanned } from './Banner/Restriction/Banned';
import { BannerRestrictionPayment } from './Banner/Restriction/Payment';
import { BannerRestrictionWaitForInfo } from './Banner/Restriction/WaitForInfo';
import { NavButton } from './NavButton';
import { ComponentIsVisible } from './utils/isVisible';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    paddingTop: '0',
    paddingBottom: '0'
  }
})(MenuItem);

const DivContainer = styled.div`
  background-color: #32546d;
  max-height: ${props => (props.hasBanner ? '210px' : '72px')};
  padding: ${props => (props.hasBanner ? '0' : '0.75rem')};
  position: sticky;
  top: 0;
  z-index: 5;
  border-bottom: 0.5px solid #d3d3d366;

  @media (min-width: 1200px) {
    max-height: ${props => (props.hasBanner ? '175px' : '72px')};
  }
`;
const DivWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: ${props => (props.hasBanner ? '0.75rem' : '')};

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: ${props => (props.hasBanner ? '.75rem 1.2rem' : '0 1.2rem')};
  }
`;
const DivMenu = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;
const ButtonCart = styled.button`
  outline: none;
  border: none;
  background-color: #c30144;
  border-radius: 4px;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;

  :hover {
    background-color: #c30144e0;
  }

  svg {
    fill: #fff;
  }
`;
const SpanNumber = styled.span`
  background-color: #86bcd3;
  font-size: 9pt;
  border-radius: 50%;
  color: #32546d;
  display: block;
  width: 20px;
  height: 20px;
  padding: 4px;
  position: absolute;
  top: -10px;
  right: -10px;
  font-weight: bold;
  line-height: 9pt;
`;
const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  > span {
    color: #f3f4f6;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    margin: 0 1rem;
  }
`;
const MenuItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  padding: 6px 16px;
  display: flex;
  flex-grow: 1;
`;

const AnchorCustomerLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin: 0 1rem;

  span {
    color: #f3f4f6;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
    font-style: normal;
  }
`;
const LanguageWrapper = styled.ul`
  display: block;
  padding-left: 0;

  > li {
    margin: 20px 6px;
    color: white;
    font-size: 10pt;
    display: inline;
    cursor: pointer;
  }

  > li:not(:last-child) {
    border-right: 1px solid darkgrey;
    padding-right: 6px;
    margin-right: 0px;
  }
`;
export const LoginButton = styled.button`
  margin: 4px 1rem 0;
  padding: 0 0.5rem;
  height: 40px;
  border: 0;
  background-color: #ffffff;
  color: #32546d;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: 2rem;
  cursor: pointer;
  display: flex;

  svg {
    fill: #32546d;
  }
  svg:first-child {
    margin-right: 0.5rem;
  }
  svg:last-child {
    margin-left: 0.5rem;
  }

  :disabled {
    color: #867a7a;
  }

  :hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.95);
  }
`;
export const LiLanguage = styled.li`
  opacity: ${props => (props.isCurrent ? '1' : '0.5')};
`;
export const SpanCreditTitle = styled.span`
  display: block;
  font-size: 10px;
  font-weight: bold;
  color: #405a6b;
  text-transform: uppercase;
`;
export const SpanCreditField = styled.span`
  display: block;
  font-size: 24px;
  font-weight: normal;
  color: #32546d;
`;
export const DivCreditItem = styled.span`
  font-family: Lato;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-bottom: 1px solid #d2d2d7;
  display: block;
  padding: 8px 16px;
`;
export const MainHeader = ({ cart, user, setLanguage }) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const currentQueryParams = location.search;

  const setLanguageAndReplace = async ({ locale }) => {
    await setLanguage({ locale });
    let replaceUrl = match.path.replace(':locale', locale);
    if (match.params.id) {
      replaceUrl = replaceUrl.replace(':id', match.params.id);
    }
    replaceUrl = `${replaceUrl}${currentQueryParams}`;

    history.replace(replaceUrl);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const isLogged = Boolean(user && user.id);
  const sub_type =
    user.subscription && user.subscription.length > 0 ? user.subscription[0].sub_type : 'local';

  const isNewUser = Boolean(
    !user.treetrades ||
      !user.vat_number ||
      !user.companyname ||
      !user.address ||
      !user.zip ||
      !user.city ||
      !user.code_of_conduct_time
  );
  const localeLocalStorage =
    (typeof localStorage !== 'undefined' && localStorage.getItem('locale')) || user.locale;

  if (isLogged && match.path !== ROUTES.REGISTER.path && isNewUser) {
    history.push(ROUTES.REGISTER.handler());
  }
  const handleLogout = () => {
    logout();
  };

  return (
    <DivContainer hasBanner={isShowingBanner.includes(user.verified_status)}>
      <DivWrapper hasBanner={isShowingBanner.includes(user.verified_status)}>
        <LinkStyled
          to={
            isLogged
              ? ROUTES.DASHBOARD.handler(currentQueryParams)
              : ROUTES.HOME.handler(currentQueryParams)
          }
        >
          <DarkLogo />
        </LinkStyled>
        <DivMenu>
          {width > 768 && isLogged && (
            <ComponentIsVisible when={user.verified_status !== EVerifiedStatus.Banned}>
              <LinkStyled to={`${ROUTES.HELP.handler()}`}>
                <FormattedMessage tagName="span" id="mainheader.link.help" />
              </LinkStyled>
              <ComponentIsVisible when={allowedToSeeJobList.includes(user.verified_status)}>
                <LinkStyled to={`${ROUTES.JOBS.handler()}`}>
                  <FormattedMessage tagName="span" id="mainheader.link.jobs" />
                </LinkStyled>
              </ComponentIsVisible>
              <ComponentIsVisible when={allowedToAccessHistory.includes(user.verified_status)}>
                <LinkStyled to={`${ROUTES.PURCHASED.handler()}`}>
                  <FormattedMessage tagName="span" id="mainheader.link.purchases" />
                </LinkStyled>
              </ComponentIsVisible>
            </ComponentIsVisible>
          )}
          {!isLogged && width > 768 && (
            <>
              <AnchorCustomerLink href={`https://jaimy.be${window.location.search}`}>
                <FormattedMessage tagName="span" id="mainheader.link.customer" />
              </AnchorCustomerLink>
              <LinkStyled to={`${ROUTES.HELP.handler()}`}>
                <FormattedMessage tagName="span" id="mainheader.link.help" />
              </LinkStyled>
              <LinkStyled to={ROUTES.LOGIN.handler(location.search)}>
                <FormattedMessage tagName="span" id="mainheader.link.login" />
              </LinkStyled>
            </>
          )}
          {isLogged && (
            <ComponentIsVisible when={user.verified_status !== EVerifiedStatus.Banned}>
              <LinkStyled to={`${ROUTES.CART.handler()}`}>
                <ButtonCart data-cy-button="cart-link-button" isMobile={width < 768}>
                  {cart.cart_size > 0 && (
                    <SpanNumber data-cy-span="cart-length">{cart.cart_size}</SpanNumber>
                  )}
                  <CartSVG />
                </ButtonCart>
              </LinkStyled>
            </ComponentIsVisible>
          )}
          {width <= 768 && (
            <NavButton
              isLogged={isLogged}
              subType={sub_type}
              userVerifiedStatus={user.verified_status}
              setLanguage={setLanguageAndReplace}
              localeLocalStorage={localeLocalStorage}
              logout={handleLogout}
              disabled={user.isLoading}
            />
          )}
          {width > 768 && isLogged && (
            <>
              <LoginButton data-cy-button="login-button" onClick={handleClick}>
                <PersonOutlineIcon />
                <span data-cy="username">{user.firstname}</span>
                <ArrowDropDownIcon />
              </LoginButton>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {user.credits_remaining > 0 && (
                  <DivCreditItem>
                    <SpanCreditTitle>
                      <FormattedMessage tagName="span" id="navbutton.availableCredit" />
                    </SpanCreditTitle>
                    <SpanCreditField>
                      <FormattedMessage
                        id="cart.price.credit"
                        values={{ price: centsToEuro(user.credits_remaining) }}
                      />
                    </SpanCreditField>
                  </DivCreditItem>
                )}
                <ComponentIsVisible when={allowedToAccessMyProfile.includes(user.verified_status)}>
                  <StyledMenuItem data-cy-menuitem="profile" disableGutters>
                    <MenuItemLink to={`${ROUTES.PROFILE.handler()}`} onClick={handleClose}>
                      <ListItemText
                        primary={<FormattedMessage tagName="span" id="navbutton.link.profile" />}
                      />
                    </MenuItemLink>
                  </StyledMenuItem>
                </ComponentIsVisible>
                {/* //HEREEEEEEEEEEEEEE */}
                <ComponentIsVisible when={allowedToAccessMyCalendar.includes(user.verified_status)}>
                  <ComponentIsVisible when={PremiumSub.includes(sub_type)}>
                    <StyledMenuItem data-cy-menuitem="calendar" disableGutters>
                      <MenuItemLink to={`${ROUTES.CALENDAR.handler()}`} onClick={handleClose}>
                        <ListItemText
                          primary={<FormattedMessage tagName="span" id="navbutton.link.calendar" />}
                        />
                      </MenuItemLink>
                    </StyledMenuItem>
                  </ComponentIsVisible>
                </ComponentIsVisible>
                <ComponentIsVisible when={allowedToAccessMyPrebook.includes(user.verified_status)}>
                  <ComponentIsVisible when={proOrPremiumSub.includes(sub_type)}>
                    <StyledMenuItem data-cy-menuitem="prebook" disableGutters>
                      <MenuItemLink to={`${ROUTES.PREBOOK.handler()}`} onClick={handleClose}>
                        <ListItemText
                          primary={<FormattedMessage tagName="span" id="navbutton.link.prebook" />}
                        />
                      </MenuItemLink>
                    </StyledMenuItem>
                  </ComponentIsVisible>
                </ComponentIsVisible>
                <ComponentIsVisible when={allowedToAccessInvoice.includes(user.verified_status)}>
                  <StyledMenuItem data-cy-menuitem="invoices" disableGutters>
                    <MenuItemLink to={`${ROUTES.INVOICES.handler()}`} onClick={handleClose}>
                      <ListItemText
                        primary={<FormattedMessage tagName="span" id="navbutton.link.invoices" />}
                      />
                    </MenuItemLink>
                  </StyledMenuItem>
                </ComponentIsVisible>
                <MenuItem
                  data-cy-menuitem="logout"
                  onClick={handleLogout}
                  disabled={user.isLoading}
                >
                  <ListItemText
                    primary={<FormattedMessage tagName="span" id="navbutton.link.log_out" />}
                  />
                </MenuItem>
              </StyledMenu>
            </>
          )}
          {width > 768 && (
            <LanguageWrapper>
              <LiLanguage
                key="fr"
                role="button"
                isCurrent={isLogged ? user.locale === 'fr' : localeLocalStorage === 'fr'}
                onClick={() => setLanguageAndReplace({ locale: 'fr' })}
              >
                <FormattedMessage tagName="span" id="navbutton.language.fr" />
              </LiLanguage>
              <LiLanguage
                key="nl"
                role="button"
                isCurrent={
                  isLogged
                    ? user.locale === 'nl'
                    : localeLocalStorage === 'nl' || !localeLocalStorage
                }
                onClick={() => setLanguageAndReplace({ locale: 'nl' })}
              >
                <FormattedMessage tagName="span" id="navbutton.language.nl" />
              </LiLanguage>
            </LanguageWrapper>
          )}
        </DivMenu>
      </DivWrapper>
      <BannerRestrictionBanned user={user} />
      <BannerRestrictionPayment user={user} />
      <BannerRestrictionWaitForInfo user={user} />
    </DivContainer>
  );
};

MainHeader.defaultProps = {
  user: {},
  cart: {
    cart_size: 0
  }
};
MainHeader.propTypes = {
  user: UserPropType,
  cart: PropTypes.shape({
    cart_size: PropTypes.number
  }),
  setLanguage: PropTypes.func.isRequired
};

export default MainHeader;
