import PropTypes from 'prop-types';

const CartProp = PropTypes.shape({
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  hasError: PropTypes.bool
});

export default CartProp;
