import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { DivHeader, H1ProfileTitle, SectionTopHeader } from '../../components/common/Header';
import { FixMeLater } from '../../types/FixMeLater';

import { getRelevantJobs } from '../../store/reducers/services/actions';
import { getTraderInvoices } from '../../store/reducers/user/actions';
import { requestTraderPrebooksData } from '../../store/reducers/prebooks/actions';
import RelevantJobs from '../../components/dashboard/RelevantJobs';
import { IRelevantJob } from '../../types/RelevantJob';
import JaimyFlex from '../../theme/components/layout/Flex';

import RelevantJobsLoading from '../../components/dashboard/RelevantJobs/Loading';
import DashboardReviews from '../../components/dashboard/Reviews';
import DashboardPreBook from '../../components/dashboard/Prebook';
import theme from '../../theme';
import InvoicesLoading from '../../components/dashboard/Invoices/Loading';
import DashboardAgenda from '../../components/dashboard/Agenda';
import { ComponentIsVisible } from '../../components/utils/isVisible';
import { IUser } from '../../types/User';
import { IInitialState } from '../../types/RootState';
import IInvoice from '../../types/Invoice';
import DashboardInvoices from '../../components/dashboard/Invoices/DashboardInvoices';

interface IDashboard {
  relevantJobs: IRelevantJob[];
  invoices: IInvoice[];
  user: IUser;
  getRelevantJobs: () => void;
  getTraderInvoices: () => void;
  requestTraderPrebooksData: () => void;
  prebooks: FixMeLater;
}

const Dashboard = ({
  user,
  getRelevantJobs,
  getTraderInvoices,
  requestTraderPrebooksData,
  relevantJobs,
  invoices,
  prebooks
}: IDashboard) => {
  const jobsLoading = useSelector(
    (state: FixMeLater) => state.services.relevantJobs.relevantJobLoading
  );
  const invoicesLoading = useSelector((state: FixMeLater) => state.user.invoicesLoading);
  const prebooksLoading = useSelector((state: FixMeLater) => state.prebooks.isLoading);
  // Fetch relevant jobs, invoices and prebooks

  useEffect(() => {
    getRelevantJobs();
    getTraderInvoices();
    requestTraderPrebooksData();
  }, [getRelevantJobs, getTraderInvoices, requestTraderPrebooksData]);

  return (
    <JaimyFlex flexDirection="column" gap="1rem" margin="0 0 1rem 0">
      <DivHeader>
        <SectionTopHeader>
          <H1ProfileTitle>
            <FormattedMessage tagName="span" id="welcome" defaultMessage=" Dashboard" />{' '}
            {user.firstname}!
          </H1ProfileTitle>
        </SectionTopHeader>
      </DivHeader>
      <ComponentIsVisible when={jobsLoading}>
        <RelevantJobsLoading />
      </ComponentIsVisible>
      <ComponentIsVisible when={!jobsLoading}>
        <RelevantJobs
          userCheckedPurchasesLeft={user.checked_purchases_left}
          userWaitForInfoPurchasesLeft={user.wait_for_info_purchases_left}
          relevantJobs={relevantJobs}
          userVerifiedStatus={user.verified_status}
        />
      </ComponentIsVisible>
      <hr style={{ width: '80%', backgroundColor: theme.colors.secondary.base }} />
      <JaimyFlex
        justifyContent="space-between"
        flexDirection="row"
        directionSm="column"
        maxWidth="1200px"
        margin="0 auto"
        padding="0 1.2rem"
        gap="1rem"
      >
        <DashboardReviews />
        <ComponentIsVisible when={invoicesLoading}>
          <InvoicesLoading />
        </ComponentIsVisible>
        <ComponentIsVisible when={!invoicesLoading}>
          <DashboardInvoices invoices={invoices as IInvoice[]} />
        </ComponentIsVisible>

        <JaimyFlex flexDirection="column" justifyContent="center" alignItems="center" gap="10px">
          <ComponentIsVisible when={prebooksLoading}>
            <InvoicesLoading />
          </ComponentIsVisible>
          <ComponentIsVisible when={!prebooksLoading}>
            <DashboardPreBook prebooks={prebooks} user={user} />
          </ComponentIsVisible>
          <ComponentIsVisible when={!user.intervention_partnerships}>
            <InvoicesLoading />
          </ComponentIsVisible>
          <ComponentIsVisible when={!!user.intervention_partnerships}>
            <DashboardAgenda user={user} />
          </ComponentIsVisible>
        </JaimyFlex>
      </JaimyFlex>
    </JaimyFlex>
  );
};

const mapStateToProps = (state: IInitialState) => {
  return {
    user: state.user.user,
    relevantJobs: state.services.relevantJobs.data && state.services.relevantJobs.data,
    invoices: state.user.invoicesData && state.user.invoicesData,
    prebooks: state.prebooks.prebooks
  };
};

const mapDispatchToProps = {
  getRelevantJobs,
  getTraderInvoices,
  requestTraderPrebooksData
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
