import styled from 'styled-components';

export const DivCard = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #d8dae0;
  border-left: 1px solid #d8dae0;
  border-right: 1px solid #d8dae0;
  border-radius: 4px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-basis: 48%;
  }
`;

export const DivCardHeader = styled.div`
  background-color: #32546d;
  padding: 1.2rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  position: relative;
`;

export const DivCardHeaderTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-basis: 80%;

  hgroup > h5 {
    color: #86bcd3;
    font-size: 10px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
  }

  hgroup > h2 {
    color: #fff;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
  }
`;

export const H2Title = styled.h2`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  position: absolute;
  top: -9px;
  left: 0;

  > span {
    z-index: 1;
    position: relative;
    margin: auto;
  }

  > svg {
    position: relative;
    margin: 0 auto;
    top: -44px;
    right: -22px;
  }
`;
export const H1TitleBelfius = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
`;

export const DivCardMain = styled.div``;

export const SpanOriginalPrice = styled.span`
  margin-left: 0.5rem;
  font-size: 12px;
  opacity: 0.6;
  text-decoration: line-through;
`;
