import { store } from './store/configureStore';

const defaultLocale = 'nl';
const locales = ['nl', 'fr'];
export function isLocale(tested) {
  return locales.some(locale => locale === tested);
}
const getLocale = () => {
  const { user } = store.getState().user;
  //Search in redux storage
  if (user && user.locale) {
    return user.locale;
  }
  //Search in localStorage
  const storageLocale = localStorage.getItem('locale');
  if (storageLocale !== null) {
    return storageLocale;
  }

  // Match with Browser configs
  const [browserSetting] = navigator.language.split('-');
  if (isLocale(browserSetting)) {
    localStorage.setItem('locale', browserSetting);
    return browserSetting;
  }

  //Set default
  return defaultLocale;
};
export default getLocale;
