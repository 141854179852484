import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as AgendaIcon } from '../../assets/images/agenda.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-up.svg';
import { ReactComponent as DarkLogo } from '../../assets/images/jaimy-logo.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as FbIcon } from '../../assets/images/facebook.svg';
import { ReactComponent as IGIcon } from '../../assets/images/instagram.svg';
import { Link } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import getLocale from '../../getLocale';
import { ROUTES } from '../../routes';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

const DivFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #32546d;
  box-sizing: border-box;
  padding: 2rem 1rem 1rem 1rem;

  ${props =>
    props.isCartPage &&
    props.isMobile &&
    css`
      margin-bottom: 144px;
    `}
`;
const DivWrapper = styled.div`
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
`;
const DivContent = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
  }
`;
const DivCopyright = styled.div`
  @media (max-width: 600px) {
    > p {
      display: inline;
    }
  }
  @media (max-width: 400px) {
    display: inline-grid;
  }
  color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;

  @media (min-width: 1200px) {
    justify-content: flex-start;

    > p {
      margin-right: 1rem;
    }

    > button {
      margin-left: auto;
    }
  }
`;
const AnchorTerms = styled.a`
  cursor: pointer;
  color: inherit;

  :hover {
    text-decoration: underline;
  }
`;
const ButtonGotop = styled.button`
  @media (max-width: 400px) {
    display: none;
  }
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  cursor: pointer;
`;
const DivItem = styled.div`
  display: flex;
  font-family: 'Lato', sans-serif;
  align-items: center;

  @media (max-width: 400px) {
    display: block;
  }

  @media (min-width: 1200px) {
    :not(:first-of-type) {
      flex-direction: column;
      align-items: flex-start;
    }

    :first-of-type {
      > a:first-of-type {
        margin-right: 4rem;
      }
    }
  }

  :nth-of-type(even) {
    margin: 1rem 0;
  }

  > p {
    display: flex;
    color: #86bcd3;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 18px;

    > span {
      margin-left: 1rem;
      text-transform: uppercase;
    }
  }

  > p + a {
    font-weight: bold;
    font-size: 16px;
    line-height: 34px;
    color: #ffffff;
    margin-left: auto;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }

  > p + span {
    font-weight: bold;
    font-size: 16px;
    line-height: 34px;
    color: #ffffff;
    margin-left: auto;
  }
`;
const LinkLogoStyled = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    color: #f3f4f6;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    margin-right: 2rem;
  }
`;
const LinkStyled = styled(Link)`
  cursor: pointer;
  color: inherit;

  :hover {
    text-decoration: underline;
  }
`;
const AnchorSocial = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 2px solid rgba(255, 255, 255, 0.317116);
  border-radius: 50%;
  box-sizing: border-box;

  :nth-of-type(2) {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
`;
const DivSocial = styled.div`
  display: flex;
`;
export const Footer = ({ isCartPage, locale }) => {
  const intl = useIntl();

  const onClickTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const facebookLink = useMemo(() => {
    const facebookLinkBasedOnLocale =
      locale === 'nl'
        ? 'https://www.facebook.com/groups/jaimypro'
        : 'https://www.facebook.com/groups/jaimy';

    return facebookLinkBasedOnLocale;
  }, [locale]);

  const cookiePolicyUrl = useMemo(() => {
    if (locale === 'fr') {
      return 'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Politique+de+cookies.pdf';
    }
    return 'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Cookiebeleid.pdf';
  }, [locale]);

  return (
    <DivFooter isCartPage={isCartPage} isMobile={isMobile}>
      <DivWrapper>
        <DivContent>
          <DivSocial>
            <LinkLogoStyled to={`${ROUTES.HOME.handler()}`}>
              <DarkLogo />
            </LinkLogoStyled>

            <AnchorSocial
              href="https://www.instagram.com/jaimy.be"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IGIcon />
            </AnchorSocial>
            <AnchorSocial href={facebookLink} target="_blank" rel="noopener noreferrer">
              <FbIcon />
            </AnchorSocial>
          </DivSocial>
          <DivItem>
            <p>
              <AgendaIcon width={16} height={16} fill="#86BCD3" />
              <FormattedMessage tagName="span" id="footer.open_hours.label" />
            </p>
            <FormattedMessage tagName="span" id="footer.open_hours" />
          </DivItem>
          <DivItem>
            <p>
              <PhoneIcon width={16} height={16} fill="#86BCD3" />
              <FormattedMessage tagName="span" id="footer.phone.label" />
            </p>
            <a
              href={`tel:${intl.messages['footer.phone']}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage tagName="span" id="footer.phone" />
            </a>
          </DivItem>
          <DivItem>
            <p>
              <EmailIcon width={16} height={16} fill="#86BCD3" />
              <FormattedMessage tagName="span" id="footer.email.label" />
            </p>
            <a href="mailto:info@jaimy.be" target="_blank" rel="noopener noreferrer">
              <FormattedMessage tagName="span" id="footer.email" />
            </a>
          </DivItem>
        </DivContent>
        <DivCopyright>
          <p>
            <FormattedMessage
              tagName="span"
              id="footer.by.jaimy"
              values={{ year: new Date().getFullYear() }}
            />
          </p>
          <p>
            <LinkStyled to={`${ROUTES.TERMS_AND_CONDITIONS.handler()}`}>
              <FormattedMessage tagName="span" id="footer.terms.conditions" />
            </LinkStyled>
          </p>
          <p>
            <AnchorTerms
              href={`https://jaimy.be/${getLocale() || 'nl'}/privacy`}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage tagName="span" id="footer.terms.privacy_policy" />
            </AnchorTerms>
          </p>
          <p>
            <AnchorTerms href={cookiePolicyUrl} target="_blank" rel="noreferrer">
              <FormattedMessage tagName="span" id="footer.terms.cookie_policy" />
            </AnchorTerms>
          </p>
          <ButtonGotop onClick={onClickTop}>
            <ArrowUpIcon />
          </ButtonGotop>
        </DivCopyright>
      </DivWrapper>
    </DivFooter>
  );
};

export default Footer;
