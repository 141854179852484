import TagManager from 'react-gtm-module';
import 'url-search-params-polyfill';

/* eslint-disable class-methods-use-this */
import { getTraderId, handleResult } from '../../util/serviceHelpers';
import { productParser } from '../../logging/analytics';

import { getToken } from '../../util/tokenProvider';
import { API_URL } from '../../util/url';

class JobService {
  /**
   * Get list of jobs (with filters)
   */
  async getFilteredList(filters) {
    let serviceRequests;
    const formData = new FormData();
    const authorization = { Authorization: `Bearer ${await getToken()}` };
    const id = getTraderId();
    if (filters.service_view === 'favorites') {
      serviceRequests = await fetch(`${API_URL}/api/traders/v1/traders/${id}/favorite`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          ...authorization
        }
      });
    } else if (filters.service_view === 'trash') {
      serviceRequests = await fetch(`${API_URL}/api/traders/v1/traders/${id}/trash`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          ...authorization
        }
      });
    } else {
      if (filters.pagination && filters.pagination.page) {
        formData.append('page', filters.pagination.page);
      }
      if (filters.languages && filters.languages.length > 0) {
        filters.languages.forEach(e => formData.append('filter[locales][]', e));
      }

      if (filters.order_direction) {
        formData.append('order_by', 'price_cents');
        formData.append('order_direction', filters.order_direction);
      }

      if (filters.radius) {
        formData.append('filter[radius]', filters.radius);
      }

      if (filters.category_list) {
        filters.category_list.forEach(e => formData.append('filter[treetrades][]', e));
      }
      const body = new URLSearchParams(formData);
      const url = `${API_URL}/api/traders/v1/service_requests`;

      serviceRequests = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          ...authorization
        },
        body
      });
    }
    try {
      const data = await handleResult(serviceRequests);
      const { category_list, ...rest } = filters;

      TagManager.dataLayer({
        dataLayer: {
          event: 'Product List Filtered',
          sorts: [{ type: 'order_direction', value: filters.order_direction }],
          filters: [
            {
              type: 'order_direction',
              value: rest.order_direction
            },
            { type: 'radius', value: JSON.stringify(filters.radius) },
            { type: 'service_view', value: filters.service_view },
            { type: 'languages', value: filters.languages.sort().join(',') }
          ],
          page: filters.pagination.page,
          total_job_on_search: parseInt(serviceRequests.headers.get('total'), 10),
          total: parseInt(serviceRequests.headers.get('total'), 10),
          perPage: parseInt(serviceRequests.headers.get('per-page'), 10),
          products: data.map(productParser)
        },
        dataLayerName: 'ProDataLayer'
      });
      const pagination = {
        total: parseInt(serviceRequests.headers.get('total'), 10),
        perPage: parseInt(serviceRequests.headers.get('per-page'), 10)
      };

      let filteredData;
      switch (filters.service_view) {
        case 'new':
          filteredData = data.filter(item => !item.more_than_48_hours && !item.is_on_sale);
          return {
            items: filteredData,
            pagination
          };
        case 'active':
          filteredData = data.filter(item => item.is_on_sale);
          return {
            items: filteredData,
            pagination
          };
        default:
          return {
            items: data,
            pagination
          };
      }
    } catch (error) {
      throw new Error(error.message);
    }
    // return { error:s true, info: data };
  }

  /**
   * Get job by id
   */
  async getById(id, isGuest = false) {
    let headers = { 'Content-type': 'application/json; charset=UTF-8' };
    let url;
    if (isGuest) {
      url = `${API_URL}/api/traders/v1/service_requests/guest/${id}`;
    } else {
      url = `${API_URL}/api/traders/v1/service_requests/${id}`;
      headers = {
        ...headers,
        Authorization: `Bearer ${await getToken()}`
      };
    }
    return fetch(url, {
      method: 'GET',
      headers
    });
  }

  /**
   * Toggle job favorite status
   */
  async toggleFavorite(id) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/${id}/favorite`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Trash job
   */
  async trash(id) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/${id}/trash`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Ask question about job
   */
  async askQuestion({ id, text }) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/${id}/questions`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({ content: text })
    }).then(handleResult);
  }

  /**
   * Buy job with free lead
   */
  async useFreeLeadOnId(id) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/estimates_mollie/use_free_lead`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({
        service_request_id: id,
        min_estimate: 100,
        max_estimate: 100,
        start_date: 'flexible'
      })
    });
  }
}

export default JobService;
