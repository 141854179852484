import { EVerifiedStatus } from '../constants/verifiedStatus';
import { VERIFIED_STATUSES } from './constants';

const localeParser = locale => {
  switch (locale) {
    case 'fr':
      return 'fr';
    case 'nl':
      return 'nl';
    case 'en':
      return 'en';

    default:
      return 'nl';
  }
};

const userParser = user => ({
  ...user,
  address: user.address,
  avatar: user.avatar,
  city: user.city,
  companyname: user.companyname,
  email: user.email,
  firstname: user.firstname,
  lastname: user.lastname,
  id: user.id,
  locale: localeParser(user.locale),
  mobile_phone: user.mobile_phone,
  phone: user.phone,
  treetrades: user.treetrades,
  service_radius: parseInt(user.service_radius, 10),
  vat_number: user.vat_number,
  website_url: user.website_url,
  iban: user.iban ? user.iban.replaceAll(' ', '') : user.iban,
  zip: user.zip,
  radius_zip: user.radius_zip,
  coupon: user.coupon,
  free_leads_remaining: user.free_leads_remaining,
  traderCoupons: user.traderCoupons,
  activityStatus: user.activity_status,
  leadSourceId: user.lead_source_id,
  vatValidationState: user.vat_validation_state,
  credits_remaining: user.credits_remaining,
  matching_locales: user.matching_locales,
  bought_nb: user.bought_nb,
  verified_status: user.verified_status,
  checked_purchases_left:
    user.verified_status === VERIFIED_STATUSES.checked
      ? 3 - (user.bought_nb + parseInt(user.free_leads_used, 10))
      : 0,
  wait_for_info_purchases_left:
    user.verified_status === EVerifiedStatus.RestrictedWaitForInfo
      ? 10 - (user.bought_nb + parseInt(user.free_leads_used, 10))
      : 0,
  free_leads_used: user.free_leads_used,
  invoice_later_authorization: user.invoice_later_authorization,
  isCoupon:
    user.traderCoupons &&
    user.traderCoupons.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0]
      ? user.traderCoupons
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0]
          .name.toLowerCase()
          .startsWith('coupon')
      : false,
  couponValue:
    user.traderCoupons &&
    user.traderCoupons.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0]
      ? parseInt(
          user.traderCoupons
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0]
            .name.toLowerCase()
            .replace('coupon', '')
            .replace('euros', ''),
          10
        )
      : 0
});

export default userParser;
