import { handleResult } from '../../util/serviceHelpers';
import { AUTH_URL } from '../../util/url';

class AuthService {
  /**
   * User login
   */
  async login({ username, password }) {
    const result = await fetch(`${AUTH_URL}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({ email: username, password })
    });
    const data = handleResult(result);
    return data;
  }
  /**
   * User login
   */
  async refresh(accessToken) {
    const result = await fetch(`${AUTH_URL}/api/users/refresh`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const data = handleResult(result);
    return data;
  }

  // ...todo
}
export default AuthService;
