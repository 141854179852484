import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ROUTES } from '../../../routes';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const DivSuccess = styled.div`
  font-family: 'Lato', sans-serif;
  text-align: center;
  > h2 {
    color: #32546d;
    font-size: 20px;
    line-height: 21px;
    margin-bottom: 2rem;
  }
  > p,
  a {
    color: #637780;
    font-style: normal;
    font-size: 16px;
  }
  > a {
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TimerDialog = ({ children, isOpen, title, onConfirm, onClose }) => {
  const history = useHistory();
  const [timer, setTimer] = useState(5);
  const [counting, setCounting] = useState(false);
  useEffect(() => {
    if (counting) {
      setTimeout(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          setCounting(false);
          onConfirm();
          history.push(ROUTES.LOGIN.handler());
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, counting]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setCounting(false);
        onClose();
      }}
    >
      <DialogContent data-cy-modalname="signup-success-modal">
        <DivSuccess>
          <h2>{title}</h2>
          <p>
            {children}
            <FormattedMessage
              tagName="span"
              id="profile.form.logout.dialog.timer"
              values={{ sec: timer }}
            />
          </p>
        </DivSuccess>
      </DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button
            disabled={counting}
            onClick={() => {
              setCounting(true);
              setTimer(5);
            }}
            color="primary"
          >
            <FormattedMessage tagName="span" id="yes" values={{ sec: timer }} />
          </Button>
        )}
        {onClose && (
          <Button
            disabled={counting}
            onClick={() => {
              setCounting(false);
              onClose();
            }}
            color="primary"
          >
            <FormattedMessage tagName="span" id="no" values={{ sec: timer }} />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

TimerDialog.defaultProps = {
  children: null,
  isOpen: false,
  title: 'Empty',
  onConfirm: null,
  onClose: null
};
TimerDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};
export default TimerDialog;
