import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@font-face {
  font-family: Belfius;
  font-weight: 700;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Belfius'), url('https://d1ngac5455aayn.cloudfront.net/fonts/BelfiusFont/WOFF2/BelfiusNormal_Bold.woff2')
    format('woff2');
}
`;
