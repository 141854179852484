import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FuncPropType } from '../../util/PropTypeValues';

import { ButtonDarkRed } from '../../components/common/Button';
import JaimyCheckbox from '../../components/Checkbox';

const Container = styled.div`
  max-width: 342px;
  margin: auto;

  h3 {
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    font-family: Lato, sans-serif;
    margin: 0 auto 30px;
    font-size: 14px;
    letter-spacing: 0.18px;
    text-align: center;
    width: 342px;
  }

  button {
    width: 100%;
    margin: 1rem auto 2rem;
  }
`;

const StepCodeOfHonor = ({ onClickContinue }) => {
  const intl = useIntl();
  const [hasAcceptedRules, setHasAcceptedRules] = useState(false);

  const renderSteps = () =>
    Array.from({ length: 6 }, (v, i) => i + 1).map(value => (
      <React.Fragment key={value}>
        <h3>{intl.messages[`register.step.codeOfHonor.${value}.title`]}</h3>
        <FormattedMessage tagName="p" id={`register.step.codeOfHonor.${value}.text`} />
      </React.Fragment>
    ));

  // @todo Missing message for button
  return (
    <Container>
      {renderSteps()}
      <label htmlFor="hasAcceptedRules">
        <JaimyCheckbox
          id="hasAcceptedRules"
          type="checkbox"
          checked={hasAcceptedRules}
          onChange={e => setHasAcceptedRules(e.target.checked)}
        />
        {intl.messages[`register.step.codeOfHonor.hasAcceptedRules`]}
      </label>
      <ButtonDarkRed
        data-cy="submit"
        onClick={() => {
          onClickContinue({
            code_of_conduct_time: true
          });
        }}
        secondary
        disabled={!hasAcceptedRules}
      >
        {intl.messages[`register.step.codeOfHonor.next`]}
      </ButtonDarkRed>
    </Container>
  );
};

StepCodeOfHonor.defaultProps = { onClickContinue: {} };
StepCodeOfHonor.propTypes = { onClickContinue: FuncPropType };

export default StepCodeOfHonor;
