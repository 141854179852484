import PropTypes from 'prop-types';

export const InvoicesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    fileName: PropTypes.string,
    invoice_number: PropTypes.string,
    creation_date: PropTypes.string,
    jobs_count: PropTypes.number,
    amount: PropTypes.number,
    structured_communication: PropTypes.string,
    payment_status: PropTypes.string,
    due_date: PropTypes.string,
    payment_link: PropTypes.string,
    payment_date: PropTypes.string,
    payment_method: PropTypes.string
  })
).isRequired;

export const UserPropType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  companyname: PropTypes.string,
  email: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  id: PropTypes.number,
  locale: PropTypes.string,
  mobile_phone: PropTypes.string,
  phone: PropTypes.string,
  treetrades: PropTypes.arrayOf(PropTypes.number),
  service_radius: PropTypes.number,
  vat_number: PropTypes.string,
  iban: PropTypes.string,
  website_url: PropTypes.string,
  zip: PropTypes.string,
  radius_zip: PropTypes.string,
  free_leads_remaining: PropTypes.number,
  traderCoupons: PropTypes.arrayOf(PropTypes.object),
  activityStatus: PropTypes.string,
  leadSourceId: PropTypes.string,
  vatValidationState: PropTypes.string,
  credits_remaining: PropTypes.number,
  matching_locales: PropTypes.arrayOf(PropTypes.string),
  bought_nb: PropTypes.number,
  free_leads_used: PropTypes.number,
  invoice_later_authorization: PropTypes.bool
}).isRequired;

export const ServiceRequestPropTypes = PropTypes.shape({
  id: PropTypes.number,
  dateSubmitted: PropTypes.string,
  trade: PropTypes.number,
  postcode: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  ),
  traderAlreadySeen: PropTypes.bool,
  traderAlreadyEstimated: PropTypes.bool,
  price_cents: PropTypes.number,
  estimates: PropTypes.shape({
    submitted: PropTypes.number,
    maximum: PropTypes.number
  }),
  customer: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    postcode: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string
  }),
  estimate: PropTypes.shape({
    min_estimate: PropTypes.number,
    max_estimate: PropTypes.number,
    start_date: PropTypes.string
  }),
  townNames: PropTypes.shape({
    nl: PropTypes.string,
    fr: PropTypes.string
  }),
  freeTraderLeadsRemaining: PropTypes.number,
  job_date: PropTypes.string,
  additionalInfo: PropTypes.string,
  rooms: PropTypes.oneOf([null]),
  materials: PropTypes.oneOf([null]),
  windows: PropTypes.oneOf([null]),
  casingType: PropTypes.oneOf([null]),
  windowType: PropTypes.oneOf([null]),
  sr_status: PropTypes.string,
  is_on_sale: PropTypes.bool,
  cart_id: PropTypes.number,
  category: PropTypes.arrayOf(PropTypes.string),
  category_fr: PropTypes.arrayOf(PropTypes.string),
  category_nl: PropTypes.arrayOf(PropTypes.string),
  original_price: PropTypes.number,
  creditsTraderRemaining: PropTypes.number
}).isRequired;

export const CartItemPropType = PropTypes.shape({
  id: PropTypes.number,
  cart_id: PropTypes.number,
  service_request_id: PropTypes.number,
  workflow_state: PropTypes.string,
  price_cents: PropTypes.number,
  postcode: PropTypes.string,
  published_date: PropTypes.string,
  additionalInfo: PropTypes.string,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  ),
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string
    })
  ),
  townNames: PropTypes.shape({ nl: PropTypes.string, fr: PropTypes.string }),
  proposed_by: PropTypes.string,
  category: PropTypes.arrayOf(PropTypes.string),
  category_fr: PropTypes.arrayOf(PropTypes.string),
  category_nl: PropTypes.arrayOf(PropTypes.string),
  original_price: PropTypes.number
});

export const CartPropType = PropTypes.shape({
  cart_price: PropTypes.number,
  cart_size: PropTypes.number,
  data: PropTypes.arrayOf(CartItemPropType),
  non_available_anymore_sr: PropTypes.number
});

export const ArrayStringPropType = PropTypes.arrayOf(PropTypes.string);

export const BoolPropType = PropTypes.bool;
export const StringPropType = PropTypes.string;
export const NumberPropType = PropTypes.number;
export const FuncPropType = PropTypes.func;
export const ArrayPropType = PropTypes.array;
export const ObjectPropType = PropTypes.object;
