import styled from 'styled-components';

interface IJaimyTextAreaProps {
  margin?: string;
}

const TextArea = styled.textarea<IJaimyTextAreaProps>`
  margin: ${props => (props.margin ? props.margin : '2rem auto')};
  min-height: 128px;
  width: 100%;
  background-color: #f3f4f6;
  border: 1px solid #d8dae0;
  border-radius: 4px;
  color: #32546d;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 0.4rem;
  resize: none;

  @media (min-width: 1200px) {
    min-width: 700px;
  }
`;

export default TextArea;
