import styled from 'styled-components';

interface IJaimyGridProps {
  alignItems?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderTop?: string;
  columnGap?: string;
  columns?: string;
  columnsSm?: string;
  gridGap?: string;
  height?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  rowGap?: string;
  rows?: string;
  rowsSm?: string;
  maxWidth?: string;
}

const JaimyGrid = styled.div<IJaimyGridProps>`
  align-items: ${props => props.alignItems && props.alignItems};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  border-color: ${props => props.borderColor && props.borderColor};
  border-top: ${props => props.borderTop && props.borderTop};
  column-gap: ${props => props.columnGap && props.columnGap};
  display: grid;
  grid-gap: ${props => props.gridGap && props.gridGap};
  grid-template-columns: ${props => props.columns && props.columns};
  grid-template-rows: ${props => props.rows && props.rows};
  height: ${props => props.height && props.height};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  row-gap: ${props => props.rowGap && props.rowGap};
  max-width: ${props => props.maxWidth && props.maxWidth};
  width: 100%;

  @media screen and (max-width: 767px) {
    grid-template-columns: ${props => props.columnsSm && props.columnsSm};
    grid-template-rows: ${props => props.rowsSm && props.rowsSm};
  }
`;

export default JaimyGrid;
