import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ButtonAddCart,
  ButtonMoreInfo,
  ButtonPrimaryBuy,
  ButtonPrimaryDeny
} from '../../../components/common/Button';
import { H1TitleBelfius, SpanOriginalPrice } from '../../../components/common/Card';
import {
  DivHeader,
  H2Subtitle,
  H4Title,
  SectionTopHeader
} from '../../../components/common/Header';
import { CHECKOUT_ERRORS, VAT_VALIDATION_ERRORS } from '../../../util/constants';
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { productParser, raisePageEvent } from '../../../logging/analytics';
import {
  addServiceToCart,
  buyService,
  checkoutStart,
  invoiceService,
  removeFromCart
} from '../../../store/reducers/cart/actions';
import {
  addServiceFavorite,
  requestJobById,
  resetJob,
  serviceNotInterested,
  serviceUpdateId,
  serviceUseFreeLead
} from '../../../store/reducers/services/actions';
import { formatSubtitle, formatTitle, showSubtitle } from '../../../util/cardHelpers';
import { calculateJobPrice, centsToEuro } from '../../../util/priceCents';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ArchiveIcon } from '../../../assets/images/archive-icon.svg';
import { ReactComponent as CartIcon } from '../../../assets/images/cart-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as FavoriteIcon } from '../../../assets/images/favorite-icon.svg';
import { ReactComponent as GiftIcon } from '../../../assets/images/gift-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/images/money-icon.svg';
import { ReactComponent as ReferenceIcon } from '../../../assets/images/reference-icon.svg';
import CheckedAlert from '../../../components/alerts/CheckedAlert';
import FreeLeadAlert from '../../../components/alerts/FreeLeadAlert';
import WaitingForInfoAlert from '../../../components/alerts/WaitingForInfo';
import GreenBanner from '../../../components/Banner/Green';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import Carousel from '../../../components/Carousel';
import CheckoutDialog from '../../../components/CheckoutDialog';
import { AnchorBack } from '../../../components/common/Link';
import ContactCard from '../../../components/ContactCard';
import MediaList from '../../../components/MediaList';
import { ComponentIsVisible } from '../../../components/utils/isVisible';
import {
  allowedToPayWithMollie,
  allowedToSeeAddCartButton,
  EVerifiedStatus
} from '../../../constants/verifiedStatus';
import { ROUTES } from '../../../routes';
import { validateCheckout } from '../../../util/businessLogicHelpers';

const useStyles = makeStyles({
  dialogRoot: {
    padding: '8px 8px',
    '&:first-child': {
      paddingTop: '8px'
    }
  },
  paper: {
    margin: '24px'
  },
  paperWidthSm: {
    // reduce the size of dialog container on mobile landscape view for the image to fit better
    '@media (max-height: 600px)': {
      maxWidth: '450px'
    }
  },
  scrollPaper: {
    maxHeight: 'calc(100% - 80px)'
  }
});

const DivWrapper = styled.div``;
const DivTitleWrapper = styled.div`
  padding: 0 1.2rem;
  @media (min-width: 800px) {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    h4,
    h1 {
      flex-basis: 100%;
    }

    h4,
    h2,
    h1 {
      padding: 0;
    }
  }
  @media (max-width: 800px) {
    h1 {
      flex-basis: 100%;
    }
    h4 {
      padding: 0;
    }
    margin-bottom: 1rem;
  }
`;
const ButtonHeader = styled.button`
  background: #f3f4f6;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 44px;
  margin: 0 0.3rem;
  cursor: pointer;

  svg {
    fill: ${props => (props.isActive ? '#C30144' : '#32546d')};
  }
`;
const PDescription = styled.p`
  color: #32546d;
  width: 100%;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: justify;
  span {
    font-weight: bold;
    padding-right: 1rem;
  }

  @media (min-width: 800px) {
    line-height: 24px;
  }
`;
const DivCardMain = styled.div`
  border-top: 1px solid #d8dae0;
  padding: 0 1rem;
  display: flex;
`;
const DivCardItem = styled.div`
  margin-bottom: 0.2rem;
  display: flex;
  padding: 0.2rem 0;
  align-items: baseline;

  & > span {
    font-family: 'Lato', sans-serif;
    align-self: center;

    &:nth-of-type(1) {
      width: 1rem;
      clear: both;
      display: inline-flex;
      justify-content: center;

      & > svg {
        fill: #b7c0c4;
      }
    }
    &:nth-of-type(2) {
      color: #c30144;
      text-transform: uppercase;
      font-style: normal;
      min-width: 6rem;
      font-weight: 900;
      letter-spacing: 0.1em;
      font-size: 10px;
      line-height: 18px;
      margin: 0 0.6rem;
      color: #c30144;
    }

    &:nth-of-type(3) {
      color: #32546d;
      font-weight: bold;
      font-size: ${props => (props.reference ? '14px' : '16px')};
    }
  }
`;

const DivMap = styled.div`
  > div {
    margin-top: 1rem;
    border-radius: 5px;
    width: 100%;
    height: 236px;
    background-color: #f2f2f2;
  }
`;
const ButtonCart = styled.button`
  border: 2px solid ${props => (props.inCart ? '#32546d' : '#c30144')};
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-top: 1rem;
  cursor: pointer;

  svg {
    fill: ${props => (props.inCart ? '#32546d' : '#c30144')};
  }

  svg:nth-of-type(2) {
    display: ${props => (props.inCart ? 'none' : 'inherit')};
  }

  :hover {
    background-color: ${props => (props.inCart ? '#32546d' : '#c30144')};
    border: ${props => (props.inCart ? 'solid 2px #32546d' : '0')};

    svg {
      fill: #fff;
    }

    svg:first-child {
      display: ${props => (props.inCart ? 'none' : 'inherit')};
    }
    svg:nth-of-type(2) {
      display: ${props => (props.inCart ? 'block' : 'inherit')};
    }
  }
`;

const ButtonCartFreeLead = styled(ButtonCart)`
  background-color: #86bcd3;
  border-color: #86bcd3;

  svg {
    fill: #fff;
  }
  :hover {
    border: 2px solid #86bcd3;
    background-color: #fff;
  }

  :hover {
    svg {
      fill: #86bcd3;
    }
  }
`;
const DivMargin = styled.div`
  margin-top: 4rem;
  padding: 1rem;

  @media (min-width: 800px) {
    width: 350px;
    margin-left: 1rem;
    margin-top: ${props => (props.isGuest ? '22rem' : '20rem')};
    padding: 1.2rem;

    > div {
      width: 350px;
    }
  }
`;
const DivMapPriceCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #d8dae0;
  border-radius: 5px;
  padding: 1rem;
  max-width: 100%;

  @media (min-width: 800px) {
    width: 350px;
    margin-left: 1rem;
    position: absolute;
    right: 0;
  }
  @media (max-width: 800px) {
    margin: 0 auto;
  }
`;
const DivActions = styled.div`
  display: flex;
  flex-direction: column;
  ${props => (props.isGuest ? 'margin-bottom: 5rem' : '')};

  button {
    margin-bottom: 0.6rem;
  }

  @media (min-width: 800px) {
    margin: -30px 350px 0 auto;
  }
  @media (max-width: 800px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;
const DivContent = styled.div`
  @media (min-width: 800px) {
    display: flex;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
`;
const DivMessage = styled.div`
  display: block;
  background-color: #c30144;
  color: white;
  border-radius: 4px;
  padding: 1rem 2rem;
  margin: 1rem;
  border: 1px #c30144 solid;

  @media (min-width: 800px) {
    display: flex;
    max-width: 1200px;
    width: 100%;
    margin: 1rem auto;
  }
`;
const SectionBuyActions = styled.section`
  display: flex;
  width: 80%;
  padding: 1rem 0;
  margin: 0 auto;

  button:nth-of-type(2) {
    margin-top: 0;
    margin-left: 0;
    width: 286px;
    ${props => (props.free_leads_remaining ? '' : 'justify-content: center')};
  }
  > button:first-of-type {
    flex-basis: ${props => (props.free_leads_remaining ? '50%' : '40%')};
    ${props =>
      props.free_leads_remaining
        ? 'display: flex; align-content: space-around; justify-content: center;'
        : ''}
  }
`;

const SectionBuyMobile = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;

  @media (min-width: 800px) {
    display: none;
  }

  > button {
    width: 286px;
    ${props => (props.free_leads_remaining ? '' : 'justify-content: center')};
  }
`;
const Diverror = styled.div`
  margin-bottom: 1.2rem;
  color: #c30b30;

  @media (min-width: 800px) {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto 1.2rem auto;
    padding: 0 1.2rem;
  }
`;
const DivDescriptionAndBuy = styled.div`
  min-width: 250px;
  flex-basis: 100%;
`;

const AnchorBackError = styled(Link)`
  color: #fff;
  text-decoration: underline;
  margin-left: 1rem;
  cursor: pointer;
`;

const SectionFreeLeadActions = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0.5rem auto;

  button:first-of-type {
    margin: 1rem 0;
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
    margin: 2rem auto;
    padding: 0 1.2rem;
    button:first-of-type {
      margin: 0 2rem 0 0;
    }
  }

  :disabled {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const FreeLeadDiv = styled.div`
  text-align: center;
  margin: 0.5rem 0 1rem;
  @media (min-width: 800px) {
    margin: 2rem 1rem;
  }
`;
const ButtonPrimaryBuyFreeLead = styled(ButtonPrimaryBuy)`
  > span {
    margin: 0;
  }
`;
const DivFormWrapper = styled.div`
  label {
    color: #32546d;
    margin-top: 1rem;

    > span {
      :nth-of-type(2) {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;
const PGuestMedia = styled.p`
  padding: 1rem 1rem 0 1rem;
  margin: 0 auto;
  max-width: 1200px;
  color: #32546d;
  font-family: Lato;
  font-size: 14px;
`;

const AnchorErrorLink = styled(Link)`
  text-decoration: underline;
  color: inherit;
`;

const FreeLeadModal = ({
  open,
  setIsFreeLeadModalOpen,
  freeLeadsRemaining,
  onBuyItem,
  isCoupon
}) => (
  <Dialog data-cy-dialog="free-lead" open={open} onClose={() => setIsFreeLeadModalOpen(false)}>
    <DialogContent>
      <FreeLeadDiv>
        <H4Title>
          <FormattedMessage tagName="span" id="servicecardlist.free_leads.title" />
        </H4Title>
        <H2Subtitle isFreeLead>
          <FormattedMessage
            tagName="span"
            id={`servicecardlist.free_leads${isCoupon ? '.coupon' : ''}.subtitle`}
            values={{ count: freeLeadsRemaining || 0 }}
          />
        </H2Subtitle>
        <DivFormWrapper>
          <SectionFreeLeadActions>
            <ButtonPrimaryBuyFreeLead
              data-cy-button="buy-free-lead"
              isFreeLead
              secondary
              type="submit"
              onClick={() => onBuyItem()}
            >
              <FormattedMessage tagName="span" id="servicecardlist.free_leads.confirm" />
            </ButtonPrimaryBuyFreeLead>
            <ButtonPrimaryDeny
              data-cy-button="cancel-buy"
              onClick={() => setIsFreeLeadModalOpen(false)}
            >
              <FormattedMessage tagName="span" id="servicecardlist.free_leads.deny" />
            </ButtonPrimaryDeny>
          </SectionFreeLeadActions>
        </DivFormWrapper>
      </FreeLeadDiv>
    </DialogContent>
  </Dialog>
);

const Service = ({
  cart,
  addFavorite,
  notInterested,
  addToCart,
  removeFromCart,
  buySR,
  requestJobById,
  resetJob,
  user,
  useFreeLead,
  free_leads_remaining,
  isCoupon,
  couponValue,
  credits,
  invoiceLaterService,
  checkoutStart,
  services: { noLongerAvailable, jobData, jobError }
}) => {
  let map;
  const {
    additionalInfo,
    traderAlreadyEstimated,
    price_cents,
    original_price,
    postcode,
    townNames,
    photos,
    id,
    favorite,
    job_date,
    is_on_sale,
    more_than_48_hours
  } = jobData;

  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const classes = useStyles();
  const title = formatTitle(jobData, user.locale);
  const subtitle = formatSubtitle(jobData, user.locale);
  const mapRef = useRef(null);

  const [cartId, setCartId] = useState(jobData.cart_id);
  const [isFreeLeadModalOpen, setIsFreeLeadModalOpen] = useState(false);
  const [isGuest, setGuest] = useState(true);

  const filesOfTypeImage = useMemo(() => {
    if (!photos || photos.length === 0) return [];
    return photos.filter(item => item.type !== 'video');
  }, [photos]);

  const filesOfTypeVideo = useMemo(() => {
    if (!photos || photos.length === 0) return [];
    return photos.filter(item => item.type === 'video');
  }, [photos]);

  const mediaItems = [...(photos || [])];
  const [showMediaIndex, setShowMediaIndex] = useState(-1);
  let town = '';
  if (townNames && user) {
    town = user.locale === 'fr' ? townNames.fr : townNames.nl;
  }
  const postcodeFormatted = postcode || '';
  const [isInterested, setIsInterested] = useState(false);
  const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);
  const isValidCoupon = isCoupon ? couponValue >= price_cents / 100 : true;
  const metaTitle = intl.messages['jobspage.id.title'];

  useEffect(() => {
    const fetchData = async () => {
      const isGuest = !Boolean(user.id);
      setGuest(isGuest);
      const { id } = params;
      const data = await requestJobById({ id, isGuest });
      if (data?.redirect) {
        history.replace(ROUTES.JOBS.handler());

        return;
      }
      if (data) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'Product Viewed',
            data: productParser(data)
          },
          dataLayerName: 'ProDataLayer'
        });
        if (data.traderAlreadyEstimated) {
          history.push(ROUTES.PURCHASED_ID.handler(id));
        }
      }
      window.scrollTo({ top: 0 });
    };
    fetchData();
    raisePageEvent({ title: metaTitle });
    return () => {
      resetJob();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobData.cart_id) setCartId(jobData.cart_id);
  }, [jobData.cart_id]);
  useEffect(() => {
    if (cart && cart.redirect_url) {
      window.location.href = cart.redirect_url;
    }
  }, [cart, cart.redirect_url]);

  //CDM
  useEffect(() => {
    try {
      const googleMaps = async () => {
        const request = {
          query: `${town}, Belgium`,
          fields: ['geometry']
        };
        try {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          map = new google.maps.Map(mapRef.current, {
            zoom: 12,
            center: { lat: 50.8550625, lng: 4.3053506 },
            mapTypeId: 'terrain',
            disableDefaultUI: true
          });
          const service = new google.maps.places.PlacesService(map);
          service.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              new google.maps.Circle({
                strokeColor: '#C30B30',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#C30B30',
                fillOpacity: 0.35,
                map,
                center: results[0].geometry.location,
                radius: 1000
              });
              map.setCenter(results[0].geometry.location);
            }
          });
        } catch (err) {
          console.error("Couldn't load google maps, probably due to bad or no network connection");
        }
      };
      if (postcode) {
        googleMaps();
      }
    } catch (err) {
      console.error({ err });
    }
  }, [postcode]);

  const handleAddToCart = e => {
    if (isGuest) {
      history.push(ROUTES.LOGIN.handler(), { from: location.pathname });
    } else if (cartId) {
      removeFromCart(cartId);
      setCartId(null);
    } else {
      addToCart(e);
      setTimeout(() => {
        setCartId(
          cart.data.find(e => e.service_request_id === id)
            ? cart.data.find(e => e.service_request_id === id).cart_id
            : null
        );
      }, 500);
    }
  };
  const checkoutEvent = (name, timestamp) => {
    const { totalCreditsUsed, totalPaymentExclVat, totalPaymentInclVat } = calculateJobPrice(
      jobData.price_cents,
      user.credits_remaining
    );
    checkoutStart({
      name,
      products: [jobData],
      totalPaymentInclVat,
      totalPaymentExclVat,
      discount: totalCreditsUsed,
      timestamp
    });
  };

  const invoiceLater = async () => {
    const timestamp = new Date().getTime();
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Checkout Started',
          timestamp: timestamp
        },
        dataLayerName: 'ProDataLayer'
      });
      await invoiceLaterService({ credits, id, timestamp, discount_of });
    } catch (error) {
      setShowCheckoutDialog(false);
      await requestJobById({ id });
      checkoutEvent('Order Cancelled', timestamp);
    }
  };
  const onBuyItem = async () => {
    const timestamp = new Date().getTime();
    if (isGuest) {
      history.push(ROUTES.LOGIN.handler(), { from: location.pathname });
    } else if (free_leads_remaining > 0 && isValidCoupon) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useFreeLead(id);
    } else {
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: 'Checkout Started',
            timestamp: timestamp
          },
          dataLayerName: 'ProDataLayer'
        });
        await buySR({
          id,
          credits: user.credits_remaining,
          timestamp,
          discount_of: cart.discount_of
        });
      } catch (error) {
        setShowCheckoutDialog(false);
        await requestJobById({ id });
        checkoutEvent('Order Cancelled', timestamp);
      }
    }
  };
  const onTrashJob = async () => {
    if (!isInterested) {
      notInterested(id);
      setIsInterested(true);
      history.push(ROUTES.JOBS.handler());
    }
  };
  const isFreeLead = free_leads_remaining && free_leads_remaining > 0 && isValidCoupon;
  const showFreeleadButtonContent = isFreeLead && cart && !cart.isLoading;
  const { disableCheckout, checkoutAlerts } = validateCheckout(price_cents, user);

  // add conditions to know if additionalInfo is free format or structured format

  // useEffect(() => {
  //   if (!additionalInfo) return;
  //   additionalInfo.split('\n').map(property => {
  //     if (additionalInfo.split('\n').length > 1) {
  //       return (
  //         <p>
  //           {property.split(': ')[0]}: {property.split(': ')[0]}
  //         </p>
  //       );
  //     } else {
  //       console.log(property);
  //       return <p>{property}</p>;
  //     }
  //   });
  // }, [additionalInfo]);

  const hasShowCartButton = useMemo(() => {
    const isAllowedBasedOnVerifiedStatus = allowedToSeeAddCartButton.includes(user.verified_status);
    if (isAllowedBasedOnVerifiedStatus) {
      return isAllowedBasedOnVerifiedStatus;
    }

    const isVerifiedStatusCheckedAndHasPurchacesLeft =
      user.verified_status === EVerifiedStatus.Checked && user.checked_purchases_left > 0;
    if (isVerifiedStatusCheckedAndHasPurchacesLeft) {
      return isVerifiedStatusCheckedAndHasPurchacesLeft;
    }

    const isVerifiedStatusWaitForInfoHasPurchasesLeft =
      user.verified_status === EVerifiedStatus.RestrictedWaitForInfo &&
      user.wait_for_info_purchases_left > 0;
    if (isVerifiedStatusWaitForInfoHasPurchasesLeft && price_cents <= 4000) {
      return isVerifiedStatusWaitForInfoHasPurchasesLeft;
    }

    return false;
  }, [
    price_cents,
    user.checked_purchases_left,
    user.verified_status,
    user.wait_for_info_purchases_left
  ]);

  const hasShowBuyButton = useMemo(() => {
    const isAllowedBasedOnVerifiedStatus = allowedToPayWithMollie.includes(user.verified_status);
    return isAllowedBasedOnVerifiedStatus;
  }, [user.verified_status]);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivWrapper>
        <DivHeader>
          {traderAlreadyEstimated && (
            <DivMessage>
              <FormattedMessage tagName="span" id="servicespage.already_bought_redirect" />
            </DivMessage>
          )}
          {(noLongerAvailable || jobError) && (
            <DivMessage>
              <p>
                <FormattedMessage tagName="span" id="servicespage.no_longer_available" />
              </p>
              <AnchorBackError to={`${ROUTES.JOBS.handler()}`}>
                <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
              </AnchorBackError>
            </DivMessage>
          )}
          {isValidCoupon && <FreeLeadAlert style={{ paddingTop: '1rem' }} />}
          <CheckedAlert style={{ paddingTop: '1rem' }} />
          <WaitingForInfoAlert />
          <SectionTopHeader>
            {!isGuest && !isMobile && (
              <Breadcrumbs>
                <Link to={`${ROUTES.HOME.handler()}`}>
                  <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
                </Link>
                <Link to={`${ROUTES.JOBS.handler()}`}>
                  <FormattedMessage tagName="span" id="breadcrumbs.link.job_list" />
                </Link>
                <Link to={`${ROUTES.JOBS_ID.handler(id)}`}>
                  <FormattedMessage tagName="span" id="breadcrumbs.link.job_detail" />
                </Link>
              </Breadcrumbs>
            )}
            {isMobile && (
              <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
                <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
              </AnchorBack>
            )}
            {isMobile && (
              <div>
                <ButtonHeader isActive={isInterested} onClick={onTrashJob}>
                  <ArchiveIcon width={14} height={14} />
                </ButtonHeader>
                <ButtonHeader isActive={favorite} onClick={() => addFavorite(id)}>
                  <FavoriteIcon width={14} height={14} />
                </ButtonHeader>
              </div>
            )}
          </SectionTopHeader>
          <DivTitleWrapper>
            {showSubtitle(jobData) ? (
              <>
                <H4Title>{title}</H4Title>
                <H1TitleBelfius isMobile={isMobile}>{subtitle}</H1TitleBelfius>
                {is_on_sale && <GreenBanner content="banner.active.message" />}
                {!more_than_48_hours && <GreenBanner content="banner.new.message" />}
              </>
            ) : (
              <>
                <H1TitleBelfius isMobile={isMobile}>{title}</H1TitleBelfius>
                {is_on_sale && <GreenBanner content="banner.active.message" />}
                {!more_than_48_hours && <GreenBanner content="banner.new.message" />}
              </>
            )}
            {!isMobile && (
              <>
                <DivActions isGuest={isGuest}>
                  {!isGuest && (
                    <>
                      <ButtonHeader isActive={isInterested} onClick={onTrashJob}>
                        <ArchiveIcon width={14} height={14} />
                      </ButtonHeader>
                      <ButtonHeader isActive={favorite} onClick={() => addFavorite(id)}>
                        <FavoriteIcon width={14} height={14} />
                      </ButtonHeader>
                    </>
                  )}
                </DivActions>
                <DivMapPriceCard>
                  {(!free_leads_remaining || (free_leads_remaining && !isValidCoupon)) && (
                    <DivCardItem>
                      <span>
                        <MoneyIcon />
                      </span>
                      <span>
                        <FormattedMessage tagName="span" id="servicecardlist.card.price" />
                      </span>
                      {price_cents && (
                        <FormattedMessage
                          tagName="span"
                          id="servicecardlist.card.price.euro"
                          values={{
                            price: centsToEuro(price_cents)
                          }}
                        />
                      )}

                      {!!original_price && (
                        <SpanOriginalPrice>
                          <FormattedMessage
                            tagName="span"
                            id="servicecardlist.card.price.euro"
                            values={{
                              price: centsToEuro(original_price)
                            }}
                          />
                        </SpanOriginalPrice>
                      )}
                    </DivCardItem>
                  )}
                  <DivCardItem>
                    <span>
                      <LocationIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.location" />
                    </span>
                    <span>{`${postcodeFormatted} ${town}`}</span>
                  </DivCardItem>
                  <DivCardItem>
                    <span>
                      <ClockIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="serviceestimated.jobdate.label" />
                    </span>
                    {job_date && (
                      <FormattedMessage
                        tagName="span"
                        id={`serviceestimated.jobdate.${job_date}`}
                      />
                    )}
                  </DivCardItem>
                  <DivCardItem reference>
                    <span>
                      <ReferenceIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="serviceestimated.reference" />
                    </span>
                    <span>{id}</span>
                  </DivCardItem>
                  <DivMap id="map">
                    <div ref={mapRef} />
                  </DivMap>
                </DivMapPriceCard>
              </>
            )}
          </DivTitleWrapper>
          {isMobile && (
            <DivCardMain>
              <div>
                {(!free_leads_remaining || (free_leads_remaining && !isValidCoupon)) && (
                  <DivCardItem>
                    <span>
                      <MoneyIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.price" />
                    </span>
                    {price_cents && (
                      <FormattedMessage
                        tagName="span"
                        id="servicecardlist.card.price.euro"
                        values={{
                          price: centsToEuro(price_cents)
                        }}
                      />
                    )}
                    {!!original_price && (
                      <SpanOriginalPrice>
                        <FormattedMessage
                          tagName="span"
                          id="servicecardlist.card.price.euro"
                          values={{
                            price: centsToEuro(original_price)
                          }}
                        />
                      </SpanOriginalPrice>
                    )}
                  </DivCardItem>
                )}
                <DivCardItem>
                  <span>
                    <LocationIcon />
                  </span>
                  <span>
                    <FormattedMessage tagName="span" id="servicecardlist.card.location" />
                  </span>
                  <span>{`${postcodeFormatted} ${town}`}</span>
                </DivCardItem>
                <DivCardItem>
                  <span>
                    <ClockIcon />
                  </span>
                  <span>
                    <FormattedMessage tagName="span" id="serviceestimated.jobdate.label" />
                  </span>
                  {job_date && (
                    <FormattedMessage tagName="span" id={`serviceestimated.jobdate.${job_date}`} />
                  )}
                </DivCardItem>
                <DivCardItem reference>
                  <span>
                    <ReferenceIcon />
                  </span>
                  <span>
                    <FormattedMessage tagName="span" id="serviceestimated.reference" />
                  </span>
                  <span>{id}</span>
                </DivCardItem>
              </div>
              {!jobError && !noLongerAvailable && (
                <>
                  {!isGuest &&
                    (!free_leads_remaining || (free_leads_remaining && !isValidCoupon)) &&
                    cartId && (
                      <ButtonCart data-cy-button="job-in-cart" inCart onClick={handleAddToCart}>
                        <CheckIcon />
                        <CloseIcon width={24} height={24} viewBox="0 0 24 24" />
                      </ButtonCart>
                    )}
                  {(!free_leads_remaining || (free_leads_remaining && !isValidCoupon)) && !cartId && (
                    <ButtonCart onClick={() => handleAddToCart(jobData)}>
                      <CartIcon />
                    </ButtonCart>
                  )}
                </>
              )}
              {!!free_leads_remaining && isValidCoupon && (
                <ButtonCartFreeLead onClick={() => setIsFreeLeadModalOpen(true)} isDetailPage>
                  <GiftIcon width={24} height={24} viewBox="0 0 24 24" />
                </ButtonCartFreeLead>
              )}
            </DivCardMain>
          )}
          {!isGuest && <MediaList media={photos || []} setShowMediaIndex={setShowMediaIndex} />}
          {isGuest && (!!filesOfTypeVideo || !!filesOfTypeImage) && (
            <PGuestMedia>
              <FormattedMessage
                id="service.details.guest_no_media"
                values={{
                  videos: (!!filesOfTypeVideo && filesOfTypeVideo.length) || 0,
                  images: (!!filesOfTypeImage && filesOfTypeImage.length) || 0,
                  are: !!filesOfTypeVideo
                    ? filesOfTypeVideo.length > 1
                      ? 1
                      : 0
                    : !!filesOfTypeImage && filesOfTypeImage.length > 1
                    ? 1
                    : 0,
                  and: !!filesOfTypeVideo && !!filesOfTypeImage ? 1 : 0
                }}
                defaultMessage="There is media for this job, log in to view"
              />
            </PGuestMedia>
          )}
        </DivHeader>
        <DivContent>
          {jobError || noLongerAvailable ? (
            <Diverror>
              <FormattedMessage
                id="question.error.unexpected"
                defaultMessage="Something went wrong"
              />
            </Diverror>
          ) : (
            <DivDescriptionAndBuy>
              {additionalInfo &&
              additionalInfo.includes(':') &&
              additionalInfo.split('\n').length > 1 ? (
                additionalInfo
                  .split('\n')
                  .slice(0, -1)
                  .map((property, i) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          gap: '.5rem',
                          padding: '.5rem 0',
                          borderBottom: '1px solid #32546d50',
                          margin: '0 auto',
                          width: '80%'
                        }}
                        key={i}
                      >
                        <PDescription>
                          <span>{property.split(': ')[0]}:</span>
                        </PDescription>
                        <PDescription>{property.split(': ')[1]}</PDescription>
                      </div>
                    );
                  })
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '.5rem',
                    padding: '.5rem 0',
                    borderBottom: '1px solid #32546d50',
                    margin: '0 auto',
                    width: '80%'
                  }}
                >
                  <PDescription>{additionalInfo}</PDescription>
                </div>
              )}
              {isMobile && (
                <DivMap id="map">
                  <div ref={mapRef} />
                </DivMap>
              )}
              {cart && cart.jobError && (
                <Diverror>
                  <FormattedMessage
                    id="checkout.error.message"
                    defaultMessage="Something went wrong"
                  />
                </Diverror>
              )}
              {!disableCheckout &&
                cart.error &&
                VAT_VALIDATION_ERRORS.includes(user.vatValidationState) && (
                  <Diverror>
                    <FormattedMessage
                      tagName="p"
                      id="cart.error.invalid_vat"
                      values={{
                        profile: (
                          <AnchorErrorLink to={ROUTES.PROFILE.handler()}>
                            <FormattedMessage tagName="span" id="navbutton.link.profile" />
                          </AnchorErrorLink>
                        )
                      }}
                    />
                  </Diverror>
                )}
              {CHECKOUT_ERRORS.includes(cart.error) && (
                <Diverror>
                  <FormattedMessage tagName="p" id={`cart.error.${cart.error}`} />
                </Diverror>
              )}

              <ComponentIsVisible when={checkoutAlerts[EVerifiedStatus.Checked]}>
                <Diverror>
                  <strong>
                    <FormattedMessage tagName="span" id="general.error.checked.not_so_fast.pre" />
                  </strong>
                  <FormattedMessage
                    tagName="span"
                    id="general.error.checked.not_so_fast.description"
                    values={{
                      link: (
                        <FormattedMessage tagName="span" id="general.error.checked.verify_link" />
                      ),
                      count: user.checked_purchases_left
                    }}
                    s
                  />
                </Diverror>
              </ComponentIsVisible>

              <ComponentIsVisible when={checkoutAlerts[EVerifiedStatus.RestrictedWaitForInfo]}>
                <Diverror>
                  <strong>
                    <FormattedMessage
                      tagName="span"
                      id="general.error.missingInfo.not_so_fast.pre"
                    />
                  </strong>
                  <FormattedMessage
                    tagName="span"
                    id="general.error.missingInfo.not_so_fast.description"
                    values={{
                      link: (
                        <FormattedMessage
                          tagName="span"
                          id="general.error.missingInfo.verify_link"
                        />
                      ),
                      count: user.wait_for_info_purchases_left
                    }}
                    s
                  />
                </Diverror>
              </ComponentIsVisible>

              {!isMobile && (
                <SectionBuyActions
                  free_leads_remaining={!!free_leads_remaining && free_leads_remaining > 0}
                >
                  {' '}
                  <ComponentIsVisible when={hasShowCartButton}>
                    {!isGuest &&
                    (!free_leads_remaining || (free_leads_remaining && !isValidCoupon)) &&
                    cartId ? (
                      <ButtonAddCart
                        data-cy-button="job-in-cart"
                        inCart
                        isDetailPage
                        onClick={handleAddToCart}
                      >
                        <CheckIcon />
                        <CloseIcon width={24} height={24} viewBox="0 0 24 24" />
                        {!isMobile && (
                          <FormattedMessage tagName="span" id="servicecardlist.card.added_cart" />
                        )}
                      </ButtonAddCart>
                    ) : (
                      <ButtonAddCart
                        data-cy-button="add-to-cart"
                        onClick={() => handleAddToCart(jobData)}
                        isDetailPage
                      >
                        {cart.isLoading && <CircularProgress size={20} color="inherit" />}
                        {!cart.isLoading && (
                          <>
                            <CartIcon />
                            {!isMobile && (
                              <FormattedMessage tagName="span" id="servicecardlist.card.add_cart" />
                            )}
                          </>
                        )}
                      </ButtonAddCart>
                    )}
                  </ComponentIsVisible>
                  <ComponentIsVisible when={hasShowBuyButton}>
                    <ButtonMoreInfo
                      inCart={cartId}
                      data-cy-button="buy-directly"
                      isFreeLead={isFreeLead}
                      secondary={isFreeLead}
                      disabled={(cart && cart.isLoading) || disableCheckout}
                      onClick={() => {
                        if (isFreeLead) {
                          setIsFreeLeadModalOpen(true);
                        } else if (
                          user.invoice_later_authorization &&
                          credits < price_cents / 100
                        ) {
                          setShowCheckoutDialog(true);
                        } else {
                          onBuyItem();
                        }
                      }}
                    >
                      {cart && cart.isLoading && <CircularProgress size={10} />}
                      {!showFreeleadButtonContent && (
                        <FormattedMessage
                          tagName="span"
                          id={
                            credits >= price_cents / 100
                              ? 'servicespage.buy_job.credits'
                              : 'servicespage.buy_job'
                          }
                        />
                      )}
                      {Boolean(showFreeleadButtonContent) && (
                        <>
                          <GiftIcon width={24} height={24} viewBox="0 0 24 24" />
                          <FormattedMessage
                            tagName="span"
                            id={`servicespage.buy_job${isCoupon ? '.coupon' : ''}.free`}
                            defaultMessage="Get Free"
                          />
                        </>
                      )}
                    </ButtonMoreInfo>
                  </ComponentIsVisible>
                </SectionBuyActions>
              )}
            </DivDescriptionAndBuy>
          )}
          {isMobile && (
            <ComponentIsVisible when={hasShowCartButton}>
              <SectionBuyMobile free_leads_remaining={free_leads_remaining && isValidCoupon}>
                {((!!free_leads_remaining && free_leads_remaining > 0) || isValidCoupon) && (
                  <ButtonMoreInfo
                    inCart={cartId}
                    data-cy-button="buy-directly"
                    isFreeLead={!!free_leads_remaining && free_leads_remaining > 0 && isValidCoupon}
                    onClick={() => {
                      if (!!free_leads_remaining && free_leads_remaining > 0 && isValidCoupon) {
                        setIsFreeLeadModalOpen(true);
                      } else if (user.invoice_later_authorization) {
                        setShowCheckoutDialog(true);
                      } else {
                        onBuyItem();
                      }
                    }}
                  >
                    {!showFreeleadButtonContent && (
                      <FormattedMessage tagName="span" id="servicespage.buy_job" />
                    )}
                    {showFreeleadButtonContent && (
                      <>
                        <GiftIcon width={24} height={24} viewBox="0 0 24 24" />
                        <FormattedMessage
                          tagName="span"
                          id={`servicespage.buy_job${isCoupon ? '.coupon' : ''}.free`}
                          defaultMessage="Get Free"
                        />
                      </>
                    )}
                  </ButtonMoreInfo>
                )}
              </SectionBuyMobile>
            </ComponentIsVisible>
          )}
          {!(noLongerAvailable || jobError) && (
            <DivMargin isGuest={isGuest}>
              <ContactCard
                id={id}
                title={<FormattedMessage tagName="span" id="servicespage.contact_us" />}
              >
                <FormattedMessage tagName="span" id="servicespage.contact_us.description" />
              </ContactCard>
            </DivMargin>
          )}
        </DivContent>
        <CheckoutDialog
          open={showCheckoutDialog}
          handleClose={() => setShowCheckoutDialog(false)}
          payWithCard={onBuyItem}
          invoiceLater={invoiceLater}
          isLoading={cart.isLoading}
          userVerifiedStatus={user.verified_status}
        />
      </DivWrapper>
      {mediaItems.length > 0 && (
        <Carousel
          mediaItems={mediaItems}
          selectedIndex={showMediaIndex}
          isMobile={isMobile}
          classes={{
            root: classes.dialogRoot,
            paper: classes.paper,
            paperWidthSm: classes.paperWidthSm,
            scrollPaper: classes.scrollPaper
          }}
          closeMediaModal={() => setShowMediaIndex(-1)}
        />
      )}
      <FreeLeadModal
        open={isFreeLeadModalOpen}
        setIsFreeLeadModalOpen={setIsFreeLeadModalOpen}
        freeLeadsRemaining={free_leads_remaining}
        onBuyItem={onBuyItem}
        isCoupon={isCoupon}
      />
    </>
  );
};

const mapStateToProps = ({ services, user: { user }, cart }) => ({
  services,
  cart,
  user,
  free_leads_remaining: user.free_leads_remaining,
  isCoupon: user.isCoupon,
  couponValue: user.couponValue,
  credits: user.credits_remaining / 100
});
const mapDispatchToProps = dispatch => ({
  update: payload => dispatch(serviceUpdateId(payload)),
  addFavorite: payload => dispatch(addServiceFavorite(payload)),
  notInterested: payload => dispatch(serviceNotInterested(payload)),
  addToCart: payload => dispatch(addServiceToCart(payload)),
  removeFromCart: payload => dispatch(removeFromCart(payload)),
  buySR: payload => dispatch(buyService(payload)),
  useFreeLead: payload => dispatch(serviceUseFreeLead(payload)),
  invoiceLaterService: payload => dispatch(invoiceService(payload)),
  requestJobById: payload => dispatch(requestJobById(payload)),
  resetJob: () => dispatch(resetJob()),
  checkoutStart: payload => dispatch(checkoutStart(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Service);
