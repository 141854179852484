import { API_URL, AUTH_URL } from '../../util/url';
/* eslint-disable class-methods-use-this */
import { getTraderId, handleResult } from '../../util/serviceHelpers';

import { getToken } from '../../util/tokenProvider';

class TraderService {
  /**
   * Create trader (sign up)
   */
  async create(body) {
    return fetch(`${API_URL}/api/traders/v1/traders/create`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        ...body
      })
    }).then(handleResult);
  }

  /**
   * Update trader profile
   */
  async update(body) {
    const id = getTraderId();
    return fetch(`${API_URL}/api/traders/v1/traders/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({
        ...body
      })
    }).then(handleResult);
  }

  /**
   * Get teamleader link
   */
  async getTeamleaderUrl() {
    return fetch(`${API_URL}/api/traders/v1/traders/teamleader_redirect_url`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Resend onboarding mail
   */
  resendOnboardingMail(body) {
    return fetch(`${AUTH_URL}/api/users/onboarded_email?trader_id=${body.id}`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(body)
    }).then(handleResult);
  }

  /**
   * Get invoices
   */
  async getInvoices() {
    return fetch(`${API_URL}/api/traders/v1/traders/${getTraderId()}/invoices`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }
  /**
   * Get prebooks
   */
  async getPrebooks() {
    return fetch(`${API_URL}/api/traders/v1/traders/${getTraderId()}/prebooks`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }
  /**
   * Update prebooks
   */
  async putPrebooks(body) {
    return fetch(`${API_URL}/api/traders/v1/traders/${getTraderId()}/prebook`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({
        ...body
      })
    }).then(handleResult);
  }
  /**
   * Get relevant jobs
   */
  async relevantJobs() {
    return fetch(`${API_URL}/api/traders/v1/traders/${getTraderId()}/relevant_jobs`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   *  Grade a SR by a trader
   */
  async postSrGradeByTrader(body) {
    return fetch(`${API_URL}/api/homeowner/v2/grades/jaimy_grade`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify(body)
    }).then(handleResult);
  }
  /**
   *  Register Trader Zoho Integration
   */
  async postRegisterTraderToZoho(body) {
    return fetch(`${API_URL}/api/traders/v1/traders/${getTraderId()}/register_to_zoho`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify(body)
    }).then(handleResult);
  }
}

export default TraderService;
