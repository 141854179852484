import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { hideSnackbar } from '../../store/reducers/alerts/actions';

const StyledSnackbarContent = withStyles({
  root: {
    backgroundColor: '#4caf50'
  }
})(SnackbarContent);

const StyledErrorSnackbarContent = withStyles({
  root: {
    backgroundColor: '#af4c4c'
  }
})(SnackbarContent);

const SnackBarMessage = ({ text, stack, decreaseSnacStack, hasError }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    open={!!stack}
  >
    {!hasError ? (
      <StyledSnackbarContent
        message={text}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => decreaseSnacStack(0)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    ) : (
      <StyledErrorSnackbarContent
        message={text}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => decreaseSnacStack(0)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    )}
  </Snackbar>
);

SnackBarMessage.defaultProps = {
  text: null,
  stack: 0,
  decreaseSnacStack: null
};
SnackBarMessage.propTypes = {
  text: PropTypes.string,
  stack: PropTypes.number,
  decreaseSnacStack: PropTypes.func
};
const mapStateToProps = ({ alerts, prebooks }) => ({
  text: alerts.snackText,
  stack: alerts.snackStack,
  hasError: prebooks.hasError
});
const mapDispatchToProps = dispatch => ({
  decreaseSnacStack: payload => dispatch(hideSnackbar(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarMessage);
