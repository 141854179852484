import { MenuItem, Select } from '@mui/material';
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom';
import { ButtonPrimary } from '../../../../components/common/Button';

import { NOT_SATISFIED_REASONS } from '../../../../constants/communicationForms';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import TraderService from '../../../../lib/services/traderService';
import theme from '../../../../theme'
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading'
import querystring from 'query-string';
import { ComponentIsVisible } from '../../../../components/utils/isVisible';

const traderService = new TraderService();
type NotSatisfiedFormType = {
  reason: string
}

const NotSatisfiedTraderForm = ({ grade }: { grade: number }) => {
  const location = useLocation();
  const query = querystring.parse(location.search);
  const { width } = useWindowDimensions();

  const [showNextStep, setShowNextStep] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isDirty, isSubmitSuccessful }
  } = useForm<NotSatisfiedFormType>({});

  const handleNotSatisfiedFormSubmit = useCallback(
    async (values: any) => {
      if (showNextStep) return

      const body = {
        token: query.token,
        grade: grade,
        reason: values.reason,
      }
      try {
        await traderService.postSrGradeByTrader(body);
        setShowNextStep(true)
      } catch (error) {
        console.log("error", error)
      }
    },
    [grade, query.token, showNextStep]
  );

  return (
    <form onSubmit={handleSubmit(handleNotSatisfiedFormSubmit)}>
      <ComponentIsVisible when={!showNextStep}>
        <JaimyFlex gap={"1rem"} flexDirection="column">
          <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
            <FormattedMessage id="communication.form.not_satisfied.title" />
          </JaimyHeading.H1>
          <JaimyHeading.H2 color={theme.colors.secondary.base}>
            <FormattedMessage id="communication.form.not_satisfied.subTitle" />
          </JaimyHeading.H2>
          <JaimyHeading.H2 color={theme.colors.secondary.base}>
            <FormattedMessage id="communication.form.not_satisfied.question" />
          </JaimyHeading.H2>
        </JaimyFlex>
        <JaimyFlex flexDirection='column' alignItems='center' justifyContent='center' gap={"1rem"} margin="20px 0px">
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select onChange={onChange} value={value || ""} fullWidth displayEmpty sx={{
                overflowWrap: "break-word",
                fontSize: width < 475 ? "12px" : "16px",
                border: `2px solid ${theme.colors.secondary.dark}`
              }}
              >
                <MenuItem value="" disabled>
                  <FormattedMessage id="communication.form.not_satisfied.form_label" />
                </MenuItem>
                {NOT_SATISFIED_REASONS.map(option => (
                  <MenuItem
                    data-cy-menuitem={option.value}
                    value={option.value}
                    key={option.value}
                    sx={{
                      fontSize: width < 475 ? "12px" : "16px"
                    }}
                  >
                    <FormattedMessage
                      tagName="span"
                      id={option.id}
                      defaultMessage={option.id}
                    />
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <ButtonPrimary
            type="submit"
            disabled={!isDirty || isSubmitSuccessful}
            style={{ width: width < 750 ? '250px' : '300px', display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <FormattedMessage tagName="span" id="question.send" defaultMessage="Send" />
          </ButtonPrimary>
        </JaimyFlex>
      </ComponentIsVisible>
      <ComponentIsVisible when={showNextStep}>
        <JaimyFlex gap={"1rem"} flexDirection="column">
          <JaimyHeading.H1 color={theme.colors.secondary.base} margin="20px 0px">
            <FormattedMessage id="communication.form.not_satisfied.grade_sent.title" />
          </JaimyHeading.H1>
          <JaimyHeading.H2 color={theme.colors.secondary.base}>
            <FormattedMessage id="communication.form.not_satisfied.grade_sent.subTitle" />
          </JaimyHeading.H2>
        </JaimyFlex>
      </ComponentIsVisible>
    </form>
  )
}

export default NotSatisfiedTraderForm
