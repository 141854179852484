import { FuncPropType, StringPropType, UserPropType } from '../../util/PropTypeValues';
import React, { useEffect, useState } from 'react';
import { login, requestUser, updateUserProfile } from '../../store/reducers/user/actions';
import { raisePageEvent } from '../../logging/analytics';
import { useHistory, useLocation } from 'react-router-dom';

import AccountService from '../../lib/services/accountService';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { ROUTES } from '../../routes';
import StepCompanyAddress from './stepCompanyAddress';
import StepCompanyDetails from './stepCompanyDetails';
import StepComplete from './stepComplete';
import StepPassword from './stepPassword';
import StepSomethingWrong from './stepSomethingWrong';
import { TEAMLEADER_REGISTER_LOCALSTORAGE } from '../../util/constants';
import TraderService from '../../lib/services/traderService';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import querystring from 'query-string';
import styled from 'styled-components';
import StepCodeOfHonor from './stepCodeOfHonor';
import StepVatValidated from './stepVatValidated';
import TagManager from 'react-gtm-module';

const DivRegister = styled.div`
  max-width: 480px;
  border-radius: ${props => (!!props.mobile ? 0 : '5px')};
  border: ${props => (!!props.mobile ? 'none' : 'solid 1.2px #acb7bb')};
  background-color: #ffffff;
  margin: ${props => (!!props.mobile ? '0 auto' : '5rem auto 0')};
  font-family: Lato;
  color: #405a6b;
  @media (max-width: 1200px) {
    margin-bottom: 4rem;
  }
`;
const DivProgress = styled.div`
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  > span {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    color: #86bcd3;
    margin: 1.5rem auto;
    text-transform: uppercase;
  }
`;
const DivProgressBar = styled.div`
  margin: 0 auto 1.5rem;
  height: 4px;
  background-color: #d2d2d7;
  width: 80%;
  border-radius: 5px;
  opacity: 0.5;
  > div {
    background-color: #86bcd3;
    height: 100%;
    ${props => (props.progress ? `width: ${props.progress}%;` : '')}
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;
const DivProgressComplete = styled.div`
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  > span {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    color: ${props => (props.red ? `#af1e3c` : `#339955`)};
    margin: 1.5rem auto;
    text-transform: uppercase;
  }
  > div {
    margin: 0 auto 1.5rem;
    height: 4px;
    background-color: ${props => (props.red ? `#af1e3c` : `#339955`)};
    width: 80%;
    border-radius: 5px;
  }
`;
const DivStep = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  > p,
  > span,
  > h2,
  > div {
    margin: 0 auto;
  }
  > a,
  > label,
  > input {
    width: 80%;
    margin: 0.4rem auto 1rem;
  }
  > button {
    width: 80%;
    margin: 1rem auto 2rem;
  }
  > p {
    margin-bottom: 1rem;
    width: 342px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.18px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  h3 {
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
  }
  .subtitle {
    display: inline-block;
    margin: auto;
    width: 80%;
    font-size: 12px;
    font-weight: bold;
  }
  .smallRed {
    font-size: 14px;
    color: #af1e3c;
  }
`;

const accountService = new AccountService();
const traderService = new TraderService();

const Register = ({ user, locale, updateUserProfile, requestUser, login }) => {
  const history = useHistory();
  const location = useLocation();
  const query = querystring.parse(location.search);
  const isLogged = user && user.id;
  const hasPasswordTokens = !query.uid || !query['access-token'] || !query.client || !query.id;
  const metaTitle = 'jaimy.be - register';

  if (hasPasswordTokens && !isLogged) {
    history.push(ROUTES.HOME.handler());
  }

  const progressMaxStep = 3;
  useEffect(() => {
    if (isLogged) {
      requestUser(user.id);
    }
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!user.firstname) {
      setStep(1);
    } else if (!user.companyname || !user.vat_number) {
      setStep(2);
    } else if (!user.address) {
      setStep(3);
    } else if (
      user.vatValidationState === 'not_checked' ||
      user.vatValidationState === 'company_closed' ||
      user.vatValidationState === 'invalid_vat_number_format' ||
      user.vatValidationState === 'belgian_vat_number_does_not_exist'
    ) {
      setStep(4);
    } else if (user.vatValidationState === `valid_vat` && !user.code_of_conduct_time) {
      setStep(5);
    } else if (!user.code_of_conduct_time) {
      setStep(6);
    } else {
      setStep(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const submitPassword = async (password, registerToken, authTokens) => {
    try {
      await accountService.setPassword(password, registerToken, authTokens);
      await login({ username: authTokens.uid, password });

      TagManager.dataLayer({
        dataLayer: {
          event: 'Password Filled in'
        },
        dataLayerName: 'ProDataLayer'
      });
      if (Boolean(localStorage.getItem(TEAMLEADER_REGISTER_LOCALSTORAGE))) {
        traderService
          .getTeamleaderUrl()
          .then(({ redirect_url }) => {
            localStorage.removeItem(TEAMLEADER_REGISTER_LOCALSTORAGE);
            //TODO find approach that uses react-router
            window.location = `${redirect_url}${window.location.search}`;
          })
          .catch(error => console.error("Couldn't get Teamleader URL", error.message));
      } else {
        history.push(ROUTES.JOBS.handler());
      }

      setStep(2);
    } catch (error) {
      console.error(error);
    }
  };

  const submitCompanyDetails = async data => {
    try {
      await updateUserProfile(data);
      TagManager.dataLayer({
        dataLayer: {
          event: 'VAT filled in'
        },
        dataLayerName: 'ProDataLayer'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const submitCompanyAddress = async data => {
    try {
      await updateUserProfile(data);

      TagManager.dataLayer({
        dataLayer: {
          event: 'Address Filled in'
        },
        dataLayerName: 'ProDataLayer'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const goToCodeOfHonor = () => {
    setStep(6);
  };

  const submitCodeOfHonor = async data => {
    try {
      await updateUserProfile(data);
      TagManager.dataLayer({
        dataLayer: {
          event: 'Code of honor signed'
        },
        dataLayerName: 'ProDataLayer'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onVatValidationComplete = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Trader Checked'
      },
      dataLayerName: 'ProDataLayer'
    });
    history.push(ROUTES.JOBS.handler());
  };

  return (
    <DivRegister mobile={isMobile}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      {step <= progressMaxStep && (
        <DivProgress>
          <FormattedMessage
            tagName="span"
            id="register.steps"
            values={{ current: step, max: progressMaxStep }}
          />
          <DivProgressBar progress={(step / progressMaxStep) * 100}>
            <div />
          </DivProgressBar>
        </DivProgress>
      )}
      {step === 5 && (
        <DivProgressComplete>
          <FormattedMessage
            tagName="span"
            id="register.step.vatValidated.title"
            values={{ current: step, max: progressMaxStep }}
          />
          <div />
        </DivProgressComplete>
      )}
      {step === 6 && (
        <DivProgressComplete red>
          <FormattedMessage tagName="span" id="register.step.codeOfHonor.title" />
          <div />
        </DivProgressComplete>
      )}
      {step === 10 && (
        <DivProgressComplete red>
          <FormattedMessage
            tagName="span"
            id="register.step.complete.title"
            values={{ current: step, max: progressMaxStep }}
          />
          <div />
        </DivProgressComplete>
      )}
      <DivStep>
        {step <= 1 && <StepPassword onClickContinue={submitPassword} query={query} />}
        {step === 2 && (
          <StepCompanyDetails onClickContinue={submitCompanyDetails} locale={locale} />
        )}
        {step === 3 && <StepCompanyAddress onClickContinue={submitCompanyAddress} />}
        {step === 4 && (
          <StepSomethingWrong
            user={user}
            refreshUser={requestUser}
            vatValidating={user.vatValidationState === 'not_checked'}
          />
        )}
        {step === 5 && <StepVatValidated onClickContinue={goToCodeOfHonor} />}
        {step === 6 && <StepCodeOfHonor onClickContinue={submitCodeOfHonor} />}
        {step === 10 && <StepComplete onClickContinue={onVatValidationComplete} />}
      </DivStep>
    </DivRegister>
  );
};

Register.propTypes = {
  user: UserPropType,
  locale: StringPropType,
  updateUserProfile: FuncPropType
};
Register.defaultProps = { user: {}, locale: 'nl', updateUserProfile: {} };

const mapStateToProps = ({ user: { user } }) => ({ user, locale: user.locale });
const mapDispatchToProps = { updateUserProfile, requestUser, login };
export default connect(mapStateToProps, mapDispatchToProps)(Register);
