import React, { useEffect } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { formatSubtitle, formatTitle, showSubtitle } from '../../util/cardHelpers';

import { Helmet } from 'react-helmet';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import CardSkeleton from '../../components/CardSkeleton';
import ServiceCard from '../../components/ServiceCard/purchased';
import ServiceFilter from '../../components/ServiceFilter/purchased';
import { AnchorButton } from '../../components/common/Link';
import PurchasedService from '../../lib/services/purchasedService';
import { raisePageEvent } from '../../logging/analytics';
import { ROUTES } from '../../routes';
import { getUser } from '../../store/reducers/user/actions';
import { countCurrentIndex } from '../../util/helpers';

import { ButtonPrimary } from '../../components/common/Button';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { serviceFilter } from '../../store/reducers/services/actions';

const ButtonLoadMore = styled.button`
  visibility: hidden;
`;
const ListDiv = styled.div`
  padding: 1.2rem;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;
const ListLoadingDiv = styled(ListDiv)`
  padding: 0 1.2rem;
  margin-top: ${props => (props.infiniteScroll ? '-1.2rem' : 'inherit')};
`;
const DivCartEmpty = styled.div`
  font-family: 'Lato', sans-serif;
  margin: 2rem auto 4rem auto;
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  p {
    align-content: center;
    color: #32546d;
    display: flex;
    font-size: 22px;
    justify-content: center;
    text-align: center;
  }

  a {
    margin-top: 1rem;
  }
`;
const purchasedService = new PurchasedService();
export const Purchased = ({ user, setFilter, services }) => {
  const { width } = useWindowDimensions();
  const intl = useIntl();

  const filters = useSelector(state => state.services.filters);
  const metaTitle = intl.messages['purchasedpage.title'];
  const queryClient = useQueryClient();

  const { data, fetchNextPage, isFetchingNextPage, isLoading, hasNextPage, isError, refetch } =
    useInfiniteQuery(
      ['purchased-services', filters],
      async ({ pageParam = 1 }) => {
        let { pagination, ...updatedFilters } = filters;
        updatedFilters.pagination = { page: pageParam };
        const {
          data,
          pagination: { total }
        } = await purchasedService.getFilteredList(updatedFilters);
        return { data, pageParam: pageParam + 1, total: Number.isNaN(total) ? data.length : total };
      },
      {
        refetchOnWindowFocus: false,
        staleTime: 60000,
        getNextPageParam: (lastPage, allPages) => {
          const currentTotal = countCurrentIndex(allPages);
          return currentTotal < lastPage.total ? lastPage.pageParam : undefined;
        }
      }
    );
  const isEmpty = React.useMemo(() => (data ? countCurrentIndex(data?.pages) === 0 : true), [data]);
  const [loadMoreButtonRef, inView] = useInView();
  React.useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    getUser();
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutation = useMutation(id => purchasedService.toggleArchived(id), {
    onSettled: () => {
      //Just invalidateQueries should be enough, but for some reason sometime the order of calls is reverted
      // Resulting in refetch before finishing archive call.
      queryClient.invalidateQueries('purchased-services');
      setTimeout(() => refetch(), 500);
    }
  });

  const resetFiltersWithSameCategories = () => {
    const state = {
      ...services.filters,
      radius: user.service_radius,
      category_list: user.treetrades,
      request_type: '',
      search_value: '',
      status: 'all',
      originalState: true,
      languages: user.matching_locales ? user.matching_locales : [user.locale]
    };
    setFilter(state);
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <ServiceFilter itemCount={data?.pages[0]?.total ?? 0} />
      {data?.pages?.map((page, index) => (
        <ListDiv key={index}>
          {page?.data.map(e => (
            <ServiceCard
              key={e.id}
              id={e.id}
              serviceRequestId={e.id}
              location={e.postcode}
              title={formatTitle(e, user.locale)}
              subtitle={formatSubtitle(e, user.locale)}
              showSubtitle={showSubtitle(e)}
              isMobile={width <= 768}
              files={e.photos ? e.photos.length : 0}
              isNew={!e.traderAlreadySeen}
              customer={e.customer}
              town={e.townNames && (user.locale === 'fr' ? e.townNames.fr : e.townNames.nl)}
              description={e.additionalInfo}
              onArchive={id => mutation.mutateAsync(id)}
              onUnarchive={id => mutation.mutateAsync(id)}
              requestType={e.request_type && e.request_type}
              irDate={e.ir_date && e.ir_date}
              compensated={e.compensated}
              status={e.archived ? 'archived' : 'active'}
              page="purchased"
            />
          ))}
        </ListDiv>
      ))}
      {(isFetchingNextPage || isLoading) && !isError && (
        <ListLoadingDiv>
          <CardSkeleton key="0" />
          <CardSkeleton key="1" />
          <CardSkeleton key="2" />
          <CardSkeleton key="3" />
        </ListLoadingDiv>
      )}
      <ButtonLoadMore
        ref={loadMoreButtonRef}
        onClick={() => fetchNextPage()}
        disabled={!hasNextPage || isFetchingNextPage}
      >
        Load More
      </ButtonLoadMore>
      {isEmpty && !isLoading && !isError && (
        <ListDiv>
          <EmptyList
            originalFiltersState={filters.originalState}
            resetFilters={resetFiltersWithSameCategories}
          />
        </ListDiv>
      )}
    </>
  );
};
const EmptyList = ({ originalFiltersState, resetFilters }) => (
  <DivCartEmpty>
    {originalFiltersState && (
      <>
        <p>
          <FormattedMessage tagName="span" id="purchased.empty.title" />
        </p>
        <AnchorButton to={`${ROUTES.JOBS.handler()}`}>
          <FormattedMessage id="purchased.empty.link" />
        </AnchorButton>
      </>
    )}
    {!originalFiltersState && (
      <>
        <p>
          <FormattedMessage tagName="span" id="purchased.empty.title" />
        </p>
        <ButtonPrimary onClick={() => resetFilters()}>
          <FormattedMessage id="servicefilter.reset_filter" />
        </ButtonPrimary>
      </>
    )}
  </DivCartEmpty>
);

const mapStateToProps = ({ services, user: { user } }) => ({ user, services });
const mapDispatchToProps = dispatch => ({
  setFilter: payload => dispatch(serviceFilter(payload)),
  getUser
});
export default connect(mapStateToProps, mapDispatchToProps)(Purchased);
