import querystring from 'query-string';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { DivHeader, H1ProfileTitle, SectionTopHeader } from '../../../components/common/Header';
import { AnchorBack } from '../../../components/common/Link';
import { ComponentIsVisible } from '../../../components/utils/isVisible';
import { ROUTES } from '../../../routes';
import theme from '../../../theme';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';

import { useEffect, useMemo } from 'react';

import TraderService from '../../../lib/services/traderService';
import NotSatisfiedTraderForm from './NotSatisfiedTraderForm';
import SatisfiedTraderForm from './SatisfiedTraderForm';

const traderService = new TraderService();

const CommunicationPage = () => {
  const location = useLocation();
  const query = querystring.parse(location.search);

  const formData = useMemo(() => {
    switch (true) {
      case location.pathname.includes('very-unsatisfied'):
        return {
          formUrl: 'very-unsatisfied',
          gradeByTrader: 1
        };
      case location.pathname.includes('very-satisfied'):
        return {
          formUrl: 'very-satisfied',
          gradeByTrader: 4
        };
      case location.pathname.includes('not-satisfied'):
        return {
          formUrl: 'not-satisfied',
          gradeByTrader: 2
        };
      case location.pathname.includes('satisfied'):
        return {
          formUrl: 'satisfied',
          gradeByTrader: 3
        };
      default:
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!query.token) return;
    if (!formData?.gradeByTrader) return;
    const body = {
      grade: formData?.gradeByTrader || 3,
      token: query.token
    };
    const getTraderToken = async () => {
      try {
        await traderService.postSrGradeByTrader(body);
      } catch (error) {
        console.log(error);
      }
    };
    getTraderToken();
  }, [formData, query.token]);

  return (
    <>
      <DivHeader>
        <SectionTopHeader>
          <ComponentIsVisible when={!isMobile}>
            <Breadcrumbs>
              <></>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
            </Breadcrumbs>
          </ComponentIsVisible>
          <ComponentIsVisible when={isMobile}>
            <AnchorBack to={`${ROUTES.HOME.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          </ComponentIsVisible>
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="communication.header" />
        </H1ProfileTitle>
      </DivHeader>
      <JaimyFlex
        flexDirection="column"
        minHeight="70vh"
        position="relative"
        justifyContent="center"
        alignItems="center"
      >
        <JaimyFlex
          padding={'2rem'}
          zIndex={2}
          backgroundColor={theme.colors.whiteOpacity}
          alignItems="center"
          textAlign="center"
          flexDirection="column"
        >
          <ComponentIsVisible when={formData?.formUrl === 'satisfied' || formData?.formUrl === 'very-satisfied'}>
            <SatisfiedTraderForm grade={formData?.gradeByTrader as number} />
          </ComponentIsVisible>
          <ComponentIsVisible
            when={formData?.formUrl === 'not-satisfied' || formData?.formUrl === 'very-unsatisfied'}
          >
            <NotSatisfiedTraderForm grade={formData?.gradeByTrader as number} />
          </ComponentIsVisible>
        </JaimyFlex>
        <JaimyBox width="100%" height="100%" position="absolute">
          <Image
            alt="Jaimy confirmation"
            src="https://cdn.jaimy.be/images/Depositphotos_14042979_xl-2015.jpg"
          />
        </JaimyBox>
      </JaimyFlex>
    </>
  );
};

export default CommunicationPage;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
`;
