import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import React from 'react';
import SelectUI from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

interface SelectProps {
  className?: string,
  id?: string,
  name?: string,
  value?: string | number,
  children?: any,
  onChange?: any,
  fullWidth?: boolean,
  displayEmpty?: boolean,
  multiple?: boolean,
  tag?: any,
  required?: boolean,
  error?: boolean,
  color?: string,
  defaultValue?: string | number,
}
// @ts-ignore
const styledBy = (property, mapping) => (props) => mapping[props[property]];

const InputStyled = withStyles(theme => ({
  root: {
    minWidth: 76,
    color: '#32546D',
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '& > svg': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #D8DAE0',
      borderRadius: '4px',
      marginRight: '8px'
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: styledBy('color', {
      primary: theme.palette.background.paper,
      secondary: '#F3F4F6'
    }),
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: styledBy('color', {
      primary: '9px 34px 10px 12px',
      secondary: '0.8rem 0.9rem'
    }),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#fff'
    }
  }
}))(InputBase);

const Select = ({
  className,
  id,
  name,
  value,
  children,
  onChange,
  fullWidth,
  displayEmpty,
  multiple,
  tag,
  required,
  error,
  color = 'primary',
  ...props
}: SelectProps) => {
  const hasRenderValue = multiple
    ? {
      renderValue: (selected: any) => (
        <div>
          {/* @ts-ignore */}
          {selected.map(e => (
            <Chip key={e} label={e} />
          ))}
        </div>
      )
    }
    : {};

  const hasTag = tag
    ? {
      renderValue: (selected: any) => {
        if (selected.length === 0) {
          return tag;
        }

        return selected.join(', ');
      },
      multiple: true
    }
    : {};
  return (
    <FormControl error={error} className={className} fullWidth={fullWidth}>
      <SelectUI
        displayEmpty={displayEmpty}
        multiple={multiple}
        value={value}
        onChange={onChange}
        input={<InputStyled color={color} error={error} required={required} name={name} id={id} />}
        error={error}
        {...hasRenderValue}
        {...hasTag}
        {...props}
      >
        {children}
      </SelectUI>
    </FormControl>
  );
};

export default Select;
