module.exports = {
  USER_DATA_REQUEST: 'USER_DATA_REQUEST',
  USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
  USER_DATA_ERROR: 'USER_DATA_REQUEST',

  USER_UPDATE_PROFILE: 'USER_UPDATE_PROFILE',
  USER_UPDATE_PROFILE_SUCCESS: 'USER_UPDATE_PROFILE_SUCCESS',
  USER_UPDATE_PROFILE_ERROR: 'USER_UPDATE_PROFILE_ERROR',
  USER_HAS_UPDATED_PROFILE: 'USER_HAS_UPDATED_PROFILE',

  USER_LOCALE_CHANGE: 'USER_LOCALE_CHANGE',

  USER_TEAMLEADER_OAUTH: 'USER_TEAMLEADER_OAUTH',
  USER_TEAMLEADER_OAUTH_SUCCESS: 'USER_TEAMLEADER_OAUTH_SUCCESS',
  USER_TEAMLEADER_OAUTH_ERROR: 'USER_TEAMLEADER_OAUTH_ERROR',

  USER_LOG_IN: 'USER_LOG_IN',
  USER_LOG_IN_SUCCESS: 'USER_LOG_IN_SUCCESS',
  USER_LOG_IN_ERROR: 'USER_LOG_IN_ERROR',

  USER_LOG_OUT: 'USER_LOG_OUT',

  TRADER_INVOICES: 'TRADER_INVOICES',
  TRADER_INVOICES_SUCCESS: 'TRADER_INVOICES_SUCCESS',
  TRADER_INVOICES_ERROR: 'TRADER_INVOICES_ERROR'
};
