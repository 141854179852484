import 'flatpickr/dist/flatpickr.css';
import '../../../custom-flatpickr.css';

import { FormattedMessage } from 'react-intl';
import theme from '../../../../../theme';
import JaimyFlex from '../../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../../theme/components/Typography/Heading';

import Flatpickr from 'react-flatpickr';
import JaimyLabel from '../../../../../theme/components/form/Label';
import JaimyButton from '../../../../../theme/components/Button';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { addDays, addYears, endOfYear, format, startOfYear } from 'date-fns';
import { FixMeLater } from '../../../../../types/FixMeLater';
import {
  IGetOnlyJaimyAppointmentPayloadData,
  IJaimyAppointment
} from '../../../../../types/Calendar';
import { EVacationWarningType, IVacationWarnings } from '../../../../../types/Vacation';
import { connect } from 'react-redux';
import { getOnlyJaimyAppointments } from '../../../../../store/reducers/calendar/actionCreators';
import {
  createVacationPlanning,
  getAllVacations,
  getAllVacationsOfCalendar,
  getAllVacationsOfPrebook,
  IGetCalendarVacationsRequestAction,
  IGetPrebookVacationsRequestAction,
  IVacationPlaninngRequestAction,
  updateVacationPlanning
} from '../../../../../store/reducers/vacation/actions';
import { AnyAction } from 'redux';
import JaimyText from '../../../../../theme/components/Typography/Text';
import { ComponentIsVisible } from '../../../../utils/isVisible';
import styled from 'styled-components';
import JaimyBox from '../../../../../theme/components/layout/Box';
import { IInterventionPartnership, ISubscription } from '../../../../../types/User';
import { IPrebook } from '../../../../../types/Prebook';
import { requestTraderPrebooksData } from '../../../../../store/reducers/prebooks/actions';
import { ComponentErrorMessage } from '../../../../utils/ErrorMessage';
import { ErrorMessage } from '@hookform/error-message';
import { PremiumSub, proOrPremiumSub, Subscriptions } from '../../../../../constants/subscriptions';
import { IVacationState } from '../../../../../store/reducers/vacation/reducer';
import { useLocation } from 'react-router-dom';
import { ComponentAlertMessage } from '../../../../utils/AlertMessage';

interface IVacationPlanningFormProps {
  handleClose: () => void;
  openedFromPrebook?: boolean;
  calendarId?: string;
  prebookId?: string;
  createVacationPlanning: (action: IVacationPlaninngRequestAction) => AnyAction;
  updateVacationPlanning: (action: IVacationPlaninngRequestAction) => AnyAction;
  getTraderPrebooksData: () => AnyAction;
  getOnlyJaimyAppointments: (payload: IGetOnlyJaimyAppointmentPayloadData) => AnyAction;
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) => AnyAction;
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) => AnyAction;
  getAllVacations: (traderId: number) => AnyAction;
  traderPrebooks: IPrebook[];
  interventionPartnerships: IInterventionPartnership[];
  subscription: ISubscription[];
  userId: number;
  jaimyAppointments: IJaimyAppointment[];
  appointmentsLoading: boolean;
  createVacationPlanningLoading: boolean;
  updateVacationPlanningLoading: boolean;
  vacationWarnings: IVacationWarnings[];
}

interface IVacationPlanningFormData {
  startDate: Date;
  endDate: Date;
  noEndDate: boolean;
  stopEmailCommunication: boolean;
  stopCampaings: boolean;
  stopCalendars: boolean;
}

const VacationPlanningForm = ({
  handleClose,
  calendarId,
  prebookId,
  openedFromPrebook,
  createVacationPlanning,
  updateVacationPlanning,
  getTraderPrebooksData,
  getAllVacationsOfCalendar,
  getAllVacationsOfPrebook,
  getAllVacations,
  getOnlyJaimyAppointments,
  traderPrebooks,
  interventionPartnerships,
  subscription,
  userId,
  jaimyAppointments,
  appointmentsLoading,
  createVacationPlanningLoading,
  updateVacationPlanningLoading,
  vacationWarnings
}: IVacationPlanningFormProps) => {
  const location = useLocation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<IVacationPlanningFormData>({
    criteriaMode: 'all',
    mode: 'onBlur'
  });

  const watchedStartDate = watch('startDate');
  const watchedNoEndDate = watch('noEndDate');
  const watchedStopCalendars = watch('stopCalendars');
  const watchedStopPrebooks = watch('stopCampaings');

  const onChangeEndDateLoadAppointments = useCallback(
    (date: { start?: Date; end?: Date }) => {
      if (date.start && date.end) {
        setValue('endDate', date.end);

        if (calendarId) {
          getOnlyJaimyAppointments({
            calendarId: parseInt(calendarId, 10),
            range: {
              end: date.end.toISOString(),
              start: date.start.toISOString()
            }
          });
        }
      }
    },
    [calendarId, getOnlyJaimyAppointments, setValue]
  );

  const handleCheckNoEndDate = useCallback(() => {
    if (calendarId) {
      if (watchedStartDate && !watchedNoEndDate) {
        getOnlyJaimyAppointments({
          calendarId: parseInt(calendarId, 10),
          range: {
            end: endOfYear(new Date()).toISOString(),
            start: watchedStartDate.toISOString()
          }
        });
      }
    }
  }, [calendarId, getOnlyJaimyAppointments, watchedNoEndDate, watchedStartDate]);

  const calendarsPaused = useMemo(() => {
    const calendarsPausedFound = vacationWarnings.filter(
      vacationWarning => vacationWarning.type === EVacationWarningType.calendar
    );

    return calendarsPausedFound;
  }, [vacationWarnings]);

  const prebooksPaused = useMemo(() => {
    const prebooksPausedFound = vacationWarnings.filter(
      vacationWarning => vacationWarning.type === EVacationWarningType.prebook
    );

    return prebooksPausedFound;
  }, [vacationWarnings]);

  const userSubscription = useMemo(() => {
    const userSubscriptionType =
      subscription.length > 0 ? subscription[0].sub_type : Subscriptions.Local;

    return {
      premium: PremiumSub.includes(userSubscriptionType as Subscriptions),
      proOrPremium: proOrPremiumSub.includes(userSubscriptionType as Subscriptions)
    };
  }, [subscription]);

  const calendarIds = useMemo(() => {
    let calendarIds: number[] = [];
    if (calendarId) {
      calendarIds.push(parseInt(calendarId, 10));
    }
    if (watchedStopCalendars) {
      const traderCalendarsId = interventionPartnerships.map(
        interventionPartnership => interventionPartnership.id
      );
      calendarIds = traderCalendarsId;
    }

    return calendarIds;
  }, [calendarId, interventionPartnerships, watchedStopCalendars]);

  const prebookIds = useMemo(() => {
    let prebookIds: number[] = [];

    if (prebookId) {
      prebookIds.push(parseInt(prebookId, 10));
    }
    if (watchedStopPrebooks) {
      const traderPrebooksId = traderPrebooks.map(traderPrebook => traderPrebook.id);
      prebookIds = traderPrebooksId;
    }

    return prebookIds;
  }, [prebookId, traderPrebooks, watchedStopPrebooks]);

  const onSubmit = useCallback(
    (data: IVacationPlanningFormData) => {
      if (!userId) {
        return;
      }
      const pauseCalendar = location.pathname.includes('calendar');
      const pausePrebook = location.pathname.includes('campagne');

      const endDateFormatted = data.noEndDate
        ? format(addYears(startOfYear(new Date()), 5), 'dd-MM-yyyy')
        : format(data.endDate, 'dd-MM-yyyy');
      const startDateFormatted = format(data.startDate, 'dd-MM-yyyy');

      createVacationPlanning({
        payload: {
          endDate: endDateFormatted,
          traderId: userId,
          pauseCalendar: pauseCalendar || data.stopCalendars,
          pauseCommunication: data.noEndDate,
          pausePreebook: pausePrebook || data.stopCampaings,
          startDate: startDateFormatted,
          prebookIds,
          calendarIds
        },
        functions: {
          reloadVacations: () => {
            if (calendarId) {
              getAllVacationsOfCalendar({
                payload: {
                  calendarId: calendarId as string,
                  traderId: userId
                }
              });
            }
            if (prebookId) {
              getAllVacationsOfPrebook({
                payload: {
                  prebookId: prebookId as string,
                  traderId: userId
                }
              });
            }
            if (!calendarId && !prebookId) {
              getAllVacations(userId);
            }
          },
          closeModal: () => handleClose()
        }
      });

      // updateVacationPlanning({
      //   payload: {
      //     endDate: data.endDate,
      //     traderId: userId,
      //     pauseCalendar: data.stopCalendars,
      //     pauseCommunication: data.noEndDate,
      //     pausePreebook: data.stopCampaings,
      //     startDate: data.startDate
      //   },
      //   functions: {
      //     closeModal: () => handleClose()
      //   }
      // });
    },
    [
      calendarId,
      calendarIds,
      createVacationPlanning,
      getAllVacations,
      getAllVacationsOfCalendar,
      getAllVacationsOfPrebook,
      handleClose,
      location.pathname,
      prebookId,
      prebookIds,
      userId
    ]
  );

  useEffect(() => {
    if (!openedFromPrebook) {
      getTraderPrebooksData();
    }
  }, [getTraderPrebooksData, openedFromPrebook]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <JaimyFlex flexDirection="column" gap="16px" py={1}>
        <JaimyFlex flexDirection="column">
          <Controller
            name="startDate"
            control={control}
            rules={{ required: 'missing-properties.message.required' }}
            render={({ field: { onChange } }) => (
              <>
                <JaimyLabel htmlFor="startDate">
                  <FormattedMessage id="calendar.vacation.from" />
                </JaimyLabel>
                <Flatpickr
                  autoFocus
                  onChange={dates => onChange(dates[0])}
                  options={{
                    static: true,
                    dateFormat: 'd-m-Y',
                    minDate: addDays(new Date(), 1).toISOString()
                  }}
                  aria-label="startDate"
                  className="custom-flatpickr"
                />
              </>
            )}
          />
          <ComponentIsVisible when={!watchedStartDate && !errors.startDate}>
            <ComponentAlertMessage message="calendar.vacation.fillStartDate" />
          </ComponentIsVisible>
          <ErrorMessage
            name="startDate"
            errors={errors}
            render={({ message }) => <ComponentErrorMessage message={message} />}
          />
        </JaimyFlex>
        <JaimyFlex flexDirection="column">
          <Controller
            name="endDate"
            control={control}
            rules={{ required: !watchedNoEndDate && 'missing-properties.message.required' }}
            render={() => (
              <>
                <JaimyLabel htmlFor="endDate">
                  <FormattedMessage id="calendar.vacation.to" />
                </JaimyLabel>
                <Flatpickr
                  onChange={dates =>
                    onChangeEndDateLoadAppointments({
                      start: watchedStartDate,
                      end: dates[0]
                    })
                  }
                  disabled={!watchedStartDate || watchedNoEndDate}
                  options={{
                    static: true,
                    dateFormat: 'd-m-Y',
                    minDate: watchedStartDate
                  }}
                  aria-label="endDate"
                  className="custom-flatpickr"
                />
              </>
            )}
          />
          <ErrorMessage
            name="endDate"
            errors={errors}
            render={({ message }) => <ComponentErrorMessage message={message} />}
          />
        </JaimyFlex>

        <FormControlLabel
          className="custom-checkbox"
          control={
            <Controller
              name="noEndDate"
              control={control}
              render={({ field }) => (
                <Checkbox
                  disabled={!watchedStartDate}
                  onClick={handleCheckNoEndDate}
                  color="error"
                  {...field}
                />
              )}
            />
          }
          label={
            <JaimyLabel>
              <FormattedMessage id="calendar.vacation.noEndDate" />
            </JaimyLabel>
          }
        />
      </JaimyFlex>

      <JaimyHeading.H4 color={theme.colors.primary.base}>
        <FormattedMessage id="calendar.vacation.pause" />
      </JaimyHeading.H4>

      <JaimyFlex flexDirection="column">
        <FormControlLabel
          control={
            <Controller
              name="stopEmailCommunication"
              control={control}
              render={({ field }) => (
                <Checkbox disabled={!watchedStartDate} color="error" {...field} />
              )}
            />
          }
          label={
            <JaimyLabel>
              <FormattedMessage id="calendar.vacation.emails" />
            </JaimyLabel>
          }
        />
        <ComponentIsVisible when={traderPrebooks.length >= 1 && userSubscription.proOrPremium}>
          <FormControlLabel
            control={
              <Controller
                name="stopCampaings"
                control={control}
                render={({ field }) => (
                  <Checkbox disabled={!watchedStartDate} color="error" {...field} />
                )}
              />
            }
            label={
              <JaimyLabel>
                <FormattedMessage id="calendar.vacation.campaigns" />
              </JaimyLabel>
            }
          />
        </ComponentIsVisible>

        <ComponentIsVisible when={interventionPartnerships.length >= 1 && userSubscription.premium}>
          <FormControlLabel
            control={
              <Controller
                name="stopCalendars"
                control={control}
                render={({ field }) => (
                  <Checkbox disabled={!watchedStartDate} color="error" {...field} />
                )}
              />
            }
            label={
              <JaimyLabel>
                <FormattedMessage id="calendar.vacation.calendars" />
              </JaimyLabel>
            }
          />
        </ComponentIsVisible>
      </JaimyFlex>

      <ComponentIsVisible when={jaimyAppointments.length > 0}>
        <JaimyBox backgroundColor={theme.colors.primary.lighter} padding="1rem" maxWidth="30rem">
          <JaimyText>
            <FormattedMessage id="calendar.vacation.appointmentPlannedTitle" />
          </JaimyText>
          <WarningList>
            {jaimyAppointments.map((jaimyAppointment, index) => (
              <WarningItem key={`${index} - ${jaimyAppointment.clientName}`}>
                <JaimyText fontWeight="600">
                  {`${jaimyAppointment.clientName}: ${jaimyAppointment.startTime}h - ${jaimyAppointment.date} `}
                </JaimyText>
              </WarningItem>
            ))}
          </WarningList>
          <JaimyText>
            <FormattedMessage id="calendar.vacation.appointmentPlannedWarning" />
          </JaimyText>
        </JaimyBox>
      </ComponentIsVisible>

      <ComponentIsVisible when={calendarsPaused.length > 0}>
        <JaimyBox backgroundColor={theme.colors.primary.lighter} padding="1rem" maxWidth="30rem">
          <JaimyText fontWeight={600}>
            <FormattedMessage id="calendar.vacation.pausedTitle" />
          </JaimyText>
          <JaimyText>
            <FormattedMessage id="calendar.vacation.pausedText" />
          </JaimyText>
          <WarningList>
            {calendarsPaused.map((calendarPaused, index) => (
              <WarningItem key={`${index} - ${calendarPaused.name}`}>
                <JaimyText fontWeight="600">
                  {`${calendarPaused.name}: ${calendarPaused.startDateFormatted} - ${calendarPaused.endDateFormatted} `}
                </JaimyText>
              </WarningItem>
            ))}
          </WarningList>
          <JaimyText>
            <FormattedMessage id="calendar.vacation.calendarPausedWarning" />
          </JaimyText>
        </JaimyBox>
      </ComponentIsVisible>

      <ComponentIsVisible when={prebooksPaused.length > 0}>
        <JaimyBox
          backgroundColor={theme.colors.primary.lighter}
          padding=".75rem 1rem"
          maxWidth="30rem"
        >
          <JaimyText fontWeight={600}>
            <FormattedMessage id="calendar.vacation.pausedTitle" />
          </JaimyText>
          <JaimyText>
            <FormattedMessage id="calendar.vacation.pausedText" />
          </JaimyText>
          <WarningList>
            {prebooksPaused.map((prebookPaused, index) => (
              <WarningItem key={`${index} - ${prebookPaused.name}`}>
                <JaimyText fontWeight="600">
                  {`${prebookPaused.name}: ${prebookPaused.startDateFormatted} - ${prebookPaused.endDateFormatted} `}
                </JaimyText>
              </WarningItem>
            ))}
          </WarningList>
          <JaimyText>
            <FormattedMessage id="calendar.vacation.prebookPausedWarning" />
          </JaimyText>
        </JaimyBox>
      </ComponentIsVisible>

      <JaimyFlex gap="8px" margin="1rem auto 0 auto" width="fit-content">
        <JaimyButton.BaseSecondary height="2.25rem" type="button" onClick={handleClose}>
          <FormattedMessage id="cancel" />
        </JaimyButton.BaseSecondary>
        <JaimyButton.Base
          height="2.25rem"
          type="submit"
          disabled={
            appointmentsLoading || createVacationPlanningLoading || updateVacationPlanningLoading
          }
          width="10rem"
        >
          <ComponentIsVisible
            when={!createVacationPlanningLoading && !updateVacationPlanningLoading}
          >
            <FormattedMessage id="profile.form.save" />
          </ComponentIsVisible>
          <ComponentIsVisible when={createVacationPlanningLoading || updateVacationPlanningLoading}>
            <CircularProgress size={20} color="inherit" disableShrink />
          </ComponentIsVisible>
        </JaimyButton.Base>
      </JaimyFlex>
    </form>
  );
};

const mapStateToProps = ({
  user: {
    user: { id, subscription, intervention_partnerships }
  },
  prebooks: { prebooks },
  calendars: { jaimyAppointments, appointmentsLoading },
  vacation: { createVacationPlanningLoading, updateVacationPlanningLoading, vacationWarnings }
}: {
  user: FixMeLater;
  prebooks: FixMeLater;
  calendars: FixMeLater;
  vacation: IVacationState;
}) => ({
  userId: id,
  interventionPartnerships: intervention_partnerships,
  subscription,
  traderPrebooks: prebooks,
  jaimyAppointments,
  appointmentsLoading,
  createVacationPlanningLoading,
  updateVacationPlanningLoading,
  vacationWarnings
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  createVacationPlanning: (action: IVacationPlaninngRequestAction) =>
    dispatch(createVacationPlanning(action)),
  updateVacationPlanning: (action: IVacationPlaninngRequestAction) =>
    dispatch(updateVacationPlanning(action)),
  getTraderPrebooksData: () => dispatch(requestTraderPrebooksData()),
  getOnlyJaimyAppointments: (payload: IGetOnlyJaimyAppointmentPayloadData) =>
    dispatch(getOnlyJaimyAppointments(payload)),
  getAllVacations: (traderId: number) => dispatch(getAllVacations(traderId)),
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) =>
    dispatch(getAllVacationsOfCalendar(action)),
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) =>
    dispatch(getAllVacationsOfPrebook(action))
});

export default connect(mapStateToProps, mapDispatchToProps)(VacationPlanningForm);

const WarningList = styled.ul`
  margin: 0.25rem 0;

  &::before {
    position: absolute;
    height: 100%;
    width: 2px;
    content: ' ';
    background-color: ${theme.colors.primary.dark};
    left: 0;
    top: 0;
  }
`;

const WarningItem = styled.li``;
