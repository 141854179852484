import styled, { css } from 'styled-components';

export const LinkButton = styled.button`
  color: #c30b30;
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  visibility: ${props => (props.invisible ? 'hidden' : 'visible')};

  :hover {
    text-decoration: underline;
  }
`;
export const ButtonPrimary = styled.button`
  background-color: ${props => (props.secondary ? '#C30144' : '#fff')};
  border: 2px solid #c30144;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${props => (props.secondary ? '#FFFFFF' : '#C30144')};
  display: ${props => (props.center ? 'block' : 'flex')};
  margin: ${props => (props.center ? '1rem auto 0.5rem' : 'inherit')};
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 1.6rem;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  cursor: pointer;

  svg {
    fill: ${props => (props.secondary ? '#FFFFFF' : '#C30144')};
  }

  :disabled {
    background-color: #d8dae0;
    border-color: #d8dae0;
    cursor: auto;
    color: #867a7a;
  }

  :hover:not(:disabled) {
    color: #fff;
    background-color: #c30144;

    svg {
      fill: #fff;
    }
  }
`;
export const ButtonPrimaryBuy = styled(ButtonPrimary)`
  ${props =>
    props.isFreeLead
      ? css`
          background-color: #86bcd3;
          border: 2px solid #86bcd3;
          > span {
            margin-left: 1rem;
          }
          :hover:not(:disabled) {
            background-color: #86bcd3c4;
          }
        `
      : ''}
`;
export const ButtonPrimaryDeny = styled(ButtonPrimary)`
  border: 2px solid #86bcd3;
  color: #86bcd3;
  margin: 0;

  :hover:not(:disabled) {
    background-color: #86bcd3;
  }
`;
export const ButtonAddCart = styled.button`
  background-color: ${props => (props.inCart ? '#32546d' : '#c30144')};
  border: 0;
  border-radius: 4px;
  position: relative;
  width: 168px;
  height: 50px;
  cursor: pointer;
  margin-right: 0.8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  svg {
    fill: #fff;
  }

  > span {
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-left: 0.4rem;

    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }

  svg:nth-of-type(2),
  :disabled {
    display: ${props => (props.inCart ? 'none' : 'inherit')};
  }

  :hover {
    background-color: ${props => (props.inCart ? '#32546d' : '#c30144c4')};
    border: ${props => (props.inCart ? 'solid 2px #32546d' : '0')};
    & svg {
      fill: #fff;
    }

    svg:first-child {
      display: ${props => (props.inCart ? 'none' : 'inherit')};
    }
    svg:nth-of-type(2) {
      display: ${props => (props.inCart ? 'block' : 'inherit')};
    }

    > span {
      color: #fff;
    }
  }
  :focus {
    outline: ${props => (props.inCart ? 'none' : 'inherit')};
  }

  @media (min-width: 1200px) {
    flex-basis: ${props => (props.isDetailPage ? '25%' : '35%')};
  }
`;
export const ButtonAddCartLead = styled(ButtonAddCart)`
  background-color: #86bcd3;
  border: 2px solid #86bcd3;

  :hover:not(:disabled) {
    border: 2px solid #86bcd3c4;
    background-color: #86bcd3c4;
  }
`;
export const ButtonActions = styled.button`
  background: none;
  border: 2px solid
    ${props =>
      props.isFavorite ? '#C30B30' : props.isNotInterested ? '#fff' : 'rgba(255, 255, 255, 0.5)'};
  width: 32px;
  background-color: ${props =>
    props.isFavorite ? '#C30B30' : props.isNotInterested ? '#fff' : 'transparent'};
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  cursor: pointer;
  visibility: ${props => (props.invisible ? 'hidden' : 'visible')};

  &:nth-of-type(1) {
    margin-bottom: 0.6rem;
  }

  &:hover:not(:disabled) {
    background-color: ${props =>
      props.isFavorite ? '#c30144e0' : props.isNotInterested ? '#32546d' : 'none'};
    border: 2px solid ${props => (props.isFavorite ? '#c30144e0' : '#fff')};

    svg {
      fill: #fff;
    }
  }
  :disabled {
    border: 2px solid rgba(255, 255, 255, 0.3);
    svg {
      fill: rgba(255, 255, 255, 0.3);
    }
  }
  svg {
    fill: ${props =>
      props.isFavorite
        ? 'rgba(255, 255, 255, 0.8)'
        : props.isNotInterested
        ? '#32546d'
        : 'rgba(255, 255, 255, 0.5)'};
  }
`;

export const ButtonMoreInfo = styled(ButtonPrimary)`
  color: ${props => (props.inCart ? '#32546d' : '#c30144')};
  background-color: #fff;
  border: 2px solid ${props => (props.inCart ? '#32546d' : '#c30144')};
  display: block;

  @media (min-width: 1200px) {
    flex-basis: 70%;
  }

  :hover:not(:disabled) {
    color: #fff;
    background-color: ${props => (props.inCart ? '#32546d' : '#c30144')};
  }

  ${props =>
    props.isFreeLead
      ? css`
          flex-basis: 100%;
          display: flex;
          padding: 0 1.6rem;
          min-height: 40px;
          justify-content: center;
          color: #fff;
          background-color: #86bcd3;
          border: 2px solid #86bcd3;
          > span {
            margin-left: 1rem;
          }
          svg {
            fill: #fff;
            display: block;
          }
          :hover:not(:disabled) {
            background-color: #86bcd3c4;
          }
        `
      : ''}
`;
export const ButtonMoreInfoFreeLead = styled(ButtonMoreInfo)`
  color: #86bcd3;
  border-color: #86bcd3;
  background-color: #fff;

  :hover:not(:disabled) {
    background-color: #86bcd3;
  }
`;

export const ButtonDarkRed = styled.button`
  color: white;
  text-transform: uppercase;
  height: 48px;
  border-radius: 3px;
  background-color: #af1e3c;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  cursor: pointer;
  border: none;

  &:disabled {
    background-color: #d8dae0;
    color: #af1e3c;
  }
`;
