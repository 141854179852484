import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { proOrPremiumSub, Subscriptions } from '../../../constants/subscriptions';
import { allowedToAccessMyPrebook } from '../../../constants/verifiedStatus';
import { ROUTES } from '../../../routes';
import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import { IPrebook } from '../../../types/Prebook';
import { IUser } from '../../../types/User';
import DashboardCard from '../../cards/DashboardCard';
import { ComponentIsVisible } from '../../utils/isVisible';

interface IDashboardPrebookProps {
  user: IUser;
  prebooks: IPrebook[];
}

const DashboardPreBook = ({ prebooks, user }: IDashboardPrebookProps) => {
  const history = useHistory();

  const prebooksFiltered = useMemo(() => {
    const activePrebooks = prebooks.filter(pb => !pb.pause);
    const pausedPrebooks = prebooks.filter(pb => pb.pause);

    return {
      active: activePrebooks,
      paused: pausedPrebooks
    };
  }, [prebooks]);

  const sub_type = useMemo(() => {
    const userSubscription =
      user.subscription?.length > 0 ? user.subscription[0].sub_type : Subscriptions.Local;
    return userSubscription;
  }, [user.subscription]);

  const handleManage = () => {
    history.push(ROUTES.PREBOOK.handler());
  };

  const renderNoSubscription = () => (
    <JaimyFlex margin="1rem 0" justifyContent="center">
      <JaimyText color={theme.colors.white}>
        <FormattedMessage id="dashboard.prebook.noSub" />{' '}
        <EmailStyled href={`mailto:sales@jaimy.be`}>sales@jaimy.be</EmailStyled>
      </JaimyText>
    </JaimyFlex>
  );

  const renderCampaigns = () => (
    <JaimyFlex justifyContent="start" flexDirection="column" alignItems="center" gap="1rem">
      <ComponentIsVisible when={prebooks.length > 0}>
        <JaimyText fontWeight={700} color={theme.colors.white}>
          {prebooksFiltered.active.length} - <FormattedMessage id="dashboard.campaign" />
        </JaimyText>
        <JaimyText fontWeight={700} color={theme.colors.white}>
          {prebooksFiltered.paused.length} - <FormattedMessage id="dashboard.campaign.pause" />
        </JaimyText>
        <ComponentIsVisible when={allowedToAccessMyPrebook.includes(user.verified_status)}>
          <JaimyButton.BaseSecondary
            backgroundColor={theme.colors.blue.lightBlue}
            color={theme.colors.white}
            onClick={handleManage}
          >
            <FormattedMessage id="manage" />
          </JaimyButton.BaseSecondary>
        </ComponentIsVisible>
        <ComponentIsVisible when={!allowedToAccessMyPrebook.includes(user.verified_status)}>
          <JaimyText
            color={theme.colors.white}
            textAlign="center"
            fontSize={theme.typography.fontSizes.sm}
          >
            <FormattedMessage id="dashboard.restriction.message" />
          </JaimyText>
        </ComponentIsVisible>
      </ComponentIsVisible>
      <ComponentIsVisible when={!prebooks.length}>
        <JaimyText color={theme.colors.white}>
          <FormattedMessage id="dashboard.prebook.sub.desc" />{' '}
          <EmailStyled href={`mailto:${user.owner.email}`}>{user.owner.email}</EmailStyled>
        </JaimyText>
      </ComponentIsVisible>
    </JaimyFlex>
  );

  return (
    <DashboardCard backgroundColor={theme.colors.blue.darkBlue}>
      <JaimyBox padding="10px">
        <JaimyHeading.H3 fontSize="1.1rem" color={theme.colors.white}>
          <FormattedMessage id="dashboard.prebook.sub" />
        </JaimyHeading.H3>
      </JaimyBox>
      <JaimyFlex flexDirection="column" justifyContent="center" margin="1rem 0" alignItems="center">
        <ComponentIsVisible when={proOrPremiumSub.includes(sub_type as Subscriptions)}>
          {renderCampaigns()}
        </ComponentIsVisible>
        <ComponentIsVisible when={!proOrPremiumSub.includes(sub_type as Subscriptions)}>
          {renderNoSubscription()}
        </ComponentIsVisible>
      </JaimyFlex>
    </DashboardCard>
  );
};

export default DashboardPreBook;

const EmailStyled = styled.a`
  color: ${props => props.theme.colors.white};
`;
