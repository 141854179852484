import { ButtonPrimaryBuy, ButtonPrimaryDeny } from '../common/Button';
import { H2Subtitle, H4Title } from '../common/Header';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from '../../routes';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const ButtonPrimaryBuyFreeLead = styled(ButtonPrimaryBuy)`
  > span {
    margin: 0;
  }
`;
const SectionFreeLeadActions = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0.5rem auto;

  button:first-of-type {
    margin: 1rem 0;
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
    margin: 2rem auto;
    padding: 0 1.2rem;
    button:first-of-type {
      margin: 0 2rem 0 0;
    }
  }

  :disabled {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const FreeLeadDiv = styled.div`
  text-align: center;
  margin: 0.5rem 0 1rem;
  @media (min-width: 1200px) {
    margin: 2rem 1rem;
  }
`;
const DivFormWrapper = styled.div`
  label {
    color: #32546d;
    margin-top: 1rem;

    > span {
      :nth-of-type(2) {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;

const FreeLeadModal = ({
  open,
  closeFreeLeadModal,
  freeLeadsRemaining,
  onBuyItem,
  id,
  isCoupon
}) => {
  const history = useHistory();
  return (
    <Dialog data-cy-dialog="free-lead" open={open} onClose={closeFreeLeadModal}>
      <DialogContent>
        <FreeLeadDiv>
          <H4Title>
            <FormattedMessage tagName="span" id="servicecardlist.free_leads.title" />
          </H4Title>
          <H2Subtitle isFreeLead>
            <FormattedMessage
              tagName="span"
              id={`servicecardlist.free_leads${isCoupon ? '.coupon' : ''}.subtitle`}
              values={{ count: freeLeadsRemaining || 0 }}
            />
          </H2Subtitle>
          <DivFormWrapper>
            <SectionFreeLeadActions>
              <ButtonPrimaryBuyFreeLead
                data-cy-button="buy-free-lead"
                isFreeLead
                secondary
                type="submit"
                onClick={() => onBuyItem()}
              >
                <FormattedMessage tagName="span" id="servicecardlist.free_leads.confirm" />
              </ButtonPrimaryBuyFreeLead>
              <ButtonPrimaryDeny
                onClick={() => history.push(ROUTES.JOBS_ID.handler(id))}
                data-cy-button="more-information"
              >
                <FormattedMessage tagName="span" id="servicecardlist.card.more_information" />
              </ButtonPrimaryDeny>
            </SectionFreeLeadActions>
          </DivFormWrapper>
        </FreeLeadDiv>
      </DialogContent>
    </Dialog>
  );
};
export default FreeLeadModal;
