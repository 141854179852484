import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as ImagesIcon } from '../../../assets/images/images-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/images/money-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone.svg';
import { ROUTES } from '../../../routes';
import { truncText } from '../../../util/text';
import { SpanOriginalPrice } from '../../common/Card';
import { AnchorPrimary } from '../../common/Link';
import CardActions from '../CardActions';

const DivCardContent = styled.div``;
const DivCardMain = styled.div``;
const CardDescription = styled.div`
  color: #637780;
  width: 100%;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  text-align: justify;
  padding: 1rem;
  min-height: 7rem;
`;
const DivReadMore = styled.div`
  flex-basis: 70%;
  margin-left: auto;
  margin: ${props => (props.hasActions ? '0' : '0 0 1rem auto')};
`;
const DivCardInfo = styled.div`
  display: flex;
`;
const DivReadMoreLink = styled.div`
  margin-bottom: 1rem;
`;
const DivCardDetails = styled.div`
  flex-basis: ${props => (props.isPurchased ? '40%' : '35%')};
`;
const DivCardItem = styled.div`
  padding: ${props => (props.first ? '1rem 1rem 0 1rem' : '0 1rem')};
  margin-bottom: 0.2rem;
  @media (min-width: 1200px) {
    ${props => props.name === 'location' && `min-height: ${props.first ? '7rem' : '4rem'}`}
  }

  & > span {
    font-family: 'Lato', sans-serif;
    line-height: 24px;

    &:nth-of-type(2) {
      color: #c30144;
      font-style: normal;
      font-weight: 900;
      font-size: 11px;
      margin: 0 0.5rem;
      text-transform: uppercase;
      letter-spacing: 0.08em;
    }

    &:nth-of-type(3) {
      color: #32546d;
      font-weight: bold;
      font-size: 14px;
    }
  }
`;
const DivCardPurchasedItem = styled(DivCardItem)`
  word-break: break-all;
`;
const DivCardImages = styled.div`
  padding: ${props => (props.hasActions ? '0 1rem 1rem' : '0 0 0 1rem')};

  & > span {
    font-family: 'Lato', sans-serif;

    &:nth-of-type(2) {
      color: #637780;
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      margin: 0 0.5rem;
    }
  }

  @media (min-width: 1200px) {
    margin-top: ${props => (props.hasActions ? '2rem' : '1rem')};
    padding: 0 1rem 1rem 1rem;
  }
`;
const DivCardValues = styled.div`
  margin-left: 0.3rem;
  color: #32546d;
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 700;
  width: ${props => (props.break ? 'inherit' : 'max-content')};
  font-family: 'Lato', sans-serif;
`;

const CardItems = (
  hasFreelead,
  isMobile,
  price,
  originalPrice,
  isOnSale,
  location,
  files,
  town
) => (
  <>
    {!hasFreelead && (
      <DivCardItem first name="price">
        <span>
          <MoneyIcon />
        </span>
        <span>
          <FormattedMessage tagName="span" id="servicecardlist.card.price" />
        </span>
        {!isMobile ? (
          <DivCardValues>
            <FormattedMessage
              tagName="span"
              id="servicecardlist.card.price.euro"
              values={{ price }}
            />
            {!!isOnSale && (
              <SpanOriginalPrice>
                <FormattedMessage
                  tagName="span"
                  id="servicecardlist.card.price.euro"
                  values={{ price: originalPrice }}
                />
              </SpanOriginalPrice>
            )}
          </DivCardValues>
        ) : (
          <>
            <FormattedMessage
              tagName="span"
              id="servicecardlist.card.price.euro"
              values={{ price }}
            />
            {!!isOnSale && (
              <SpanOriginalPrice>
                <FormattedMessage
                  tagName="span"
                  id="servicecardlist.card.price.euro"
                  values={{ price: originalPrice }}
                />
              </SpanOriginalPrice>
            )}
          </>
        )}
      </DivCardItem>
    )}
    <DivCardItem first={hasFreelead && hasFreelead > 0} name="location">
      <span>
        <LocationIcon />
      </span>
      <span>
        <FormattedMessage tagName="span" id="servicecardlist.card.location" />
      </span>
      {!isMobile ? (
        <DivCardValues>{`${location} ${town}`}</DivCardValues>
      ) : (
        <span>{`${location} ${town}`}</span>
      )}
    </DivCardItem>
    <DivCardImages>
      <span>
        <ImagesIcon />
      </span>
      <span>
        <FormattedMessage
          tagName="span"
          id="servicecardlist.card.assets.files"
          values={{ number: files || 0 }}
        />
      </span>
    </DivCardImages>
  </>
);
const CustomerItems = (town, location, files, isMobile, email, phone) => (
  <>
    <DivCardPurchasedItem first>
      <span>
        <MailIcon width={12} height={12} fill="#ACB7BB" />
      </span>
      <span>
        <FormattedMessage tagName="span" id="servicecardlist.card.email" />
      </span>
      {!isMobile ? (
        <DivCardValues break>
          <span>{email}</span>
        </DivCardValues>
      ) : (
        <span>{email}</span>
      )}
    </DivCardPurchasedItem>
    <DivCardPurchasedItem>
      <span>
        <LocationIcon />
      </span>
      <span>
        <FormattedMessage tagName="span" id="servicecardlist.card.location" />
      </span>
      {!isMobile ? (
        <DivCardValues>{`${location} ${town}`}</DivCardValues>
      ) : (
        <span>{`${location} ${town}`}</span>
      )}
    </DivCardPurchasedItem>
    <DivCardPurchasedItem>
      <span>
        <PhoneIcon width={12} height={12} fill="#ACB7BB" />
      </span>
      <span>
        <FormattedMessage tagName="span" id="servicecardlist.card.phone" />
      </span>
      {!isMobile ? (
        <DivCardValues>
          <span>{phone}</span>
        </DivCardValues>
      ) : (
        <span>
          <span>{phone}</span>
        </span>
      )}
    </DivCardPurchasedItem>
    <DivCardImages>
      <span>
        <ImagesIcon />
      </span>
      <span>
        {!(files === 0) && (
          <FormattedMessage
            tagName="span"
            id="servicecardlist.card.assets.files"
            values={{ number: files || 0 }}
          />
        )}
      </span>
    </DivCardImages>
  </>
);

const CardContent = ({
  id,
  serviceRequestId,
  page,
  cartIsLoading,
  setFreeLeadSrId,
  isLoading,
  cartId,
  handleAddToCart,
  isMobile,
  hasFreelead,
  price,
  originalPrice,
  isOnSale,
  location,
  files,
  town,
  description,
  customer,
  isPurchased,
  hasActions
}) => {
  const readMoreLink = (
    <DivReadMoreLink>
      <AnchorPrimary
        to={
          page === 'purchased'
            ? `${ROUTES.PURCHASED_ID.handler(serviceRequestId)}`
            : `${ROUTES.JOBS_ID.handler(serviceRequestId)}`
        }
      >
        <FormattedMessage tagName="span" id="servicecardlist.read_more" />
      </AnchorPrimary>
    </DivReadMoreLink>
  );

  return (
    <DivCardContent>
      {isMobile ? (
        <DivCardMain>
          {isPurchased
            ? CustomerItems(town, location, files, isMobile, customer.email, customer.phone)
            : CardItems(
                hasFreelead,
                isMobile,
                price,
                originalPrice,
                isOnSale,
                location,
                files,
                town
              )}
          <CardDescription>{truncText(description)}</CardDescription>
          {!hasActions && readMoreLink}
        </DivCardMain>
      ) : (
        <DivCardInfo>
          <DivCardDetails isPurchased>
            {isPurchased
              ? CustomerItems(town, location, files, isMobile, customer.email, customer.phone)
              : CardItems(
                  hasFreelead,
                  isMobile,
                  price,
                  originalPrice,
                  isOnSale,
                  location,
                  files,
                  town
                )}
          </DivCardDetails>
          <DivReadMore hasActions={hasActions}>
            <CardDescription>
              {description &&
                description
                  .split('\n')
                  .slice(0, -1)
                  .map((property, i) => {
                    return (
                      <p key={i}>
                        <span style={{ fontWeight: 'bold' }}>{property.split(': ')[0]} </span>
                        {property.split(': ')[1]}
                        <br />
                      </p>
                    );
                  })}
            </CardDescription>
            {/* <CardDescription>{truncText(description)}</CardDescription> */}
            {!hasActions && readMoreLink}
          </DivReadMore>
        </DivCardInfo>
      )}
      {hasActions && (
        <CardActions
          id={id}
          hasFreelead={hasFreelead}
          cartIsLoading={cartIsLoading}
          setFreeLeadSrId={setFreeLeadSrId}
          isLoading={isLoading}
          isMobile={isMobile}
          cartId={cartId}
          handleAddToCart={handleAddToCart}
          price={price}
        />
      )}
    </DivCardContent>
  );
};

export default CardContent;
