/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { login, updateUserProfile } from '../store/reducers/user/actions';

import querystring from 'query-string';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AnchorPrimary } from '../components/common/Link';
import { LoginForm } from '../components/LoginForm';
import TraderService from '../lib/services/traderService';
import { raisePageEvent } from '../logging/analytics';
import { ROUTES } from '../routes';
import { getUtmMedium, getUtmSource } from '../util/utmSourceHandler';

const traderService = new TraderService();

const SectionSignUp = styled.section`
  align-items: baseline;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem 0;
  flex-wrap: wrap;
  text-align: center;

  a {
    font-size: 16px;
    margin-left: 0.4rem;
  }

  span {
    color: #32546d;
  }
`;

const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
`;

const Login = ({ user, login, updateUserProfile, unauthorized, hasError, banned, isLoading }) => {
  const history = useHistory();
  const location = useLocation();
  const query = querystring.parse(location.search);
  const { source } = query;
  const metaTitle = 'jaimy.be - log in';
  const from = location.state?.from ? location.state.from : undefined;

  useEffect(() => {
    if (user && user.id) {
      history.replace(ROUTES.DASHBOARD.handler());
    }
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (username, password) => {
    try {
      await login({ username, password }).then(user => {
        if (user.rik_ip_id || user.b2b_ip_id) {
          history.push({
            pathname: ROUTES.CHOOSE_PLATFORM.handler(),
            search: location.search,
            state: { from }
          });
        } else {
          history.replace(from);
        }
      });
      if ([source, getUtmSource(query)].includes('teamleader')) {
        traderService
          .getTeamleaderUrl()
          .then(({ redirect_url }) => {
            window.location = `${redirect_url}${window.location.search}`;
          })
          .catch(error => console.error("Couldn't get Teamleader URL", error.message));

        updateUserProfile({
          utmSource: getUtmSource(query),
          utmMedium: getUtmMedium(query)
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const utmSource = source ? `?utm_source=${source}` : '';
  return (
    <DivContainer>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <SectionSignUp>
        <FormattedMessage id="login.sign_up.label" tagName="span" />
        <AnchorPrimary to={ROUTES.HOME.handler()} data-cy-link="signup">
          <FormattedMessage id="login.sign_up.link" />
        </AnchorPrimary>
      </SectionSignUp>
      <LoginForm
        onSubmit={handleLogin}
        isLoading={isLoading}
        disabled={isLoading}
        unauthorized={unauthorized}
        hasUserError={hasError}
        isBanned={banned}
      />
    </DivContainer>
  );
};
const mapStateToProps = ({ user: { user, unauthorized, hasError, banned, isLoading } }) => ({
  user,
  unauthorized,
  hasError,
  banned
});
const mapDispatchToProps = {
  login,
  updateUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
