import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import 'url-search-params-polyfill';
import { ComponentIsVisible } from '../components/utils/isVisible';
import { logout } from '../util/tokenProvider';

const DivContainer = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - 72px);
  place-items: center;
  place-content: center;
`;

const DivImgContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 10;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: center;
`;

const DivTextContainer = styled.div`
  z-index: 20;
  display: flex;
  padding: 2rem;
  gap: 2rem;
  border-radius: 0.5rem;
  flex-direction: column;
  text-align: center;
  place-items: center;
  height: fit-content;
  width: clamp(300px, 60%, 700px);
  background-color: hsla(0, 0%, 100%, 0.7);
`;

const Heading = styled.h1`
  color: hsl(205, 37%, 31%);
  font-family: 'BelfiusNormal', sans-serif;
`;

const LogOffPage = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter(prevCounter => {
        if (prevCounter === 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (counter === 0) {
      logout();
    }
  }, [counter]);

  return (
    <DivContainer>
      <DivImgContainer>
        <Image
          alt="Jaimy Unsubscribe"
          src="https://d1ngac5455aayn.cloudfront.net/images/hero-images/jaimy-404.jpg"
        />
      </DivImgContainer>
      <DivTextContainer>
        <>
          <Heading>
            <FormattedMessage id="logoff.title" />
          </Heading>
          <ComponentIsVisible when={counter < 2}>
            <CircularProgress />
          </ComponentIsVisible>
          <Heading>{counter > 1 && counter}</Heading>
        </>
      </DivTextContainer>
    </DivContainer>
  );
};

export default LogOffPage;
