import { add, eachDayOfInterval, endOfMonth, endOfWeek, format, startOfWeek } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import getLocale from '../../../getLocale';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyGrid from '../../../theme/components/layout/Grid';
import fr from 'date-fns/locale/fr';
import nlBE from 'date-fns/locale/nl-BE';
import { generateWeekDayNames } from '../../../util/generateWeekDayNames';
import theme from '../../../theme';
import { FixMeLater } from '../../../types/FixMeLater';
import { IRange, IEvent, IGetAppointmentsPayload } from '../../../types/Calendar';
import {
  getAppointments,
  setFirstDayOfSelectedMonth
} from '../../../store/reducers/calendar/actionCreators';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { useParams } from 'react-router-dom';
import { MyCalendarHeader } from './Header';
import { MyCalendarMonthDay } from './MonthDay';
import VacationPlanningDialog from '../../Dialog/Vacation/Planning';
import {
  getAllVacationsOfCalendar,
  IGetCalendarVacationsRequestAction
} from '../../../store/reducers/vacation/actions';
import { IVacationState } from '../../../store/reducers/vacation/reducer';
import { ICalendarVacation } from '../../../types/Vacation';

interface IMyCalendarProps {
  isSelectedDay: Date;
  today: Date;
  setIsSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  getAppointments: (payload: IGetAppointmentsPayload) => AnyAction;
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) => AnyAction;
  appointments: IEvent[];
  firstDayOfSelectedMonth: Date;
  setFirstDayOfSelectedMonth: (payload: { dateFormatted: string }) => AnyAction;
  userId: number;
  calendarVacations: ICalendarVacation[];
  openVacationPlanning: boolean;
  handleToggleVacationPlanning: () => void;
}

function MyCalendar({
  isSelectedDay,
  setIsSelectedDay,
  today,
  appointments,
  getAppointments,
  getAllVacationsOfCalendar,
  firstDayOfSelectedMonth,
  calendarVacations,
  setFirstDayOfSelectedMonth,
  userId,
  handleToggleVacationPlanning,
  openVacationPlanning
}: IMyCalendarProps) {
  const { id } = useParams<{ id?: string }>();

  const weekDays = useMemo(() => {
    const generatedWeekDays = generateWeekDayNames(today);
    const formattedWeekDays = generatedWeekDays.map(generatedWeekDay => {
      return format(generatedWeekDay, 'E', { locale: getLocale() === 'fr' ? fr : nlBE });
    });

    return formattedWeekDays;
  }, [today]);

  const monthDays = useMemo(() => {
    const generatedMonthDays = eachDayOfInterval({
      start: startOfWeek(firstDayOfSelectedMonth),
      end: endOfWeek(endOfMonth(firstDayOfSelectedMonth))
    });

    return generatedMonthDays;
  }, [firstDayOfSelectedMonth]);

  const handleChangeCurrentMonth = useCallback(
    (value: number) => {
      const firstDayOfNewMonth = add(firstDayOfSelectedMonth, { months: value });
      setFirstDayOfSelectedMonth({
        dateFormatted: format(firstDayOfNewMonth, 'MMM-yyyy')
      });
    },
    [firstDayOfSelectedMonth, setFirstDayOfSelectedMonth]
  );

  const loadAppointments = useCallback(() => {
    if (!id) {
      return;
    }

    const formattedDateStart = format(startOfWeek(firstDayOfSelectedMonth), 'yyyy/MM/dd');
    const formattedDateEnd = format(endOfWeek(endOfMonth(firstDayOfSelectedMonth)), 'yyyy/MM/dd');

    getAppointments({
      id: parseInt(id, 10),
      range: {
        end: formattedDateEnd,
        start: formattedDateStart
      }
    });
  }, [firstDayOfSelectedMonth, getAppointments, id]);

  const appointmentsFilteredByStatus = useMemo(() => {
    let personalAppointments: IEvent[] = [];
    let jaimyAppointments: IEvent[] = [];

    if (appointments) {
      personalAppointments = appointments.filter(
        appointment => appointment.status === 'block_event'
      );
      jaimyAppointments = appointments.filter(appointment => appointment.status !== 'block_event');
    }

    return {
      personal: personalAppointments,
      jaimy: jaimyAppointments
    };
  }, [appointments]);

  useEffect(() => {
    loadAppointments();
  }, [loadAppointments]);

  const loadCalendarVacations = useCallback(() => {
    if (!id) {
      return;
    }

    getAllVacationsOfCalendar({
      payload: {
        calendarId: id,
        traderId: userId
      }
    });
  }, [getAllVacationsOfCalendar, id, userId]);

  useEffect(() => {
    loadCalendarVacations();
  }, [loadCalendarVacations]);

  return (
    <JaimyBox>
      <MyCalendarHeader
        firstDayOfSelectedMonth={firstDayOfSelectedMonth}
        handleChangeCurrentMonth={handleChangeCurrentMonth}
      />
      <JaimyGrid columns="repeat(7, 1fr)">
        {weekDays.map((weekDay, index) => (
          <WeekDay key={`${weekDay} - ${index}`}>{weekDay}</WeekDay>
        ))}
      </JaimyGrid>

      <JaimyGrid columns="repeat(7, 1fr)">
        {monthDays.map((monthDay, index) => (
          <MyCalendarMonthDay
            appointmentsFilteredByStatus={appointmentsFilteredByStatus}
            firstDayOfSelectedMonth={firstDayOfSelectedMonth}
            index={index}
            monthDay={monthDay}
            isSelectedDay={isSelectedDay}
            key={`${index} - ${monthDay}`}
            setIsSelectedDay={setIsSelectedDay}
            calendarVacations={calendarVacations}
          />
        ))}
      </JaimyGrid>

      <VacationPlanningDialog
        calendarId={id}
        open={openVacationPlanning}
        handleClose={handleToggleVacationPlanning}
      />
    </JaimyBox>
  );
}

const mapStateToProps = ({
  user: {
    user: { id }
  },
  calendars: { firstDayOfSelectedMonth, appointments },
  vacation: { calendarVacations }
}: {
  calendars: FixMeLater;
  user: FixMeLater;
  vacation: IVacationState;
}) => ({
  userId: id,
  appointments,
  firstDayOfSelectedMonth,
  calendarVacations
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  getAppointments: (payload: { id: number; range: IRange }) =>
    dispatch(getAppointments({ range: payload.range, id: payload.id })),
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) =>
    dispatch(getAllVacationsOfCalendar(action)),
  setFirstDayOfSelectedMonth: (payload: { dateFormatted: string }) =>
    dispatch(setFirstDayOfSelectedMonth(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar);

const WeekDay = styled.span`
  align-items: center;
  justify-content: center;
  display: flex;

  margin-bottom: 0.5rem;

  font-weight: 600;
  color: ${theme.colors.primary.base};

  text-transform: capitalize;
`;
