import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AnchorPrimary = styled(Link)`
  color: #c30b30;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

export const AnchorButton = styled(Link)`
  background-color: #c30b30;
  border: 2px solid #c30b30;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 1.6rem;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  > span {
    margin: 0 auto;
  }

  :hover {
    color: #c30b30;
    background-color: #fff;
  }
`;

export const AnchorBack = styled(Link)`
  color: #637780;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;
