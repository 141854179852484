import { FormattedMessage, useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import PurchasedArchiveIcon from '@material-ui/icons/Archive';
import RestoreIcon from '@material-ui/icons/RestoreFromTrash';
import PurchasedUnarchiveIcon from '@material-ui/icons/Unarchive';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ArchiveIcon } from '../../../assets/images/archive-icon.svg';
import { ReactComponent as CardNewIcon } from '../../../assets/images/card-top.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as FavoriteIcon } from '../../../assets/images/favorite-icon.svg';
import { ButtonActions } from '../../common/Button';
import CardHeaderActions from './CardHeaderActions';

const DivCardHeader = styled.div`
  background-color: #32546d;
  padding: 1.2rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  position: relative;
`;
const DivCardHeaderTitle = styled.div`
  min-height: 4rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-basis: 80%;
  hgroup > h5 {
    color: #86bcd3;
    font-size: 10px;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
  }
  hgroup > h2 {
    color: #fff;
    font-size: 19px;
    font-family: 'BelfiusNormal', sans-serif;
  }
  hgroup > h3 {
    color: #fff;
    font-size: 14px;
    font-family: 'BelfiusNormal', sans-serif;
  }
`;
const H2Title = styled.h2`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  position: absolute;
  top: -9px;
  left: 0;

  > span {
    z-index: 1;
    position: relative;
    margin: auto;
    padding-left: 1rem;
  }

  > svg {
    position: relative;
    margin: 0 auto;
    top: -44px;
    right: -22px;
  }
`;
const CardHeader = ({
  id,
  isNew,
  isOnSale,
  hasFreelead,
  title,
  subtitle,
  showSubtitle,
  page,
  status,
  isNotInterested,
  isFavorite,
  onFavorite,
  onArchive,
  onUnarchive,
  onNotInterested,
  isPurchased,
  isLoadingActions
}) => {
  const intl = useIntl();
  const jobPageHeaderActions = (
    <>
      <Tooltip
        title={
          isNotInterested
            ? intl.messages['servicecardlist.card.action.tooltip.restore']
            : intl.messages['servicecardlist.card.action.tooltip.trash']
        }
        aria-label={
          isNotInterested
            ? intl.messages['servicecardlist.card.action.tooltip.restore']
            : intl.messages['servicecardlist.card.action.tooltip.trash']
        }
      >
        <ButtonActions
          data-cy-button="not-interested"
          isNotInterested={isNotInterested}
          onClick={() => onNotInterested(id)}
        >
          {isNotInterested ? (
            <RestoreIcon width={24} height={24} />
          ) : (
            <ArchiveIcon width={14} height={14} />
          )}
        </ButtonActions>
      </Tooltip>
      <Tooltip
        title={
          isFavorite
            ? intl.messages['servicecardlist.card.action.tooltip.unfavorite']
            : intl.messages['servicecardlist.card.action.tooltip.favorite']
        }
        aria-label={
          isFavorite
            ? intl.messages['servicecardlist.card.action.tooltip.unfavorite']
            : intl.messages['servicecardlist.card.action.tooltip.favorite']
        }
      >
        <ButtonActions
          data-cy-button="is-favorite"
          invisible={isNotInterested}
          isFavorite={isFavorite}
          onClick={() => onFavorite(id)}
        >
          <FavoriteIcon width={12} height={12} />
        </ButtonActions>
      </Tooltip>
    </>
  );
  const cartPageHeaderActions = (
    <ButtonActions
      data-cy-button="remove-from-cart"
      onClick={onArchive ? () => onArchive(id) : null}
    >
      <CloseIcon width={24} height={24} viewBox="0 0 24 24" />
    </ButtonActions>
  );
  const purchasedPageHeaderActions = (
    <Tooltip title={intl.messages['servicecardlist.card.action.tooltip.archive']}>
      <div>
        <ButtonActions
          disabled={isLoadingActions}
          data-cy-button="archive-button"
          onClick={() => onArchive(id)}
        >
          <PurchasedArchiveIcon fontSize="small" />
        </ButtonActions>
      </div>
    </Tooltip>
  );
  const archivedPageHeaderActions = (
    <Tooltip title={intl.messages['servicecardlist.card.action.tooltip.activate']}>
      <div>
        <ButtonActions
          disabled={isLoadingActions}
          data-cy-button="unarchive-button"
          onClick={() => onUnarchive(id)}
        >
          <PurchasedUnarchiveIcon fontSize="small" />
        </ButtonActions>
      </div>
    </Tooltip>
  );

  return (
    <DivCardHeader>
      {!isPurchased && (isNew || (isOnSale && !(hasFreelead > 0))) && (
        <H2Title>
          {isNew && (!isOnSale || hasFreelead > 0) && (
            <FormattedMessage tagName="span" id="servicecardlist.card.new" />
          )}
          {isOnSale && !(hasFreelead > 0) && (
            <FormattedMessage tagName="span" id="servicecardlist.card.on_sale" />
          )}
          <CardNewIcon width="100" height="72" />
        </H2Title>
      )}
      <DivCardHeaderTitle>
        <hgroup>
          {showSubtitle ? (
            <>
              <h3>{id}</h3>
              <h5>{title}</h5>
              <h2>{subtitle}</h2>
            </>
          ) : (
            <h2>{title}</h2>
          )}
        </hgroup>
      </DivCardHeaderTitle>
      <CardHeaderActions>
        {page === 'jobs' && jobPageHeaderActions}
        {page === 'cart' && cartPageHeaderActions}
        {page === 'purchased' && status === 'active' && purchasedPageHeaderActions}
        {page === 'purchased' && status === 'archived' && archivedPageHeaderActions}
      </CardHeaderActions>
    </DivCardHeader>
  );
};

CardHeader.defaultProps = {
  status: null,
  onFavorite: null,
  onArchive: null,
  onUnarchive: null,
  onNotInterested: null,
  isLoadingActions: false
};

CardHeader.propTypes = {
  id: PropTypes.number.isRequired,
  isNew: PropTypes.bool.isRequired,
  isOnSale: PropTypes.bool.isRequired,
  hasFreelead: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  showSubtitle: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  isNotInterested: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  isPurchased: PropTypes.bool.isRequired,
  status: PropTypes.string,
  onFavorite: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  onNotInterested: PropTypes.func,
  isLoadingActions: PropTypes.bool
};

export default CardHeader;
