import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import theme from '../../../theme';

import { CalendarTodayOutlined } from '@material-ui/icons';
import { format, getHours, isBefore, isSameDay, startOfToday } from 'date-fns';
import { fr, nlBE } from 'date-fns/locale';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppointmentCard from '../../../components/Calendar/AppointmentCard';
import CardVacation from '../../../components/Calendar/Card/Vacation';
import MyCalendar from '../../../components/Calendar/MyCalendar';
import { MyCalendarSubtitles } from '../../../components/Calendar/MyCalendar/Subtitles';
import AddAppointmentDialog from '../../../components/Dialog/Appointment/Add';
import ManageCalendarDialog from '../../../components/Dialog/ManageCalendar';
import { ComponentIsVisible } from '../../../components/utils/isVisible';
import getLocale from '../../../getLocale';
import { IVacationState } from '../../../store/reducers/vacation/reducer';
import JaimyButton from '../../../theme/components/Button';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyContainer from '../../../theme/components/layout/Container';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyGrid from '../../../theme/components/layout/Grid';
import { IEvent } from '../../../types/Calendar';
import { FixMeLater } from '../../../types/FixMeLater';
import { ICalendarVacation } from '../../../types/Vacation';

interface ILocationState {
  calendarName: string;
  isInsuranceServicesCategory: boolean;
}

interface ICalendarViewProps {
  appointments: IEvent[];
  calendarVacations: ICalendarVacation[];
}

const CalendarView = ({ appointments, calendarVacations }: ICalendarViewProps) => {
  const routerLocation = useLocation<ILocationState>();
  // modal controls
  const [open, setOpen] = useState<boolean>(false);
  const [openVacationPlanning, setOpenVacationPlanning] = useState<boolean>(false);
  const [openManageCalendar, setOpenManageCalendar] = useState<boolean>(false);

  // Calendar control
  const today = startOfToday();
  const [isSelectedDay, setIsSelectedDay] = useState<Date>(today);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleVacationPlanning = () => {
    setOpenVacationPlanning(currentState => !currentState);
  };

  const handleManageCalendar = () => {
    setOpenManageCalendar(currentState => !currentState);
  };

  const selectedDayAppointments = useMemo(() => {
    if (!appointments) {
      return [];
    }
    const appointmentsFilteredToReturn = appointments
      .filter(
        appointment =>
          isSameDay(appointment.start, isSelectedDay) || isSameDay(appointment.end, isSelectedDay)
      )
      .sort((firstAppointmentToCompare, secondAppointmentToCompare) => {
        const firstAppointmentHour = getHours(firstAppointmentToCompare.start);
        const secondAppointmentHour = getHours(secondAppointmentToCompare.start);

        if (firstAppointmentHour > secondAppointmentHour) {
          return 1;
        }

        return -1;
      });

    return appointmentsFilteredToReturn;
  }, [appointments, isSelectedDay]);

  const selectedDayVacations = useMemo(() => {
    if (!calendarVacations) {
      return [];
    }

    const vacationsFilteredToReturn = calendarVacations.filter(
      calendarVacation =>
        isSameDay(calendarVacation.startDate, isSelectedDay) ||
        isSameDay(calendarVacation.endDate, isSelectedDay) ||
        (isBefore(calendarVacation.startDate, isSelectedDay) &&
          isBefore(isSelectedDay, calendarVacation.endDate))
    );

    return vacationsFilteredToReturn;
  }, [calendarVacations, isSelectedDay]);

  return (
    <JaimyContainer>
      <JaimyHeading.H2 fontSize="1.8rem" margin="0 0 8px 0" color={theme.colors.primary.base}>
        {routerLocation?.state?.calendarName || 'Calendar'}
      </JaimyHeading.H2>
      <JaimyFlex gap="16px" flexDirection="row" flexSm="column-reverse">
        <JaimyFlex
          hideScrollbar
          borderRadius={0.5}
          border={theme.border}
          padding="16px"
          flexDirection="column"
          height="fit-content"
          gap="16px"
        >
          <MyCalendar
            isSelectedDay={isSelectedDay}
            setIsSelectedDay={setIsSelectedDay}
            today={today}
            openVacationPlanning={openVacationPlanning}
            handleToggleVacationPlanning={handleToggleVacationPlanning}
          />

          <JaimyFlex
            alignItems="flex-end"
            gap="1rem"
            justifyContent="space-between"
            directionSm="column"
          >
            <MyCalendarSubtitles />
            <JaimyGrid columnGap="8px" columns="repeat(2, auto)" justifyContent="flex-end">
              <JaimyButton.Base
                maxWidth="12rem"
                height="2.5rem"
                onClick={handleToggleVacationPlanning}
              >
                <FormattedMessage id="calendar.vacation" />
              </JaimyButton.Base>
              <JaimyButton.Base
                height="2.5rem"
                backgroundColor={theme.colors.secondary.base}
                onClick={handleManageCalendar}
              >
                <FormattedMessage id="calendar.manage" />
              </JaimyButton.Base>
            </JaimyGrid>
          </JaimyFlex>
        </JaimyFlex>
        <JaimyFlex
          padding="1rem"
          width="30%"
          widthSm="100%"
          border={theme.border}
          borderRadius={0.5}
          flexDirection="column"
          justifyContent="space-between"
          gap="8px"
        >
          <JaimyFlex
            flexDirection="row"
            alignItems="center"
            backgroundColor={theme.colors.secondary.base}
            color={theme.colors.white}
            height="fit-content"
            padding=".5rem 1rem"
            borderRadius={0.5}
          >
            <JaimyBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="3rem"
              height="3rem"
              position="absolute"
              backgroundColor={theme.colors.white}
              borderRadius={20}
              border={`2px solid ${theme.colors.secondary.base}`}
              boxShadow={theme.shadow}
            >
              <CalendarTodayOutlined style={{ fill: theme.colors.secondary.base }} />
            </JaimyBox>
            <JaimyText color={theme.colors.white} margin="0 0 0 4rem" textTransform="capitalize">
              {format(isSelectedDay, 'd MMMM', {
                locale: getLocale() === 'fr' ? fr : nlBE
              })}
            </JaimyText>
          </JaimyFlex>
          <JaimyFlex
            justifyContent="flex-start"
            flexDirection="column"
            hideScrollbar
            gap="16px"
            overflow="scroll"
            height={
              !selectedDayAppointments.length && !selectedDayVacations.length
                ? 'fit-content'
                : '90%'
            }
            maxHeight="calc(100vh - 348px)"
          >
            <ComponentIsVisible when={selectedDayAppointments.length > 0}>
              {selectedDayAppointments.map(appointment => (
                <AppointmentCard key={appointment.id} appointment={appointment} />
              ))}
            </ComponentIsVisible>
            <ComponentIsVisible
              when={!selectedDayAppointments.length && !selectedDayVacations.length}
            >
              <JaimyText color="#888" margin="1rem auto 0" textAlign="center" width="75%">
                <FormattedMessage id="calendar.emptyAppointments" />
              </JaimyText>
            </ComponentIsVisible>
            <ComponentIsVisible when={selectedDayVacations.length > 0}>
              {selectedDayVacations.map(vacation => (
                <CardVacation vacation={vacation} key={vacation.id} />
              ))}
            </ComponentIsVisible>
          </JaimyFlex>
          <JaimyButton.Base
            height="2.5rem"
            backgroundColor={theme.colors.secondary.base}
            onClick={() => setOpen(true)}
          >
            <FormattedMessage id="calendar.addAppointment" />
          </JaimyButton.Base>
        </JaimyFlex>
      </JaimyFlex>

      <ManageCalendarDialog
        open={openManageCalendar}
        handleClose={handleManageCalendar}
        isInsuranceServicesCategory={routerLocation?.state?.isInsuranceServicesCategory}
      />
      <AddAppointmentDialog open={open} handleClose={handleClose} />
    </JaimyContainer>
  );
};

const mapStateToProps = ({
  calendars: { appointments, appointmentsLoading },
  vacation: { calendarVacations }
}: {
  calendars: FixMeLater;
  vacation: IVacationState;
}) => ({
  appointments,
  calendarVacations
});

export default connect(mapStateToProps, null)(CalendarView);
