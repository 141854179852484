import TraderService from '../../../lib/services/traderService';
import { HIDE_SNACK, SHOW_SNACK } from '../alerts/actionTypes';
import {
  GET_PREBOOKS_ERROR,
  GET_PREBOOKS_REQUEST,
  GET_PREBOOKS_SUCCESS,
  PUT_PREBOOKS_ERROR,
  PUT_PREBOOKS_REQUEST,
  PUT_PREBOOKS_SUCCESS
} from './actionTypes';

const traderService = new TraderService();

export function requestTraderPrebooksData() {
  return async dispatch => {
    dispatch({
      type: GET_PREBOOKS_REQUEST
    });
    try {
      const data = await traderService.getPrebooks();
      dispatch({
        type: GET_PREBOOKS_SUCCESS,
        payload: {
          prebooks: data.data,
          monthlyData: data.total
        }
      });
    } catch (error) {
      dispatch({
        type: GET_PREBOOKS_ERROR,
        payload: {
          error: error.message
        }
      });
    }
  };
}

export function modifyTraderPrebook(payload, snack) {
  return async dispatch => {
    dispatch({
      type: PUT_PREBOOKS_REQUEST
    });
    try {
      await traderService.putPrebooks(payload);
      dispatch({
        type: PUT_PREBOOKS_SUCCESS
        // payload: { prebooks: data }
      });
      if (snack) {
        dispatch({
          type: SHOW_SNACK,
          payload: { snackText: snack }
        });
        setTimeout(() => {
          dispatch({
            type: HIDE_SNACK
          });
        }, 3000);
      }
    } catch (error) {
      dispatch({
        type: PUT_PREBOOKS_ERROR,
        payload: {
          error: error.message
        }
      });
      dispatch({
        type: SHOW_SNACK,
        payload: { snackText: error.message }
      });
      setTimeout(() => {
        dispatch({
          type: HIDE_SNACK
        });
      }, 3000);
    }
  };
}
