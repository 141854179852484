import styled, { css } from 'styled-components';
import theme from '../..';

interface IButtonProps {
  alignItems?: string;
  backgroundColor?: string;
  border?: string;
  borderColor?: string;
  borderRadius?: number;
  bottom?: number | string;
  color?: string;
  columnGap?: string;
  display?: string;
  disabled?: boolean;
  fontSize?: string;
  gradient?: boolean;
  fontWeight?: string | number;
  height?: string;
  justifyContent?: string;
  left?: number | string;
  letterSpacing?: string;
  margin?: string;
  maxWidth?: string;
  opacity?: number;
  overflow?: string;
  padding?: string;
  position?: string;
  right?: number | string;
  rotateIcon?: string;
  textTransform?: string;
  transition?: string;
  transform?: string;
  top?: number | string;
  width?: string;
  zIndex?: number;
  float?: string;
}

const Base = styled.button<IButtonProps>`
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.primary.base};
  background: ${props => (props.gradient ? props.theme.gradients.primary : undefined)};
  border: ${props =>
    props.border
      ? props.border
      : `2px solid ${props.backgroundColor || props.theme.colors.primary.base}`};
  border-color: ${props => props.borderColor && props.borderColor};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 0.5)}rem;
  bottom: ${props => props.bottom && props.bottom};
  color: ${props => (props.color ? props.color : props.theme.colors.white)};
  display: ${props => (props.display ? props.display : 'flex')};
  font-size: ${props => props.fontSize && props.fontSize};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  height: ${props => (props.height ? props.height : '2rem')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  left: ${props => props.left && props.left};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : 'normal')};
  margin: ${props => props.margin && props.margin};
  max-width: ${props => props.maxWidth && props.maxWidth};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  overflow: ${props => props.overflow && props.overflow};
  padding: ${props => (props.padding ? props.padding : '1rem')};
  position: ${props => props.position && props.position};
  right: ${props => props.right && props.right};
  top: ${props => props.top && props.top};
  transform: ${props => props.transform && props.transform};
  transition: ${props => (props.transition ? props.transition : 'all 0.2s ease')};
  text-transform: ${props => (props.textTransform ? props.textTransform : 'uppercase')};
  width: ${props => props.width && props.width};
  z-index: ${props => props.zIndex && props.zIndex};
  float: ${props => props.float && props.float};

  cursor: pointer;

  ${props =>
    props.rotateIcon &&
    css`
      svg {
        transform: ${props.rotateIcon};
        transition: all 0.2s ease;
      }
    `};

  &:hover {
    opacity: ${props => (props.opacity ? 1 : 0.75)};
  }
`;

const TextOnly = styled(Base)<IButtonProps>`
  background-color: transparent;
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  height: ${props => (props.height ? props.height : '2rem')};
  width: ${props => (props.width ? props.width : 'fit-content')};

  &.dropdownHover {
    &:hover {
      background-color: ${props => props.theme.colors.gray[100]};
      border-radius: 0;
      opacity: 1;
    }
  }
`;

const BaseSecondary = styled(Base)<IButtonProps>`
  border: ${props =>
    props.disabled
      ? `2px solid ${theme.colors.gray}`
      : props.border
      ? props.border
      : `2px solid ${theme.colors.secondary.light}`};
  color: ${props =>
    props.disabled ? theme.colors.gray : props.color ? props.color : theme.colors.secondary.base};
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  padding: ${props => (props.padding ? props.padding : '.5rem 1rem')};
  opacity: ${props => (props.disabled ? 0.5 : props.opacity ? props.opacity : props.opacity)};
  &:hover {
    background-color: ${props => props.theme.colors.gray[100]};
    opacity: ${props => (props.opacity ? 1 : 0.75)};
  }
`;

const WithIcon = styled(Base)<IButtonProps>`
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  column-gap: 0.5rem;
  height: ${props => (props.height ? props.height : '2.25rem')};
  width: ${props => (props.width ? props.width : 'fit-content')};
`;

const IconOnly = styled(Base)<IButtonProps>`
  background-color: transparent;
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-size: ${props => (props.fontSize ? props.fontSize : 0)};
  padding: ${props => (props.padding ? props.padding : '0.5rem')};
`;

const Progress = styled(Base)<IButtonProps>`
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.primary.base};
  height: ${props => (props.height ? props.height : '2.25rem')};
  width: ${props => (props.width ? props.width : '270px')};

  &:active,
  &:hover {
    filter: brightness(0.75);
    opacity: 1;
  }
`;

const JaimyButton = {
  Base,
  BaseSecondary,
  TextOnly,
  WithIcon,
  IconOnly,
  Progress
};

export default JaimyButton;
