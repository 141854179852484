import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import getLocale, { isLocale } from './getLocale';

import querystring from 'query-string';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Home from './pages';
import Cart from './pages/cart';
import Checkout from './pages/cart/checkout';
import ForgotPassword from './pages/forgot-password';
import Help from './pages/help/help';
import Invoices from './pages/invoices';
import Services from './pages/jobs';
import Service from './pages/jobs/[id]';
import Questions from './pages/jobs/[id]/question';
import Login from './pages/login';
import Logoff from './pages/logoff';
import Profile from './pages/profile';
import Purchased from './pages/purchased';
import ServiceEstimated from './pages/purchased/[id]';
import Register from './pages/register';
import ResetPassword from './pages/reset-password/[id]';
import Unsubscribe from './pages/unsubscribe';
import Error from './pages/_error';
import PrivateRoute from './PrivateRoute';
import { setLanguage } from './store/reducers/user/actions';
import { setUpUTMSource } from './util/utmSourceHandler';

import Calendar from './pages/calendar';
import CalendarView from './pages/calendar/[id]';

import Prebook from './pages/prebook';
import PrebookView from './pages/prebook/[id]';

import { ChoosePlatform } from './pages/choose-platform';
import Dashboard from './pages/dashboard';

import CommunicationPage from './pages/communication/[id]';
import ConfirmationPageGuest from './pages/confirmation';
import ConfirmationPageUser from './pages/confirmation-user';
import TermsAndConditions from './pages/termsAndConditions/terms-and-conditions';

export const ROUTES = {
  CALENDAR: {
    path: '/:locale/calendar',
    handler: (query = '') => `/${getLocale()}/calendar${query}`
  },
  CALENDAR_ID: {
    path: '/:locale/calendar/:id',
    handler: (id, query = '') => `/${getLocale()}/calendar/${id}${query}`
  },
  DASHBOARD: {
    path: '/:locale/dashboard',
    handler: (query = '') => `/${getLocale()}/dashboard${query}`
  },
  PREBOOK: {
    path: '/:locale/campagne',
    handler: (query = '') => `/${getLocale()}/campagne${query}`
  },
  PREBOOK_ID: {
    path: '/:locale/campagne/:id',
    handler: id => `/${getLocale()}/campagne/${id}`
  },
  HOME: {
    path: `/:locale`,
    handler: (query = '') => `/${getLocale()}${query}`
  },
  LOGIN: {
    path: '/:locale/login',
    handler: (query = '') => `/${getLocale()}/login${query}`
  },
  LOGOFF: {
    path: '/:locale/logoff',
    handler: (query = '') => `/${getLocale()}/logoff${query}`
  },
  CHOOSE_PLATFORM: {
    path: '/:locale/choose-platform',
    handler: (query = '') => `/${getLocale()}/choose-platform${query}`
  },
  COMMUNICATION_ID: {
    path: '/:locale/communication/:id',
    handler: (id, query = '') => `/${getLocale()}/communication/${id}/${query}`
  },
  CONFIRMATION: {
    path: '/:locale/confirmation',
    handler: (query = '') => `/${getLocale()}/confirmation${query}`
  },
  CONFIRMATION_USER: {
    path: '/:locale/confirmation-user',
    handler: (query = '') => `/${getLocale()}/confirmation${query}`
  },
  PROFILE: {
    path: '/:locale/profile',
    handler: (query = '') => `/${getLocale()}/profile${query}`
  },
  JOBS: {
    path: '/:locale/jobs',
    handler: (query = '') => `/${getLocale()}/jobs${query}`
  },
  JOBS_ID: {
    path: '/:locale/jobs/:id',
    handler: (id, query = '') => `/${getLocale()}/jobs/${id}${query}`
  },
  JOB_QUESTION: {
    path: '/:locale/jobs/:id/question',
    handler: (id, query = '') => `/${getLocale()}/jobs/${id}/question${query}`
  },
  CART: {
    path: '/:locale/cart',
    handler: (query = '') => `/${getLocale()}/cart${query}`
  },
  CART_CHECKOUT: {
    path: '/:locale/cart/checkout',
    handler: (query = '') => `/${getLocale()}/cart/checkout${query}`
  },
  INVOICES: {
    path: '/:locale/invoices',
    handler: (query = '') => `/${getLocale()}/invoices${query}`
  },
  PURCHASED: {
    path: '/:locale/purchased',
    handler: (query = '') => `/${getLocale()}/purchased${query}`
  },
  PURCHASED_ID: {
    path: '/:locale/purchased/:id',
    handler: id => `/${getLocale()}/purchased/${id}`
  },
  RESET_PASSWORD: {
    path: '/:locale/reset-password',
    handler: (query = '') => `/${getLocale()}/reset-password${query}`
  },
  REGISTER: {
    path: '/:locale/register',
    handler: (query = '') => `/${getLocale()}/register${query}`
  },
  FORGOT_PASSWORD: {
    path: '/:locale/forgot-password',
    handler: (query = '') => `/${getLocale()}/forgot-password${query}`
  },
  HELP: {
    path: '/:locale/help',
    handler: (query = '') => `/${getLocale()}/help${query}`
  },
  UNSUBSCRIBE: {
    path: '/:locale/unsubscribe',
    handler: (query = '') => `/${getLocale()}/unsubscribe${query}`
  },
  TERMS_AND_CONDITIONS: {
    path: '/:locale/terms-and-conditions',
    handler: () => `/${getLocale()}/terms-and-conditions`
  }
};

/**
 * JaimyRoute is wrapped in PageLayout (Footer+Header)
 * @param {boolean} isGuest allow public routes
 * @param {boolean} authed authentification check - redirect to login if not
 */
const Rt = ({ component: Component, authed, isGuest = false, locale, setLanguage, ...props }) => {
  const location = useLocation();
  const query = querystring.parse(location.search);
  setUpUTMSource(query);
  // Comparison between the current locale (either pulled from redux or local storage)
  // With the url locale slug to check if a locale update is needed:
  // This will fix the explicit locale change in the url
  useEffect(() => {
    if (
      isLocale(props.computedMatch.params.locale) &&
      locale !== props.computedMatch.params.locale
    ) {
      setLanguage({ locale: props.computedMatch.params.locale });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.computedMatch.params.locale]);

  // Render Error component because the first parameter is a slug and can only by fr or nl
  if (!isLocale(props.computedMatch.params.locale)) {
    return <PrivateRoute {...props} isGuest={isGuest} component={Error} />;
  }

  if (isGuest) {
    return <PrivateRoute {...props} authed={authed} isGuest={isGuest} component={Component} />;
  }
  // An unauthenticated user can try open some valid link.
  // In this case a redirection with state will be performed that holds previously opened link
  if (!authed) {
    return (
      <Route
        {...props}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: `${ROUTES.LOGIN.handler()}`,
              search: location.search,
              state: { from: location }
            }}
          />
        )}
      />
    );
  }
  return <PrivateRoute {...props} authed={authed} component={Component} />;
};
const mapStateToProps = state => ({
  authed: Boolean(state.user.user.id),
  locale: state.user.user.locale
});
const mapDispatchToProps = {
  setLanguage
};
const JaimyRoute = connect(mapStateToProps, mapDispatchToProps)(Rt);

const Routes = () => {
  return (
    <Switch>
      {/* redirect with utm_source and voucher when user is followed from email link */}
      <Redirect from="/welkom" to="/nl?utm_source=mailing&voucher=WELCOME20" />
      <Redirect from="/bienvenue" to="/fr?utm_source=mailing&voucher=WELCOME20" />

      {/* Support of Legacy routes */}
      <Route
        exact
        path="/jobs/:id"
        render={props => (
          <Redirect
            to={`${ROUTES.JOBS_ID.handler(props.match.params.id)}${window.location.search}`}
          />
        )}
      />
      <Route
        exact
        path="/purchased/:id"
        render={props => (
          <Redirect
            to={`${ROUTES.PURCHASED_ID.handler(props.match.params.id)}${window.location.search}`}
          />
        )}
      />
      <Redirect
        from="/reset-password"
        to={`${ROUTES.RESET_PASSWORD.handler()}${window.location.search}`}
      />
      <Redirect
        exact
        from="/cart/checkout"
        to={`${ROUTES.CART_CHECKOUT.handler()}${window.location.search}`}
      />

      <Redirect from="/register" to={`${ROUTES.REGISTER.handler()}${window.location.search}`} />
      <Redirect from="/jobs" to={`${ROUTES.JOBS.handler()}${window.location.search}`} />
      <Redirect from="/cart" to={`${ROUTES.CART.handler()}${window.location.search}`} />
      <Redirect from="/invoices" to={`${ROUTES.INVOICES.handler()}${window.location.search}`} />
      <Redirect from="/calendar" to={`${ROUTES.CALENDAR.handler()}${window.location.search}`} />
      <Redirect from="/prebook" to={`${ROUTES.PREBOOK.handler()}${window.location.search}`} />
      <Redirect from="/purchased" to={`${ROUTES.PURCHASED.handler()}${window.location.search}`} />
      <Redirect from="/profile" to={`${ROUTES.PROFILE.handler()}${window.location.search}`} />
      <Redirect from="/login" to={`${ROUTES.LOGIN.handler()}${window.location.search}`} />
      <Redirect
        from="/choose-platform"
        to={`${ROUTES.CHOOSE_PLATFORM.handler(window.location.search)}`}
      />
      <Redirect from="/help" to={`${ROUTES.HELP.handler()}${window.location.search}`} />

      {/* Home with locale */}
      <Redirect exact from="/" to={`${ROUTES.DASHBOARD.handler(window.location.search)}`} />

      {/* Public Routes */}
      <JaimyRoute isGuest exact path={`${ROUTES.HOME.path}`} component={Home} />
      <JaimyRoute isGuest exact path={`${ROUTES.LOGIN.path}`} component={Login} />
      <JaimyRoute isGuest exact path={`${ROUTES.LOGOFF.path}`} component={Logoff} />
      <JaimyRoute
        isGuest
        exact
        path={`${ROUTES.CONFIRMATION.path}`}
        component={ConfirmationPageGuest}
      />
      <JaimyRoute
        exact
        isGuest
        path={`${ROUTES.TERMS_AND_CONDITIONS.path}`}
        component={TermsAndConditions}
      />

      {/* Private routes */}
      <JaimyRoute exact path={`${ROUTES.CHOOSE_PLATFORM.path}`} component={ChoosePlatform} />
      <JaimyRoute exact path={`${ROUTES.DASHBOARD.path}`} component={Dashboard} />
      <JaimyRoute exact path={`${ROUTES.PROFILE.path}`} component={Profile} />
      <JaimyRoute exact path={`${ROUTES.JOBS.path}`} component={Services} />
      <JaimyRoute exact path={`${ROUTES.JOBS_ID.path}`} component={Service} />
      <JaimyRoute exact path={`${ROUTES.JOB_QUESTION.path}`} component={Questions} />
      <JaimyRoute exact path={`${ROUTES.CART.path}`} component={Cart} />
      <JaimyRoute exact path={`${ROUTES.CART_CHECKOUT.path}`} component={Checkout} />
      <JaimyRoute exact path={`${ROUTES.INVOICES.path}`} component={Invoices} />
      <JaimyRoute exact path={`${ROUTES.CALENDAR.path}`} component={Calendar} />
      <JaimyRoute exact path={`${ROUTES.CALENDAR_ID.path}`} component={CalendarView} />
      <JaimyRoute exact path={`${ROUTES.PREBOOK.path}`} component={Prebook} />
      <JaimyRoute exact path={`${ROUTES.PREBOOK_ID.path}`} component={PrebookView} />
      <JaimyRoute exact path={`${ROUTES.PURCHASED.path}`} component={Purchased} />
      <JaimyRoute exact path={`${ROUTES.PURCHASED_ID.path}`} component={ServiceEstimated} />
      <JaimyRoute
        exact
        path={`${ROUTES.CONFIRMATION_USER.path}`}
        component={ConfirmationPageUser}
      />

      {/* Public Routes */}
      <JaimyRoute isGuest exact path={`${ROUTES.RESET_PASSWORD.path}`} component={ResetPassword} />
      <JaimyRoute
        isGuest
        exact
        path={`${ROUTES.FORGOT_PASSWORD.path}`}
        component={ForgotPassword}
      />
      <JaimyRoute isGuest exact path={ROUTES.UNSUBSCRIBE.path} component={Unsubscribe} />
      <JaimyRoute isGuest exact path={`${ROUTES.REGISTER.path}`} component={Register} />
      <JaimyRoute isGuest exact path={`${ROUTES.HELP.path}`} component={Help} />
      {/* Public Satisfaction Email Forms */}
      <JaimyRoute
        isGuest
        exact
        path={`${ROUTES.COMMUNICATION_ID.path}`}
        component={CommunicationPage}
      />
      {/* 404/500 Route */}
      <JaimyRoute isGuest component={Error} />
    </Switch>
  );
};
const mapStateToPropsRoutes = state => ({
  locale: state.user.user.locale
});
export default connect(mapStateToPropsRoutes, null)(Routes);
// export default Routes;
