import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const JaimyCheckbox = withStyles({
  root: {
    color: '#32546D',
    '&:checked': {
      color: '#32546D'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default JaimyCheckbox;
