module.exports = {
  CART_LOAD: 'CART_LOAD',
  CART_LOAD_SUCCESS: 'CART_LOAD_SUCCESS',
  CART_LOAD_ERROR: 'CART_LOAD_ERROR',

  CART_ADD_ITEM: 'CART_ADD_ITEM',
  CART_ADD_ITEM_SUCCESS: 'CART_ADD_ITEM_SUCCESS',
  CART_ADD_ITEM_ERROR: 'CART_ADD_ITEM_ERROR',

  CART_REMOVE_ITEM: 'CART_REMOVE_ITEM',
  CART_REMOVE_ITEM_SUCCESS: 'CART_REMOVE_ITEM_SUCCESS',
  CART_REMOVE_ITEM_ERROR: 'CART_REMOVE_ITEM_ERROR',

  CART_EXPIRED_REQUEST: 'CART_EXPIRED_REQUEST',
  CART_EXPIRED_SUCCESS: 'CART_EXPIRED_SUCCESS',
  CART_EXPIRED_ERROR: 'CART_EXPIRED_SUCCESS',

  CART_EXPIRED_DELETE_REQUEST: 'CART_EXPIRED_DELETE_REQUEST',
  CART_EXPIRED_DELETE_SUCCESS: 'CART_EXPIRED_DELETE_SUCCESS',
  CART_EXPIRED_DELETE_ERROR: 'CART_EXPIRED_DELETE_SUCCESS',

  CART_CHECKOUT_REQUEST: 'CART_CHECKOUT_REQUEST',
  CART_CHECKOUT_REQUEST_ERROR: 'CART_CHECKOUT_REQUEST_ERROR',
  CART_CHECKOUT_AWATING_APPROVAL: 'CART_CHECKOUT_AWATING_APPROVAL',
  CART_CHECKOUT_APPROVED: 'CART_CHECKOUT_APPROVED',
  CART_CHECKOUT_REFUSED: 'CART_CHECKOUT_REFUSED',

  CART_INVOICE_REQUEST: 'CART_INVOICE_REQUEST',
  CART_INVOICE_REQUEST_ERROR: 'CART_INVOICE_REQUEST_ERROR',
  CART_INVOICE_AWATING_APPROVAL: 'CART_INVOICE_AWATING_APPROVAL',
  CART_INVOICE_APPROVED: 'CART_INVOICE_APPROVED',
  CART_INVOICE_REFUSED: 'CART_INVOICE_REFUSED',

  CART_UPDATE_ID: 'CART_UPDATE_ID',
  CART_MERGE_ALL: 'CART_MERGE_ALL',

  CART_REPLACE_ALL: 'CART_REPLACE_ALL',

  CART_RESET_HAS_ERROR: 'CART_RESET_HAS_ERROR',

  REQUEST_PAYMENT_STATUS_START: 'REQUEST_PAYMENT_STATUS_START',
  REQUEST_PAYMENT_STATUS_SUCCESS: 'REQUEST_PAYMENT_STATUS_SUCCESS',
  REQUEST_PAYMENT_STATUS_ERROR: 'REQUEST_PAYMENT_STATUS_ERROR'
};
