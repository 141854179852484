import { addDays, startOfWeek } from 'date-fns';

export function generateWeekDayNames(today: Date) {
  const weekStartDate = startOfWeek(today);
  const weekDays = [];

  for (let day = 0; day < 7; day++) {
    weekDays.push(addDays(weekStartDate, day));
  }

  return weekDays;
}
