import React from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: '#182a35'
  },
  tooltip: {
    color: 'white',
    maxWidth: '300px',
    padding: '12px',
    fontSize: '14px',
    backgroundColor: '#182a35',
    fontFamily: 'Lato'
  }
}));
const JaimyTooltip = ({ children, title, placement, ...props }) => {
  const tooltipClass = useStylesBootstrap();
  return (
    <Tooltip
      {...props}
      classes={tooltipClass}
      title={title}
      aria-label={title}
      arrow
      enterTouchDelay={50}
    >
      {children}
    </Tooltip>
  );
};
export default JaimyTooltip;
