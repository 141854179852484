import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as ReferenceIcon } from '../../../assets/images/reference-icon.svg';
import { formatPrice } from '../../../formatters/price';
import { ROUTES } from '../../../routes';
import { addServiceToCart } from '../../../store/reducers/cart/actions';
import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import { IRelevantJob } from '../../../types/RelevantJob';
import DashboardCard from '../../cards/DashboardCard';

import { Divider } from '@material-ui/core';
import { allowedToSeeAddCartButton, EVerifiedStatus } from '../../../constants/verifiedStatus';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ComponentIsVisible } from '../../utils/isVisible';

interface IRelevantJobSection {
  relevantJobs: IRelevantJob[];
  userVerifiedStatus: EVerifiedStatus;
  userCheckedPurchasesLeft: number;
  userWaitForInfoPurchasesLeft: number;
}

const RelevantJobsSection = ({
  relevantJobs,
  userCheckedPurchasesLeft,
  userVerifiedStatus,
  userWaitForInfoPurchasesLeft
}: IRelevantJobSection) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const addToCart = useCallback(async (job: IRelevantJob) => {
    await dispatch(addServiceToCart(job));
    history.push(ROUTES.CART.handler());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeeMore = (id: number) => {
    history.push(ROUTES.JOBS_ID.handler(id));
  };
  const handleLeadsPage = () => {
    history.push(ROUTES.JOBS.handler());
  };

  const spanMessage = {
    span: (msg: any) => {
      return <span style={{ color: theme.colors.primary.dark, fontWeight: 'bolder' }}>{msg}</span>;
    }
  };

  const hasShowCartButton = useMemo(() => {
    const isAllowedBasedOnVerifiedStatus = allowedToSeeAddCartButton.includes(userVerifiedStatus);
    if (isAllowedBasedOnVerifiedStatus) {
      return isAllowedBasedOnVerifiedStatus;
    }

    const isVerifiedStatusCheckedAndHasPurchacesLeft =
      userVerifiedStatus === EVerifiedStatus.Checked && userCheckedPurchasesLeft > 0;
    if (isVerifiedStatusCheckedAndHasPurchacesLeft) {
      return isVerifiedStatusCheckedAndHasPurchacesLeft;
    }

    const isVerifiedStatusWaitForInfoHasPurchasesLeft =
      userVerifiedStatus === EVerifiedStatus.RestrictedWaitForInfo &&
      userWaitForInfoPurchasesLeft > 0;
    if (isVerifiedStatusWaitForInfoHasPurchasesLeft) {
      return isVerifiedStatusWaitForInfoHasPurchasesLeft;
    }

    return false;
  }, [userCheckedPurchasesLeft, userVerifiedStatus, userWaitForInfoPurchasesLeft]);

  return (
    <JaimyFlex
      id="relevant-jobs"
      flexDirection="column"
      alignItems="center"
      margin="1rem auto"
      width="100%"
      maxWidth="1200px"
      gap="1rem"
      padding="0 1.2rem"
      paddingSm="0"
      marginSm="0"
    >
      <JaimyFlex
        widthSm="300px"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        padding="10px 0px"
      >
        <JaimyHeading.H3 fontSize="1.2rem" color={theme.colors.blue.darkBlue}>
          <FormattedMessage
            id="dashboard.relevantJobs"
            /* @ts-ignore */
            values={spanMessage}
          />
        </JaimyHeading.H3>
      </JaimyFlex>
      {relevantJobs.map(job => (
        <DashboardCard key={job.id}>
          <JaimyFlex
            directionSm="column"
            alignItems="center"
            padding="1rem 0"
            gap={width <= 768 ? '1.5rem' : '1rem'}
          >
            <JaimyFlex justifyContent="space-around" alignItems="center">
              <JaimyFlex justifyContent="center" alignItems="center" gap="0.5rem">
                <ReferenceIcon width={25} height={25} fill="#274154" />
                <JaimyText fontWeight={700}>{job.id}</JaimyText>
              </JaimyFlex>
              <JaimyFlex justifyContent="center">
                <JaimyHeading.H6 color={theme.colors.primary.dark} fontWeight={700}>
                  <ComponentIsVisible when={intl.locale === 'fr'}>
                    {job.treetrade_parent
                      ? `${job.treetrade.fr_name} - ${job.treetrade_parent.fr_name}`
                      : `${job.treetrade.fr_name}`}
                  </ComponentIsVisible>
                  <ComponentIsVisible when={intl.locale === 'nl'}>
                    {job.treetrade_parent
                      ? `${job.treetrade.nl_name} - ${job.treetrade_parent.nl_name}`
                      : `${job.treetrade.nl_name}`}
                  </ComponentIsVisible>
                </JaimyHeading.H6>
              </JaimyFlex>
            </JaimyFlex>
            <ComponentIsVisible when={width > 768}>
              <Divider orientation="vertical" variant="middle" flexItem />
            </ComponentIsVisible>
            <JaimyFlex justifyContent="space-around" alignItems="center" gap="1rem">
              <JaimyFlex justifyContent="center" alignItems="center" gap="0.5rem">
                <LocationIcon
                  width={25}
                  height={25}
                  fill="#274154"
                  style={{ marginLeft: width <= 768 ? '25px' : 0 }}
                />
                <JaimyText fontWeight={700}>
                  {job.postcode} {job.city}
                </JaimyText>
              </JaimyFlex>
              <JaimyFlex justifyContent="center" justifyContentSm="start">
                <JaimyText fontWeight={700}>{formatPrice(job.price)}</JaimyText>
              </JaimyFlex>
            </JaimyFlex>
            <ComponentIsVisible when={width > 768}>
              <Divider orientation="vertical" variant="middle" flexItem />
            </ComponentIsVisible>
            <JaimyFlex
              gap="1rem"
              justifyContent="center"
              justifyContentSm="center"
              alignItems="center"
            >
              <ComponentIsVisible when={hasShowCartButton}>
                <JaimyButton.Base onClick={() => addToCart(job)} gradient>
                  <FormattedMessage id="buy" />
                </JaimyButton.Base>
              </ComponentIsVisible>
              <JaimyButton.TextOnly
                color={theme.colors.secondary.base}
                border={`2px solid ${theme.colors.secondary.base}`}
                onClick={() => handleSeeMore(job.id)}
              >
                <FormattedMessage id="see" />
              </JaimyButton.TextOnly>
            </JaimyFlex>
          </JaimyFlex>
        </DashboardCard>
      ))}
      <JaimyButton.BaseSecondary
        height="2.5rem"
        margin="20px 0px"
        onClick={() => handleLeadsPage()}
      >
        <FormattedMessage id="dashboard.relevantJobs.seeAllMissions" />
      </JaimyButton.BaseSecondary>
    </JaimyFlex>
  );
};

export default RelevantJobsSection;
