import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import querystring from 'query-string';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ReactComponent as AlertIcon } from '../assets/images/alert-icon.svg';
import { ReactComponent as BrushIcon } from '../assets/images/card-top.svg';
import { ReactComponent as HeartIcon } from '../assets/images/heart-icon.svg';
import { ReactComponent as MoneyIcon } from '../assets/images/home-money-icon.svg';
import mobilePic from '../assets/images/home-phone.svg';
import { ReactComponent as PhoneIcon } from '../assets/images/phone.svg';
import { ReactComponent as WeirdArrowIcon } from '../assets/images/weird-arrow.svg';
import { ButtonPrimary } from '../components/common/Button';
import { CardTitle } from '../components/ContactCard';
import SignUp from '../components/forms/SignUp';
import Stepper from '../components/Stepper';
import { raisePageEvent } from '../logging/analytics';
import { ROUTES } from '../routes';
import { updateForm } from '../store/reducers/forms/actions';
import { TEAMLEADER_REGISTER_LOCALSTORAGE } from '../util/constants';
import { getUtmSource } from '../util/utmSourceHandler';

const DivHome = styled.div`
  background-color: #fff;
`;
const DivTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 1rem 2rem;
  color: #fff;
  position: relative;
`;
const H2TopTitle = styled.h2`
  font-size: 28px;
  text-align: center;
  line-height: 31px;
  text-shadow: 0 2px 28px rgba(0, 0, 0, 0.3);
  font-family: 'BelfiusNormal', sans-serif;

  @media (min-width: 1200px) {
    font-size: 48px;
    line-height: 48px;
    text-align: left;
  }
`;
const H4Subtitle = styled.h4`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-top: 1.2rem;
  width: 272px;
  text-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);

  @media (min-width: 1200px) {
    text-align: left;
    width: 400px;
  }
`;
const DivCallUs = styled.div`
  align-items: center;
  background-color: #32546d;
  border-radius: 55.5px;
  display: flex;
  width: 208px;
  height: 52px;
  padding: 0.6rem;
  margin: 1.2rem 0 1rem;

  @media (min-width: 1200px) {
    width: 320px;
    height: 88px;
    margin-top: 2.6rem;
  }

  > a {
    :first-of-type {
      align-items: center;
      background-color: #86bcd3;
      border-radius: 50%;
      display: flex;
      height: 36px;
      justify-content: center;
      width: 36px;

      @media (min-width: 1200px) {
        height: 64px;
        width: 64px;

        > svg {
          height: 19px;
          width: 19px;
        }
      }
    }
  }
  > div {
    :first-of-type {
      > p {
        margin-left: 0.6rem;
        font-family: 'Lato', sans-serif;
        font-style: normal;

        :first-of-type {
          color: #86bcd3;
          font-weight: 900;
          font-size: 10px;
          line-height: 18px;
        }

        :nth-of-type(2) {
          color: #fff;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;

          @media (min-width: 1200px) {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
      > a {
        text-decoration: none;
        margin-left: 0.6rem;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        @media (min-width: 1200px) {
          font-size: 20px;
          line-height: 22px;
        }
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
const DivRegistration = styled.div`
  z-index: 2;
  background-color: #86bcd3;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 336px;
  height: 40px;
  padding: 1.2rem;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  bottom: -22px;

  @media (min-width: 1200px) {
    display: none;
  }

  > svg {
    margin-right: 0.6rem;
  }
`;
const PPrimaryTitle = styled.p`
  font-family: 'BelfiusNormal', sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin: 0 auto;
  color: #c30144;
  ${props =>
    props.white &&
    css`
      color: #fff;
    `}
`;
const PSecondaryTitle = styled.p`
  font-family: 'BelfiusNormal', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0.5rem;
  padding: 0 5rem;
  color: #fff;
`;
const PUnderSignUpCTA = styled.p`
  padding: 0 1rem;
  margin: 0.2rem auto;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`;

const DivBrush = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 2rem;

  @media (min-width: 1200px) {
    justify-content: flex-start;
    max-width: 1200px;
    margin: 0 auto 2rem;
    padding-top: 2rem;
  }

  > span {
    color: #fff;
    font-family: 'BelfiusNormal', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    position: absolute;
    top: 66px;
    bottom: 0;
    left: -52px;
    right: 0;
    margin: auto;
    text-align: center;

    @media (min-width: 1200px) {
      left: 60px;
      text-align: left;
    }
  }
`;
const DivCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 2.8rem 1rem;
  box-shadow: 0px 2px 18px rgba(173, 173, 173, 0.27087);
  margin: 2rem 1rem;
  font-family: 'Lato', sans-serif;
  position: relative;
  ${props =>
    props.bordered &&
    css`
      border: 1px solid #d8dae0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `}

  > h2 {
    color: #c30144;
    font-family: 'BelfiusNormal', sans-serif;
    font-size: 18px;
    line-height: 21px;
  }

  > p {
    color: #32546d;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0.6rem;
  }
`;

const DivTestimonial = styled.div`
  flex-basis: 33%;
  margin: 2rem 1rem;
  flex-direction: column;
  align-items: flex-start;

  > h3 {
    color: #c30144;
    font-family: 'BelfiusNormal', sans-serif;
    font-size: 20px;
    line-height: 21px;
  }

  > p {
    color: #a9a9a9;
    font-weight: 600;
    font-size: 10pt;
    line-height: 24px;
  }
`;

const DivYoutubeContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 890px;
  margin-bottom: 0.5rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SpanWeirdIcon = styled.span`
  background-color: #86bcd3;
  width: 56px;
  height: 56px;
  display: block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -26px;
  left: 28px;
`;
const DivMobile = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 1.2rem;
  position: relative;
  height: 449px;
  margin: 0 1rem 14rem;
  background-image: url('/static/images/trader-on-phone.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 768px 100%;
  border-radius: 18px;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
    background-size: 100%;
  }

  > p {
    margin-bottom: 2rem;
    width: 286px;

    @media (min-width: 1200px) {
      order: 2;
      flex-basis: 70%;
    }
  }

  > svg {
    width: 196px;
    height: 406px;
    position: absolute;
    bottom: -186px;

    @media (min-width: 1200px) {
      position: relative;
      bottom: 0;
      order: 1;
      flex-basis: 30%;
      width: 320px;
      height: 658px;
    }
  }
`;
const DivMovileWrapper = styled.div`
  background-color: #fff;

  @media (min-width: 1200px) {
    width: 1200px;
    margin: 0 auto;

    > div {
      border-radius: 12px;
    }
  }

  > p {
    width: 316px;

    @media (min-width: 1200px) {
      width: 676px;
    }
  }

  > button {
    width: 80%;
    justify-content: center;
    margin: 2rem auto 0;

    @media (min-width: 1200px) {
      width: 236px;
      margin: 2.8rem auto 0;
    }
  }
`;
const DivWrapperWidth = styled.div`
  width: ${props => props.width};
  margin: ${props => (props.mt ? props.mt : '0')} auto 0 auto;
`;
const DivHowItWorks = styled.div`
  padding: 2.6rem 1rem 1rem;
  background-image: url('/static/images/carpenter-carpentry.png');
  background-position: center -50px;
  background-repeat: no-repeat;
  background-size: 768px 100%;

  > p {
    color: #32546d;
    margin-bottom: 2rem;
    font-size: 22px;
  }

  @media (min-width: 1200px) {
    background-size: 100%;
    background-position: center -76px;
    padding-bottom: 4rem;

    > div {
      :last-of-type {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
`;
const DivHowItWorksItem = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  align-items: center;
  margin-bottom: 4rem;

  @media (min-width: 1200px) {
    position: relative;
    padding: 0 3rem;

    ::after {
      content: ' ';
      position: absolute;
      border-left-width: 0;
      height: 2px;
      background-color: #d8dae0;
      top: 20px;
      right: 0;
      width: 100%;
      ${props =>
        props.first &&
        css`
          width: 50%;
        `}
      ${props =>
        props.last &&
        css`
          width: 50%;
          left: 0;
        `}
    }
  }

  > span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #86bcd3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    z-index: 2;
  }

  > p {
    width: 200px;
    text-align: center;
    margin-top: 1rem;
  }
`;
const DivTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url('/static/images/trader-home.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 768px;

  @media (min-width: 768px) {
    background-size: contain;
    background-position: top;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    background-size: cover;
    background-position: center -108px;

    > div {
      flex-basis: 50%;

      :nth-of-type(2) {
        display: flex;
        justify-content: center;

        > div {
          max-width: 430px;
          padding: 1rem 2.8rem;
        }
      }
    }
  }
`;
const SectionTop = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media (min-width: 1200px) {
    align-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 12rem;
  }
`;
const DivStepper = styled.div`
  @media (min-width: 1200px) {
    width: 1200px;
    display: flex;
    margin: 0 auto;

    > div {
      align-self: center;
      position: relative;
      margin-bottom: 14rem;

      :nth-of-type(2) {
        top: 4rem;
      }

      :nth-of-type(3) {
        top: 10rem;
      }
    }
  }
`;
const DivComments = styled.div`
  @media (min-width: 1200px) {
    width: 1200px;
    display: flex;
    margin: 2rem auto;
  }
`;
const DivWorks = styled.div`
  @media (min-width: 1200px) {
    width: 1200px;
    display: flex;
    margin: 2rem auto;
    justify-content: center;
  }
`;
const DivImageWrapper = styled.div`
  background-image: url(${props => props.image});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 768px 100%;

  ${props =>
    props.pb &&
    css`
      padding-bottom: ${props.pb};
    `}

  @media (min-width: 1200px) {
    background-size: cover;
  }
`;
const MobilePic = styled.img`
  width: 196px;
  height: 406px;
  position: absolute;
  bottom: -186px;

  @media (min-width: 1200px) {
    position: relative;
    bottom: 0px;
    order: 1;
    flex-basis: 30%;
    width: 320px;
    height: 658px;
  }
`;

const createMarkup = id => ({
  __html: `
  <div 
    class="youtube-player" 
    data-id=${id}
    data-related="0" 
    data-control="0" 
    data-info="0" 
    data-fullscreen="0"
    style="width:100%;
    display: block; 
    position: relative;
    cursor: pointer;
    max-height:501px;
    height:100%; 
    overflow:hidden;
    padding-bottom:56.25%;
    margin:0 auto">
      <img src="//i.ytimg.com/vi/${id}/hqdefault.jpg"
      alt="img"
      style="bottom: -100%; 
      display: block; 
      left: 0; 
      margin: auto; 
      max-width: 100%; 
      width: 100%;
      height:auto; 
      position: absolute; 
      right: 0; 
      top: -100%;"> 
      <div 
        style="height: 72px; width: 72px; left: 50%; top: 50%; 
        margin-left: -36px; margin-top: -36px; position: absolute; 
        background: url('https://i.imgur.com/TxzC70f.png') no-repeat;">
      </div> 
    </div> `
});

const HomePage = ({ updatePromocode, userId, history, location }) => {
  const intl = useIntl();
  const isLogged = Boolean(userId);
  const metaTitle = intl.messages['homepage.title'];

  useEffect(() => {
    const query = querystring.parse(location.search);
    const { promocode } = query;
    if (promocode) {
      updatePromocode(promocode);
    }
    if ([query.source, getUtmSource(query)].includes('teamleader')) {
      localStorage.setItem(TEAMLEADER_REGISTER_LOCALSTORAGE, true);
    }
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var v = document.getElementsByClassName('youtube-player');
    for (var n = 0; n < v.length; n++) {
      v[n].onclick = function () {
        var iframe = document.createElement('iframe');
        iframe.setAttribute(
          'src',
          '//www.youtube.com/embed/' +
            this.dataset.id +
            '?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&rel=' +
            this.dataset.related +
            '&controls=' +
            this.dataset.control +
            '&showinfo=' +
            this.dataset.info
        );
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('id', 'youtube-iframe');
        iframe.setAttribute(
          'style',
          'width: 100%; height: 100%; position: absolute; top: 0; left: 0;'
        );
        if (this.dataset.fullscreen === 1) {
          iframe.setAttribute('allowfullscreen', '');
        }
        while (this.firstChild) {
          this.removeChild(this.firstChild);
        }
        this.appendChild(iframe);
      };
    }
  }, [intl.locale]);

  useEffect(() => {
    if (isLogged) {
      history.replace(`${ROUTES.DASHBOARD.handler()}${window.location.search}`);
    }
  }, [history, isLogged]);

  const onClickSignUp = () => {
    window.scrollTo({ top: isMobile ? 450 : 50, behavior: 'smooth' });
  };
  const steps = () => {
    const StepperWrapper = isMobile ? Stepper : DivStepper;

    return (
      <StepperWrapper>
        <DivCard>
          <SpanWeirdIcon>
            <WeirdArrowIcon />
          </SpanWeirdIcon>
          <h2>
            <FormattedMessage id="home.why_jaimy.step1.title" tagName="span" />
          </h2>
          <p>
            <FormattedMessage id="home.why_jaimy.step1.description" tagName="span" />
          </p>
        </DivCard>
        <DivCard>
          <SpanWeirdIcon>
            <MoneyIcon />
          </SpanWeirdIcon>
          <h2>
            <FormattedMessage id="home.why_jaimy.step2.title" tagName="span" />
          </h2>
          <p>
            <FormattedMessage id="home.why_jaimy.step2.description" tagName="span" />
          </p>
        </DivCard>
        <DivCard>
          <SpanWeirdIcon>
            <HeartIcon />
          </SpanWeirdIcon>
          <h2>
            <FormattedMessage id="home.why_jaimy.step3.title" tagName="span" />
          </h2>
          <p>
            <FormattedMessage id="home.why_jaimy.step3.description" tagName="span" />
          </p>
        </DivCard>
      </StepperWrapper>
    );
  };
  const comments = () => {
    return (
      <DivComments>
        <DivTestimonial>
          <DivYoutubeContainer
            dangerouslySetInnerHTML={createMarkup(
              intl.locale === 'fr' ? 'poeRFyDmeEY' : 'B43Sl7ck3dg'
            )}
          />
          <h3>
            <FormattedMessage id="home.testimonials.person1.name" tagName="span" />
          </h3>
          <p>
            <FormattedMessage id="home.testimonials.person1.location" tagName="span" />
          </p>
        </DivTestimonial>
        <DivTestimonial>
          <DivYoutubeContainer
            dangerouslySetInnerHTML={createMarkup(
              intl.locale === 'fr' ? '12SIPcp4R-Q' : 'SCNl5qb6lSo'
            )}
          />
          <h3>
            <FormattedMessage id="home.testimonials.person2.name" tagName="span" />
          </h3>
          <p>
            <FormattedMessage id="home.testimonials.person2.location" tagName="span" />
          </p>
        </DivTestimonial>
        <DivTestimonial>
          <DivYoutubeContainer
            dangerouslySetInnerHTML={createMarkup(
              intl.locale === 'fr' ? 'C0w7XHovxO4' : 'NCRGarOAmNk'
            )}
          />
          <h3>
            <FormattedMessage id="home.testimonials.person3.name" tagName="span" />
          </h3>
          <p>
            <FormattedMessage id="home.testimonials.person3.location" tagName="span" />
          </p>
        </DivTestimonial>
      </DivComments>
    );
  };
  const howItWorks = () => {
    const StepperWrapper = isMobile ? Stepper : DivWorks;

    return (
      <StepperWrapper>
        <DivHowItWorksItem first>
          <span>1</span>
          <p>
            <FormattedMessage id="home.how_it_works.step1" tagName="span" />
          </p>
        </DivHowItWorksItem>
        <DivHowItWorksItem>
          <span>2</span>
          <p>
            <FormattedMessage id="home.how_it_works.step2" tagName="span" />
          </p>
        </DivHowItWorksItem>
        <DivHowItWorksItem>
          <span>3</span>
          <p>
            <FormattedMessage id="home.how_it_works.step3" tagName="span" />
          </p>
        </DivHowItWorksItem>
        <DivHowItWorksItem last>
          <span>4</span>
          <p>
            <FormattedMessage id="home.how_it_works.step4" tagName="span" />
          </p>
        </DivHowItWorksItem>
      </StepperWrapper>
    );
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivHome>
        <DivTopWrapper>
          <DivTop>
            <SectionTop>
              <H2TopTitle>
                <FormattedMessage id="home.hero.title" tagName="span" />
              </H2TopTitle>
              <H4Subtitle>
                <FormattedMessage id="home.hero.subtitle" tagName="span" />
              </H4Subtitle>
              <DivCallUs>
                <a
                  href={`tel:${intl.messages['footer.phone']}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PhoneIcon width={16} height={16} fill="#fff" />
                </a>
                <div>
                  <p>
                    <FormattedMessage id="home.hero.phone_us" tagName="span" />
                  </p>
                  <a
                    href={`tel:${intl.messages['footer.phone']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage tagName="span" id="footer.phone" />
                  </a>
                </div>
              </DivCallUs>
              <DivRegistration>
                <AlertIcon />
                <FormattedMessage id="home.hero.mobile.no_obligation" tagName="span" />
              </DivRegistration>
            </SectionTop>
          </DivTop>
          <SignUp />
        </DivTopWrapper>
        <DivImageWrapper pb="4rem" image="/static/images/colors-containers.png">
          <DivBrush>
            <BrushIcon width="278" height="91" />
            <span>
              <FormattedMessage id="home.why_jaimy.title" tagName="span" />
            </span>
          </DivBrush>
          {steps()}
        </DivImageWrapper>
        <DivWrapperWidth mt="4rem" width="286px">
          <PPrimaryTitle>
            <FormattedMessage id="home.testimonials.title" tagName="span" />
          </PPrimaryTitle>
        </DivWrapperWidth>
        {comments()}
        <DivMovileWrapper>
          <DivMobile>
            <div>
              <PPrimaryTitle white>
                <FormattedMessage id="home.text_on_image_center.title" tagName="span" />
              </PPrimaryTitle>
              {!isMobile && (
                <PSecondaryTitle>
                  <FormattedMessage id="home.text_on_image_center.subtitle" tagName="span" />
                </PSecondaryTitle>
              )}
            </div>
            <MobilePic src={mobilePic} />
          </DivMobile>
          <PPrimaryTitle>
            <FormattedMessage id="home.text_under_image_center.title" tagName="span" />
          </PPrimaryTitle>
          <ButtonPrimary onClick={() => onClickSignUp()} secondary>
            <FormattedMessage id="home.sign_up.cta" tagName="span" />
          </ButtonPrimary>
          <PUnderSignUpCTA>
            <FormattedMessage id="home.sign_up.cta.free" tagName="span" />
          </PUnderSignUpCTA>
        </DivMovileWrapper>
        <DivHowItWorks>
          <PPrimaryTitle>
            <FormattedMessage id="home.how_does_it_work.title" tagName="span" />
          </PPrimaryTitle>
          {howItWorks()}
          <CardTitle title={<FormattedMessage id="home.question" tagName="span" />}>
            <FormattedMessage
              id="home.question.description"
              tagName="span"
              values={{
                phone: <FormattedMessage id="footer.phone" tagName="span" />
              }}
            />
          </CardTitle>
        </DivHowItWorks>
      </DivHome>
    </>
  );
};
HomePage.defaultProps = {
  userId: null
};

HomePage.propTypes = {
  userId: PropTypes.number,
  updatePromocode: PropTypes.func.isRequired
};

const mapStateToProps = ({ user: { user } }) => ({ userId: user.id });
const mapDispatchToProps = dispatch => ({
  updatePromocode: promocode => dispatch(updateForm({ key: 'promocode', value: promocode }))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
