export const convertTextToArray = (text: string) => {
  if (text) {
    if (Array.isArray(text)) {
      return text as string[];
    }
    return text
      .replace(/,\s/g, ',')
      .replace(/\s/g, ',')
      .replace(/\n/g, ',')
      .split(',')
      .map(zip => zip.trim())
      .filter(z => z !== '');
  } else {
    return undefined;
  }
};
