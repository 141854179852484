import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

import PageLayout from './components/PageLayout';
import { ROUTES } from './routes';
import { connect } from 'react-redux';
import { getUser } from './store/reducers/user/actions';
import { loadCartDetails } from './store/reducers/cart/actions';
import { getTraderId } from './util/serviceHelpers';
import TagManager from 'react-gtm-module';

// import styled from 'styled-components';
// import LinearProgress from '@material-ui/core/LinearProgress';

// const LP = styled(LinearProgress)`
//   background-color: #32546d;
//   .MuiLinearProgress-barColorPrimary {
//     background-color: #c30144;
//   }
// `;

const PrivateRoute = ({
  component: Component,
  isGuest,
  loadCartDetails,
  getUser,
  authed,
  ...props
}) => {
  const history = useHistory();
  useEffect(() => {
    if (!authed && !isGuest) {
      history.push(ROUTES.LOGIN.handler());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      await getUser();
      await loadCartDetails();
    };
    if (authed) {
      fetchData();
      const id = getTraderId();
      TagManager.dataLayer({
        dataLayer: {
          trader_id: id
        },
        dataLayerName: 'ProDataLayer'
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed]);

  return (
    <Route
      {...props}
      render={args => (
        <PageLayout>
          <Component {...args} />
        </PageLayout>
      )}
    />
  );
};

const mapDispatchToProps = {
  loadCartDetails,
  getUser
};
export default connect(null, mapDispatchToProps)(PrivateRoute);
