import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CategoriesTreeSelect from '../CategoriesTreeSelect';
import RadiusSelect from '../RadiusSelect';
import Select from '../Select';

const FormControlLabelStyled = withStyles({
  label: {
    color: '#32546D'
  }
})(FormControlLabel);
const CheckboxStyled = withStyles({
  checked: {
    color: '#c30144'
  }
})(Checkbox);

const SectionContainer = styled.section`
  display: flex;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }

  fieldset {
    border: none;
    padding: 0 1.4rem;
    border-right: 1px solid #d8dae0;

    :nth-of-type(1) {
      padding-left: 0;
    }

    :last-of-type {
      border-right: none;
    }

    > div {
      max-width: 268px;
    }

    > label {
      color: #c30144;
      display: block;
      font-family: 'Lato', sans-serif;
      font-weight: 900;
      font-size: 10px;
      line-height: 18px;
      margin-bottom: 1rem;
      text-transform: uppercase;

      &.languageCheckbox {
        display: inline-flex;
      }
    }

    > div {
      min-width: 190px;

      :nth-of-type(1) {
        margin-right: 0.6rem;
        margin-bottom: 0.6rem;
      }
    }
  }
`;
const DivSlider = styled.div`
  margin-top: 1rem;
`;

const SectionFilter = ({ filters, setFilter, categories, locale, userSubscription }) => {
  const langs = filters.languages ? filters.languages : [];
  const onLanguageChange = e => {
    const { value } = e.target;
    if (langs.includes(value)) {
      const languages = langs.filter(e => e !== value);
      const state = { ...filters, languages };
      setFilter(state);
    } else {
      const state = { ...filters, languages: [...langs, value] };
      setFilter(state);
    }
  };
  const onCategoriesChange = ({ target: { value } }) => {
    const currentTreetrades = filters.category_list ? filters.category_list : '';
    if (currentTreetrades && value && value.toString() !== currentTreetrades.toString()) {
      const state = { ...filters, category_list: value };
      setFilter(state);
    }
  };
  const onRadiusChange = ({ target: { value } }) => {
    const state = { ...filters, radius: parseInt(value, 10) };
    setFilter(state);
  };
  const onViewChange = ({ target: { value } }) => {
    const state = { ...filters, service_view: value };
    setFilter(state);
  };

  return (
    <SectionContainer>
      <fieldset>
        <label>
          <FormattedMessage tagName="span" id="sectionfilter.label" />
        </label>
        <Select name="service_view" value={filters.service_view} onChange={onViewChange}>
          <MenuItem data-cy-menuitem="simplified" value="simplified">
            <FormattedMessage id="sectionfilter.view.simplified" defaultMessage="Simplified view" />
          </MenuItem>
          <MenuItem data-cy-menuitem="new" value="new">
            <FormattedMessage id="sectionfilter.view.new" defaultMessage="New Jobs" />
          </MenuItem>
          <MenuItem data-cy-menuitem="favorites" value="favorites">
            <FormattedMessage id="sectionfilter.view.favorites" defaultMessage="Favorites" />
          </MenuItem>
          <MenuItem data-cy-menuitem="active" value="active">
            <FormattedMessage id="sectionfilter.view.active" defaultMessage="Active Jobs" />
          </MenuItem>
          <MenuItem data-cy-menuitem="not-interested" value="trash">
            <FormattedMessage id="sectionfilter.view.trash" defaultMessage="Not Interested" />
          </MenuItem>
        </Select>
        <CategoriesTreeSelect
          id="treetrades"
          name="treetrades"
          categories={categories}
          locale={locale}
          onSelectTrade={value => onCategoriesChange({ target: { value } })}
          disableUnsubCategories
          required
          primary
        />
      </fieldset>
      <fieldset>
        <label>
          <FormattedMessage tagName="span" id="sectionfilter.label.languages" />
        </label>
        <FormGroup onChange={onLanguageChange} row>
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('en')} value="en" />}
            label="EN"
          />
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('nl')} value="nl" />}
            label="NL"
          />
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('fr')} value="fr" />}
            label="FR"
          />
        </FormGroup>
      </fieldset>
      <fieldset>
        <label>
          <FormattedMessage
            tagName="span"
            id="modalfilter.slider_km"
            values={{ slider: filters.radius }}
          />
        </label>
        <DivSlider>
          <RadiusSelect
            value={filters.radius}
            onChange={onRadiusChange}
            fullWidth
            displayEmpty
            userSubscription={userSubscription}
          />
        </DivSlider>
      </fieldset>
    </SectionContainer>
  );
};

const mapStateToProps = ({ services, user: { user } }) => ({
  filters: services.filters,
  locale: user.locale,
  userSubscription: user.subscription
});

SectionFilter.defaultProps = {
  filters: {
    languages: []
  }
};

export default connect(mapStateToProps)(SectionFilter);
