import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { reducer as alertsReducer } from './reducers/alerts/reducer';
import { reducer as cartReducer } from './reducers/cart/reducer';
import { reducer as categoriesReducer } from './reducers/categories/reducer';
import { reducer as formsReducer } from './reducers/forms/reducer';
import { reducer as prebooksReducer } from './reducers/prebooks/reducer';
import { reducer as servicesReducer } from './reducers/services/reducer';
import { reducer as userReducer } from './reducers/user/reducer';
import { reducer as calendarReducer } from './reducers/calendar/reducer';
import { reducer as vacationReducer } from './reducers/vacation/reducer';

import { persistReducer, persistStore } from 'redux-persist';

const servicesPersistConfig = {
  key: 'services',
  storage,
  whitelist: ['filters']
};

export let store = null;
export let runSaga = null;

// Put outside of the fn to create a type
export const rootReducer = combineReducers({
  calendars: calendarReducer,
  services: persistReducer(servicesPersistConfig, servicesReducer),
  user: userReducer,
  cart: cartReducer,
  forms: formsReducer,
  alerts: alertsReducer,
  categories: categoriesReducer,
  prebooks: prebooksReducer,
  vacation: vacationReducer
});

export default function Store(initialState) {
  const middleware = [thunkMiddleware];
  store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  store.__persistor = persistStore(store);
  return store;
}
