import { Control, Controller, useFieldArray, UseFormWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import JaimyLabel from '../../../../theme/components/form/Label';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyGrid from '../../../../theme/components/layout/Grid';
import Flatpickr from 'react-flatpickr';

import { useCallback, useMemo, useState } from 'react';
import { IManageCalendarFormData } from '../index';
import { IWeekDay } from '../../../../types/Calendar';

import 'flatpickr/dist/flatpickr.css';
import { format } from 'date-fns';
import { WeekDayButton } from './styles';

const startTime = format(new Date().setHours(9, 0, 0, 0), 'HH:mm');
const endTime = format(new Date().setHours(18, 0, 0, 0), 'HH:mm');

const workingDays: IWeekDay[] = [
  {
    endTime: endTime,
    name: 'monday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'tuesday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'wednesday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'thursday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'friday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'saturday',
    selected: false,
    startTime: startTime
  },
  {
    endTime: endTime,
    name: 'sunday',
    selected: false,
    startTime: startTime
  }
];

interface IManageCalendarWorkingDaysProps {
  control: Control<IManageCalendarFormData>;
  watch: UseFormWatch<IManageCalendarFormData>;
  weekdays: IWeekDay[];
}

const ManageCalendarWorkingDays = ({
  control,
  watch,
  weekdays
}: IManageCalendarWorkingDaysProps) => {
  const { $t } = useIntl();
  const weekdaysFields = useFieldArray({
    name: 'weekdays',
    control
  });

  const newWorkingDays = useMemo(() => {
    const newWorkingDays = workingDays.map(workingDay => {
      const weekdayFound = weekdays.find(weekday => weekday.name === workingDay.name);
      if (weekdayFound?.selected) {
        return weekdayFound;
      }
      return workingDay;
    });
    return newWorkingDays;
  }, [weekdays]);

  const [weekDays, setWeekDays] = useState<IWeekDay[]>(newWorkingDays);

  const handleToggleSelectDay = useCallback(
    (index: number) => {
      const newDays = weekDays.map((currentDay, currentDayIndex) => {
        if (index === currentDayIndex) {
          if (currentDay.selected) {
            weekdaysFields.remove(
              weekdaysFields.fields.findIndex(field => field.name === currentDay.name)
            );
          } else {
            weekdaysFields.insert(index, currentDay);
          }
          return { ...currentDay, selected: !currentDay.selected };
        }
        return currentDay;
      });

      setWeekDays(newDays);
    },
    [weekDays, weekdaysFields]
  );

  return (
    <JaimyGrid>
      <JaimyFlex
        margin={weekdaysFields.fields.length > 0 ? '1.5rem 0 .5rem' : '1.5rem 0 1rem'}
        gap=".5rem"
      >
        {weekDays.map((weekDay, index) => {
          const weekDayFormatted = $t({ id: weekDay.name });
          return (
            <WeekDayButton
              type="button"
              selected={weekDay.selected}
              key={`${weekDay} - ${index}`}
              onClick={() => handleToggleSelectDay(index)}
            >
              {weekDayFormatted.substring(0, 1)}
            </WeekDayButton>
          );
        })}
      </JaimyFlex>
      <JaimyGrid margin="0 0 1rem" rowGap=".5rem">
        {weekdaysFields.fields.map((weekendField, index) => (
          <JaimyGrid
            key={weekendField.id}
            alignItems="center"
            columns="12rem 1fr"
            columnGap=".5rem"
            columnsSm="1fr"
          >
            <JaimyLabel>
              <FormattedMessage id={weekdaysFields.fields[index].name} />
            </JaimyLabel>
            <JaimyFlex alignItems="center" columnGap=".5rem" maxWidth="35rem" width="100%">
              <Controller
                name={`weekdays.${index}.startTime`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Flatpickr
                    defaultValue={value}
                    onChange={dates => onChange(format(new Date(dates[0]), 'HH:mm'))}
                    options={{
                      noCalendar: true,
                      minuteIncrement: 1,
                      enableTime: true,
                      time_24hr: true,
                      static: true
                    }}
                    aria-label="start-date"
                    className="custom-flatpickr"
                    maxLength={2}
                  />
                )}
              />
              <JaimyLabel textAlign="center">
                <FormattedMessage id="calendar.manage.to" />
              </JaimyLabel>
              <Controller
                name={`weekdays.${index}.endTime`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Flatpickr
                    defaultValue={value}
                    onChange={dates => onChange(format(new Date(dates[0]), 'HH:mm'))}
                    options={{
                      enableTime: true,
                      minuteIncrement: 1,
                      time_24hr: true,
                      noCalendar: true,
                      static: true,
                      minTime: watch(`weekdays.${index}.startTime`)
                    }}
                    aria-label="start-date"
                    className="custom-flatpickr"
                    maxLength={2}
                  />
                )}
              />
            </JaimyFlex>
          </JaimyGrid>
        ))}
      </JaimyGrid>
    </JaimyGrid>
  );
};

export default ManageCalendarWorkingDays;
