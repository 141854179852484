import {
  ButtonAddCart,
  ButtonAddCartLead,
  ButtonMoreInfo,
  ButtonMoreInfoFreeLead
} from '../../common/Button';

import { ReactComponent as CartIcon } from '../../../assets/images/cart-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check-icon.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GiftIcon } from '../../../assets/images/gift-icon.svg';
import { ROUTES } from '../../../routes';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ComponentIsVisible } from '../../utils/isVisible';
import { allowedToSeeAddCartButton, EVerifiedStatus } from '../../../constants/verifiedStatus';
import { useSelector } from 'react-redux';

const DivCardActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;

  button {
    margin-top: 0;
  }
  button:first-of-type {
    flex-basis: 30%;
  }
  button:last-of-type {
    flex-basis: 70%;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
    button {
      margin-top: 0;
    }
    button:first-of-type {
      flex-basis: 35%;
    }
    button:last-of-type {
      flex-basis: 65%;
    }
  }
  ${props =>
    props.isHideCartButton &&
    css`
      justify-content: flex-end;
    `}
`;
const CardActions = ({
  id,
  hasFreelead,
  cartIsLoading,
  setFreeLeadSrId,
  isLoading,
  isMobile,
  cartId,
  handleAddToCart,
  price
}) => {
  const history = useHistory();
  const user = useSelector(state => state.user.user);

  const onClickJob = () => {
    history.push(ROUTES.JOBS_ID.handler(id));
  };

  const hasShowCartButton = useMemo(() => {
    const isAllowedBasedOnVerifiedStatus = allowedToSeeAddCartButton.includes(user.verified_status);
    if (isAllowedBasedOnVerifiedStatus) {
      return isAllowedBasedOnVerifiedStatus;
    }

    const isVerifiedStatusCheckedAndHasPurchacesLeft =
      user.verified_status === EVerifiedStatus.Checked && user.checked_purchases_left > 0;
    if (isVerifiedStatusCheckedAndHasPurchacesLeft) {
      return isVerifiedStatusCheckedAndHasPurchacesLeft;
    }

    const isVerifiedStatusWaitForInfoHasPurchasesLeft =
      user.verified_status === EVerifiedStatus.RestrictedWaitForInfo &&
      user.wait_for_info_purchases_left > 0;
    if (isVerifiedStatusWaitForInfoHasPurchasesLeft && price <= 40) {
      return isVerifiedStatusWaitForInfoHasPurchasesLeft;
    }

    return false;
  }, [price, user.checked_purchases_left, user.verified_status, user.wait_for_info_purchases_left]);

  return (
    <DivCardActions isHideCartButton={!hasShowCartButton}>
      <ComponentIsVisible when={hasShowCartButton}>
        {!!hasFreelead && hasFreelead > 0 && (
          <ButtonAddCartLead
            data-cy-button="add-cart-lead"
            disable={cartIsLoading}
            onClick={() => setFreeLeadSrId(id)}
          >
            <ComponentIsVisible when={isLoading}>
              <CircularProgress color="inherit" />
            </ComponentIsVisible>
            <ComponentIsVisible when={!isLoading}>
              <GiftIcon width={24} height={24} viewBox="0 0 24 24" />
              <ComponentIsVisible when={!isMobile}>
                <FormattedMessage
                  tagName="span"
                  id="servicecardlist.card.free"
                  defaultMessage="Get Free"
                />
              </ComponentIsVisible>
            </ComponentIsVisible>
          </ButtonAddCartLead>
        )}
        {cartId && !hasFreelead && (
          <ButtonAddCart
            data-cy-button="remove-from-cart"
            disable={cartIsLoading}
            inCart
            onClick={handleAddToCart}
          >
            <CheckIcon />
            <CloseIcon width={24} height={24} viewBox="0 0 24 24" />
            {!isMobile && <FormattedMessage tagName="span" id="servicecardlist.card.added_cart" />}
          </ButtonAddCart>
        )}
        {!cartId && !hasFreelead && (
          <ButtonAddCart
            data-cy-button="add-to-cart"
            disable={cartIsLoading}
            onClick={handleAddToCart}
          >
            <CartIcon />
            {!isMobile && <FormattedMessage tagName="span" id="servicecardlist.card.add_cart" />}
          </ButtonAddCart>
        )}
      </ComponentIsVisible>

      {hasFreelead ? (
        <ButtonMoreInfoFreeLead
          onClick={onClickJob}
          data-cy-button="more-info-free-lead"
          inCart={cartId}
        >
          <FormattedMessage tagName="span" id="servicecardlist.card.more_information" />
        </ButtonMoreInfoFreeLead>
      ) : (
        <ButtonMoreInfo onClick={onClickJob} data-cy-button="more-info" inCart={cartId}>
          <FormattedMessage tagName="span" id="servicecardlist.card.more_information" />
        </ButtonMoreInfo>
      )}
    </DivCardActions>
  );
};

export default CardActions;
