export const PREBOOK_FREQUENCY = [
  {
    name: 'weekly',
    id: 'prebook.form.frequency.weekly',
    defaultMessage: 'Hebdomadaire'
  },
  {
    name: 'monthly',
    id: 'prebook.form.frequency.monthly',
    defaultMessage: 'Mensuelle'
  },
  {
    name: 'quarterly',
    id: 'prebook.form.frequency.quarterly',
    defaultMessage: 'Trimestrielle'
  },
  {
    name: 'yearly',
    id: 'prebook.form.frequency.yearly',
    defaultMessage: 'Annuelle'
  }
];
export const EMAIL_NOTIFICATION = [
  {
    name: 'all_emails',
    id: 'prebook.form.email_choice.immediate',
    defaultMessage: 'Immediate'
  },
  {
    name: 'one_email_a_day',
    id: 'prebook.form.email_choice.once_a_day',
    defaultMessage: 'Once a day'
  },
  {
    name: 'no_emails',
    id: 'prebook.form.email_choice.no_emails',
    defaultMessage: 'No emails'
  }
];

export const SERVICE_RADIUS = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200];
