import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { DivHeader, SectionTopHeader } from '../../../components/common/Header';
import { AnchorBack } from '../../../components/common/Link';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ROUTES } from '../../../routes';
import { requestTraderPrebooksData } from '../../../store/reducers/prebooks/actions';
import theme from '../../../theme';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import PrebookForm from './PrebookForm';

const PrebookView = ({ traderPrebooks, getTraderPrebooksData }) => {
  const [selectedPreBook, setSelectedPreBook] = useState();
  const { width } = useWindowDimensions();
  const intl = useIntl();
  const location = useLocation();

  const methods = useForm({
    defaultValues: {
      selectedPreBook
    }
  });

  const metaTitle = intl.messages['prebook.metaTitle'];

  useEffect(() => {
    getTraderPrebooksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!traderPrebooks) return;
    setSelectedPreBook(traderPrebooks.find(prebook => prebook.id === location.state));
    return () => {
      setSelectedPreBook([]);
    };
  }, [location.state, traderPrebooks]);

  useEffect(() => {
    methods.reset(selectedPreBook);
  }, [methods, selectedPreBook]);

  return (
    <>
      <Helmet>
        <title>{`${metaTitle}`}</title>
      </Helmet>
      {selectedPreBook && (
        <>
          <DivHeader>
            <SectionTopHeader>
              {width > 768 && (
                <Breadcrumbs>
                  <Link to={`${ROUTES.HOME.handler()}`}>
                    <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
                  </Link>
                  <Link to={`${ROUTES.PREBOOK.handler()}`}>
                    <FormattedMessage tagName="span" id="breadcrumbs.link.prebook" />
                  </Link>
                </Breadcrumbs>
              )}
              {width < 768 && (
                <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
                  <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
                </AnchorBack>
              )}
            </SectionTopHeader>
            <JaimyFlex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              margin="0 auto"
            >
              <JaimyHeading.H1 color={theme.colors.primary.base}>
                {selectedPreBook.name ? selectedPreBook.name : 'Prebook'}
              </JaimyHeading.H1>
            </JaimyFlex>
          </DivHeader>
          <FormProvider {...methods}>
            {!selectedPreBook ? (
              <CircularProgress />
            ) : (
              <PrebookForm selectedPreBook={selectedPreBook} />
            )}
          </FormProvider>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ user: { user }, prebooks: { prebooks } }) => ({
  locale: user.locale,
  user,
  traderPrebooks: prebooks
});

const mapDispatchToProps = dispatch => ({
  getTraderPrebooksData: () => dispatch(requestTraderPrebooksData())
});

export default connect(mapStateToProps, mapDispatchToProps)(PrebookView);
