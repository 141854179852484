import styled, { css, CSSProperties } from 'styled-components';
import theme from '../../..';

interface IJaimyBoxProps {
  _hover?: CSSProperties;
  _last?: CSSProperties;
  _svg?: CSSProperties;
  alignItems?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: number;
  borderRight?: string;
  borderRightColor?: string;
  bottom?: number | string;
  color?: string;
  cursor?: string;
  display?: string;
  fontFamily?: string;
  height?: string;
  justifyContent?: string;
  left?: number | string;
  m?: (string | null)[];
  margin?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  opacity?: number;
  overflow?: string;
  overflowX?: string;
  overflowY?: string;
  p?: (string | null)[];
  padding?: string;
  paddingSm?: string;
  position?: string;
  px?: number;
  py?: number;
  mx?: number;
  my?: number;
  right?: number | string;
  textAlign?: string;
  top?: number | string;
  transform?: string;
  transition?: string;
  width?: string;
  zIndex?: number;
  boxShadow?: string;
  objectFit?: string;
  objectPosition?: string;
}

const JaimyBox = styled.div<IJaimyBoxProps>`
  align-items: ${props => props.alignItems && props.alignItems};
  background-color: ${props => props.backgroundColor && props.backgroundColor};
  border: ${props => props.border && props.border};
  border-radius: ${props => props.borderRadius && `${props.borderRadius}rem`};
  border-right: ${props => props.borderRight && props.borderRight};
  border-right-color: ${props => props.borderRightColor && props.borderRightColor};
  bottom: ${props => props.bottom && props.bottom};
  color: ${props => props.color && props.color};
  cursor: ${props => props.cursor && props.cursor};
  display: ${props => props.display && props.display};
  font-family: ${props => props.fontFamily && props.fontFamily};
  height: ${props => props.height && props.height};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  left: ${props => props.left && props.left};
  margin: ${props => props.m && props.m[0]};
  margin-bottom: ${props => props.marginBottom && props.marginBottom};
  margin-left: ${props => props.marginLeft && props.marginLeft};
  margin-right: ${props => props.marginRight && props.marginRight};
  margin-top: ${props => props.marginTop && props.marginTop};
  max-height: ${props => props.maxHeight && props.maxHeight};
  max-width: ${props => props.maxWidth && props.maxWidth};
  min-height: ${props => props.minHeight && props.minHeight};
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  overflow: ${props => props.overflow && props.overflow};
  overflow-x: ${props => props.overflowX && props.overflowX};
  overflow-y: ${props => props.overflowY && props.overflowY};
  padding: ${props => props.p && props.p[0]};
  position: ${props => (props.position ? props.position : 'relative')};
  right: ${props => props.right && props.right};
  text-align: ${props => props.textAlign && props.textAlign};
  top: ${props => props.top && props.top};
  transform: ${props => props.transform && props.transform};
  transition: ${props => props.transition && props.transition};
  width: ${props => props.width && props.width};
  box-shadow: ${props => props.boxShadow && props.boxShadow};
  object-fit: ${props => props.objectFit && props.objectFit};
  object-position: ${props => props.objectPosition && props.objectPosition};
  z-index: ${props => props.zIndex && props.zIndex};

  ${props =>
    props.px &&
    css`
      padding-left: ${props.px}rem;
      padding-right: ${props.px}rem;
    `}
  ${props =>
    props.py &&
    css`
      padding-bottom: ${props.py}rem;
      padding-top: ${props.py}rem;
    `}

  ${props =>
    props.mx &&
    css`
      margin-left: ${props.mx}rem;
      margin-right: ${props.mx}rem;
    `}
  ${props =>
    props.my &&
    css`
      margin-bottom: ${props.my}rem;
      margin-top: ${props.my}rem;
    `}

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

  ${props =>
    props._last &&
    css`
      &:last-child {
        border: ${props._last.border};
      }
    `}

  ${props =>
    props._hover &&
    css`
      &:hover {
        transform: ${props._hover?.transform};
      }
    `}

  ${props =>
    props._svg &&
    css`
      & svg {
        height: ${props._svg.height};
        stroke: ${props._svg.stroke};
        width: ${props._svg.width};
      }
    `}

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin: ${props => props.m && props.m[1]};
    padding: ${props => props.p && props.p[1]};

    ${props =>
      props.paddingSm &&
      css`
        padding: ${props.paddingSm};
      `}
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin: ${props => props.m && props.m[2]};
    padding: ${props => props.p && props.p[2]};
  }
  @media screen and (max-width: ${theme.breakpoints.lg}) {
    margin: ${props => props.m && props.m[3]};
    padding: ${props => props.p && props.p[3]};
  }

  &.iconSvgSize svg {
    height: 60px;
    width: 60px;
  }
`;

export default JaimyBox;
