import React, { useState } from 'react';
import { AnchorBack, AnchorButton, AnchorPrimary } from '../../../components/common/Link';

import CircularProgress from '@material-ui/core/CircularProgress';
import TagManager from 'react-gtm-module';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonPrimary } from '../../../components/common/Button';
import { colors } from '../../../components/shared/styles/global';

import JobService from '../../../lib/services/jobService';
import { ROUTES } from '../../../routes';
import ErrorMessage from '../../../util/ErrorMessage';
import TextArea from '../../../components/Textarea';

const DivHeader = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #d8dae0;
  margin-bottom: 1rem;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 1px solid #d8dae0;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 2rem 1rem 1rem;
  font-family: 'Lato', sans-serif;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;
const SectionTopHeader = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;
const H2Title = styled.h2`
  color: #32546d;
  font-size: 32px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 1rem;
`;
const PDesc = styled.p`
  color: ${colors.gray};
  font-size: 16px;
  text-align: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;

  > div:nth-of-type(1n) {
    display: flex;
    justify-content: center;

    button {
      margin: 0 1rem;
    }
  }
`;

const StyledLabel = styled.label`
  color: ${colors.gray};
  font-size: 16px;
  text-align: center;
`;

const DivError = styled.div`
  color: #c30144;
  margin: 0 auto 1rem auto;
  text-align: center;
`;

const DivSuccess = styled.div`
  padding-top: 2rem;
  margin-bottom: 2rem;

  > h2 {
    margin-bottom: 2rem;
    line-height: 28px;
  }

  > a {
    margin-top: 2rem;
  }
`;
const jobService = new JobService();

export const Questions = () => {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { id } = useParams();
  const onChange = ({ target: { value } }) => {
    setText(value);
  };
  const onSubmit = () => {
    if (text !== '') {
      setLoading(true);
      setHasError(false);
      jobService
        .askQuestion({ id, text })
        .then(res => {
          if (!res.error) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'Question Submitted'
              },
              dataLayerName: 'ProDataLayer'
            });
            setLoading(false);
            setHasError(false);
            setSuccess(true);
          } else {
            setLoading(false);
            setHasError(new ErrorMessage('question.error.unexpected'));
            setSuccess(false);
          }
        })
        .catch(() => {
          setLoading(false);
          setHasError(new ErrorMessage('question.error.unexpected'));
          setSuccess(false);
        });
    }
  };

  return (
    <>
      <DivHeader>
        <SectionTopHeader>
          <AnchorBack to={`${ROUTES.JOBS_ID.handler(id)}`}>
            <FormattedMessage tagName="span" id="servicespage.back_to_details" />
          </AnchorBack>
        </SectionTopHeader>
      </DivHeader>
      <DivContainer>
        {!success && (
          <>
            <H2Title>
              <FormattedMessage tagName="span" id="question.title" />
            </H2Title>
            <PDesc>
              <FormattedMessage tagName="span" id="question.subtitle" />
            </PDesc>
            <Form onSubmit={e => e.preventDefault()}>
              <StyledLabel htmlFor="textarea">
                <FormattedMessage htmlFor="textarea" tagName="label" id="question.textarea-label" />
              </StyledLabel>
              <TextArea
                value={text}
                onChange={onChange}
                aria-multiline
                content-editable
                required
                id="textarea"
              />
              {hasError && (
                <DivError>
                  {hasError && hasError.name === 'ErrorMessage' && (
                    <FormattedMessage tagName="span" id={hasError.message} />
                  )}
                </DivError>
              )}
              <div>
                <AnchorPrimary to={`${ROUTES.JOBS_ID.handler(id)}`}>
                  <FormattedMessage tagName="span" id="question.cancel" />
                </AnchorPrimary>
                <ButtonPrimary type="submit" onClick={onSubmit}>
                  {loading && <CircularProgress size={20} color="inherit" disableShrink />}
                  {!loading && <FormattedMessage tagName="span" id="question.send" />}
                </ButtonPrimary>
              </div>
            </Form>
          </>
        )}
        {success && (
          <DivSuccess>
            <H2Title>
              <FormattedMessage tagName="span" id="question.success.title" />
            </H2Title>
            <PDesc>
              <FormattedMessage tagName="span" id="question.success.subtitle" />
            </PDesc>
            <AnchorButton to={`${ROUTES.JOBS_ID.handler(id)}`}>
              <FormattedMessage tagName="span" id="question.done" />
            </AnchorButton>
          </DivSuccess>
        )}
      </DivContainer>
    </>
  );
};

export default Questions;
