import {
  GET_PREBOOKS_ERROR,
  GET_PREBOOKS_REQUEST,
  GET_PREBOOKS_SUCCESS,
  PUT_PREBOOKS_ERROR,
  PUT_PREBOOKS_REQUEST,
  PUT_PREBOOKS_SUCCESS
} from './actionTypes';
const initialState = {
  prebooks: [],
  isLoading: false,
  error: null,
  hasError: false,
  monthlyData: []
};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREBOOKS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case GET_PREBOOKS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null
      };

    case GET_PREBOOKS_ERROR:
      return {
        ...state,
        ...action.payload,
        error: action.payload.error,
        hasError: true
      };
    case PUT_PREBOOKS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case PUT_PREBOOKS_SUCCESS:
      return {
        ...state,
        // ...action.payload,
        isLoading: false,
        error: null
      };

    case PUT_PREBOOKS_ERROR:
      return {
        ...state,
        ...action.payload,
        error: action.payload.error,
        isLoading: false,
        hasError: true
      };

    default:
      return state;
  }
};
