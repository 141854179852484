import { BoolPropType, FuncPropType, NumberPropType } from '../../util/PropTypeValues';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { centsToEuro } from '../../util/priceCents';

const DivCartCheckout = styled.aside`
  background-color: #ffffff;
  z-index: 1;
  > .proceedButton {
    text-align: center;
  }
  ${props =>
    props.isMobile
      ? css`
          border-top: solid 1px #d8dae0;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
        `
      : css`
          border: solid 1px #d8dae0;
          position: sticky;
          top: 6rem;
          float: right;
          padding: 1rem;
          border-radius: 4px;
          max-width: 270px;
          margin-top: -2rem;
        `}
`;
const ButtonBuy = styled.button`
  background-color: #c30144;
  border: 1px solid #c30b30;
  border-radius: ${props => (props.isCollapsedOpen ? '0' : '5px')};
  color: #fff;
  text-transform: uppercase;
  padding: 1rem;
  font-family: 'Lato';
  font-size: 12px;
  cursor: pointer;
  :disabled {
    background-color: #d8dae0;
    border-color: #d8dae0;
    color: #867a7a;
  }
`;
const DivButtonExpand = styled.div`
  width: 0;
  margin: 0;
  > button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    > svg {
      color: #405a6b;
    }
  }
`;
const CheckoutInfo = styled.div`
  opacity: 0.9;
  background-color: #ffffff;
  > div {
    &:first-of-type {
      > h3 {
        margin-top: ${props => (props.hasItems ? '1rem' : '0')};
      }
    }
    > h3 {
      margin: 1rem 0 0.3rem;
      font-family: Lato;
      font-size: 14px;
      font-weight: 600;
      color: #405a6b;
    }
    > p {
      max-width: 220px;
      font-family: Lato;
      font-size: 14px;
      color: #637780;
    }
  }
`;
const SpanCheckoutSubtitle = styled.span`
  margin-top: 1rem;
  text-transform: uppercase;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  color: #979faa;
`;
const SpanCheckoutSubtitle2 = styled.span`
  font-family: Lato;
  font-size: 14px;
  color: #637780;
`;
const SpanPriceSmall = styled.span`
  font-family: BelfiusNormal;
  font-size: 16px;
  font-weight: bold;
  color: #405a6b;
  margin: 0.1rem 0 0.8rem;
`;
const PriceBeforeDiscount = styled(SpanPriceSmall)`
  text-decoration: line-through;
`;
const SpanPriceSmall2 = styled(SpanPriceSmall)`
  margin: 0;
`;
const SpanPriceRed = styled.span`
  font-family: BelfiusNormal;
  font-size: 16px;
  font-weight: bold;
  color: #af1e3c;
  margin: 0.1rem 0 0.8rem;
`;
const SpanPriceBig = styled.span`
  font-family: BelfiusNormal;
  font-weight: bold;
  margin: 0.1rem 0 0.8rem;
  font-size: 32px;
  color: #405a6b;
`;
const WarningMessage = styled.span`
  font-family: Lato;
  font-size: 12px;
  color: #e57878;
  margin: 1rem auto;
  text-align: center;
`;

const DivFlex = styled.div`
  display: inline-flex;
  flex-direction: column;
  display: flex;
  margin: 1rem;
  > button {
    margin: 1rem 0;
  }
`;
const DivFlexMobile = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
    > button {
      margin-left: auto;
      margin: 1rem 1rem 1rem auto;
    }
    > div {
      display: flex;
      flex-direction: column;
      place-content: center;
      margin: auto auto auto 1rem;
      > span {
        margin: 0 !important;
      }
    }
  }
  > button {
    margin: 0;
    min-height: 4rem;
  }
`;

const Checkout = ({
  cartPrice,
  userCredits,
  discount,
  itemCount,
  isMobile,
  vatDuplicated,
  disableCheckout,
  onClickBuy,
  totalExclVat,
  totalCreditsUsed,
  totalPaymentExclVat,
  totalPaymentInclVat,
  totalCreditsLeft
}) => {
  const intl = useIntl();
  const [isCollapsedOpen, setIsCollapsedOpen] = useState(true);

  const Prices = (
    <>
      {discount > 0 && (
        <>
          <SpanCheckoutSubtitle>
            {intl.messages['cart.checkout.initial_price']}
          </SpanCheckoutSubtitle>
          <PriceBeforeDiscount>
            <FormattedMessage
              tagName="span"
              id="cart.price.credit"
              values={{ price: cartPrice / 100 }}
            />
          </PriceBeforeDiscount>
          <SpanCheckoutSubtitle>{intl.messages['cart.checkout.discount']}</SpanCheckoutSubtitle>
          <SpanPriceSmall>
            <FormattedMessage tagName="span" id="cart.discount" values={{ discount }} />
          </SpanPriceSmall>
        </>
      )}
      {userCredits > 0 && (
        <>
          <SpanCheckoutSubtitle>
            {intl.messages['cart.checkout.total_excl_vat']}
          </SpanCheckoutSubtitle>
          <SpanPriceSmall>
            <FormattedMessage
              tagName="span"
              id="cart.price"
              values={{ price: centsToEuro(totalExclVat) }}
            />
          </SpanPriceSmall>
        </>
      )}
      {userCredits > 0 && (
        <>
          <SpanCheckoutSubtitle>{intl.messages['cart.checkout.from_credit']}</SpanCheckoutSubtitle>
          <SpanPriceRed>
            <FormattedMessage
              tagName="span"
              id="cart.price"
              values={{ price: centsToEuro(-totalCreditsUsed) }}
            />
          </SpanPriceRed>
        </>
      )}
      <SpanCheckoutSubtitle>
        {totalCreditsUsed > 0 && totalPaymentExclVat > 0
          ? intl.messages['cart.checkout.total_rest_to_pay']
          : intl.messages['cart.checkout.total_to_pay']}
      </SpanCheckoutSubtitle>
      <SpanPriceBig>
        <FormattedMessage
          tagName="span"
          id="cart.price"
          values={{ price: centsToEuro(totalPaymentExclVat) }}
        />
      </SpanPriceBig>
      <SpanCheckoutSubtitle2>
        <FormattedMessage
          tagName="span"
          id="cart.checkout.incl_vat"
          values={{ price: centsToEuro(totalPaymentInclVat) }}
        />
      </SpanCheckoutSubtitle2>
    </>
  );
  const BuyButton = (
    <>
      {vatDuplicated ? (
        <WarningMessage>{intl.messages['cart.checkout.vat_duplicated']}</WarningMessage>
      ) : (
        <ButtonBuy
          disabled={disableCheckout}
          onClick={onClickBuy}
          isCollapsedOpen={isCollapsedOpen && isMobile}
        >
          {totalCreditsUsed > 0 && totalPaymentExclVat === 0
            ? intl.messages['cart.button.checkout_using_credits']
            : intl.messages['cart.button.checkout']}
        </ButtonBuy>
      )}
    </>
  );

  const CreditsRemaining = (
    <>
      <SpanCheckoutSubtitle>
        {intl.messages['cart.checkout.credit_after_checkout']}
      </SpanCheckoutSubtitle>
      <SpanPriceSmall2>
        <FormattedMessage
          tagName="span"
          id="cart.price"
          values={{ price: centsToEuro(totalCreditsLeft) }}
        />
      </SpanPriceSmall2>
      <br />
    </>
  );
  const CollapseButton = (
    <IconButton onClick={() => setIsCollapsedOpen(!isCollapsedOpen)}>
      {isCollapsedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );

  return (
    <DivCartCheckout isMobile={isMobile}>
      {!isMobile && (
        <DivFlex>
          {itemCount > 0 && (
            <>
              {Prices}
              {BuyButton}
              {userCredits > 0 && CreditsRemaining}
            </>
          )}
          {itemCount === 0 && userCredits > 0 && (
            <>
              <SpanCheckoutSubtitle>
                {intl.messages['cart.checkout.your_credit']}
              </SpanCheckoutSubtitle>
              <SpanPriceSmall>
                <FormattedMessage id="cart.price" values={{ price: centsToEuro(userCredits) }} />
              </SpanPriceSmall>
            </>
          )}
          {(itemCount > 0 || userCredits > 0) && <Divider />}
          <CheckoutInfo hasItems={itemCount > 0 || userCredits > 0}>
            {(itemCount > 0
              ? ['move_quickly', 'cold_lead']
              : ['order_book', 'budget_control', 'build_reputation']
            ).map(e => (
              <div key={e}>
                <FormattedMessage id={`cart.checkout.title.${e}`} tagName="h3" />
                <FormattedMessage id={`cart.checkout.text.${e}`} tagName="p" />
              </div>
            ))}
          </CheckoutInfo>
        </DivFlex>
      )}
      {isMobile && (
        <>
          <Collapse in={isCollapsedOpen}>
            {itemCount > 0 && (
              <DivFlexMobile>
                <div>
                  {userCredits > 0 && (
                    <div>
                      <SpanCheckoutSubtitle>
                        {intl.messages['cart.checkout.from_credit']}
                      </SpanCheckoutSubtitle>
                      <SpanPriceRed>
                        <FormattedMessage
                          tagName="span"
                          id="cart.price"
                          values={{ price: centsToEuro(-totalCreditsUsed) }}
                        />
                      </SpanPriceRed>
                    </div>
                  )}
                  <div>
                    <SpanCheckoutSubtitle>
                      {intl.messages['cart.checkout.total_to_pay']}
                    </SpanCheckoutSubtitle>
                    <SpanPriceSmall>
                      <FormattedMessage
                        tagName="span"
                        id="cart.price"
                        values={{ price: centsToEuro(totalPaymentExclVat) }}
                      />
                    </SpanPriceSmall>
                  </div>
                  {CollapseButton}
                </div>
                {BuyButton}
              </DivFlexMobile>
            )}
            {itemCount === 0 && userCredits > 0 && (
              <DivFlex>
                <SpanCheckoutSubtitle>
                  {intl.messages['cart.checkout.your_credit']}
                </SpanCheckoutSubtitle>
                <SpanPriceSmall2>
                  <FormattedMessage id="cart.price" values={{ price: centsToEuro(userCredits) }} />
                </SpanPriceSmall2>
              </DivFlex>
            )}
          </Collapse>
          <Collapse in={!isCollapsedOpen}>
            <DivFlex>
              {Prices}
              {BuyButton}
              {userCredits > 0 && CreditsRemaining}
            </DivFlex>
            <DivButtonExpand>{CollapseButton}</DivButtonExpand>
          </Collapse>
        </>
      )}
    </DivCartCheckout>
  );
};

Checkout.defaultProps = {
  userCredits: 0,
  itemCount: 0,
  isMobile: false,
  vatDuplicated: false,
  disableCheckout: true,
  onClickBuy: null,
  totalExclVat: 0,
  totalCreditsUsed: 0,
  totalPaymentExclVat: 0,
  totalPaymentInclVat: 0,
  totalCreditsLeft: 0
};

Checkout.propTypes = {
  userCredits: NumberPropType,
  itemCount: NumberPropType,
  isMobile: BoolPropType,
  vatDuplicated: BoolPropType,
  disableCheckout: BoolPropType,
  onClickBuy: FuncPropType,
  totalExclVat: NumberPropType,
  totalCreditsUsed: NumberPropType,
  totalPaymentExclVat: NumberPropType,
  totalPaymentInclVat: NumberPropType,
  totalCreditsLeft: NumberPropType
};

export default Checkout;
