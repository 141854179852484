import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PremiumSub, Subscriptions } from '../../../constants/subscriptions';
import { allowedToAccessMyCalendar } from '../../../constants/verifiedStatus';
import { ROUTES } from '../../../routes';
import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import { IUser } from '../../../types/User';
import DashboardCard from '../../cards/DashboardCard';
import { ComponentIsVisible } from '../../utils/isVisible';

interface IDashboardCalendar {
  user: IUser;
}

const DashboardCalendar = ({ user }: IDashboardCalendar) => {
  const history = useHistory();

  const sub_type = useMemo(() => {
    const userSubscription =
      user.subscription.length > 0 ? user.subscription[0].sub_type : Subscriptions.Local;

    return userSubscription;
  }, [user.subscription]);

  const interventionPartnerships = useMemo(() => {
    if (user && user.intervention_partnerships.length > 0) {
      const interventionPartnershipsLength = user.intervention_partnerships.length;

      const interventionPartnershipsPausedLength = user.intervention_partnerships.filter(
        intPartnerShip => intPartnerShip.pause
      ).length;

      const interventionPartnershipsActiveLength = user.intervention_partnerships.filter(
        intPartnerShip => !intPartnerShip.pause
      ).length;

      return {
        length: interventionPartnershipsLength,
        paused: interventionPartnershipsPausedLength,
        active: interventionPartnershipsActiveLength
      };
    }

    return {
      length: 0,
      paused: 0,
      active: 0
    };
  }, [user]);

  const handleManage = () => {
    history.push(ROUTES.CALENDAR.handler());
  };

  const renderNoSubscription = () => (
    <JaimyFlex margin="1rem 0" justifyContent="center">
      <JaimyText>
        <FormattedMessage id="dashboard.calendar.no_sub_or_pro_sub" />{' '}
        <EmailStyled href={`mailto:sales@jaimy.be`}>sales@jaimy.be</EmailStyled>
      </JaimyText>
    </JaimyFlex>
  );

  const renderNumberOfCalendars = () => (
    <JaimyFlex justifyContent="start" flexDirection="column" alignItems="center" gap="1rem" >
      <ComponentIsVisible when={interventionPartnerships.length > 0}>
        <JaimyText fontWeight={700}>
          {interventionPartnerships.active} - <FormattedMessage id="dashboard.calendar.active" />
        </JaimyText>
        <JaimyText fontWeight={700}>
          {interventionPartnerships.paused} - <FormattedMessage id="dashboard.calendar.pause" />
        </JaimyText>
        <ComponentIsVisible when={allowedToAccessMyCalendar.includes(user.verified_status)}>
          <JaimyButton.BaseSecondary
            backgroundColor={theme.colors.blue.lightBlue}
            color={theme.colors.white}
            onClick={handleManage}
          >
            <FormattedMessage id="manage" />
          </JaimyButton.BaseSecondary>
        </ComponentIsVisible>
        <ComponentIsVisible when={!allowedToAccessMyCalendar.includes(user.verified_status)}>
          <JaimyText
            color={theme.colors.secondary.light}
            textAlign="center"
            fontSize={theme.typography.fontSizes.sm}
          >
            <FormattedMessage id="dashboard.restriction.message" />
          </JaimyText>
        </ComponentIsVisible>
      </ComponentIsVisible>
      <ComponentIsVisible when={!interventionPartnerships}>
        <JaimyText>
          <FormattedMessage id="dashboard.calendar.premium.no_active" />{' '}
          <EmailStyled href={`mailto:${user.owner.email}`}>{user.owner.email}</EmailStyled>
        </JaimyText>
      </ComponentIsVisible>
    </JaimyFlex>
  );

  return (
    <DashboardCard lastCard>
      <JaimyBox padding="10px">
        <JaimyHeading.H3 fontSize="1.2rem" color={theme.colors.blue.darkBlue} textAlign="center">
          <FormattedMessage id="dashboard.calendar" />
        </JaimyHeading.H3>
      </JaimyBox>
      <JaimyFlex flexDirection="column" justifyContent="center" margin="1rem 0" alignItems="center">
        <ComponentIsVisible when={PremiumSub.includes(sub_type as Subscriptions)}>
          {renderNumberOfCalendars()}
        </ComponentIsVisible>
        <ComponentIsVisible when={!PremiumSub.includes(sub_type as Subscriptions)}>
          {renderNoSubscription()}
        </ComponentIsVisible>
      </JaimyFlex>
    </DashboardCard>
  );
};

export default DashboardCalendar;

const EmailStyled = styled.a`
  color: ${props => props.theme.colors.secondary.base};
`;
