/* eslint-disable class-methods-use-this */
import { getTraderId, handleResult } from '../../util/serviceHelpers';

import { API_URL } from '../../util/url';
import { getToken } from '../../util/tokenProvider';

class CartService {
  /**
   * Handle mollie id creation
   */
  async createMollieId() {
    const authorization = { Authorization: `Bearer ${await getToken()}` };
    const id = getTraderId();
    const getFirstPayment = await fetch(
      `${API_URL}/api/traders/v1/traders/${id}/first_payment_mollie`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          ...authorization
        }
      }
    );
    const firstData = await handleResult(getFirstPayment);
    if (firstData && !firstData.customer) {
      return fetch(`${API_URL}/api/traders/v1/traders/${id}/track_first_payment_mollie`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          ...authorization
        }
      }).then(handleResult);
    }
    return firstData;
  }

  /**
   * Get jobs in cart and handle mollie id creation
   */
  async get() {
    // Exclude non_available_anymore_sr since CartDetails gets called on the server (first load)
    // non_available_anymore_sr is used to display a message to the user
    // const cartData = await CartDetails(req, res, tokens, { id });
    const id = getTraderId();
    const request = await fetch(`${API_URL}/api/traders/v1/traders/${id}/cart`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    });

    this.createMollieId();

    return handleResult(request);
  }

  /**
   * Get cart_details info
   */
  async cartDetails() {
    const id = getTraderId();
    return fetch(`${API_URL}/api/traders/v1/traders/${id}/cart_details`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Get jobs in cart and handle mollie id creation + cart_details info
   */
  async getWithDetails() {
    const data = await this.get();
    const cartData = await this.cartDetails();
    return { data, ...cartData };
  }

  /**
   * Add job to cart
   */
  async add(id) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/${id}/cart_service_requests`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Remove job to cart
   */
  async remove(id) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/${id}/cart_service_requests`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }

  /**
   * Cart checkout
   */
  async checkout(ids, redirectUrl) {
    let request = await fetch(`${API_URL}/api/traders/v1/service_requests/estimates_mollie/cart`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({ service_request_ids: ids, redirect_url: redirectUrl })
    });

    const data = await handleResult(request);
    if (data?._links?.checkout?.href) {
      return { redirect_url: data._links.checkout.href };
    }
    return { redirect_url: redirectUrl };
  }

  /**
   * Cart checkout with invoice later (also used for buying 1 job on invoice later)
   */
  async checkoutInvoiceLater(ids) {
    return fetch(`${API_URL}/api/traders/v1/service_requests/estimates_mollie/invoice_later`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      },
      body: JSON.stringify({
        service_request_ids: ids
      })
    }).then(handleResult);
  }

  /**
   * Get payment status
   */
  async getPaymentStatus() {
    const id = getTraderId();
    return fetch(`${API_URL}/api/traders/v1/service_requests/estimates_mollie/${id}/cart`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    }).then(handleResult);
  }
  /**
   * Get expired service requests of the trader
   */
  async getExpired() {
    const id = getTraderId();
    const request = await fetch(`${API_URL}/api/traders/v1/traders/${id}/expired`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    });
    return handleResult(request);
  }
  /**
   * Clear all expired jobs of the trader
   */
  async deleteExpired() {
    const id = getTraderId();
    const request = await fetch(`${API_URL}/api/traders/v1/traders/${id}/expired`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    });
    return handleResult(request);
  }
}

export default CartService;
