import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import AccountService from '../lib/services/accountService';
import { raisePageEvent } from '../logging/analytics';

const DivContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
`;

const accountService = new AccountService();

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');

  const metaTitle = 'jaimy.be - forgot password';

  useEffect(() => {
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleForgotPassword = async email => {
    setIsLoading(true);
    setHasError(false);
    if (!isLoading) {
      accountService
        .forgotPassword(email)
        .then(response => {
          setIsLoading(false);
          if (response.error) {
            setHasError(true);
          } else if (response.success) {
            setMessage(response.message);
          }
        })
        .catch(err => {
          console.error({ err });
          setIsLoading(false);
          setHasError(true);
        });
    }
  };

  return (
    <DivContainer>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <ForgotPasswordForm
        onSubmit={handleForgotPassword}
        isLoading={isLoading}
        unauthorized={hasError}
        message={message}
      />
    </DivContainer>
  );
};

export default ForgotPassword;
