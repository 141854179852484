interface IOptionType {
  label: string;
  value: string | number;
}

export const TIME_DURATION: IOptionType[] = [
  { label: '15m', value: 15 },
  { label: '30m', value: 30 },
  { label: '1h', value: 60 },
  { label: '1h 30m', value: 90 },
  { label: '2h', value: 120 },
  { label: '2h 30m', value: 150 },
  { label: '3h', value: 180 },
  { label: '4h', value: 240 }
];

export const TIME_DURATION_DRIVING: IOptionType[] = [
  { label: '15m', value: 15 },
  { label: '20m', value: 20 },
  { label: '25m', value: 25 },
  { label: '30m', value: 30 },
  { label: '35m', value: 35 },
  { label: '40m', value: 40 },
  { label: '45m', value: 45 },
  { label: '50m', value: 50 },
  { label: '1h', value: 60 },
  { label: '1h 15m', value: 75 },
  { label: '1h 30m', value: 90 },
  { label: '1h 45m', value: 105 },
  { label: '2h', value: 120 },
  { label: '2h 30m', value: 150 }
];

export const SLOT_PROPOSAL_DELAY: IOptionType[] = [
  { label: '24 hours', value: 1440 },
  { label: '48 hours', value: 2880 },
  { label: '72 hours', value: 4320 },
  { label: '96 hours', value: 5760 },
  { label: '120 hours', value: 7200 },
  { label: '7 days', value: 10080 },
  { label: '14 days', value: 20160 }
];

export const TIME_FREQUENCY: IOptionType[] = [
  { label: 'daily', value: 'daily' },
  { label: 'weekly', value: 'weekly' },
  { label: 'yearly', value: 'yearly' }
];

export const INTERVENTION_TYPES_MATCHING: IOptionType[] = [
  { label: 'All', value: 'all' },
  { label: 'Only basic', value: 'default' },
  { label: 'Only urgent', value: 'urgent' }
];

export const RADIUS: IOptionType[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '30', value: 30 },
  { label: '40', value: 40 },
  { label: '50', value: 50 },
  { label: '60', value: 60 },
  { label: '70', value: 70 },
  { label: '80', value: 80 },
  { label: '90', value: 90 },
  { label: '100', value: 100 },
  { label: '200', value: 200 }
];
