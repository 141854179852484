import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl';
import Breadcrumbs from '../../components/Breadcrumbs';
import { DivHeader, H1ProfileTitle, SectionTopHeader } from '../../components/common/Header';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { AnchorBack } from '../../components/common/Link';
import JaimyFlex from '../../theme/components/layout/Flex';
import { useSinglePrismicDocument } from '@prismicio/react';
import { useSelector } from 'react-redux';
import { FixMeLater } from '../../types/FixMeLater';
import { ComponentIsVisible } from '../../components/utils/isVisible';
import { PrismicSliceType } from '../../types/Prismic';
import { PrismicRichText } from '../../components/Prismic/PrismicRichText/PrismicRichText';
import JaimyBox from '../../theme/components/layout/Box';
import theme from '../../theme';
import { H3PreFootertitle, H4PreFooterSubtitle } from '../help/help';
import { CircularProgress } from '@mui/material';

const TermsAndConditions = () => {
  const locale = useSelector((state: FixMeLater) => state.user.user.locale);
  const intl = useIntl();
  const { width } = useWindowDimensions();
  const metaTitle = intl.messages['termsAndConditions.title'];

  const [prismicData] = useSinglePrismicDocument('terms-and-conditions', {
    lang: locale === 'fr' ? 'fr-be' : 'nl-be'
  });

  const [termsContentGroup, setTermsContentGroup] = useState<PrismicSliceType[]>([]);
  const [preFooterSlice, setPreFooterSlice] = useState<PrismicSliceType>();

  useEffect(() => {
    if (!prismicData) return;

    setTermsContentGroup(prismicData?.data?.body?.filter((slice: PrismicSliceType) => slice.slice_type === 'termsandconditions'));
    setPreFooterSlice(prismicData?.data?.body?.find((slice: PrismicSliceType) => slice.slice_type === 'prefooter'));

  }, [prismicData]);

  return (
    <>
      <Helmet>
        <title>{metaTitle.toString()}</title>
      </Helmet>
      <DivHeader>
        <SectionTopHeader>
          {width > 768 && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.TERMS_AND_CONDITIONS.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.termsAndConditions" />
              </Link>
            </Breadcrumbs>
          )}
          {width > 768 && (
            <AnchorBack to={`${ROUTES.DASHBOARD.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          )}
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="termsAndConditions.title" defaultMessage="Terms and Conditions" />
        </H1ProfileTitle>
      </DivHeader>
      <ComponentIsVisible when={!termsContentGroup || termsContentGroup.length === 0}>
        <JaimyFlex justifyContent="center" alignItems="center">
          <CircularProgress />
        </JaimyFlex>
      </ComponentIsVisible >
      <ComponentIsVisible when={!!termsContentGroup && termsContentGroup.length > 0}>
        <JaimyFlex justifyContent="center" alignItems="center">
          {termsContentGroup.map((slice: PrismicSliceType) => {
            return (
              <JaimyFlex key={slice.id} margin="2rem" padding="1rem" flexDirection='column' width="80%" backgroundColor="white">
                {slice?.items.map((item, index) => {
                  const field = item['section_content'];
                  return (
                    <JaimyBox key={index} border={`2px dotted ${theme.colors.secondary.dark}`} padding={"1rem"}>
                      <PrismicRichText render={field} />
                    </JaimyBox>
                  );
                })}
              </JaimyFlex>
            );
          })}
        </JaimyFlex>
      </ComponentIsVisible >
      {preFooterSlice && (
        <JaimyFlex width="80%" flexDirection="column" backgroundColor={theme.colors.secondary.light} margin="0 auto" textAlign='center' padding="30px" >
          <H3PreFootertitle>{preFooterSlice.primary.prefooter_title || ''}</H3PreFootertitle>
          <H4PreFooterSubtitle>{preFooterSlice.primary.prefooter_subtitle || ''}</H4PreFooterSubtitle>
        </JaimyFlex>
      )}
    </>
  )
}

export default TermsAndConditions
