import { ReactNode } from 'react';
import theme from '../../../theme';
import JaimyFlex from '../../../theme/components/layout/Flex';

interface IDashboardCard {
  children: ReactNode;
  backgroundColor?: string;
  lastCard?: boolean
}

const DashboardCard = ({ children, backgroundColor, lastCard }: IDashboardCard) => {
  return (
    <JaimyFlex
      padding="0.5rem 1rem"
      boxShadow={theme.shadow}
      flexDirection="column"
      alignItems="center"
      backgroundColor={backgroundColor ? backgroundColor : theme.colors.white}
      widthSm="320px"
      marginSm={lastCard ? '0 0 6rem 0' : '0 auto'}
    >
      {children}
    </JaimyFlex>
  );
};

export default DashboardCard;
