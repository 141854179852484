export enum Subscriptions {
  Local = 'local',
  Custom_Pro_6 = 'custom_pro_6',
  Custom_Pro_12 = 'custom_pro_12',
  Custom_Premium_6 = 'custom_premium_6',
  Custom_Premium_12 = 'custom_premium_12',
  Custom_RikSilver_12 = 'custom_rik_silver_12',
  Custom_RikGolden_12 = 'custom_rik_golden_12',
  Premium_3 = 'premium_3',
  Premium_12 = 'premium_12',
  Pro_3 = 'pro_3',
  TrialPro_1 = 'trial_pro_1',
  TrialPremium_1 = 'trial_premium_1',
  Pro_12 = 'pro_12',
  Premium_6 = 'premium_6',
  Pro_6 = 'pro_6',
  Premium_1 = 'premium_1',
  RikSilver_12 = 'rik_silver_12',
  RikGolden_12 = 'rik_golden_12'
}

export const proOrPremiumSub = [
  Subscriptions.Custom_Pro_6,
  Subscriptions.Custom_Pro_12,
  Subscriptions.Custom_Premium_6,
  Subscriptions.Custom_Premium_12,
  Subscriptions.Custom_RikSilver_12,
  Subscriptions.Custom_RikGolden_12,
  Subscriptions.TrialPro_1,
  Subscriptions.TrialPremium_1,
  Subscriptions.Pro_3,
  Subscriptions.Pro_6,
  Subscriptions.Pro_12,
  Subscriptions.Premium_1,
  Subscriptions.Premium_3,
  Subscriptions.Premium_6,
  Subscriptions.Premium_12,
  Subscriptions.RikGolden_12,
  Subscriptions.RikSilver_12
];
export const PremiumSub = [
  Subscriptions.Custom_Premium_6,
  Subscriptions.Custom_Premium_12,
  Subscriptions.Custom_RikSilver_12,
  Subscriptions.Custom_RikGolden_12,
  Subscriptions.TrialPremium_1,
  Subscriptions.Premium_1,
  Subscriptions.Premium_3,
  Subscriptions.Premium_6,
  Subscriptions.Premium_12,
  Subscriptions.RikSilver_12,
  Subscriptions.RikGolden_12
];
