import React, { useEffect, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import SelectUI from '@material-ui/core/Select';
import { isMobile } from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../routes';
import { requestCategories } from '../../store/reducers/categories/actions';
import { serviceFilter } from '../../store/reducers/services/actions';
import { arraysEqual } from '../../util/helpers';
import { Breadcrumbs } from '../Breadcrumbs';
import { LinkButton } from '../common/Button';
import { H1TitleBelfius } from '../common/Card';
import ModalFilter from '../ModalFilter';
import SectionFilter from '../SectionFilter';
import Select from '../Select';

const DivServiceFilter = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #d8dae0;
  padding: 1rem;
`;
const SectionTitle = styled.section`
  color: #c30b30;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    font-size: 22px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
`;
const SectionActions = styled.section`
  display: flex;
  justify-content: space-between;

  & > button,
  & > div {
    flex-basis: 48%;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
`;
const Button = styled.button`
  background: #f3f4f6;
  border: 2px solid rgba(255, 255, 255, 0.317116);
  box-sizing: border-box;
  border-radius: 4px;
  color: #32546d;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  line-height: 12px;
  height: 40px;
  padding: 0 1.2rem;
  cursor: pointer;
`;
const SectionResetFilter = styled.section`
  margin: 1.2rem 0;
  padding: 0 1.2rem;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 1rem auto;
  }
`;

const ServiceFilter = ({
  services,
  user,
  setFilter,
  isLoading,
  categories,
  catError,
  catLoading,
  hasFreelead,
  locale,
  total,
  getCategories
}) => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (!catError && !catLoading) {
      getCategories(user.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const [isModalOpen, setModalOpen] = useState(false);
  const items = (services && services.data) || [];
  const orderBy =
    services.filters && services.filters.order_direction ? services.filters.order_direction : '';
  const onOrderBy = ({ target: { value } }) =>
    // only set filter when the value actually changes
    value !== orderBy && setFilter({ ...services.filters, order_direction: value });
  const onViewChange = ({ target: { value } }) => {
    const state = { ...services.filters, service_view: value };
    setFilter(state);
  };
  const onResetFilters = () => {
    const state = {
      ...services.filters,
      radius: user.service_radius,
      category_list: user.treetrades,
      languages: user.matching_locales ? user.matching_locales : [user.locale]
    };
    setFilter(state);
  };
  const showResetFilters =
    user.service_radius !== services.filters.radius ||
    (user.treetrades &&
      services.filters.category_list &&
      user.treetrades.toString() !== services.filters.category_list.toString()) ||
    !arraysEqual(services.filters.languages, user.matching_locales);

  const getPageTitleKey = () => {
    switch (services.filters.service_view) {
      case 'new':
        return 'sectionfilter.view.new';
      case 'unseen':
        return 'sectionfilter.view.unseen';
      case 'favorites':
        return 'sectionfilter.view.favorites';
      case 'trash':
        return 'sectionfilter.view.trash';
      default:
        return 'servicefilter.title';
    }
  };
  return (
    <>
      <DivServiceFilter>
        <SectionTitle>
          {width > 768 && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.JOBS.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.job_list" />
              </Link>
            </Breadcrumbs>
          )}
          <H1TitleBelfius>
            <FormattedMessage tagName="span" id={getPageTitleKey()} />
          </H1TitleBelfius>
        </SectionTitle>
        {width < 768 && (
          <SectionActions>
            <Button onClick={() => setModalOpen(!isModalOpen)}>
              <FormattedMessage tagName="span" id="servicefilter.show_filters" />
            </Button>
            <Select
              name="service_view"
              value={services.filters.service_view}
              onChange={onViewChange}
            >
              <MenuItem data-cy-menuitem="simplified" value="simplified">
                <FormattedMessage
                  id="sectionfilter.view.simplified"
                  defaultMessage="Simplified view"
                />
              </MenuItem>
              <MenuItem data-cy-menuitem="new" value="new">
                <FormattedMessage id="sectionfilter.view.new" defaultMessage="New Jobs" />
              </MenuItem>
              <MenuItem data-cy-menuitem="favorites" value="favorites">
                <FormattedMessage id="sectionfilter.view.favorites" defaultMessage="Favorites" />
              </MenuItem>
              <MenuItem data-cy-menuitem="not-interested" value="trash">
                <FormattedMessage id="sectionfilter.view.trash" defaultMessage="Not Interested" />
              </MenuItem>
              <MenuItem data-cy-menuitem="active" value="active">
                <FormattedMessage id="sectionfilter.view.active" defaultMessage="Active" />
              </MenuItem>
            </Select>
          </SectionActions>
        )}
        {width > 768 && (
          <SectionFilter setFilter={setFilter} categories={categories} locale={locale} />
        )}
      </DivServiceFilter>
      <SectionResetFilter>
        {/* Need changes in backend to make this work again */}
        {/* {width > 768 && (
          <span style={{ visibility: total > 0 ? 'visible' : 'hidden' }}>
            <FormattedMessage
              tagName="span"
              id="servicefilter.showing"
              values={{ count: total }}
              defaultMessage="{count, plural, =0 {No jobs} one {# job} other {# jobs}}"
            />
          </span>
        )} */}
        <LinkButton invisible={!showResetFilters} onClick={onResetFilters}>
          <FormattedMessage tagName="span" id="servicefilter.reset_filter" />
        </LinkButton>
        <SelectUI
          value={orderBy}
          onChange={onOrderBy}
          displayEmpty
          style={{ visibility: hasFreelead || total === 0 ? 'hidden' : 'visible' }}
        >
          <MenuItem value="">
            <FormattedMessage tagName="span" id="servicefilter.default" />
          </MenuItem>
          <MenuItem value="DESC">
            <FormattedMessage tagName="span" id="servicefilter.sort_high" />
          </MenuItem>
          <MenuItem value="ASC">
            <FormattedMessage tagName="span" id="servicefilter.sort_low" />
          </MenuItem>
        </SelectUI>
      </SectionResetFilter>
      <ModalFilter
        items={items}
        setFilter={setFilter}
        categories={categories}
        isLoading={isLoading}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={() => setModalOpen(false)}
        isMobile={isMobile}
        locale={locale}
        total={total}
        userSubscription={user.subscription}
      />
    </>
  );
};

const mapStateToProps = ({ categories, services, user: { user } }) => ({
  services,
  user,
  locale: user.locale,
  categories: categories.tree,
  catLoading: categories.loading,
  catError: categories.error
});
const mapDispatchToProps = dispatch => ({
  setFilter: payload => dispatch(serviceFilter(payload)),
  getCategories: payload => dispatch(requestCategories(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceFilter);
