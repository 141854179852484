import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../routes';
import TextField from '../TextField';
import styled from 'styled-components';

const DivContainer = styled.div`
  margin: 4rem;
  max-width: 468px;

  @media (min-width: 768px) {
    margin: 4rem auto;
  }
`;
const DivForm = styled.div`
  max-width: 468px;
  margin: 0 auto 1.5rem auto;

  > div {
    margin-top: 1rem;
  }
`;
const H2Title = styled.h2`
  color: #32546d;
  text-align: center;
  font-family: 'Lato', sans-serif;
`;
const DivError = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-style: italic;
  max-width: 468px;
  background-color: #c7cfd9;
  color: #32546d;
  border-radius: 4px;
  padding: 0.7rem 1rem;
  transition: all 2s ease-in-out;
`;
const Anchor = styled(Link)`
  display: inline-block;
  cursor: pointer;
  color: #32546d;
  margin-top: 0.5rem;

  span {
    font-size: 10pt;
    font-weight: bold;
    text-decoration: underline;
    font-family: 'Lato', sans-serif;
    font-style: normal;
  }
`;

export const ButtonLogin = styled.button`
  background-color: #c30144;
  border: 2px solid #c30144;
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0.7rem 1rem;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  justify-content: ${props => (props.fullWidth ? 'center' : 'auto')};
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
  :disabled {
    background-color: #d8dae0;
    border-color: #d8dae0;
    cursor: auto;
    color: #867a7a;
  }

  :hover:not(:disabled) {
    background-color: #c30144c4;

    svg {
      fill: #fff;
    }
  }
`;

export const LoginForm = ({ onSubmit, isLoading, disabled, isBanned, hasUserError }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(null);
  const onClickSubmit = e => {
    e.preventDefault();
    if (username.trim() !== '' && password.trim() !== '') {
      onSubmit(username, password);
    } else {
      setHasError({
        required: {
          username: !username,
          password: !password
        }
      });
    }
  };
  const validate = field => {
    if (hasError && hasError.required) {
      return hasError.required[field];
    }

    return false;
  };
  const onChange = (field, value) => {
    if (field === 'username') {
      sessionStorage.setItem('forgotEmail', value);
      setUsername(value);
    } else if (field === 'password') {
      setPassword(value);
    }

    if (hasError && hasError.required && hasError.required.hasOwnProperty(field)) {
      const { required } = hasError;
      delete required[field];

      setHasError({
        required
      });
    }
  };

  const hasRequiredFieldsError = hasError && Object.values(hasError.required).includes(true);

  return (
    <DivContainer>
      <H2Title>
        <FormattedMessage tagName="span" id="login.title" />
      </H2Title>
      <form onSubmit={onClickSubmit}>
        <DivForm>
          <TextField
            id="username"
            value={username}
            onChange={e => onChange('username', e.target.value)}
            label="Email"
            type="email"
            error={validate('username')}
            fullWidth
          />
          <TextField
            id="password"
            value={password}
            onChange={e => onChange('password', e.target.value)}
            label="Password"
            type="password"
            error={validate('password')}
            fullWidth
          />
        </DivForm>
        {hasError && hasRequiredFieldsError && (
          <DivError data-cy-div="login-error">
            <FormattedMessage tagName="span" id="login.error.required" />
          </DivError>
        )}
        {hasUserError && (
          <DivError data-cy-div="login-error">
            <FormattedMessage tagName="span" id="login.error" />
          </DivError>
        )}
        {isBanned && (
          <DivError data-cy-div="login-error">
            <FormattedMessage tagName="span" id="login.banned" />
          </DivError>
        )}
        <ButtonLogin type="submit" disabled={disabled} fullWidth>
          {isLoading && <CircularProgress color="inherit" disableShrink />}
          {!isLoading && <FormattedMessage tagName="span" id="login.submit" />}
        </ButtonLogin>
      </form>
      <Anchor to={`${ROUTES.FORGOT_PASSWORD.handler()}`} data-cy-link="forgot-password">
        <FormattedMessage tagName="span" id="login.forgot_password" />
      </Anchor>
    </DivContainer>
  );
};

LoginForm.defaultProps = {
  isBanned: false,
  isLoading: false,
  hasUserError: false,
  disabled: false
};

LoginForm.propTypes = {
  isBanned: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  hasUserError: PropTypes.bool,
  disabled: PropTypes.bool
};

export default LoginForm;
