export enum EVerifiedStatus {
  Confirmed = 'confirmed',
  Checked = 'checked',
  Validated = 'validated',
  Banned = 'banned',
  Archived = 'archived',
  RestrictedLatePayment45 = 'restricted_late_payment_45_days',
  RestrictedLatePayment60 = 'restricted_late_payment_60_days',
  RestrictedWaitForInfo = 'restricted_waiting_for_info'
}

export const isShowingBanner = [
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedLatePayment60,
  EVerifiedStatus.RestrictedWaitForInfo,
  EVerifiedStatus.Banned
];

export const allowedToSeeAddCartButton = [
  EVerifiedStatus.Confirmed,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedWaitForInfo
];

export const allowedToSeeJobList = [
  EVerifiedStatus.Confirmed,
  EVerifiedStatus.Checked,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedLatePayment60,
  EVerifiedStatus.RestrictedWaitForInfo
];

export const allowedToPayWithMollie = [
  EVerifiedStatus.Checked,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedWaitForInfo
];

export const allowedToLateInvoiceFeature = [EVerifiedStatus.Validated];

export const allowedToAccessInvoice = [
  EVerifiedStatus.Confirmed,
  EVerifiedStatus.Checked,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedLatePayment60,
  EVerifiedStatus.RestrictedWaitForInfo,
  EVerifiedStatus.Banned
];

export const allowedToAccessHistory = [
  EVerifiedStatus.Confirmed,
  EVerifiedStatus.Checked,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedWaitForInfo
];

export const allowedToAccessMyCalendar = [
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45
];

export const allowedToAccessMyPrebook = [EVerifiedStatus.Validated];

export const allowedToAccessMyProfile = [
  EVerifiedStatus.Confirmed,
  EVerifiedStatus.Checked,
  EVerifiedStatus.Validated,
  EVerifiedStatus.RestrictedLatePayment45,
  EVerifiedStatus.RestrictedLatePayment60,
  EVerifiedStatus.RestrictedWaitForInfo
];
