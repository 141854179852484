import styled from 'styled-components';

interface IJaimyFormControlProps {
  padding?: string | number;
  valid?: boolean;
  isLast?: boolean;
  margin?: string;
  minWidth?: string;
  display?: string
}

const JaimyFormControl = styled.div<IJaimyFormControlProps>`
  margin: ${props => (props.margin ? props.margin : "1rem 1rem 0 0")};
  min-width: ${props => (props.minWidth ? props.minWidth : "200px")};
  padding: ${props => (props.padding ? props.padding : "0px")};
  display: ${props => (props.display ? props.display : "block")};
  ${props => (props.isLast ? 'margin-bottom: 1rem;' : '')}

  > label {
    display: block;
    color: #32546d;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }

  input:disabled {
    opacity: 0.45;
  }

  input:invalid {
    border-color: #c30b30;
  }

`;

export default JaimyFormControl;
