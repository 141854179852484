import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import 'url-search-params-polyfill';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { API_URL } from '../util/url';

const DivContainer = styled.div`
  position: relative;
  display: flex;
  height: calc(100vh - 72px);
  place-items: center;
  place-content: center;
`;

const DivImgContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 10;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: center;
`;

const DivTextContainer = styled.div`
  z-index: 20;
  display: flex;
  padding: 2rem;
  gap: 2rem;
  border-radius: 0.5rem;
  flex-direction: column;
  text-align: center;
  place-items: center;
  height: fit-content;
  width: clamp(300px, 60%, 700px);
  background-color: hsla(0, 0%, 100%, 0.7);
`;

const Heading = styled.h1`
  color: hsl(205, 37%, 31%);
  font-family: 'BelfiusNormal', sans-serif;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const CustomTextArea = styled.textarea`
  width: 100%;
  height: 70px;
  border-radius: 0.25rem;
  border: 1px solid #32546d;

  background-color: hsla(0, 0%, 100%, 0.3);
  padding: 0.5rem 1rem;
`;

const UnsubscribeButton = styled.button`
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: hsl(354, 81%, 42%);
  color: white;
  border: none;
  padding: 1rem 1.25rem;
  font-size: 1.125rem;
  font-weight: bold;
  :hover {
    background-color: hsl(354, 81%, 32%);
  }
`;

const Unsubscribe = () => {
  const { token } = queryString.parse(useLocation().search);

  const [unsubscribed, setUnsubscribed] = useState(false);
  const [value, setValue] = useState('');
  const { register, handleSubmit } = useForm();

  const handleUnsubscribe = useCallback(
    values => {
      fetch(`${API_URL}/api/backoffice/v1/emails/unsubscribe_email`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({ token: token, reason: values.reason || 'no reason given' })
      });
      setUnsubscribed(true);
    },
    [token]
  );

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <DivContainer>
      <DivImgContainer>
        <Image
          alt="Jaimy Unsubscribe"
          src="https://d1ngac5455aayn.cloudfront.net/images/hero-images/jaimy-404.jpg"
        />
      </DivImgContainer>
      <DivTextContainer>
        {unsubscribed ? (
          <Heading>
            <FormattedMessage id="unsubscribe.successful" />
          </Heading>
        ) : (
          <>
            <Heading>
              <FormattedMessage id="unsubscribe.confirmMessage" />
            </Heading>
            <Form onSubmit={handleSubmit(handleUnsubscribe)}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <FormattedMessage id="unsubscribe.reason" />
                </FormLabel>
                <RadioGroup aria-label="reason" name="reason" value={value} onChange={handleChange}>
                  <FormControlLabel
                    {...register('reason')}
                    value="no_interest"
                    control={<Radio />}
                    label={<FormattedMessage id="unsubscribe.no_interest" />}
                  />
                  <FormControlLabel
                    {...register('reason')}
                    value="to_many"
                    control={<Radio />}
                    label={<FormattedMessage id="unsubscribe.to_many" />}
                  />
                  <FormControlLabel
                    {...register('reason')}
                    value="not_reading"
                    control={<Radio />}
                    label={<FormattedMessage id="unsubscribe.not_reading" />}
                  />
                  <FormControlLabel
                    {...register('reason')}
                    value="other"
                    control={<Radio />}
                    label={<FormattedMessage id="unsubscribe.other" />}
                  />
                </RadioGroup>
              </FormControl>
              {value === 'other' && <CustomTextArea {...register('reason')} />}

              <UnsubscribeButton type="submit">
                <FormattedMessage id="unsubscribe.confirm" />
              </UnsubscribeButton>
            </Form>
          </>
        )}
      </DivTextContainer>
    </DivContainer>
  );
};

export default Unsubscribe;
