import styled, { createGlobalStyle, css } from 'styled-components';

import CartProp from '../store/reducers/cart/proptype';
import { Footer } from './Footer';
import { MainHeader } from './MainHeader';
import PropTypes from 'prop-types';
import Snackbar from './Snackbar';
import UserProp from '../store/reducers/user/proptype';
import { connect } from 'react-redux';
import { setLanguage } from '../store/reducers/user/actions';
import { useLocation } from 'react-router-dom';
import CookieBanner from './CookieBanner';
import useWindowDimensions from '../hooks/useWindowDimensions';
import BannedPopup from './popup/Banned';

const DivWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  input:required:focus:invalid {
    border-color: #c30b30;
  }
`;
const DivContent = styled.div`
  padding-bottom: 296px;
  background-color: ${props => (props.white ? '#fff' : '#F2F2F2')};
  min-height: calc(100vh - 296px);
  @media (min-width: 1200px) {
    ${props =>
      props.pb &&
      css`
        padding-bottom: ${props.pb};
      `}
  }
`;
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: 'Lato', sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
  }`;
const PageLayout = ({ children, user, cart, setLanguage, pb }) => {
  const { width } = useWindowDimensions();

  const { pathname } = useLocation();

  const white = pathname.includes('cart') || pathname.includes('register');
  return (
    <DivWrapper>
      <GlobalStyle />
      <MainHeader cart={cart} user={user} isMobile={width <= 768} setLanguage={setLanguage} />
      <DivContent pb={pb} white={white}>
        {children}
      </DivContent>
      <CookieBanner />
      {/* {!noFooter && <Footer />} */}
      <Footer locale={user.locale} isCartPage={pathname.includes('cart')} />
      <Snackbar />
      <BannedPopup />
    </DivWrapper>
  );
};

PageLayout.defaultProps = {
  pb: '',
  white: false,
  noFooter: false
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  user: UserProp.isRequired,
  cart: CartProp.isRequired,
  white: PropTypes.bool,
  noFooter: PropTypes.bool,
  setLanguage: PropTypes.func.isRequired,
  pb: PropTypes.string
};

const mapStateToProps = ({ user: { user }, cart }) => ({ user, cart });
const mapDispatchToProps = {
  setLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
