import styled from 'styled-components';

interface IJaimyTextProps {
  color?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: number | string;
  fontFamily?: string;
  letterSpacing?: string;
  lineHeight?: string;
  margin?: string;
  padding?: string;
  textAlign?: string;
  textTransform?: string;
  variant?: 'preHeader' | 'preHeaderBorder' | 'preHeaderBorderHeavierGray';
  width?: string;
}

const JaimyText = styled.p<IJaimyTextProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'Lato, sans-serif')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: ${props => props.letterSpacing && props.letterSpacing};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  text-align: ${props => props.textAlign && props.textAlign};
  text-transform: ${props => props.textTransform && props.textTransform};
  width: ${props => props.width && props.width};

  &.stepCustomText {
    line-height: 1.5rem;
    word-break: break-word;
    a {
      color: ${props => props.theme.colors.primary.base};
      #fixed-price {
        background-color: ${props => props.theme.colors.primary.base};
      }
    }
    button {
      background-color: ${props => props.theme.colors.secondary.base};
      border: 0;
      border-radius: 10px;
      color: white;
      font-size: 1rem;
      height: auto;
      padding: 5px 10px;
    }
  }
`;

export default JaimyText;

