import { CircularProgress, MenuItem, Switch } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import JaimyCheckbox from '../../../components/Checkbox';
import { ButtonPrimary } from '../../../components/common/Button';

import { Controller, useForm } from 'react-hook-form';
import { PrebookType } from '../../../components/Prebook/PrebookCard/PrebookCard';
import Select from '../../../components/Select';
import TextField from '../../../components/TextField';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import JaimyFormControl from '../../../theme/components/form/FormControl';
import JaimyFlex from '../../../theme/components/layout/Flex';
import { EMAIL_NOTIFICATION, PREBOOK_FREQUENCY, SERVICE_RADIUS } from './FormConstants';

import { Checkbox, FormControlLabel } from '@mui/material';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import VacationCancelDialog from '../../../components/Dialog/Vacation/Cancel';
import VacationPlanningDialog from '../../../components/Dialog/Vacation/Planning';
import Snackbar from '../../../components/Snackbar';
import TextArea from '../../../components/Textarea';
import { ComponentErrorMessage } from '../../../components/utils/ErrorMessage';
import { ComponentIsVisible } from '../../../components/utils/isVisible';
import { modifyTraderPrebook } from '../../../store/reducers/prebooks/actions';
import {
  getAllVacationsOfPrebook,
  IGetPrebookVacationsRequestAction
} from '../../../store/reducers/vacation/actions';
import { IVacationState } from '../../../store/reducers/vacation/reducer';
import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyLabel from '../../../theme/components/form/Label';
import JaimyText from '../../../theme/components/Typography/Text';
import { FixMeLater } from '../../../types/FixMeLater';
import { PrebookFormType } from '../../../types/PrebookFormType';
import { IPrebookVacation } from '../../../types/Vacation';

interface PrebookFormProps {
  selectedPreBook: PrebookType;
  putTraderPrebooksData: FixMeLater;
  userId: number;
  preBooksIsLoading: boolean;
  vacations: IPrebookVacation[];
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) => AnyAction;
}

const PrebookForm = ({
  selectedPreBook,
  putTraderPrebooksData,
  preBooksIsLoading,
  vacations,
  userId,
  getAllVacationsOfPrebook
}: PrebookFormProps) => {
  const { id: prebookId } = useParams<{ id?: string }>();
  const intl = useIntl();
  const { width } = useWindowDimensions();

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors }
  } = useForm<PrebookFormType>({
    defaultValues: {
      pause: selectedPreBook.pause,
      matching_with_radius: selectedPreBook.matching_with_radius,
      frLanguage: selectedPreBook?.locale.includes('fr'),
      nlLanguage: selectedPreBook?.locale.includes('nl')
    }
  });

  const [vacationPlanningOpen, setVacationPlanningOpen] = useState<boolean>(false);
  const [vacationCancelOpen, setVacationCancelOpen] = useState<boolean>(false);
  const [vacationToCancel, setVacationToCancel] = useState<IPrebookVacation | undefined>(undefined);

  const watchedFrLanguage = watch('frLanguage');
  const watchedNlLanguage = watch('nlLanguage');

  //fields used for conditional rendering
  const matchingWithRadius = watch('matching_with_radius');
  const noEndDate = watch('no_end_date');

  const handleToggleVacationPlanning = () => {
    setVacationPlanningOpen(currentValue => !currentValue);
  };

  const handleToggleVacationCancel = (vacation: IPrebookVacation) => {
    setVacationToCancel(vacation);
    setVacationCancelOpen(true);
  };

  const handleCloseVacationCancel = () => {
    setVacationCancelOpen(false);
  };

  const loadPrebookVacations = useCallback(() => {
    if (!prebookId || !userId) {
      return;
    }

    getAllVacationsOfPrebook({
      payload: {
        prebookId,
        traderId: userId
      }
    });
  }, [getAllVacationsOfPrebook, prebookId, userId]);

  const handlePrebookSubmit = useCallback(
    (values: any) => {
      const locales: string[] = [];

      if (values.frLanguage) {
        locales.push('fr');
      }
      if (values.nlLanguage) {
        locales.push('nl');
      }

      const newValues = {
        ...values,
        email: typeof values.email === 'string' ? values.email : values.email[0],
        end_date_pause: noEndDate ? null : values.end_date_pause && values.end_date_pause,
        accepted_postcodes:
          typeof values.accepted_postcodes === 'string'
            ? values.accepted_postcodes.split(',').map(String)
            : values.accepted_postcodes,
        rejected_postcodes:
          typeof values.rejected_postcodes === 'string'
            ? values.rejected_postcodes.split(',').map(String)
            : values.rejected_postcodes
      };

      const payload = { ...newValues, prebook_id: selectedPreBook.id, locale: locales };

      putTraderPrebooksData(payload, intl.messages['prebook.form.update.successful']);
    },
    [intl.messages, noEndDate, putTraderPrebooksData, selectedPreBook.id]
  );

  useEffect(() => {
    loadPrebookVacations();
  }, [loadPrebookVacations]);

  return (
    <DivPrebook>
      <Form onSubmit={handleSubmit(handlePrebookSubmit)}>
        {/* FREQUENCY */}
        <DivHeaderSection>
          <LabelTitle>
            <FormattedMessage
              tagName="span"
              id="prebook.page.frequency"
              defaultMessage="Frequency"
            />
          </LabelTitle>
        </DivHeaderSection>
        <JaimyFlex padding="0 0 3rem 0" flexDirection={width > 768 ? 'row' : 'column'}>
          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.page.form.frequency"
                defaultMessage="Frequency"
              />
            </label>
            <Controller
              control={control}
              name="frequency"
              defaultValue={selectedPreBook.frequency}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  color="secondary"
                  defaultValue={selectedPreBook.frequency}
                  fullWidth
                  required
                >
                  {PREBOOK_FREQUENCY.map(option => {
                    return (
                      <MenuItem
                        data-cy-menuitem={option.name}
                        value={option.name}
                        key={option.name}
                      >
                        <FormattedMessage
                          tagName="span"
                          id={option.id}
                          defaultMessage={option.defaultMessage}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </JaimyFormControl>
          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.form.max_quantity"
                defaultMessage="Frequency Quantity"
              />
            </label>
            <Controller
              control={control}
              name="max_quantity"
              defaultValue={selectedPreBook.max_quantity}
              rules={{ required: true, min: 1 }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  required
                  fullWidth
                  type="number"
                  error={!!errors.max_quantity}
                  min={1}
                />
              )}
            />
          </JaimyFormControl>
        </JaimyFlex>
        {/* NOTIFICATIONS */}
        <DivHeaderSection>
          <LabelTitle>
            <FormattedMessage
              tagName="span"
              id="prebook.page.notifications"
              defaultMessage="Notifications"
            />
          </LabelTitle>
        </DivHeaderSection>
        <JaimyFlex padding="0 0 3rem 0" flexDirection={width > 768 ? 'row' : 'column'}>
          <JaimyFormControl>
            <label>
              <FormattedMessage tagName="span" id="prebook.form.email" defaultMessage="email" />
            </label>
            <Controller
              control={control}
              name="email"
              defaultValue={selectedPreBook.email?.toString()}
              render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value} type="email" fullWidth />
              )}
            />
          </JaimyFormControl>
          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.form.email_choice"
                defaultMessage="Frequency"
              />
            </label>
            <Controller
              control={control}
              name="email_choice"
              defaultValue={selectedPreBook.email_choice}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  color="secondary"
                  defaultValue={selectedPreBook.email_choice}
                  fullWidth
                >
                  {EMAIL_NOTIFICATION.map(option => {
                    return (
                      <MenuItem
                        data-cy-menuitem={option.name}
                        value={option.name}
                        key={option.name}
                      >
                        <FormattedMessage
                          tagName="span"
                          id={option.id}
                          defaultMessage={option.defaultMessage}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </JaimyFormControl>
        </JaimyFlex>
        {/* ZONES */}
        <DivHeaderSection>
          <LabelTitle>
            <FormattedMessage tagName="span" id="prebook.page.zone" defaultMessage="Zone" />
          </LabelTitle>
        </DivHeaderSection>
        <JaimyFlex
          padding="0 0 3rem 0"
          flexDirection={width > 768 ? 'row' : 'column'}
          flexWrap="wrap"
        >
          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.form.match_with_radius"
                defaultMessage="Match with radius"
              />
            </label>
            <Controller
              control={control}
              name="matching_with_radius"
              render={({ field: { onChange, value } }) => (
                <JaimyCheckbox
                  defaultChecked={selectedPreBook.matching_with_radius}
                  onChange={onChange}
                  value={value}
                  id="matching_with_radius"
                  type="checkbox"
                />
              )}
            />
          </JaimyFormControl>
          <ComponentIsVisible when={!!matchingWithRadius}>
            <JaimyFormControl>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="prebook.form.postcode"
                  defaultMessage="postcode"
                />
              </label>
              <Controller
                control={control}
                name="zip"
                defaultValue={selectedPreBook.zip}
                render={({ field: { onChange, value } }) => (
                  <TextField onChange={onChange} value={value} type="number" fullWidth />
                )}
              />
            </JaimyFormControl>
            <JaimyFormControl>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="prebook.form.service_radius"
                  defaultMessage="Service Radius"
                />
              </label>
              <Controller
                control={control}
                name="radius"
                defaultValue={selectedPreBook.radius}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    color="secondary"
                    displayEmpty
                    fullWidth
                  >
                    {SERVICE_RADIUS.map(option => {
                      return (
                        <MenuItem data-cy-menuitem={option} value={option} key={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </JaimyFormControl>
          </ComponentIsVisible>
          <ComponentIsVisible when={!matchingWithRadius}>
            <JaimyFormControl>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="prebook.form.accepted_postcodes"
                  defaultMessage="Accepted Postcodes"
                />
              </label>
              <TextArea
                defaultValue={selectedPreBook.accepted_postcodes}
                {...register('accepted_postcodes')}
                aria-multiline
                content-editable
                id="textarea"
                margin="1rem auto"
              />
            </JaimyFormControl>
          </ComponentIsVisible>

          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.form.rejected_postcodes"
                defaultMessage="Rejected Postcodes"
              />
            </label>
            <TextArea
              defaultValue={selectedPreBook.rejected_postcodes}
              {...register('rejected_postcodes')}
              aria-multiline
              content-editable
              id="textarea"
              margin="1rem 0"
            />
          </JaimyFormControl>
          <JaimyFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="prebook.form.matching_languages"
                defaultMessage="Matching Languages"
              />
            </label>
            <JaimyFormControl display="flex">
              <JaimyFlex>
                <FormControlLabel
                  control={
                    <Controller
                      name="frLanguage"
                      control={control}
                      rules={{
                        required: !watchedFrLanguage && !watchedNlLanguage
                      }}
                      render={({ field }) => (
                        <Checkbox color="error" defaultChecked={field.value} {...field} />
                      )}
                    />
                  }
                  label={<JaimyLabel>FR</JaimyLabel>}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="nlLanguage"
                      control={control}
                      rules={{
                        required: !watchedFrLanguage && !watchedNlLanguage
                      }}
                      render={({ field }) => (
                        <Checkbox color="error" defaultChecked={field.value} {...field} />
                      )}
                    />
                  }
                  label={<JaimyLabel>NL</JaimyLabel>}
                />
              </JaimyFlex>
              <ComponentIsVisible when={!!errors.frLanguage && !!errors.nlLanguage}>
                <ComponentErrorMessage message="calendar.manage.atLeastOneRequired" />
              </ComponentIsVisible>
            </JaimyFormControl>
          </JaimyFormControl>
        </JaimyFlex>
        {/* STATUS */}
        <DivHeaderSection>
          <LabelTitle>
            <FormattedMessage tagName="span" id="prebook.page.status" defaultMessage="Status" />
          </LabelTitle>
        </DivHeaderSection>
        <JaimyFlex
          padding="0 0 3rem 0"
          flexDirection={width > 768 ? 'row' : 'column'}
          flexWrap="wrap"
          columnGap="1rem"
        >
          <JaimyFlex
            backgroundColor="#F3F4F6"
            alignItems="center"
            justifyContent="center"
            maxWidth="200px"
          >
            <JaimyFormControl
              display="flex"
              margin="0"
              style={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <label>
                <FormattedMessage
                  tagName="span"
                  id={`prebook.form.status.${selectedPreBook.pause ? 'paused' : 'active'}`}
                  defaultMessage="Status"
                />
              </label>
              <Switch
                checked={selectedPreBook.pause}
                color="default"
                style={{ color: selectedPreBook.pause ? 'red' : 'green', cursor: 'default' }}
              />
            </JaimyFormControl>
          </JaimyFlex>
          <JaimyFlex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            margin="10px 0px"
          >
            <JaimyButton.Base type="button" height="3rem" onClick={handleToggleVacationPlanning}>
              <FormattedMessage id="calendar.vacation" />
            </JaimyButton.Base>
            <ComponentIsVisible when={vacations.length > 0}>
              {vacations.map(vacation => (
                <JaimyFlex
                  alignItems="center"
                  margin=".75rem 0 0"
                  gap=".5rem"
                  justifyContent="flex-end"
                  key={vacation.id}
                >
                  <JaimyText fontWeight={600}>
                    <FormattedMessage id="calendar.vacation.pauseMessage" /> {vacation.startDate}{' '}
                    <FormattedMessage id="calendar.vacation.pauseTo" /> {vacation.endDate}
                  </JaimyText>
                  <JaimyButton.Base
                    backgroundColor={theme.colors.secondary.light}
                    type="button"
                    onClick={() => handleToggleVacationCancel(vacation)}
                  >
                    <FormattedMessage id="cancel" />
                  </JaimyButton.Base>
                </JaimyFlex>
              ))}
            </ComponentIsVisible>
          </JaimyFlex>
        </JaimyFlex>
        <DivActions>
          <ButtonPrimary
            type="submit"
            disabled={preBooksIsLoading}
            style={{ width: width < 768 ? '30%' : '60%' }}
          >
            {!preBooksIsLoading && (
              <FormattedMessage tagName="span" id="profile.form.save" defaultMessage="Save" />
            )}
            {preBooksIsLoading && <CircularProgress size={20} color="inherit" disableShrink />}
          </ButtonPrimary>
        </DivActions>
      </Form>
      <Snackbar />

      <VacationPlanningDialog
        handleClose={handleToggleVacationPlanning}
        open={vacationPlanningOpen}
        openedFromPrebook
        prebookId={prebookId}
      />

      <VacationCancelDialog
        handleClose={handleCloseVacationCancel}
        open={vacationCancelOpen}
        vacation={vacationToCancel}
        prebookId={prebookId}
      />
    </DivPrebook>
  );
};
const mapStateToProps = ({
  user: { user },
  prebooks: { prebooks, isLoading, error, monthlyData },
  vacation: { prebookVacations }
}: {
  user: FixMeLater;
  prebooks: FixMeLater;
  vacation: IVacationState;
}) => ({
  locale: user.locale,
  userId: user.id,
  traderPrebooks: prebooks,
  preBooksIsLoading: isLoading,
  preBooksMonthlyData: monthlyData,
  preBooksError: error,
  vacations: prebookVacations
});

const mapDispatchToProps = (dispatch: any) => ({
  putTraderPrebooksData: (payload: any, snackbarText: any) =>
    dispatch(modifyTraderPrebook(payload, snackbarText)),
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) =>
    dispatch(getAllVacationsOfPrebook(action))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrebookForm);

// styles

const Form = styled.form`
  background-color: #fff;
  border: 1px solid #d8dae0;
  border-radius: 4px;
  padding: 3rem;
  @media (max-width: 870px) {
    padding: 1rem;
    margin-bottom: 200px;
  }
`;

const DivHeaderSection = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #e1e1e1;
`;

const LabelTitle = styled.label`
  color: #c30144;
  font-family: BelfiusNormal, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
`;

const DivPrebook = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem 1.2rem;
`;
const DivActions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  > button {
    width: 100%;
    justify-content: center;

    @media (min-width: 1200px) {
      max-width: 368px;
    }
  }
`;
