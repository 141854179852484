const { CATEGORIES_FAILED, CATEGORIES_STARTED, CATEGORIES_SUCCESS } = require('./actionTypes');

const initialState = {
  loading: false,
  tree: {},
  error: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIES_STARTED:
      return {
        ...state,
        loading: true
      };
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tree: { ...action.payload }
      };
    case CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: Boolean(action.payload.error)
      };
    default:
      return state;
  }
};
