import { IntlProvider } from 'react-intl';
import React from 'react';
import { connect } from 'react-redux';
import getLocale from '../getLocale';
import translations from '../messages/translations';

const ReactIntlProvider = ({ user, children }) => {
  const locale = user && user.locale ? user.locale : getLocale();

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = ({ user: { user } }) => ({ user });

export default connect(mapStateToProps, null)(ReactIntlProvider);
