import { HIDE_SNACK, SHOW_SNACK } from '../alerts/actionTypes';
import { SERVICE_FILTER_UPDATE, SERVICE_FILTER_UPDATE_NO_FETCH } from '../services/actionTypes';
import {
  USER_DATA_ERROR,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_HAS_UPDATED_PROFILE,
  USER_LOCALE_CHANGE,
  USER_LOG_IN,
  USER_LOG_IN_ERROR,
  USER_LOG_IN_SUCCESS,
  USER_LOG_IN_UNAUTHORIZED,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_ERROR,
  USER_UPDATE_PROFILE_SUCCESS,
  TRADER_INVOICES,
  TRADER_INVOICES_ERROR,
  TRADER_INVOICES_SUCCESS
} from './actionTypes';

import AccountService from '../../../lib/services/accountService';
import AuthService from '../../../lib/services/authService';
import TraderService from '../../../lib/services/traderService';
import { getTraderId } from '../../../util/serviceHelpers';
import jwtDecode from 'jwt-decode';
import { setToken } from '../../../util/tokenProvider';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { cookieDomain, getJaimyCookie, getQueryVariable } from '../../../logging/analytics';

const accountService = new AccountService();
const authService = new AuthService();
const traderService = new TraderService();

export const requestUser = id => {
  return async dispatch => {
    try {
      dispatch({ type: USER_DATA_REQUEST });
      const user = await accountService.user(id);
      dispatch({ type: USER_DATA_SUCCESS, payload: { user } });
    } catch (error) {
      dispatch({ type: USER_DATA_ERROR, payload: error });
    }
  };
};

/**
 * 1. Login through AAA server,
 * 2. call tokenProvider.setToken to set initial accessToken
 * 3. request user data in API
 * 4. store user, permissions(from acessToken) inside redux

 * @param {object} payload
 */
export const login = payload => {
  return async dispatch => {
    try {
      dispatch({
        type: USER_LOG_IN,
        payload
      });

      // Cal login from authService class and decoding jwt token to access user data + check permissions
      const accessToken = await authService.login(payload);
      const { permissions, trader_id } = jwtDecode(accessToken);
      setToken(accessToken);
      const user = await accountService.user(trader_id);

      //check if jaimyCookie exists, if yes update values if not create one
      const jaimyCookie = Cookies.get('jaimyCookie') && JSON.parse(Cookies.get('jaimyCookie'));
      if (jaimyCookie) {
        const jaimyCookieUpdate = {
          ...jaimyCookie,
          trader_id: trader_id || jaimyCookie.trader_id,
          utm_source: jaimyCookie.utm_source
            ? jaimyCookie.utm_source
            : getQueryVariable('utm_source', window.location.search),
          utm_campaign:
            getQueryVariable('utm_campaign', window.location.search) || jaimyCookie.utm_campaign,
          utm_medium:
            getQueryVariable('utm_medium', window.location.search) || jaimyCookie.utm_medium
        };
        Cookies.set('jaimyCookie', JSON.stringify(jaimyCookieUpdate), {
          expires: 180,
          domain: cookieDomain || '.jaimy.be'
        });
      } else if (!jaimyCookie) {
        if (process.env.NODE_ENV === 'development') {
          Cookies.set('jaimyCookie', JSON.stringify(getJaimyCookie(user.id)), {
            expires: 180
          });
        } else {
          Cookies.set('jaimyCookie', JSON.stringify(getJaimyCookie(user.id)), {
            expires: 180,
            domain: cookieDomain || '.jaimy.be'
          });
        }
      }

      const updatedJaimyCookie =
        Cookies.get('jaimyCookie') && JSON.parse(Cookies.get('jaimyCookie'));

      //if uuid of jaimyCookie does not match the one from DB, replace it in the cookie
      const res = await accountService.checkJaimyUuid(
        trader_id,
        updatedJaimyCookie.uuid,
        accessToken
      );

      if (res.uuid && res.uuid !== updatedJaimyCookie.uuid) {
        const updateUuidFromDb = { ...updatedJaimyCookie, uuid: res.uuid };
        Cookies.set('jaimyCookie', JSON.stringify(updateUuidFromDb), {
          expires: 180,
          domain: cookieDomain || '.jaimy.be'
        });
      }

      // update state that login is successful
      dispatch({
        type: USER_LOG_IN_SUCCESS,
        payload: { user, permissions }
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'Trader Logged-in',
          user_id: user.id,
          email: user.email
        },
        dataLayerName: 'ProDataLayer'
      });

      // set state for service request (jobs) filters
      dispatch({
        type: SERVICE_FILTER_UPDATE_NO_FETCH,
        payload: {
          radius: user.service_radius,
          category_list: user.treetrades,
          languages: user.matching_locales ? user.matching_locales : [user.locale],
          service_view: 'simplified',
          order_direction: '',
          pagination: {
            total: 0,
            perPage: 12,
            page: 1
          }
        }
      });

      return user;
    } catch (error) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Login error',
          errorMessage: error.message,
          stack: error.stack,
          errorjson: JSON.stringify(error),
          error
        },
        dataLayerName: 'ProDataLayer'
      });
      dispatch({
        type: USER_LOG_IN_ERROR,
        payload: error.message
      });
      throw error;
    }
  };
};

export function hasUpdatedUserProfile() {
  return dispatch => {
    dispatch({
      type: USER_HAS_UPDATED_PROFILE
    });
  };
}

const requestUserSuccess = payload => ({
  type: USER_DATA_SUCCESS,
  payload
});

export function setUser(payload) {
  return (dispatch, getState) => {
    const { user } = getState().user;
    dispatch(requestUserSuccess({ user: { ...user, ...payload } }));
    dispatch({
      type: SERVICE_FILTER_UPDATE_NO_FETCH,
      payload: {
        radius: parseInt(payload.service_radius, 10),
        category_list: payload.treetrades,
        languages: payload.matching_locales ? payload.matching_locales : [payload.locale],
        service_view: 'simplified',
        order_direction: '',
        pagination: {
          total: 0,
          perPage: 12,
          page: 1
        }
      }
    });
  };
}

export function getUser() {
  return async (dispatch, getState) => {
    const { user } = getState().user;
    dispatch({
      type: USER_DATA_REQUEST
    });
    try {
      const id = getTraderId();
      const data = await accountService.user(id);
      dispatch(requestUserSuccess({ user: { ...user, ...data } }));
    } catch (error) {
      dispatch({
        type: USER_DATA_ERROR,
        payload: {
          error: error.message
        }
      });
    }
  };
}

export function updateUserProfile(payload, snack) {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_UPDATE_PROFILE
    });
    try {
      const { user } = getState().user;
      const data = await traderService.update(payload);

      TagManager.dataLayer({
        dataLayer: {
          event: 'Trader Profile Saved'
        },
        dataLayerName: 'ProDataLayer'
      });
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: { user: { ...user, ...data } }
      });
      if (snack) {
        dispatch({
          type: SHOW_SNACK,
          payload: { snackText: snack }
        });
        setTimeout(() => {
          dispatch({
            type: HIDE_SNACK
          });
        }, 3000);
      }

      dispatch({
        type: SERVICE_FILTER_UPDATE,
        payload: {
          radius: parseInt(data.service_radius, 10),
          category_list: data.treetrades,
          languages: data.matching_locales ? data.matching_locales : [data.locale],
          service_view: 'simplified',
          order_direction: ''
        }
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_PROFILE_ERROR,
        payload: {
          error: error.message
        }
      });
    }
  };
}

export function setLanguage(payload) {
  return async (dispatch, getState) => {
    localStorage.setItem('locale', payload.locale);
    dispatch({
      type: USER_UPDATE_PROFILE
    });

    const { user } = getState().user;
    // if user is logged in, call the profile endpoint to update their profile with the locale
    if (user.id) {
      try {
        const data = await traderService.update(payload);
        // TODO bad behavior - triggers rerender even if data is the same
        // happens in other places - immutable would make sense here
        dispatch({
          type: USER_UPDATE_PROFILE_SUCCESS,
          payload: { user: { ...user, ...data } }
        });
      } catch (error) {
        dispatch({
          type: USER_UPDATE_PROFILE_ERROR,
          payload: { error: { language: ['Error occured when changing language.'] } }
        });
      }
    } else {
      // Change redux state when not logged in
      dispatch({
        type: USER_LOCALE_CHANGE,
        payload: { user: payload }
      });
    }
  };
}

export function getTraderInvoices() {
  return async dispatch => {
    dispatch({
      type: TRADER_INVOICES
    });
    try {
      const data = await traderService.getInvoices();
      dispatch({
        type: TRADER_INVOICES_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: TRADER_INVOICES_ERROR,
        payload: error.message
      });
    }
  };
}
