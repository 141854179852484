import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import getLocale from '../../../getLocale';
import { ROUTES } from '../../../routes';
import theme from '../../../theme';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import { FixMeLater } from '../../../types/FixMeLater';
import { IInterventionPartnership } from '../../../types/User';
import PhoneIcon from '@material-ui/icons/Phone';
import { ComponentIsVisible } from '../../utils/isVisible';
import { CalendarToday } from '@material-ui/icons';

interface ICalendarCardProps {
  calendar: IInterventionPartnership;
  userId: number;
}

const CalendarCard = ({ calendar }: ICalendarCardProps) => {
  const locale = getLocale();

  const calendarTreetradeCategory = useMemo(() => {
    if (calendar.treetrades) {
      const calendarTreetrade = calendar.treetrades.find(treetrade => treetrade.id);

      if (!calendarTreetrade) {
        return 'No Category Name';
      }
      const categoryName =
        locale === 'fr'
          ? calendarTreetrade.category_fr
          : calendarTreetrade.category_nl || calendarTreetrade.category;

      return categoryName;
    }
  }, [calendar.treetrades, locale]);

  const isInsuranceServicesCategory = useMemo(() => {
    if (!calendar.treetrades) return
    return calendar.treetrades.some(treetrade => treetrade.id === 736);
  }, [calendar.treetrades])

  return (
    <JaimyFlex
      flexDirection="column"
      justifyContent="space-between"
      width="300px"
      height="250px"
      gap="16px"
      backgroundColor={theme.colors.white}
      padding="1rem"
      borderRadius={0.5}
      boxShadow={theme.shadow}
    >
      <JaimyFlex alignItems='center'>
        <ComponentIsVisible when={!!isInsuranceServicesCategory}>
          <PhoneIcon htmlColor={theme.colors.primary.base} fontSize="small" style={{ marginRight: "5px" }} />
        </ComponentIsVisible>
        <ComponentIsVisible when={!isInsuranceServicesCategory}>
          <CalendarToday htmlColor={theme.colors.primary.base} fontSize="small" style={{ marginRight: "5px" }} />
        </ComponentIsVisible>
        <JaimyHeading.H2 color={theme.colors.primary.base}>{calendar.name}</JaimyHeading.H2>
      </JaimyFlex>
      <JaimyText>
        <FormattedMessage id="calendar.categories" />
        {`: ${calendarTreetradeCategory}`}
      </JaimyText>
      <CalendarButton
        to={{
          pathname: ROUTES.CALENDAR_ID.handler(calendar.id),
          state: { calendarName: calendar.name, isInsuranceServicesCategory }
        }}
      >
        <FormattedMessage id="calendar.manage" />
      </CalendarButton>
    </JaimyFlex>
  );
};

const mapStateToProps = ({
  user: {
    user: { id }
  }
}: {
  user: FixMeLater;
}) => ({
  userId: id
});

export default connect(mapStateToProps)(CalendarCard);

// Styles

const CalendarButton = styled(Link)`
  text-decoration: none;
  border: none;
  background-color: ${theme.colors.secondary.base};
  font-size: 16px;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;

  text-align: center;

  :hover {
    background-color: ${theme.colors.secondary.light};
  }
`;
