import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledGreenBanner = styled.div`
  background-color: #d9e9d3;
  border-radius: 8px;
  width: 60%;
  padding: 1rem;
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1rem;
  color: #32546d;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GreenBanner = ({ content }) => {
  return (
    <StyledGreenBanner>
      <FormattedMessage id={content} />
    </StyledGreenBanner>
  );
};

export default GreenBanner;
