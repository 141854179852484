export const VERIFIED_STATUSES = {
  checked: 'checked'
};

export const TEAMLEADER_REGISTER_LOCALSTORAGE = 'NEW_TEAMLEADER_REGISTRATION';

export const VAT_VALIDATION_ERRORS = [
  'not_checked',
  'not_valid',
  'belgian_vat_number_does_not_exist',
  'company_closed'
];
export const CHECKOUT_ERRORS = [
  'banned-or-archived',
  'service-requests-not-found',
  'invalid-vat',
  'checked--price-too-high',
  'checked--too-many-jobs',
  'no-credit-remaining'
];
