import React, { useState } from 'react';

import { ErrorMessage } from '../../components/common/Error';
import { FuncPropType } from '../../util/PropTypeValues';
import UtilService from '../../lib/services/utilService';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { ButtonDarkRed } from '../../components/common/Button';

const InputText = styled.input`
  color: #32546d;
  border: 1px solid #d8dae0;
  font-size: 18px;
  padding-left: 0.8rem;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  background-color: #f3f4f6;
  outline: none;
  height: 40px;
  &:required,
  &:focus,
  &:invalid {
    border-color: #c30b3064;
  }
  ${props => !props.valid && 'border-color: #c30b30;'}
`;
const StepCompanyAddress = ({ onClickContinue }) => {
  const [street, setStreet] = useState({ value: '', touched: false });
  const [number, setNumber] = useState({ value: '', touched: false });
  const [postcode, setPostcode] = useState({ value: '', touched: false });
  const [isZipValid, setIsZipValid] = useState(true);
  const [validationLoading, setValidationLoading] = useState(false);
  const [city, setCity] = useState({ value: '', touched: false });
  const isStreetValid = !!street.value && street.value !== '';
  const isNumberValid = !!number.value && number.value !== '';
  const isPostcodeValid = !!postcode.value && postcode.value !== '';
  const isCityValid = !!city.value && city.value !== '';
  const intl = useIntl();
  const utilService = new UtilService();
  const validateAndContinue = async () => {
    setValidationLoading(true);
    try {
      const valid = await utilService.validatePostcode(isPostcodeValid ? postcode.value : 0);
      if (valid && isStreetValid && isNumberValid && isPostcodeValid && isCityValid) {
        await onClickContinue({
          address: `${street.value} ${number.value}`,
          zip: postcode.value,
          city: city.value
        });
      } else {
        setIsZipValid(valid);
        setStreet({ value: street.value, touched: true });
        setNumber({ value: number.value, touched: true });
        setPostcode({ value: postcode.value, touched: true });
        setCity({ value: city.value, touched: true });
      }
    } catch (error) {
      console.error(error);
    }
    setValidationLoading(false);
  };

  return (
    <>
      <h2 data-cy="page-details">{intl.messages['register.step.address.title']}</h2>
      <span className="subtitle">{intl.messages['register.step.address.street']}</span>
      <InputText
        type="text"
        name="street"
        data-cy="street"
        value={street.value}
        onChange={e => setStreet({ value: e.target.value, touched: true })}
        valid={isStreetValid || !street.touched}
      />
      <span className="subtitle">{intl.messages['register.step.address.number']}</span>
      <InputText
        type="text"
        name="number"
        data-cy="number"
        value={number.value}
        onChange={e => setNumber({ value: e.target.value, touched: true })}
        valid={isNumberValid || !number.touched}
      />
      <span className="subtitle">{intl.messages['register.step.address.postcode']}</span>
      {!isZipValid && (
        <ErrorMessage>{intl.messages['register.step.address.error.postcode']}</ErrorMessage>
      )}
      <InputText
        type="number"
        name="postcode"
        data-cy="postcode"
        value={postcode.value}
        onChange={e => {
          setPostcode({ value: e.target.value, touched: true });
          setIsZipValid(true);
        }}
        valid={isZipValid}
      />
      <span className="subtitle">{intl.messages['register.step.address.town']}</span>
      <InputText
        type="text"
        name="city"
        data-cy="city"
        value={city.value}
        onChange={e => setCity({ value: e.target.value, touched: true })}
        valid={isCityValid || !city.touched}
      />
      <ButtonDarkRed onClick={validateAndContinue} disabled={validationLoading} data-cy="submit">
        {intl.messages['register.steps.continue']}
      </ButtonDarkRed>
    </>
  );
};

StepCompanyAddress.defaultProps = { onClickContinue: {} };
StepCompanyAddress.propTypes = { onClickContinue: FuncPropType };

export default StepCompanyAddress;
