import styled from 'styled-components';

interface IJaimyContainerProps {
  margin?: string;
  maxWidth?: string;
  padding?: string;
  paddingLg?: string;
  paddingMd?: string;
  paddingSm?: string;
  width?: string;
}

const JaimyContainer = styled.div<IJaimyContainerProps>`
  margin: ${props => (props.margin ? props.margin : ' 0 auto')};
  max-width: ${props => props.maxWidth && props.maxWidth};
  
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
  padding: 1.2rem;

  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
 
`;

export default JaimyContainer;
