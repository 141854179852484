import { API_URL, AUTH_URL } from '../../util/url';

import { getToken } from '../../util/tokenProvider';
/* eslint-disable class-methods-use-this */
import { handleResult } from '../../util/serviceHelpers';
import userParser from '../../util/userParser';

class AccountService {
  async user(id) {
    const result = await fetch(`${API_URL}/api/traders/v1/traders/${id}`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${await getToken()}`
      }
    });
    const data = await handleResult(result);
    const user = userParser(data);
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  }
  /**
   * Check jaimy_uuid of the user
   */
  async checkJaimyUuid(trader_id, cookieUuid, token) {
    const result = await fetch(
      `${API_URL}/api/traders/v1/traders/${trader_id}/uuid/?uuid=${cookieUuid}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`
        }
      }
    );
    const data = handleResult(result);
    return data;
  }
  /**
   * Send email to reset password
   */
  forgotPassword(email) {
    const requestBody = {
      email,
      redirect_url: '/forgotpassword'
    };

    return fetch(`${AUTH_URL}/auth/password`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }

  /**
   * Reset password
   */
  resetPassword({ resetPasswordToken, password, passwordConfirm, userData }) {
    const { token, client, uid } = userData;
    if (password !== passwordConfirm) {
      return new Promise(resolutionFunc => {
        resolutionFunc({
          success: false,
          error: 'PASSWORDS_DO_NOT_MATCH'
        });
      });
    }
    const requestBody = {
      reset_password_token: resetPasswordToken,
      password,
      password_confirmation: passwordConfirm
    };
    return fetch(`${AUTH_URL}/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'access-token': token,
        client,
        uid
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }

  /**
   * Set password
   */
  async setPassword(password, token, userData) {
    const requestBody = {
      reset_password_token: token,
      password,
      password_confirmation: password
    };
    const tokens = userData
      ? {
          'access-token': userData.token,
          client: userData.client,
          uid: userData.uid
        }
      : { Authorization: `Bearer ${await getToken()}` };
    return fetch(`${AUTH_URL}/auth/password`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        ...tokens
      },
      body: JSON.stringify(requestBody)
    }).then(handleResult);
  }
}

export default AccountService;
