import 'flatpickr/dist/flatpickr.css';
import '../../custom-flatpickr.css';
import './update.css';

import { Checkbox, Dialog, FormControlLabel, TextField } from '@material-ui/core';

import Flatpickr from 'react-flatpickr';

import theme from '../../../../theme';
import JaimyButton from '../../../../theme/components/Button';
import JaimyLabel from '../../../../theme/components/form/Label';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';

import { ErrorMessage } from '@hookform/error-message';
import { CircularProgress, DialogContent } from '@mui/material';
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  getAppointments,
  IUploadAppointmentRequestAction,
  updateAppointment
} from '../../../../store/reducers/calendar/actionCreators';
import {
  IUpdateAppointmentFormData,
  IUpdateAppointmentPayloadData
} from '../../../../types/Appointment';
import { IEvent, IRange } from '../../../../types/Calendar';
import { FixMeLater } from '../../../../types/FixMeLater';
import { ComponentErrorMessage } from '../../../utils/ErrorMessage';
import { ComponentIsVisible } from '../../../utils/isVisible';

interface IUpdateAppointmentDialogProps {
  open: boolean;
  handleClose: () => void;
  appointment: IEvent;
  firstDayOfSelectedMonth: Date;
  updateAppointment: (action: IUploadAppointmentRequestAction) => AnyAction;
  updateAppointmentLoading: boolean;
  getAppointments: (payload: { id: number; range: IRange }) => AnyAction;
}

const UpdateAppointmentDialog = ({
  open,
  handleClose,
  appointment,
  firstDayOfSelectedMonth,
  updateAppointment,
  updateAppointmentLoading,
  getAppointments
}: IUpdateAppointmentDialogProps) => {
  const { id } = useParams<{ id: string }>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<IUpdateAppointmentFormData>({
    defaultValues: {
      allDay: false,
      description: appointment.description,
      title: appointment.title,
      endDate: appointment.endFormatted,
      startDate: appointment.startFormatted,
      email: appointment.email,
      phone: appointment.phone,
      address: {
        label: appointment.address,
        value: {
          description: appointment.address
        }
      }
    },
    mode: 'onBlur',
    criteriaMode: 'all'
  });

  const watchedAllDay = watch('allDay');

  const jaimyAppointment = useMemo(() => {
    const isJaimyAppointment = appointment.status !== 'block_event';

    return isJaimyAppointment;
  }, [appointment.status]);

  const onSubmit = useCallback(
    (data: IUpdateAppointmentFormData) => {
      if (!id || !appointment.id) {
        return;
      }

      let startDateSelected = data.startDate;
      let endDateSelected = data.endDate;

      if (data.allDay) {
        startDateSelected = startOfDay(data.allDayDate as Date).toISOString();
        endDateSelected = endOfDay(data.allDayDate as Date).toISOString();
      }

      let payloadFormatted: IUpdateAppointmentPayloadData = {
        allDay: data.allDay,
        appointmentId: appointment.id,
        endDate: endDateSelected,
        startDate: startDateSelected,
        interventionPartnershipId: id,
        description: data.description,
        email: data.email,
        phone: data.phone,
        address: data.address?.label
      };

      if (jaimyAppointment) {
        payloadFormatted = {
          appointmentId: appointment.id,
          startDate: startDateSelected,
          interventionPartnershipId: id
        };
      }

      updateAppointment({
        payload: payloadFormatted,
        functions: {
          reloadAppointments: () =>
            getAppointments({
              id: parseInt(id, 10),
              range: {
                end: format(endOfWeek(endOfMonth(firstDayOfSelectedMonth)), 'yyyy/MM/dd'),
                start: format(startOfWeek(firstDayOfSelectedMonth), 'yyyy/MM/dd')
              }
            }),
          errorMessage: (message: string) => {
            setErrorMessage(message);
          },
          closeModal: () => {
            setErrorMessage('');
            handleClose();
          }
        }
      });
    },
    [
      id,
      appointment.id,
      jaimyAppointment,
      updateAppointment,
      handleClose,
      getAppointments,
      firstDayOfSelectedMonth
    ]
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="update-appointment"
      PaperProps={{
        className: 'customMuiDialogPaper'
      }}
      scroll="paper"
    >
      <DialogContent>
        <JaimyFlex flexDirection="column" width="100%" margin="0 auto" padding="2rem" gap="1rem">
          <JaimyHeading.H3 color={theme.colors.secondary.base} margin="0 auto">
            <FormattedMessage id="calendar.updateAppointment" />
          </JaimyHeading.H3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <JaimyFlex flexDirection="column" gap="1rem">
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: 'missing-properties.message.required' }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <JaimyLabel htmlFor="title">
                        <FormattedMessage id="calendar.title" />
                      </JaimyLabel>
                      <TextField
                        required
                        disabled={jaimyAppointment}
                        defaultValue={value}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        className="disabledField"
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="title"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>
              <JaimyFlex px={2} flexDirection="row" gap="1rem">
                <JaimyFlex>
                  <FormControlLabel
                    control={
                      <Controller
                        name="allDay"
                        control={control}
                        render={({ field }) => <Checkbox disabled={jaimyAppointment} {...field} />}
                      />
                    }
                    label={<FormattedMessage id="calendar.date.allDay" />}
                  />
                </JaimyFlex>
                <ComponentIsVisible when={watchedAllDay}>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="allDayDate"
                      control={control}
                      rules={{ required: watchedAllDay && 'missing-properties.message.required' }}
                      render={({ field: { onChange } }) => (
                        <>
                          <JaimyLabel htmlFor="allDayDate">
                            <FormattedMessage id="calendar.date" />
                          </JaimyLabel>
                          <Flatpickr
                            onChange={dates => onChange(dates[0])}
                            disabled={!watchedAllDay}
                            options={{
                              time_24hr: true,
                              static: true,
                              dateFormat: 'd-m-Y',
                              minDate: new Date().toISOString()
                            }}
                            aria-label="allDayDate"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="allDayDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
              </JaimyFlex>
              <ComponentIsVisible when={!watchedAllDay}>
                <JaimyFlex px={2} flexDirection="row" gap="1rem">
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'missing-properties.message.required' }}
                      render={({ field: { onChange, value, name } }) => (
                        <>
                          <JaimyLabel htmlFor="start-date">
                            <FormattedMessage id="calendar.date" />
                          </JaimyLabel>
                          <Flatpickr
                            name={name}
                            defaultValue={value}
                            onChange={dates => onChange(dates[0].toISOString())}
                            required
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              static: true,
                              dateFormat: 'd-m-Y H:i',
                              minDate: new Date().toISOString()
                            }}
                            aria-label="start-date"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="startDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: 'missing-properties.message.required' }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="endDate">
                            <FormattedMessage id="calendar.date.end" />
                          </JaimyLabel>
                          <Flatpickr
                            defaultValue={value}
                            disabled={jaimyAppointment}
                            onChange={dates => onChange(dates[0].toISOString())}
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              static: true,
                              minuteIncrement: 1,
                              dateFormat: 'd-m-Y H:i',
                              minDate: watch('startDate')
                            }}
                            aria-label="endDate"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="endDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </JaimyFlex>
              </ComponentIsVisible>

              <ComponentIsVisible when={!!errorMessage}>
                <JaimyFlex alignItems="center" justifyContent="center">
                  <ComponentErrorMessage withoutTranslation message={errorMessage} />
                </JaimyFlex>
              </ComponentIsVisible>

              <JaimyFlex flexDirection="column" px={2}>
                <JaimyLabel htmlFor="title">
                  <FormattedMessage id="calendar.address" />
                </JaimyLabel>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                      selectProps={{
                        value,
                        onChange,
                        isClearable: true,
                        placeholder: <FormattedMessage id="calendar.address.placeholder" />
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ['be', 'fr', 'nl']
                        }
                      }}
                    />
                  )}
                />
              </JaimyFlex>

              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <JaimyLabel htmlFor="email">
                        <FormattedMessage id="calendar.email" />
                      </JaimyLabel>
                      <TextField
                        disabled={jaimyAppointment}
                        defaultValue={value}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        className="disabledField"
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <JaimyLabel htmlFor="phone">
                        <FormattedMessage id="calendar.phone" />
                      </JaimyLabel>
                      <TextField
                        disabled={jaimyAppointment}
                        defaultValue={value}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        className="disabledField"
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="phone"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>

              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <JaimyLabel htmlFor="description">
                        <FormattedMessage id="calendar.description" />
                      </JaimyLabel>
                      <TextField
                        disabled={jaimyAppointment}
                        defaultValue={value}
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={4}
                        className="disabledField"
                      />
                    </>
                  )}
                />
              </JaimyFlex>
              <JaimyFlex gap="16px" px={2} justifyContent="flex-end">
                <JaimyButton.Base
                  onClick={handleClose}
                  backgroundColor={theme.colors.secondary.base}
                  type="button"
                >
                  <FormattedMessage id="question.cancel" />
                </JaimyButton.Base>
                <JaimyButton.Base type="submit" disabled={updateAppointmentLoading} width="10rem">
                  <ComponentIsVisible when={!updateAppointmentLoading}>
                    <FormattedMessage id="profile.form.save" />
                  </ComponentIsVisible>
                  <ComponentIsVisible when={updateAppointmentLoading}>
                    <CircularProgress size={20} color="inherit" disableShrink />
                  </ComponentIsVisible>
                </JaimyButton.Base>
              </JaimyFlex>
            </JaimyFlex>
          </form>
        </JaimyFlex>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({
  calendars: { appointments, updateAppointmentLoading, firstDayOfSelectedMonth }
}: {
  calendars: FixMeLater;
}) => ({
  appointments,
  firstDayOfSelectedMonth,
  updateAppointmentLoading
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  updateAppointment: (action: IUploadAppointmentRequestAction) =>
    dispatch(updateAppointment(action)),
  getAppointments: (payload: { id: number; range: IRange }) =>
    dispatch(getAppointments({ range: payload.range, id: payload.id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAppointmentDialog);
