// @flow
// @format

// Slowly refactoring/moving most common css declarations
// to a global style sheet colors, fonts, etc, since they
// seem to repeat.
// Maybe doing the same for most
// common used components <Link/>, <Paragraph />, <Headings... /> 💡 🤔 (?)

const colors = {
  gray: 'hsla(205.4, 37.1%, 31.2%, 1)', // #32546d; headings, paragraph
  grayAlt: '',
  brand: '',
  brandAlt: '',
  white: '',
  whiteAlt: '',
  dark: ''
};

export { colors };
