import { Dialog } from '@material-ui/core';

import theme from '../../../../theme';
import JaimyButton from '../../../../theme/components/Button';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  deleteAppointment,
  getAppointments,
  IDeleteAppointmentRequestAction
} from '../../../../store/reducers/calendar/actionCreators';
import { FixMeLater } from '../../../../types/FixMeLater';
import { AnyAction } from 'redux';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IEvent, IRange } from '../../../../types/Calendar';

import JaimyText from '../../../../theme/components/Typography/Text';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { CircularProgress } from '@mui/material';
import { endOfMonth, endOfWeek, format, startOfWeek } from 'date-fns';

interface IDeleteAppointmentDialogProps {
  open: boolean;
  handleClose: () => void;
  deleteAppointment: (action: IDeleteAppointmentRequestAction) => AnyAction;
  appointment: IEvent;
  deleteAppointmentLoading: boolean;
  firstDayOfSelectedMonth: Date;
  getAppointments: (payload: { id: number; range: IRange }) => AnyAction;
}

const DeleteAppointmentDialog = ({
  open,
  handleClose,
  deleteAppointment,
  appointment,
  deleteAppointmentLoading,
  firstDayOfSelectedMonth,
  getAppointments
}: IDeleteAppointmentDialogProps) => {
  const { id } = useParams<{ id: string }>();

  const handleDeleteAppointment = useCallback(() => {
    if (!id || !appointment.id) {
      return;
    }

    deleteAppointment({
      payload: {
        appointmentId: appointment.id,
        interventionPartnershipId: id
      },
      functions: {
        reloadAppointments: () =>
          getAppointments({
            id: parseInt(id, 10),
            range: {
              end: format(endOfWeek(endOfMonth(firstDayOfSelectedMonth)), 'yyyy/MM/dd'),
              start: format(startOfWeek(firstDayOfSelectedMonth), 'yyyy/MM/dd')
            }
          }),
        closeModal: () => handleClose()
      }
    });
  }, [
    id,
    appointment.id,
    deleteAppointment,
    getAppointments,
    firstDayOfSelectedMonth,
    handleClose
  ]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="delete-appointment"
      PaperProps={{
        className: 'customMuiDialogPaper'
      }}
    >
      <JaimyFlex flexDirection="column" width="100%" margin="0 auto" padding="2rem" gap="1rem">
        <JaimyHeading.H3 color={theme.colors.secondary.base} margin="0 auto">
          <FormattedMessage id="calendar.deleteAppointment" />
        </JaimyHeading.H3>

        <JaimyFlex flexDirection="column" margin="1rem 0 2rem">
          <JaimyText textAlign="center" fontSize={theme.typography.fontSizes.lg} color="#666">
            <FormattedMessage id="calendar.deleteAppointmentWarning" />
          </JaimyText>
          <JaimyText
            textAlign="center"
            fontWeight="bold"
            fontSize={theme.typography.fontSizes.xl}
            color={theme.colors.primary.base}
          >
            {appointment.title}
          </JaimyText>
        </JaimyFlex>

        <JaimyFlex gap="16px" px={2} justifyContent="center">
          <JaimyButton.Base
            onClick={handleClose}
            backgroundColor={theme.colors.secondary.base}
            type="button"
          >
            <FormattedMessage id="question.cancel" />
          </JaimyButton.Base>
          <JaimyButton.Base
            type="submit"
            disabled={deleteAppointmentLoading}
            onClick={handleDeleteAppointment}
            width="10rem"
          >
            <ComponentIsVisible when={!deleteAppointmentLoading}>
              <FormattedMessage id="calendar.deleteAppointmentConfirm" />
            </ComponentIsVisible>
            <ComponentIsVisible when={deleteAppointmentLoading}>
              <CircularProgress size={20} color="inherit" disableShrink />
            </ComponentIsVisible>
          </JaimyButton.Base>
        </JaimyFlex>
      </JaimyFlex>
    </Dialog>
  );
};

const mapStateToProps = ({
  calendars: { deleteAppointmentLoading, firstDayOfSelectedMonth }
}: {
  calendars: FixMeLater;
}) => ({
  deleteAppointmentLoading,
  firstDayOfSelectedMonth
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  deleteAppointment: (action: IDeleteAppointmentRequestAction) =>
    dispatch(deleteAppointment(action)),
  getAppointments: (payload: { id: number; range: IRange }) =>
    dispatch(getAppointments({ range: payload.range, id: payload.id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAppointmentDialog);
