import { ButtonPrimary } from '../common/Button';
import { ReactComponent as CardIcon } from '../../assets/images/card-top.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes';
import React from 'react';
import styled from 'styled-components';

const LinkS = styled(Link)`
  text-decoration: none;
  margin: auto;
`;
const DivCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #d8dae0;
  box-shadow: 0px 2px 4px rgba(216, 216, 216, 0.5);
  border-radius: 5px;
  padding: 1.2rem;

  > button {
    margin: 0 auto;
  }
`;
const H2Title = styled.h2`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  position: relative;
  top: -24px;

  > span {
    z-index: 1;
    position: relative;
    margin: auto;
    padding-left: 1rem;
  }

  > svg {
    position: relative;
    margin: 0 auto;
    top: -44px;
    right: -22px;
  }
`;
const PContent = styled.p`
  color: #32546d;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  position: relative;
  top: -32px;
`;
const PFooter = styled.p`
  color: #32546d;
  font-size: 14px;
  line-height: 28px;
  position: relative;
`;

const ContactCard = ({ children, id, title, onClick }) => {
  return (
    <DivCard>
      <H2Title>
        {title}
        <CardIcon width="211" height="72" />
      </H2Title>
      <PContent>{children}</PContent>
      <LinkS to={`${ROUTES.JOB_QUESTION.handler(id)}`}>
        <ButtonPrimary role="link" onClick={onClick}>
          <FormattedMessage tagName="span" id="servicespage.contact_us.cta" />
        </ButtonPrimary>
      </LinkS>
    </DivCard>
  );
};

export const CardTitle = ({ children, title, footer }) => {
  return (
    <DivCard>
      <H2Title>
        {title}
        <CardIcon width="211" height="72" />
      </H2Title>
      <PContent>{children}</PContent>
      {footer && <PFooter>{footer}</PFooter>}
    </DivCard>
  );
};

export default ContactCard;
