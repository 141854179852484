import { FormattedMessage } from 'react-intl';
import theme from '../../../../theme';
import JaimyBox from '../../../../theme/components/layout/Box';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyGrid from '../../../../theme/components/layout/Grid';
import JaimyText from '../../../../theme/components/Typography/Text';

export function MyCalendarSubtitles() {
  return (
    <JaimyGrid columns="repeat(2, 1fr)" gridGap=".25rem">
      <JaimyFlex alignItems="center" columnGap=".5rem">
        <JaimyBox
          backgroundColor={theme.colors.secondary.base}
          width="1rem"
          height="1rem"
          borderRadius={0.5}
        />
        <JaimyText color="#666">
          <FormattedMessage id="calendar.currentDay" />
        </JaimyText>
      </JaimyFlex>
      <JaimyFlex alignItems="center" columnGap=".5rem">
        <JaimyBox
          backgroundColor={theme.colors.primary.dark}
          width="1rem"
          height="1rem"
          borderRadius={0.5}
        />
        <JaimyText color="#666">
          <FormattedMessage id="calendar.selectedDay" />
        </JaimyText>
      </JaimyFlex>
      <JaimyFlex alignItems="center" columnGap=".5rem">
        <JaimyBox
          backgroundColor={theme.colors.secondary.lighter}
          width="1rem"
          height="1rem"
          borderRadius={0.5}
        />
        <JaimyText color="#666">
          <FormattedMessage id="calendar.personalAppointment" />
        </JaimyText>
      </JaimyFlex>
      <JaimyFlex alignItems="center" columnGap=".5rem">
        <JaimyBox
          backgroundColor={theme.colors.primary.base}
          width="1rem"
          height="1rem"
          borderRadius={0.5}
        />
        <JaimyText color="#666">
          <FormattedMessage id="calendar.jaimyAppointment" />
        </JaimyText>
      </JaimyFlex>
      <JaimyFlex alignItems="center" columnGap=".5rem">
        <JaimyBox
          backgroundColor={theme.colors.green}
          width="1rem"
          height="1rem"
          borderRadius={0.5}
        />
        <JaimyText color="#666">
          <FormattedMessage id="calendar.vacation.subtitle" />
        </JaimyText>
      </JaimyFlex>
    </JaimyGrid>
  );
}
