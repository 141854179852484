import React, { useEffect } from 'react';
import { hideAlert, showAlert } from '../../../store/reducers/alerts/actions';

import { ButtonActions } from '../../common/Button';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { VERIFIED_STATUSES } from '../../../util/constants';
import { connect } from 'react-redux';
import styled from 'styled-components';

const DivWrapper = styled.div`
  padding: 0 1.2rem 1.2rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const DivFreeLead = styled.div`
  background-color: #86bcd3;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0.4rem 1rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto 1rem;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-right: 0.6rem;
  }
`;
const ButtonClose = styled(ButtonActions)`
  :nth-of-type(1) {
    margin-bottom: 0;
  }
`;

const CheckedAlert = ({
  checked,
  hideAlert,
  showAlert,
  checked_purchases_left,
  free_leads_remaining,
  verified_status
}) => {
  useEffect(() => {
    if (verified_status === VERIFIED_STATUSES.checked && free_leads_remaining === 0) {
      showAlert({ checked: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClose = () => {
    hideAlert({ checked: false });
  };
  if (checked) {
    return (
      <DivWrapper data-cy-div="free-lead-alert">
        <DivFreeLead>
          <p>
            <strong>
              <FormattedMessage tagName="span" id="general.error.checked.get_started.pre" />
            </strong>
            <FormattedMessage
              tagName="span"
              id="general.error.checked.get_started.description"
              values={{
                count: checked_purchases_left,
                link: <FormattedMessage tagName="span" id="general.error.checked.verify_link" />
              }}
            />
          </p>
          <ButtonClose onClick={onClose}>
            <CloseIcon width={24} height={24} />
          </ButtonClose>
        </DivFreeLead>
      </DivWrapper>
    );
  }
  return null;
};

const mapStateToProps = ({
  alerts: { checked },
  user: {
    user: { free_leads_remaining, checked_purchases_left, verified_status }
  }
}) => ({
  checked,
  free_leads_remaining,
  checked_purchases_left,
  verified_status
});
const mapDispatchToProps = {
  hideAlert,
  showAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckedAlert);
