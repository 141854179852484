import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../routes';
import theme from '../../theme';
import JaimyButton from '../../theme/components/Button';
import JaimyBox from '../../theme/components/layout/Box';
import JaimyFlex from '../../theme/components/layout/Flex';
import JaimyHeading from '../../theme/components/Typography/Heading';

export function ChoosePlatform() {
  const history = useHistory();
  const location = useLocation();
  const from: any = location;

  const handleNavigateToRik = () => {
    const rikUrl = process.env.REACT_APP_RIK_PP_URL || 'https://pro-rik.jaimy.be/';
    const currentQueryParams = location.search;
    const fullRikUrl = `${rikUrl}${currentQueryParams}`;
    window.open(fullRikUrl, '_blank');
  };
  const handleNavigateToPreviousStateOrDashboard = () => {
    const currentQueryParams = location.search;
    if (from?.state?.from) {
      history.push({
        pathname: from.state.from.pathname,
        search: from.state.from.search,
        state: from.state.from.state
      });
    } else {
      const dashboardUrl = `${ROUTES.DASHBOARD.handler(currentQueryParams)}`;
      history.push(dashboardUrl);
    }
  };

  return (
    <JaimyBox
      display="grid"
      alignItems="center"
      justifyContent="center"
      padding="8rem 0 0"
      paddingSm="8rem 0rem"
    >
      <JaimyHeading.H2
        lineHeight={1.25}
        fontSize={theme.typography.fontSizes['2xl']}
        fontFamily={theme.typography.fonts.body}
        width="75%"
        margin="0 auto 5rem"
        textAlign="center"
      >
        <FormattedMessage tagName="span" id="choosePlatform" />
      </JaimyHeading.H2>
      <JaimyFlex directionSm="column" gap="2rem" width="fit-content" margin="0 auto">
        <JaimyButton.Base
          fontSize={theme.typography.fontSizes.md}
          backgroundColor={theme.gradients.primary}
          maxWidth="16.5rem"
          padding="2rem 1.5rem"
          onClick={handleNavigateToRik}
        >
          <FormattedMessage tagName="span" id="choosePlatform.rik" />
        </JaimyButton.Base>
        <JaimyButton.Base
          fontSize={theme.typography.fontSizes.md}
          backgroundColor={theme.colors.secondary.lighter}
          maxWidth="16.5rem"
          padding="2rem 1.5rem"
          onClick={handleNavigateToPreviousStateOrDashboard}
        >
          <FormattedMessage tagName="span" id="choosePlatform.pp" />
        </JaimyButton.Base>
      </JaimyFlex>
    </JaimyBox>
  );
}
