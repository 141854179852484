import { ErrorMessage } from '@hookform/error-message';
import { Checkbox, DialogContent, FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  INTERVENTION_TYPES_MATCHING,
  RADIUS,
  SLOT_PROPOSAL_DELAY,
  TIME_DURATION,
  TIME_DURATION_DRIVING
} from '../../../constants/manageCalendar';
import {
  createManageCalendar,
  IManageCalendarRequestAction,
  updateManageCalendar
} from '../../../store/reducers/calendar/actionCreators';
import { getUser } from '../../../store/reducers/user/actions';
import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyLabel from '../../../theme/components/form/Label';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyDivider from '../../../theme/components/layout/Divider';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyGrid from '../../../theme/components/layout/Grid';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import { IWeekDay } from '../../../types/Calendar';
import { FixMeLater } from '../../../types/FixMeLater';
import { IInterventionPartnership } from '../../../types/User';
import { convertTextToArray } from '../../../util/convertTextToArray';
import Select from '../../Select';
import { ComponentErrorMessage } from '../../utils/ErrorMessage';
import { ComponentIsVisible } from '../../utils/isVisible';
import ManageCalendarWorkingDays from './WorkingDays';
import PhoneIcon from '@material-ui/icons/Phone';

interface IManageCalendarDialogProps {
  open: boolean;
  handleClose: () => void;
  createManageCalendar: (action: IManageCalendarRequestAction) => AnyAction;
  updateManageCalendar: (action: IManageCalendarRequestAction) => AnyAction;
  interventionPartnerships: IInterventionPartnership[];
  userEmail: string;
  userId: number;
  createManageCalendarLoading: boolean;
  updateManageCalendarLoading: boolean;
  getUser: () => AnyAction;
  isInsuranceServicesCategory: boolean;
}

export interface IManageCalendarFormData {
  address: {
    street: string;
    number: string;
    postalCode: string;
  };
  email: string;
  maxDrivingTime: number;
  maxDrivingTimeFirstSlot: number;
  timePerIntervention: number;
  slotProposalDelay: number;
  interventionType: string;
  frLanguage: boolean;
  nlLanguage: boolean;
  matchingPostcodes: string;
  matchingWithRadius: boolean;
  rejectedPostcodes: string;
  weekdays: IWeekDay[];
  radius: number;
  zip: string;
}

function ManageCalendarDialog({
  handleClose,
  open,
  createManageCalendar,
  updateManageCalendar,
  interventionPartnerships,
  userEmail,
  userId,
  createManageCalendarLoading,
  updateManageCalendarLoading,
  getUser,
  isInsuranceServicesCategory = false
}: IManageCalendarDialogProps) {
  const { id } = useParams<{ id: string }>();

  const interventionPartnership = useMemo(() => {
    const interventionPartnershipFound = interventionPartnerships.find(
      (interventionPartnership: IInterventionPartnership) =>
        interventionPartnership.id === parseInt(id)
    );

    return interventionPartnershipFound;
  }, [id, interventionPartnerships]);

  const weekdaysFormatted: IWeekDay[] = useMemo(() => {
    let weekdays: IWeekDay[] = [];

    if (interventionPartnership?.weekdays) {
      weekdays = interventionPartnership.weekdays.map(weekday => ({
        endTime: format(new Date(weekday.end_time), 'HH:mm'),
        name: weekday.name,
        selected: interventionPartnership.weekdays.some(
          interventionPartnershipWeekday => interventionPartnershipWeekday.name === weekday.name
        ),
        startTime: format(new Date(weekday.start_time), 'HH:mm')
      }));
    }
    return weekdays;
  }, [interventionPartnership?.weekdays]);

  const {
    control,
    handleSubmit,
    watch,
    resetField,
    formState: { errors }
  } = useForm<IManageCalendarFormData>({
    defaultValues: {
      address: {
        number: interventionPartnership?.address.number,
        postalCode: interventionPartnership?.home_zip,
        street: interventionPartnership?.address.street
      },
      email: userEmail,
      frLanguage: interventionPartnership?.locale.includes('fr'),
      nlLanguage: interventionPartnership?.locale.includes('nl'),
      interventionType: interventionPartnership?.intervention_type,
      matchingPostcodes: interventionPartnership?.matching_postcodes.toString(),
      matchingWithRadius: interventionPartnership?.matching_with_radius,
      maxDrivingTime: interventionPartnership?.max_driving_time,
      maxDrivingTimeFirstSlot: interventionPartnership?.max_driving_time_first_slot,
      radius: interventionPartnership?.radius,
      rejectedPostcodes: interventionPartnership?.rejected_postcodes.toString(),
      slotProposalDelay: interventionPartnership?.slot_proposal_delay,
      timePerIntervention: interventionPartnership?.time_per_intervention,
      weekdays: weekdaysFormatted,
      zip: interventionPartnership?.zip ? interventionPartnership?.zip : undefined
    },
    criteriaMode: 'all',
    mode: 'onBlur'
  });

  const watchedFrLanguage = watch('frLanguage');
  const watchedNlLanguage = watch('nlLanguage');
  const watchedMatchingWithRadius = watch('matchingWithRadius');

  const handleManageCalendar = useCallback(
    (data: IManageCalendarFormData) => {
      if (!userId) {
        return;
      }

      const locales: string[] = [];

      if (data.frLanguage) {
        locales.push('fr');
      }
      if (data.nlLanguage) {
        locales.push('nl');
      }

      if (interventionPartnership) {
        updateManageCalendar({
          payload: {
            ...data,
            interventionPartnershipId: id,
            traderId: userId,
            locale: locales,
            matchingPostcodes: convertTextToArray(data.matchingPostcodes) || [],
            rejectedPostcodes: convertTextToArray(data.rejectedPostcodes) || []
          },
          functions: {
            closeModal: () => handleClose(),
            reloadInterventionPartnerships: () => getUser()
          }
        });
      } else {
        createManageCalendar({
          payload: {
            ...data,
            interventionPartnershipId: id,
            traderId: userId,
            locale: locales,
            matchingPostcodes: convertTextToArray(data.matchingPostcodes) || [],
            rejectedPostcodes: convertTextToArray(data.rejectedPostcodes) || []
          },
          functions: {
            reloadInterventionPartnerships: () => getUser(),
            closeModal: () => handleClose()
          }
        });
      }
    },
    [
      userId,
      interventionPartnership,
      updateManageCalendar,
      id,
      handleClose,
      getUser,
      createManageCalendar
    ]
  );

  useEffect(() => {
    if (open) {
      resetField('weekdays', { defaultValue: weekdaysFormatted });
    }
  }, [open, resetField, weekdaysFormatted]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="manage-calendar"
      scroll="paper"
    >
      <DialogContent>
        <JaimyBox maxHeight="95%" padding="2rem" paddingSm="0rem">
          <ComponentIsVisible when={!!isInsuranceServicesCategory}>
            <JaimyFlex alignItems='center'>
              <PhoneIcon htmlColor={theme.colors.secondary.base} fontSize="small" style={{ marginRight: "5px" }} />
              <JaimyHeading.H2>
                <FormattedMessage id="calendar.manage.title.phone" />
              </JaimyHeading.H2>
            </JaimyFlex>
          </ComponentIsVisible>
          <ComponentIsVisible when={!isInsuranceServicesCategory}>
            <JaimyHeading.H2>
              <FormattedMessage id="calendar.manage.title" />
            </JaimyHeading.H2>
          </ComponentIsVisible>
          <form onSubmit={handleSubmit(handleManageCalendar)}>
            <ManageCalendarWorkingDays
              control={control}
              watch={watch}
              weekdays={weekdaysFormatted}
            />

            <JaimyBox width="100%" position="relative">
              <JaimyGrid
                columns="1fr 5rem 10rem .65fr"
                columnsSm="repeat(2, 1fr)"
                columnGap=".5rem"
                gridGap=".5rem"
              >
                <ComponentIsVisible when={!isInsuranceServicesCategory}>
                  <JaimyFlex gridColumnSm="1 / 3" flexDirection="column">
                    <Controller
                      name="address.street"
                      control={control}
                      rules={{ required: 'missing-properties.message.required' }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="address.street">
                            <FormattedMessage id="calendar.manage.street" />
                          </JaimyLabel>
                          <TextField
                            defaultValue={value}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="address.street"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="address.number"
                      control={control}
                      rules={{ required: 'missing-properties.message.required' }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="address.number">
                            <FormattedMessage id="calendar.manage.number" />
                          </JaimyLabel>
                          <TextField
                            defaultValue={value}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="address.number"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="address.postalCode"
                      control={control}
                      rules={{ required: 'missing-properties.message.required' }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="address.postalCode">
                            <FormattedMessage id="calendar.manage.postalCode" />
                          </JaimyLabel>
                          <TextField
                            defaultValue={value}
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="address.postalCode"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
                <JaimyFlex gridColumnSm="1 / 3" flexDirection="column">
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: 'missing-properties.message.required' }}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <JaimyLabel htmlFor="email">
                          <FormattedMessage id="calendar.manage.email" />
                        </JaimyLabel>
                        <TextField
                          defaultValue={value}
                          onChange={onChange}
                          size="small"
                          variant="outlined"
                          fullWidth
                        />
                      </>
                    )}
                  />
                  <ErrorMessage
                    name="email"
                    errors={errors}
                    render={({ message }) => <ComponentErrorMessage message={message} />}
                  />
                </JaimyFlex>
              </JaimyGrid>

              <JaimyGrid
                margin="1rem 0 0"
                columnsSm="repeat(2, 1fr)"
                columns="1fr .7fr .5fr .7fr"
                gridGap=".5rem"
              >
                <ComponentIsVisible when={!isInsuranceServicesCategory}>
                  <JaimyFlex flexDirection="column" justifyContent="space-between">
                    <Controller
                      name="maxDrivingTimeFirstSlot"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="maxDrivingTimeFirstSlot">
                            <FormattedMessage id="calendar.manage.maxDrivingTimeFirstSlot" />
                          </JaimyLabel>
                          <Select onChange={onChange} value={value} fullWidth>
                            {TIME_DURATION_DRIVING.map(option => (
                              <MenuItem
                                data-cy-menuitem={option.value}
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column" justifyContent="space-between">
                    <Controller
                      name="maxDrivingTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="maxDrivingTime">
                            <FormattedMessage id="calendar.manage.maxDrivingTime" />
                          </JaimyLabel>
                          <Select onChange={onChange} defaultValue={value} fullWidth>
                            {TIME_DURATION_DRIVING.map(option => (
                              <MenuItem
                                data-cy-menuitem={option.value}
                                value={option.value}
                                key={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
                <JaimyFlex flexDirection="column" justifyContent="space-between">
                  <Controller
                    name="timePerIntervention"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <JaimyLabel htmlFor="timePerIntervention">
                          <FormattedMessage id="calendar.manage.timePerIntervention" />
                        </JaimyLabel>
                        <Select onChange={onChange} value={value} fullWidth>
                          {TIME_DURATION.map(option => (
                            <MenuItem
                              data-cy-menuitem={option.value}
                              value={option.value}
                              key={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </JaimyFlex>
                <JaimyFlex flexDirection="column" justifyContent="space-between">
                  <Controller
                    name="slotProposalDelay"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <JaimyLabel htmlFor="slotProposalDelay">
                          <FormattedMessage id="calendar.manage.slotProposalDelay" />
                        </JaimyLabel>
                        <Select onChange={onChange} value={value} fullWidth>
                          {SLOT_PROPOSAL_DELAY.map(option => (
                            <MenuItem
                              data-cy-menuitem={option.value}
                              value={option.value}
                              key={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  />
                </JaimyFlex>
              </JaimyGrid>
            </JaimyBox>
            <JaimyHeading.H4 color={theme.colors.secondary.base} margin="1.5rem 0 0">
              Specify Matching criteria
            </JaimyHeading.H4>
            <JaimyDivider color="#dedede" margin=".5rem 0 1rem" />

            <JaimyGrid
              columns="1fr .5fr"
              columnsSm="1fr"
              columnGap=".5rem"
              rowGap="1rem"
              margin="1rem 0 0"
            >
              <JaimyFlex flexDirection="column">
                <JaimyLabel>
                  <FormattedMessage id="calendar.manage.matchingLanguage" />
                </JaimyLabel>
                <JaimyFlex>
                  <FormControlLabel
                    control={
                      <Controller
                        name="frLanguage"
                        control={control}
                        rules={{
                          required: !watchedFrLanguage && !watchedNlLanguage
                        }}
                        render={({ field }) => <Checkbox defaultChecked={field.value} {...field} />}
                      />
                    }
                    label={<JaimyLabel>FR</JaimyLabel>}
                  />
                  <FormControlLabel
                    control={
                      <Controller
                        name="nlLanguage"
                        control={control}
                        rules={{
                          required: !watchedFrLanguage && !watchedNlLanguage
                        }}
                        render={({ field }) => <Checkbox defaultChecked={field.value} {...field} />}
                      />
                    }
                    label={<JaimyLabel>NL</JaimyLabel>}
                  />
                </JaimyFlex>
                <ComponentIsVisible when={!!errors.frLanguage && !!errors.nlLanguage}>
                  <ComponentErrorMessage message="calendar.manage.atLeastOneRequired" />
                </ComponentIsVisible>
              </JaimyFlex>

              <JaimyFlex flexDirection="column">
                <JaimyLabel>
                  <FormattedMessage id="calendar.manage.interventionType" />
                </JaimyLabel>
                <Controller
                  control={control}
                  name="interventionType"
                  render={({ field: { onChange, value } }) => (
                    <Select value={value} onChange={onChange} fullWidth>
                      {INTERVENTION_TYPES_MATCHING.map(option => (
                        <MenuItem
                          data-cy-menuitem={option.value}
                          value={option.value}
                          key={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </JaimyFlex>
            </JaimyGrid>
            <ComponentIsVisible when={!isInsuranceServicesCategory}>
              <JaimyGrid
                columns={watchedMatchingWithRadius ? 'repeat(3, 1fr)' : '1fr .5fr'}
                columnGap=".5rem"
                columnsSm={watchedMatchingWithRadius ? 'repeat(2, 1fr)' : '1fr'}
                rowGap="1rem"
                margin="1rem 0 0"
              >
                <ComponentIsVisible when={!watchedMatchingWithRadius}>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="matchingPostcodes"
                      control={control}
                      rules={{ required: 'calendar.manage.atLeastOneRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="matchingPostcodes">
                            <FormattedMessage id="calendar.manage.matchingPostcodes" />
                          </JaimyLabel>
                          <TextField
                            defaultValue={value}
                            onChange={event => onChange(convertTextToArray(event.target.value))}
                            size="small"
                            multiline
                            minRows={5}
                            maxRows={5}
                            variant="outlined"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="matchingPostcodes"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
                <ComponentIsVisible when={watchedMatchingWithRadius}>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      control={control}
                      name="zip"
                      rules={{
                        required: watchedMatchingWithRadius && 'missing-properties.message.required'
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <JaimyLabel htmlFor="zip">
                            <FormattedMessage id="calendar.manage.zip" />
                          </JaimyLabel>
                          <TextField
                            defaultValue={value}
                            type="number"
                            onChange={onChange}
                            size="small"
                            variant="outlined"
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="zip"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column">
                    <JaimyLabel>
                      <FormattedMessage id="calendar.manage.serviceRadius" />
                    </JaimyLabel>
                    <Controller
                      control={control}
                      rules={{
                        required: watchedMatchingWithRadius && 'missing-properties.message.required'
                      }}
                      name="radius"
                      render={({ field: { onChange, value } }) => (
                        <Select onChange={onChange} value={value} fullWidth>
                          {RADIUS.map(option => (
                            <MenuItem
                              data-cy-menuitem={option.value}
                              value={option.value}
                              key={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    <ErrorMessage
                      name="radius"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
                <JaimyFlex gridColumnSm="1 / 3">
                  <FormControlLabel
                    style={{ height: 'fit-content', marginTop: '.5rem' }}
                    control={
                      <Controller
                        name="matchingWithRadius"
                        control={control}
                        render={({ field }) => <Checkbox defaultChecked={field.value} {...field} />}
                      />
                    }
                    label={
                      <JaimyLabel>
                        <FormattedMessage id="calendar.manage.matchingWithRadius" />
                      </JaimyLabel>
                    }
                  />
                </JaimyFlex>
              </JaimyGrid>

              <JaimyFlex flexDirection="column" margin="1rem 0 0">
                <Controller
                  name="rejectedPostcodes"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <JaimyLabel htmlFor="rejectedPostcodes">
                        <FormattedMessage id="calendar.manage.rejectedPostcodes" />
                      </JaimyLabel>
                      <TextField
                        defaultValue={value}
                        onChange={onChange}
                        size="small"
                        multiline
                        minRows={5}
                        maxRows={5}
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                />
              </JaimyFlex>
            </ComponentIsVisible>
            <JaimyFlex gap=".5rem" justifyContent="flex-end" margin="1.5rem 0 0">
              <JaimyButton.Base
                fontSize={theme.typography.fontSizes.md}
                height="2.5rem"
                width="10rem"
                backgroundColor="transparent"
                border={`1px solid ${theme.colors.secondary.base}`}
                color={theme.colors.secondary.base}
                onClick={handleClose}
                type="button"
              >
                <FormattedMessage id="cancel" />
              </JaimyButton.Base>

              <JaimyButton.Base
                fontSize={theme.typography.fontSizes.md}
                height="2.5rem"
                type="submit"
                disabled={createManageCalendarLoading || updateManageCalendarLoading}
                width="10rem"
              >
                <ComponentIsVisible
                  when={!createManageCalendarLoading && !updateManageCalendarLoading}
                >
                  <FormattedMessage id="calendar.submit" />
                </ComponentIsVisible>
                <ComponentIsVisible
                  when={createManageCalendarLoading || updateManageCalendarLoading}
                >
                  <CircularProgress size={20} color="inherit" disableShrink />
                </ComponentIsVisible>
              </JaimyButton.Base>
            </JaimyFlex>
          </form>
        </JaimyBox>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = ({
  user: {
    user: { id, email, intervention_partnerships }
  },
  calendars: { createManageCalendarLoading, updateManageCalendarLoading }
}: {
  user: FixMeLater;
  calendars: FixMeLater;
}) => ({
  userEmail: email,
  userId: id,
  interventionPartnerships: intervention_partnerships,
  createManageCalendarLoading,
  updateManageCalendarLoading
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  createManageCalendar: (action: IManageCalendarRequestAction) =>
    dispatch(createManageCalendar(action)),
  updateManageCalendar: (action: IManageCalendarRequestAction) =>
    dispatch(updateManageCalendar(action)),
  getUser: () => dispatch(getUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendarDialog);
