import styled from 'styled-components';

interface IHeadingProps {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontSizeLg?: string;
  fontSizeMd?: string;
  fontSizeSm?: string;
  fontStyle?: string;
  fontWeight?: number | string;
  lineHeight?: number;
  margin?: string;
  textAlign?: string;
  textDecoration?: string;
  textTransform?: string;
  width?: string;
}
const H1 = styled.h1<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : props.theme.typography.fonts.heading};
  font-size: ${props => (props.fontSize ? props.fontSize : '2rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : '1.5rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : '1.75rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : '2rem')};
  }
`;

const H2 = styled.h2<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : props.theme.typography.fonts.heading};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.25rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : props.fontSize)};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : props.fontSizeSm)};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : props.fontSizeMd)};
  }
`;

const H3 = styled.h3<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : props.theme.typography.fonts.heading};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.25rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  text-transform: ${props => props.textTransform && props.textTransform};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : '1.25rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : '1.5rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : '1.75rem')};
  }
`;

const H4 = styled.h4<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : props.theme.typography.fonts.heading};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.10rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  text-decoration: ${props => props.textDecoration && props.textDecoration};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : props.fontSize)};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : props.fontSizeSm)};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : props.fontSizeMd)};
  }
`;

const H5 = styled.h5<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props => props.fontFamily && props.fontFamily};
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : '1.25rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : '1.5rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : '1.75rem')};
  }
`;

const H6 = styled.h6<IHeadingProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.heading)};
  font-family: ${props => props.fontFamily && props.fontFamily};
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  text-align: ${props => props.textAlign && props.textAlign};
  width: ${props => props.width && props.width};

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    font-size: ${props => (props.fontSizeSm ? props.fontSizeSm : '1rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: ${props => (props.fontSizeMd ? props.fontSizeMd : '1rem')};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    font-size: ${props => (props.fontSizeLg ? props.fontSizeLg : '1rem')};
  }
`;

const JaimyHeading = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6
};

export default JaimyHeading;
