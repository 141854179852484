import { SHOW_ALERT, HIDE_ALERT, HIDE_SNACK, SHOW_SNACK } from './actionTypes';

export function showAlert(payload) {
  return {
    type: SHOW_ALERT,
    payload
  };
}

export function hideAlert(payload) {
  return {
    type: HIDE_ALERT,
    payload
  };
}

export function showSnackbar(payload) {
  return {
    type: SHOW_SNACK,
    payload: { snackText: payload }
  };
}

export function hideSnackbar(payload) {
  return {
    type: HIDE_SNACK,
    payload
  };
}
