import styled, { css } from 'styled-components';
import theme from '../../../../theme';

export const monthDayColumnStartOptions = {
  0: css``,
  1: css`
    grid-column-start: 2;
  `,
  2: css`
    grid-column-start: 3;
  `,
  3: css`
    grid-column-start: 4;
  `,
  4: css`
    grid-column-start: 5;
  `,
  5: css`
    grid-column-start: 6;
  `,
  6: css`
    grid-column-start: 7;
  `
};

interface IMonthDayProps {
  borderTop?: boolean;
  columnStart: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export const MonthDay = styled.div<IMonthDayProps>`
  height: 5rem;
  ${props =>
    props.borderTop &&
    css`
      /* border-top: 1px solid #dedede; */
    `}

  ${props => props.columnStart && monthDayColumnStartOptions[props.columnStart]};
  position: relative;
`;

interface IMonthDayButtonProps {
  isEqual?: boolean;
  isSameMonth?: boolean;
  isToday?: boolean;
}

export const MonthDayButton = styled.button<IMonthDayButtonProps>`
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #dedede;
  width: 100%;

  padding: 0;

  box-sizing: border-box;

  transition: all 0.2s ease-in-out;

  ${props =>
    props.isEqual &&
    css`
      background-color: #88002f1f;
      border-color: ${theme.colors.primary.dark};

      & > span {
        border: 1px solid ${theme.colors.primary.dark};
        background-color: ${theme.colors.primary.dark};
        color: ${theme.colors.white};
      }
    `}

  ${props =>
    props.isToday &&
    css`
      background-color: #32546d1a;
      border-color: ${theme.colors.secondary.base};

      & > span {
        border: 1px solid ${theme.colors.secondary.base};
        background-color: ${theme.colors.secondary.base};
        color: ${theme.colors.white};
      }
    `}

  ${props =>
    !props.isEqual &&
    !props.isToday &&
    props.isSameMonth &&
    css`
      color: #666;

      &:hover {
        border-color: #666;

        & > span {
          border: 1px solid #666;
          background-color: #666;
          color: ${theme.colors.white};
        }
      }
    `}

  ${props =>
    !props.isEqual &&
    !props.isToday &&
    !props.isSameMonth &&
    css`
      color: #aaa;

      &:hover {
        border-color: #666;

        & > span {
          border: 1px solid #666;
          background-color: #666;
          color: ${theme.colors.white};
        }
      }
    `}
`;

interface IDayProps {
  isEqual?: boolean;
  isToday?: boolean;
}

export const Day = styled.span<IDayProps>`
  box-sizing: border-box;

  font-size: 0.75rem;
  line-height: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 0;
  top: 0;

  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0 0 0 0.5rem;

  transition: all 0.2s ease;

  @media screen and (min-width: 768px) {
    height: 1.75rem;
    width: 1.75rem;

    font-size: 0.875rem;
    line-height: 1rem;
  }
`;

export const AppointmentsContainer = styled.div`
  display: flex;
  align-items: center;

  column-gap: 0.125rem;

  position: absolute;
  bottom: 0.25rem;
  left: 0.25rem;

  @media screen and (min-width: 768px) {
    column-gap: 0.25rem;

    bottom: 0.5rem;
    left: 0.5rem;
  }
`;

interface IAppointmentProps {
  personal?: boolean;
  vacation?: boolean;
}

export const Appointment = styled.div<IAppointmentProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;

  background-color: ${theme.colors.primary.base};
  color: ${theme.colors.white};
  font-size: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.personal &&
    css`
      background-color: ${theme.colors.secondary.lighter};
      color: ${theme.colors.white};
    `}

  @media screen and (min-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.875rem;
  }

  ${props =>
    props.vacation &&
    css`
      background-color: ${theme.colors.green};
      color: ${theme.colors.white};
      position: absolute;
      left: 0.25rem;
      top: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;

      @media screen and (min-width: 768px) {
        width: 1rem;
        height: 1rem;

        top: 0.5rem;
        left: 0.5rem;
      }
    `}
`;
