import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';

import { ButtonLogin } from '../LoginForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import querystring from 'query-string';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const DivContainer = styled.div`
  margin: 4rem;
  max-width: 468px;

  @media (min-width: 768px) {
    margin: 4rem auto;
  }
`;
const DivForm = styled.div`
  max-width: 468px;
  margin: 0 auto 2rem auto;

  > div {
    margin-top: 1rem;
  }
`;
const H2Title = styled.h2`
  color: #32546d;
  text-align: center;
  font-family: 'Lato', sans-serif;
`;
const DivError = styled.div`
  color: red;
  transition: all 2s ease-in-out;
`;

export const ResetPasswordForm = ({ onSubmit, isLoading, errorMessage, message }) => {
  const location = useLocation();
  const query = querystring.parse(location.search);
  const intl = useIntl();
  const resetPasswordToken = query.id;
  const userData = {
    token: query['access-token'],
    client: query.client,
    uid: query.uid
  };
  const isNewAccount = query.new_account;
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [hasError, setHasError] = useState(null);
  const onClickSubmit = () => {
    if (password && passwordConfirm && password.length >= 8) {
      onSubmit(resetPasswordToken, password, passwordConfirm, userData);
    } else {
      setHasError({
        required: {
          password: !password,
          passwordConfirm: !passwordConfirm
        },
        validation: {
          password: password.length < 8,
          passwordConfirm: passwordConfirm.length < 8
        }
      });
    }
  };
  const validate = field => {
    if (hasError && hasError.validation) {
      return hasError.validation[field];
    }
    if (hasError && hasError.required) {
      return hasError.required[field];
    }

    return false;
  };
  const onChange = (field, value) => {
    if (field === 'password') {
      setPassword(value);
    }
    if (field === 'passwordConfirm') {
      setPasswordConfirm(value);
    }

    if (hasError && hasError.required && hasError.required.hasOwnProperty(field)) {
      const { required } = hasError;
      delete required[field];

      setHasError({
        required
      });
    }
  };

  return (
    <DivContainer>
      <H2Title>
        {!isNewAccount ? (
          <FormattedMessage tagName="span" id="reset_password.title" />
        ) : (
          <FormattedMessage tagName="span" id="reset_password.title.new_account" />
        )}
      </H2Title>
      <DivForm>
        <TextField
          id="password"
          value={password}
          onChange={e => onChange('password', e.target.value)}
          label={intl.messages['reset_password.form.password']}
          type="password"
          error={validate('password')}
          fullWidth
        />
        <TextField
          id="passwordConfirm"
          value={passwordConfirm}
          onChange={e => onChange('passwordConfirm', e.target.value)}
          label={intl.messages['reset_password.form.confirm_password']}
          type="password"
          error={validate('passwordConfirm')}
          fullWidth
        />
      </DivForm>
      <DivError>
        {hasError && hasError.required && Object.values(hasError.required).includes(true) && (
          <FormattedMessage tagName="span" id="reset_password.form.error.required" />
        )}
        {hasError && hasError.validation && Object.values(hasError.validation).includes(true) && (
          <FormattedMessage tagName="span" id="reset_password.form.error.password_validation" />
        )}
        {errorMessage && (
          <>
            {errorMessage === 'PASSWORDS_DO_NOT_MATCH' ? (
              <FormattedMessage tagName="span" id="reset_password.form.error.password_match" />
            ) : (
              <FormattedMessage tagName="span" id="reset_password.form.error.unauthorized" />
            )}
          </>
        )}
      </DivError>
      {message && <p>{message}</p>}
      <ButtonLogin onClick={onClickSubmit} disabled={isLoading} fullWidth>
        {isLoading && <CircularProgress color="inherit" disableShrink />}
        {!isLoading && (
          <>
            {!isNewAccount ? (
              <FormattedMessage tagName="span" id="reset_password.form.submit" />
            ) : (
              <FormattedMessage tagName="span" id="reset_password.form.submit.new_account" />
            )}
          </>
        )}
      </ButtonLogin>
    </DivContainer>
  );
};

ResetPasswordForm.defaultProps = {
  message: null,
  errorMessage: null,
  isLoading: false
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
  message: PropTypes.string
};

export default ResetPasswordForm;
