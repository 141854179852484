import React from 'react';
import styled from 'styled-components';

const DivCardHeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  align-items: flex-end;
  justify-content: center;
`;
const CardHeaderActions = ({ children }) => <DivCardHeaderActions>{children}</DivCardHeaderActions>;

export default CardHeaderActions;
