import { useState } from 'react';
import { format } from 'date-fns';
import theme from '../../../../theme';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import { ICalendarVacation } from '../../../../types/Vacation';
import Delete from '@material-ui/icons/Delete';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyGrid from '../../../../theme/components/layout/Grid';
import VacationCancelDialog from '../../../Dialog/Vacation/Cancel';
import JaimyButton from '../../../../theme/components/Button';
import JaimyText from '../../../../theme/components/Typography/Text';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

interface ICardVacationProps {
  vacation: ICalendarVacation;
}

const CardVacation = ({ vacation }: ICardVacationProps) => {
  const { id: calendarId } = useParams<{ id?: string }>();
  const [vacationCancelOpen, setVacationCancel] = useState<boolean>(false);

  const handleToggleCancel = () => {
    setVacationCancel(currentValue => !currentValue);
  };

  return (
    <JaimyFlex
      backgroundColor={theme.colors.background.dark}
      borderRadius={0.5}
      flexDirection="column"
      padding="1rem"
    >
      <JaimyText fontWeight={600} fontSize={theme.typography.fontSizes.md}>
        <FormattedMessage id="calendar.vacation.cardTitle" />
      </JaimyText>
      <JaimyGrid columnGap=".5rem" columns="1fr auto" margin="0.25rem 0">
        <JaimyHeading.H4
          fontFamily={theme.typography.fonts.body}
          fontSize={theme.typography.fontSizes.sm}
          color={theme.colors.primary.base}
        >
          {`${format(vacation.startDate, 'MM/dd')} - ${format(vacation.endDate, 'MM/dd')}`}
        </JaimyHeading.H4>
      </JaimyGrid>

      <JaimyFlex margin=".5rem 0 0" justifyContent="space-between" alignItems="center">
        <JaimyFlex alignItems="center" columnGap=".5rem" width="fit-content">
          {/* <JaimyButton.Base
            height="2.25rem"
            width="2.25rem"
            border={`1px solid ${theme.colors.secondary.base}`}
            color={theme.colors.secondary.base}
            backgroundColor="transparent"
            type="button"
          >
            <EditIcon fontSize="small" />
          </JaimyButton.Base> */}
          <JaimyButton.Base
            height="2.25rem"
            width="2.25rem"
            type="button"
            onClick={handleToggleCancel}
          >
            <Delete fontSize="small" />
          </JaimyButton.Base>
        </JaimyFlex>
      </JaimyFlex>

      <VacationCancelDialog
        calendarId={calendarId}
        vacation={vacation}
        open={vacationCancelOpen}
        handleClose={handleToggleCancel}
      />
    </JaimyFlex>
  );
};

export default CardVacation;
