import React from 'react'
import { ComponentIsVisible } from '../../../../components/utils/isVisible'
import SatisfiedTraderWithSubscription from './SatisfiedTraderWithSubscription'
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import SatisfiedTraderWithoutSubscription from './SatisfiedTraderWithoutSubscription';


const SatisfiedTraderForm = ({ grade }: { grade: number }) => {

  const location = useLocation();
  const query = querystring.parse(location.search);
  return (
    <>
      <ComponentIsVisible when={!!query.subscription}>
        <SatisfiedTraderWithSubscription />
      </ComponentIsVisible>
      <ComponentIsVisible when={!query.subscription}>
        <SatisfiedTraderWithoutSubscription grade={grade} />
      </ComponentIsVisible>
    </>
  )
}

export default SatisfiedTraderForm
