import { PictureAsPdf, Videocam } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import JaimyText from '../../theme/components/Typography/Text';
import JaimyBox from '../../theme/components/layout/Box';
import JaimyFlex from '../../theme/components/layout/Flex';
import JaimyTooltip from '../Tooltip';
import { ComponentIsVisible } from '../utils/isVisible';

const MediaList = ({ media, setShowMediaIndex }) => {
  const intl = useIntl();

  return (
    <JaimyFlex
      padding="1rem 1rem 0 1rem"
      margin="1rem auto 0"
      flexDirection="column"
      alignItems="center"
    >
      <JaimyFlex
        borderTop="1px solid #d8dae0"
        maxWidth="1200px"
        width="100%"
        padding="1.2rem"
        gap="8px"
        justifyContent="start"
      >
        <ComponentIsVisible when={media.length > 0}>
          {media.map((file, i) => {
            if (file.asset_type === 'document') {
              return (
                <JaimyTooltip title={file.name} key={file.name}>
                  <JaimyFlex
                    height="100px"
                    width="80px"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #d8dae0"
                    cursor="pointer"
                    _hover={{ backgroundColor: '#d8dae0' }}
                  >
                    <a href={file.url}>
                      <PictureAsPdf style={{ fontSize: '50px' }} />
                    </a>
                  </JaimyFlex>
                </JaimyTooltip>
              );
            }
            if (file.asset_type === 'video') {
              return (
                <JaimyTooltip title={file.name} key={file.name}>
                  <JaimyFlex
                    height="100px"
                    width="80px"
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid #d8dae0"
                    cursor="pointer"
                    _hover={{ backgroundColor: '#d8dae0' }}
                  >
                    <Videocam onClick={() => setShowMediaIndex(i)} style={{ fontSize: '50px' }} />
                  </JaimyFlex>
                </JaimyTooltip>
              );
            } else {
              return (
                <JaimyTooltip title={file.name} key={file.name}>
                  <JaimyBox height="100px" width="80px" cursor="pointer">
                    <img
                      height="100%"
                      width="100%"
                      src={file.url}
                      alt={file.name}
                      onClick={() => setShowMediaIndex(i)}
                      style={{ borderRadius: '8px' }}
                    />
                  </JaimyBox>
                </JaimyTooltip>
              );
            }
          })}
        </ComponentIsVisible>
      </JaimyFlex>
      <JaimyBox width="100%" padding="1rem" maxWidth="1200px">
        <JaimyText textAlign="start">
          {`${media.length} - ${
            media.length === 1 ? intl.messages['document'] : intl.messages['documents']
          }`}
        </JaimyText>
      </JaimyBox>
    </JaimyFlex>
  );
};

export default MediaList;
