const {
  JOB_ERROR,
  JOB_LOADING,
  JOB_SUCCESS,
  JOB_RESET,
  JOB_UNAVAILABLE,
  SERVICE_FAVORITE,
  SERVICE_FAVORITE_SUCCESS,
  SERVICE_FAVORITE_ERROR,
  SERVICE_TRASH,
  SERVICE_TRASH_SUCCESS,
  SERVICE_TRASH_ERROR,
  SERVICE_FILTER_UPDATE,
  SERVICE_FILTER_UPDATE_NO_FETCH,
  SERVICE_FILTER_SUCCESS,
  SERVICE_FILTER_ERROR,
  SERVICE_UPDATE_ID,
  SERVICE_UPDATE_CART_ID,
  SERVICE_USE_FREE_LEAD,
  SERVICE_USE_FREE_LEAD_ERROR,
  SERVICE_USE_FREE_LEAD_SUCCESS,
  SERVICE_LOAD_MORE_ERROR,
  SERVICE_LOAD_MORE_SUCCESS,
  RELEVANT_JOBS,
  RELEVANT_JOBS_SUCCESS,
  RELEVANT_JOBS_ERROR
} = require('./actionTypes');

const initialState = {
  noLongerAvailable: false,
  jobData: {},
  relevantJobs: {
    data: [],
    relevantJobError: null,
    relevantJobLoading: false
  },
  jobError: null,
  jobLoading: false,
  filters: {
    category_list: [],
    radius: 20,
    request_type: '',
    service_view: 'simplified',
    search_value: '',
    status: 'all',
    order_direction: '',
    pagination: {
      total: 0,
      perPage: 12,
      page: 1
    },
    originalState: true
  }
};
export const reducer = (state = initialState, action) => {
  let { data } = state;
  let item;
  let total;
  switch (action.type) {
    case SERVICE_FILTER_SUCCESS:
    case SERVICE_LOAD_MORE_SUCCESS: {
      const currentItems = state.data;
      const { items, pagination } = action.payload;

      return {
        ...state,
        data: [...currentItems, ...items],
        filters: {
          ...state.filters,
          pagination: {
            ...state.filters.pagination,
            ...pagination
          }
        },
        isLoading: false,
        hasError: false,
        error: null
      };
    }

    case SERVICE_FILTER_ERROR:
    case SERVICE_LOAD_MORE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        hasError: true
      };

    case SERVICE_FAVORITE:
      data = state.data;
      if (data) {
        item = data.find(e => e.id === action.payload);
        const itemIndex = data.findIndex(e => e.id === action.payload);

        if (item) {
          item.favorite = !item.favorite;
        }
        data[itemIndex] = item;
      }

      return {
        ...state,
        jobData: { ...state.jobData, favorite: !state.jobData.favorite },
        data
      };

    case SERVICE_FAVORITE_SUCCESS:
      data = state.data;
      if (data) {
        item = data.find(e => e.id === action.payload.service_request_id);
        const itemIndex = data.findIndex(e => e.id === action.payload.service_request_id);
        if (item) {
          item.favorite = action.payload.status === 'favorite';
        }
        data[itemIndex] = item;
      }

      return {
        ...state,
        jobData: { ...state.jobData, favorite: action.payload.status === 'favorite' },
        data
      };

    case SERVICE_FAVORITE_ERROR:
      return {
        ...state
      };

    case SERVICE_TRASH:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case SERVICE_TRASH_SUCCESS:
      data = state.data;
      if (state.filters.pagination.total) {
        total = parseInt(state.filters.pagination.total, 10) - 1;
      } else {
        total = data.length - 1;
      }
      if (data) {
        item = data.find(e => e.id === action.payload.service_request_id);
        const itemIndex = data.findIndex(e => e.id === action.payload.service_request_id);

        if (item) {
          item.sr_status = action.payload.status;
        }

        data[itemIndex] = item;
      }

      return {
        ...state,
        isLoading: false,
        error: null,
        hasError: false,
        data: data.filter(e => e.id !== action.payload.service_request_id),
        filters: {
          ...state.filters,
          pagination: {
            ...state.filters.pagination,
            total
          }
        }
      };

    case SERVICE_TRASH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: null,
        hasError: true
      };

    case SERVICE_FILTER_UPDATE:
    case SERVICE_FILTER_UPDATE_NO_FETCH:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        isLoading: true,
        error: null,
        hasError: false
      };

    case SERVICE_UPDATE_ID:
      item = data ? data.find(e => e.id === action.payload.id) : null;
      if (data && item) {
        const itemIndex = data.findIndex(e => e.id === action.payload.id);
        data[itemIndex] = action.payload;
      } else if (data) {
        data.push(action.payload);
      } else {
        data = [action.payload];
      }

      return {
        ...state,
        data,
        isLoading: false,
        error: null,
        hasError: false
      };

    case SERVICE_UPDATE_CART_ID:
      const srId = action.payload.service_request_id
        ? action.payload.service_request_id
        : action.payload.id;
      item = data ? data.find(e => e.id === srId) : null;
      if (data && item) {
        const itemIndex = data.findIndex(e => e.id === srId);
        data[itemIndex] = {
          ...data[itemIndex],
          cart_id: action.payload.cart_id
        };
      } else if (data) {
        data.push(action.payload);
      } else {
        data = [action.payload];
      }

      return {
        ...state,
        data,
        isLoading: false,
        error: null,
        hasError: false
      };

    case SERVICE_USE_FREE_LEAD:
      const indexA = state.data.findIndex(e => e.id === action.payload);
      const serviceItem = state.data[indexA];
      serviceItem.isLoading = true;
      state.data[indexA] = serviceItem;

      return {
        ...state,
        error: null,
        hasError: false
      };

    case SERVICE_USE_FREE_LEAD_SUCCESS:
      const indexB = state.data.findIndex(e => e.id === action.payload.id);
      const serviceItemB = state.data[indexB];
      serviceItemB.isLoading = false;
      serviceItemB.freeLead = true;
      state.data[indexB] = serviceItemB;

      return {
        ...state,
        isLoading: false,
        error: null,
        hasError: false
      };

    case SERVICE_USE_FREE_LEAD_ERROR:
      const indexC = state.data.findIndex(e => e.id === action.payload.id);
      const serviceItemC = state.data[indexC];
      serviceItemC.isLoading = false;
      serviceItemC.freeLead = false;
      serviceItemC.freeLeadError = action.payload.data;
      state.data[indexC] = serviceItemC;

      return {
        ...state,
        isLoading: false,
        error: null,
        hasError: true
      };
    case JOB_RESET:
    case JOB_LOADING:
      return {
        ...state,
        jobError: null,
        jobData: {},
        jobLoading: false,
        noLongerAvailable: false
      };
    case JOB_ERROR:
      return {
        ...state,
        jobError: action.payload,
        jobLoading: false
      };
    case JOB_UNAVAILABLE:
      return {
        ...state,
        jobError: action.payload,
        noLongerAvailable: true
      };
    case JOB_SUCCESS:
      return {
        ...state,
        jobError: null,
        jobData: action.payload,
        jobLoading: false
      };

    case RELEVANT_JOBS:
      return {
        ...state,
        relevantJobs: {
          relevantJobLoading: true
        }
      };
    case RELEVANT_JOBS_ERROR:
      return {
        ...state,
        relevantJobs: {
          data: [],
          relevantJobError: action.payload,
          relevantJobLoading: false
        }
      };
    case RELEVANT_JOBS_SUCCESS:
      return {
        ...state,
        relevantJobs: {
          data: action.payload,
          relevantJobError: null,
          relevantJobLoading: false
        }
      };
    default:
      return state;
  }
};
