import { format, isAfter, isBefore, isSameDay } from 'date-fns';
import { AnyAction, Dispatch } from 'redux';
import CalendarService from '../../../lib/services/calendarService';
import {
  ICreateAppointmentPayloadData,
  IDeleteAppointmentPayloadData,
  IUpdateAppointmentPayloadData
} from '../../../types/Appointment';
import {
  IAppointment,
  IManageCalendarPayloadData,
  IEvent,
  IGetAppointmentsPayload,
  IGetOnlyJaimyAppointmentPayloadData,
  IJaimyAppointment
} from '../../../types/Calendar';
import { getToken } from '../../../util/tokenProvider';
import { API_URL } from '../../../util/url';
import * as actionTypes from './actionTypes';

const calendarService = new CalendarService();

export const setFirstDayOfSelectedMonth = (payload: { dateFormatted: string }) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.SET_FIRST_DAY_OF_SELECTED_MONTH,
      payload: payload.dateFormatted
    });
  };
};

export const getAppointments = ({ id, range }: IGetAppointmentsPayload) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.GET_APPOINTMENTS
    });
    try {
      // fetch appointments for certain range
      const query = `?start_date=${range.start}&end_date=${range.end}`;

      const data: IAppointment[] = await fetch(
        `${API_URL}/api/traders/v1/traders/${id}/appointments${query}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${await getToken()}`
          }
        }
      ).then(res => res.json());

      const appointments: IEvent[] = data.map(appointment => {
        const isPersonalAppointment = appointment.status === 'block_event';
        return {
          id: appointment.slot_id,
          booked: appointment.booked,
          title: appointment.summary || `Event - ${appointment.slot_id}`,
          start: new Date(appointment.start_time),
          startFormatted: format(new Date(appointment.start_time), 'dd-MM-yyyy HH:mm'),
          end: new Date(appointment.end_time),
          endFormatted: format(new Date(appointment.end_time), 'dd-MM-yyyy HH:mm'),

          serviceRequestId: appointment.service_request_id,
          status: appointment.status,

          price: appointment.user_price_cents,
          description: appointment.service_request_info?.description || appointment.description,
          name: appointment.service_request_info?.name
            ? appointment.service_request_info?.name
            : appointment.summary,
          address: isPersonalAppointment
            ? appointment?.address
            : appointment.service_request_info?.address,
          category: appointment.service_request_info?.category,
          phone: isPersonalAppointment
            ? appointment?.phone
            : appointment.service_request_info?.phone,
          email: isPersonalAppointment
            ? appointment?.email
            : appointment.service_request_info?.email
        };
      });

      dispatch({
        type: actionTypes.GET_APPOINTMENTS_SUCCESS,
        payload: appointments
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_APPOINTMENTS_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export const getOnlyJaimyAppointments = (payload: IGetOnlyJaimyAppointmentPayloadData) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_REQUEST
      });

      const response: IAppointment[] = await calendarService.getOnlyJaimyAppointments(payload);

      const rangeStartParsed = new Date(payload.range.start);
      const rangeEndParsed = new Date(payload.range.end);

      const responseFiltered = response.filter(appointment => appointment.booked);

      let jaimyAppointments: IJaimyAppointment[] = [];

      if (responseFiltered.length > 0) {
        responseFiltered.map(appointment => {
          const appointmentStartTimeParsed = new Date(appointment.start_time);

          const hasAppointmentAtFirstDay = isSameDay(appointmentStartTimeParsed, rangeStartParsed);
          const hasAppointmentAfterStartDate = isAfter(
            appointmentStartTimeParsed,
            rangeStartParsed
          );
          const hasAppointmentBeforeEndDate = isBefore(appointmentStartTimeParsed, rangeEndParsed);
          const hasAppointmentAtLastDay = isSameDay(appointmentStartTimeParsed, rangeEndParsed);

          if (
            ((hasAppointmentAtFirstDay || hasAppointmentAfterStartDate) &&
              hasAppointmentBeforeEndDate) ||
            hasAppointmentAtLastDay
          ) {
            const appointmentName = appointment.service_request_info?.name
              ? appointment.service_request_info?.name
              : appointment.summary;

            const filteredAppointmentFormatted = {
              clientName: appointmentName || '',
              startTime: format(appointmentStartTimeParsed, 'HH:mm'),
              date: format(appointmentStartTimeParsed, 'dd/MM/yyyy')
            };

            jaimyAppointments.push(filteredAppointmentFormatted);
          }

          return appointment;
        });
      }

      dispatch({
        type: actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_SUCCESS,
        payload: jaimyAppointments
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.GET_ONLY_JAIMY_APPOINTMENTS_ERROR,
        error: error.message
      });
    }
  };
};

export interface ICreateAppointmentRequestAction {
  payload: ICreateAppointmentPayloadData;
  functions: {
    closeModal: () => void;
    reloadAppointments: () => void;
  };
}

export const createAppointment = ({ payload, functions }: ICreateAppointmentRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.CREATE_APPOINTMENT
    });

    try {
      const data: IAppointment = await calendarService.createAppointment(payload);

      const appointment = {
        id: data.slot_id,
        title: data.summary || `Event - ${data.slot_id}`,
        start: new Date(data.start_time),
        end: new Date(data.end_time),
        description: data.description
      };

      dispatch({
        type: actionTypes.CREATE_APPOINTMENT_SUCCESS,
        payload: appointment
      });

      functions.reloadAppointments();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: actionTypes.CREATE_APPOINTMENT_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IUploadAppointmentRequestAction {
  payload: IUpdateAppointmentPayloadData;
  functions: {
    closeModal: () => void;
    reloadAppointments: () => void;
    errorMessage: (message: string) => void;
  };
}

export const updateAppointment = ({ payload, functions }: IUploadAppointmentRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.UPDATE_APPOINTMENT
    });

    try {
      await calendarService.updateAppointment(payload);

      dispatch({
        type: actionTypes.UPDATE_APPOINTMENT_SUCCESS
      });

      functions.reloadAppointments();
      functions.closeModal();
    } catch (error: any) {
      if (error.message === 'This time slot is already taken') {
        functions.errorMessage(error.message);
      }
      dispatch({
        type: actionTypes.UPDATE_APPOINTMENT_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IDeleteAppointmentRequestAction {
  payload: IDeleteAppointmentPayloadData;
  functions: {
    closeModal: () => void;
    reloadAppointments: () => void;
  };
}

export const deleteAppointment = ({ payload, functions }: IDeleteAppointmentRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.DELETE_APPOINTMENT
    });

    const requestBody = {
      appointment_id: payload.appointmentId
    };

    try {
      await fetch(
        `${API_URL}/api/traders/v1/traders/${payload.interventionPartnershipId}/delete_appointment`,
        {
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${await getToken()}`
          },
          body: JSON.stringify(requestBody)
        }
      ).then(res => res.json());

      dispatch({
        type: actionTypes.DELETE_APPOINTMENT_SUCCESS
      });

      functions.reloadAppointments();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: actionTypes.DELETE_APPOINTMENT_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IManageCalendarRequestAction {
  functions: {
    closeModal: () => void;
    reloadInterventionPartnerships: () => void;
  };
  payload: IManageCalendarPayloadData;
}

export const createManageCalendar = ({ payload, functions }: IManageCalendarRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.CREATE_MANAGE_CALENDAR
    });

    const formattedWeekDays = payload.weekdays.map(weekday => {
      return {
        name: weekday.name,
        start_time: weekday.startTime,
        end_time: weekday.endTime
      };
    });

    const requestBody = {
      trader_id: payload.traderId,
      weekdays: formattedWeekDays,
      address: {
        number: payload.address.number,
        street: payload.address.street,
        postcode: payload.address.postalCode
      },
      home_zip: payload.address.postalCode,
      email: payload.email,
      intervention_type: payload.interventionType,
      locale: payload.locale,
      rejected_postcodes: payload.rejectedPostcodes,
      slot_proposal_delay: payload.slotProposalDelay,
      time_per_intervention: payload.timePerIntervention,
      matching_postcodes: payload.matchingPostcodes,
      max_driving_time_first_slot: payload.maxDrivingTimeFirstSlot,
      max_driving_time: payload.maxDrivingTime,
      matching_with_radius: payload.matchingWithRadius,
      zip: payload.zip,
      radius: payload.radius
    };

    try {
      await fetch(`${API_URL}/api/traders/v1/intervention_partnerships/create`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        },
        body: JSON.stringify(requestBody)
      }).then(res => res.json());

      dispatch({
        type: actionTypes.CREATE_MANAGE_CALENDAR_SUCCESS
      });

      functions.reloadInterventionPartnerships();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: actionTypes.CREATE_MANAGE_CALENDAR_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export const updateManageCalendar = ({ functions, payload }: IManageCalendarRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: actionTypes.UPDATE_MANAGE_CALENDAR
    });

    const formattedWeekDays = payload.weekdays.map(weekday => {
      return {
        name: weekday.name,
        start_time: weekday.startTime,
        end_time: weekday.endTime
      };
    });

    const requestBody = {
      trader_id: payload.traderId,
      weekdays: formattedWeekDays,
      address: {
        number: payload.address.number,
        street: payload.address.street,
        postcode: payload.address.postalCode
      },
      home_zip: payload.address.postalCode,
      email: payload.email,
      intervention_type: payload.interventionType,
      locale: payload.locale,
      rejected_postcodes: payload.rejectedPostcodes,
      slot_proposal_delay: payload.slotProposalDelay,
      time_per_intervention: payload.timePerIntervention,
      matching_postcodes: payload.matchingPostcodes,
      max_driving_time_first_slot: payload.maxDrivingTimeFirstSlot,
      max_driving_time: payload.maxDrivingTime,
      matching_with_radius: payload.matchingWithRadius,
      zip: payload.zip,
      radius: payload.radius
    };

    try {
      await fetch(
        `${API_URL}/api/traders/v1/intervention_partnerships/${payload.interventionPartnershipId}`,
        {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${await getToken()}`
          },
          body: JSON.stringify(requestBody)
        }
      ).then(res => res.json());

      dispatch({
        type: actionTypes.UPDATE_MANAGE_CALENDAR_SUCCESS
      });

      functions.reloadInterventionPartnerships();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: actionTypes.UPDATE_MANAGE_CALENDAR_ERROR,
        error: error.message
      });
      return error;
    }
  };
};
