import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import CategoriesTreeSelect from '../CategoriesTreeSelect';
import Select from '../Select';

import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import JaimyFlex from '../../theme/components/layout/Flex';
import JaimyText from '../../theme/components/Typography/Text';

const FormControlLabelStyled = withStyles({
  label: {
    color: '#32546D'
  }
})(FormControlLabel);
const CheckboxStyled = withStyles({
  checked: {
    color: '#c30144'
  }
})(Checkbox);

const SectionFilter = ({ filters, setFilter, categories, locale, debouncedResults }) => {
  const langs = filters.languages ? filters.languages : [];
  const onLanguageChange = e => {
    const { value } = e.target;
    if (langs.includes(value)) {
      const languages = langs.filter(e => e !== value);
      const state = { ...filters, languages, originalState: false };
      setFilter(state);
    } else {
      const state = { ...filters, languages: [...langs, value], originalState: false };
      setFilter(state);
    }
  };
  const onCategoriesChange = ({ target: { value } }) => {
    const currentTreetrades = filters.category_list ? filters.category_list : '';
    if (currentTreetrades && value && value.toString() !== currentTreetrades.toString()) {
      const state = { ...filters, category_list: value, originalState: false };
      setFilter(state);
    }
  };

  const onStatusChange = ({ target: { value } }) => {
    const state = { ...filters, status: value, originalState: false };
    setFilter(state);
  };

  return (
    <JaimyFlex margin="1rem 0rem">
      <JaimyFlex flexDirection="column" padding="0 1.4rem">
        <JaimyText padding="5px">
          <FormattedMessage tagName="span" id="sectionfilter.category.default" />
        </JaimyText>
        <CategoriesTreeSelect
          id="treetrades"
          name="treetrades"
          categories={categories}
          locale={locale}
          onSelectTrade={value => onCategoriesChange({ target: { value } })}
          disableUnsubCategories
          required
          primary
        />
      </JaimyFlex>
      <Divider orientation="vertical" flexItem />
      <JaimyFlex flexDirection="column" padding="0 1.4rem">
        <JaimyText padding="5px">
          <FormattedMessage tagName="span" id="sectionfilter.label.languages" />
        </JaimyText>
        <FormGroup onChange={onLanguageChange} row>
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('en')} value="en" />}
            label="EN"
          />
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('nl')} value="nl" />}
            label="NL"
          />
          <FormControlLabelStyled
            className="languageCheckbox"
            control={<CheckboxStyled color="default" checked={langs.includes('fr')} value="fr" />}
            label="FR"
          />
        </FormGroup>
      </JaimyFlex>
      <Divider orientation="vertical" flexItem />
      <JaimyFlex flexDirection="column" padding="0 1.4rem">
        <JaimyText padding="5px">
          <FormattedMessage tagName="span" id="prebook.page.status" />
        </JaimyText>
        <Select name="status" value={filters.status} onChange={onStatusChange} defaultValue={'all'}>
          <MenuItem data-cy-menuitem="all" value="all">
            <FormattedMessage id="servicecardlist.all" defaultMessage="All" />
          </MenuItem>
          <MenuItem data-cy-menuitem="active" value="active">
            <FormattedMessage id="servicecardlist.active" defaultMessage="Active" />
          </MenuItem>
          <MenuItem data-cy-menuitem="archived" value="archived">
            <FormattedMessage id="servicecardlist.archived" defaultMessage="Archived" />
          </MenuItem>
        </Select>
      </JaimyFlex>
      <Divider orientation="vertical" flexItem />
      <JaimyFlex flexDirection="column" padding="0 1.4rem">
        <JaimyText padding="5px">
          <FormattedMessage tagName="span" id="sectionfilter.view.search" />
        </JaimyText>
        <TextField
          name="search_value"
          onChange={debouncedResults}
          variant="outlined"
          type="search"
          key={locale}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { height: '42.5px' }
          }}
        />
      </JaimyFlex>
    </JaimyFlex>
  );
};

const mapStateToProps = ({ services, user: { user } }) => ({
  filters: services.filters,
  locale: user.locale
});

SectionFilter.defaultProps = {
  filters: {
    languages: []
  }
};

export default connect(mapStateToProps)(SectionFilter);
