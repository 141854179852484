module.exports = {
  FORMS_UPDATE: 'FORMS_UPDATE',
  FORMS_ERROR: 'FORMS_ERROR',
  FORMS_ERROR_MULTIPLES: 'FORMS_ERROR_MULTIPLES',
  FORMS_ERROR_SET_MULTIPLES: 'FORMS_ERROR_SET_MULTIPLES',

  SIGN_UP_TRADER_STARTED: 'SIGN_UP_TRADER_STARTED',
  SIGN_UP_TRADER_SUCCESS: 'SIGN_UP_TRADER_SUCCESS',
  SIGN_UP_TRADER_ERROR: 'SIGN_UP_TRADER_ERROR',
  SIGN_UP_FORM_SUBMITTED: 'SIGN_UP_FORM_SUBMITTED',

  RESEND_ONBOARDING_MAIL_STARTED: 'RESEND_ONBOARDING_MAIL_STARTED',
  RESEND_ONBOARDING_MAIL_SUCCESS: 'RESEND_ONBOARDING_MAIL_SUCCESS',
  RESEND_ONBOARDING_MAIL_ERROR: 'RESEND_ONBOARDING_MAIL_ERROR'
};
