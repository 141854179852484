// import {
//   ArrayStringPropType,
//   BoolPropType,
//   FuncPropType,
//   NumberPropType,
//   ObjectPropType,
//   StringPropType,
//   UserPropType
// } from '../../util/PropTypeValues';
import { DivHeader, SectionTopHeader } from '../../components/common/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { hasUpdatedUserProfile, updateUserProfile } from '../../store/reducers/user/actions';
import styled, { css } from 'styled-components';

import { AnchorBack } from '../../components/common/Link';
import { ReactComponent as BelFlag } from '../../assets/images/flag-be.svg';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { ButtonPrimary } from '../../components/common/Button';
import CategoriesTreeSelect from '../../components/CategoriesTreeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as DeFlag } from '../../assets/images/flag-de.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { ReactComponent as FrFlag } from '../../assets/images/flag-fr.svg';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as NlFlag } from '../../assets/images/flag-nl.svg';
import { ROUTES } from '../../routes';
import RadiusSelect from '../../components/RadiusSelect';
import Select from '../../components/Select';
import TextField from '../../components/TextField';
import TimerDialog from '../../components/Dialog/Timer/Index';
import UtilService from '../../lib/services/utilService';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { raisePageEvent } from '../../logging/analytics';
import { requestCategories } from '../../store/reducers/categories/actions';
import { withStyles } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import JaimyButton from '../../theme/components/Button';
import JaimyFlex from '../../theme/components/layout/Flex';
import VacationPlanningDialog from '../../components/Dialog/Vacation/Planning';
import VacationCancelDialog from '../../components/Dialog/Vacation/Cancel';
import JaimyText from '../../theme/components/Typography/Text';
import theme from '../../theme';
import { getAllVacations } from '../../store/reducers/vacation/actions';
import { validate } from 'uuid';

const FormControlLabelStyled = withStyles({
  label: {
    color: '#32546D'
  }
})(FormControlLabel);

const CheckboxStyled = withStyles({
  checked: {
    color: '#32546D'
  }
})(Checkbox);

const H1ProfileTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: center;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 32px;
    line-height: 32px;
  }
`;
const DivProfile = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem 1.2rem;
`;
const Form = styled.form`
  background-color: #fff;
  border: 1px solid #d8dae0;
  border-radius: 4px;
  padding: 3rem;
  @media (max-width: 870px) {
    padding: 1rem;
  }
`;
const LabelTitle = styled.label`
  color: #c30144;
  font-family: BelfiusNormal, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
`;
const DivFormControl = styled.div`
  margin-top: 1rem;
  ${props => (props.isLast ? 'margin-bottom: 1rem;' : '')}

  > label {
    display: block;
    color: #32546d;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
  }

  input:disabled {
    opacity: 0.45;
  }

  input:invalid {
    border-color: #c30b30;
  }
`;
const DivPhone = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    :first-of-type {
      min-width: 120px;
      flex-basis: 14%;
      margin-right: 0.6rem;
    }

    :nth-of-type(2) {
      flex-basis: 84%;
    }
  }
`;
const DivFilters = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;

  @media (min-width: 575px) {
    flex-wrap: nowrap;
  }

  > div {
    :first-of-type {
      flex-basis: 50%;
      margin-right: ${() => (isMobile ? '0px' : '1rem')};
      @media (max-width: 575px) {
        flex-basis: 100%;
      }
    }
    :last-of-type {
      flex-basis: 50%;
      > div {
        width: 100%;
      }
      @media (max-width: 575px) {
        flex-basis: 100%;
      }
    }
  }
`;

const DivServiceArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    > div {
      width: 100%;
    }
    flex-basis: 100%;
  }

  @media (min-width: 575px) {
    flex-wrap: nowrap;
    > div {
      :first-child {
        flex-basis: 30%;
      }
      :nth-child(2) {
        flex-basis: 40%;
        flex-basis: ${props => (props.isSameAsBilling ? '50%' : '40%')};
      }
      :nth-child(3) {
        flex-basis: 30%;
      }
      :not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
  @media (min-width: 1200px) {
    flex-wrap: nowrap;
    > div {
      :first-child {
        flex-basis: 17%;
      }
      :nth-child(2) {
        flex-basis: ${props => (props.isSameAsBilling ? '40%' : '30%')};
      }
      :nth-child(3) {
        flex-basis: 25%;
      }
      :not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
`;

const DivError = styled.div`
  color: red;
  transition: all 2s ease-in-out;
  > p {
    font-family: Lato, sans-serif;
    text-align: center;
  }
`;
const DivActions = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  > button {
    width: 100%;
    justify-content: center;

    @media (min-width: 1200px) {
      max-width: 368px;
    }
  }
`;
const DivProfileSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4rem;
`;
const DivLabelSection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #e1e1e1;
`;
const SpanFreeLeadActions = styled.span`
  margin-left: auto;
  font-family: Lato;
  display: flex;
  justify-content: space-between;
  > button {
    text-transform: uppercase;
    color: #c30144;
    font-size: 9pt;
    min-width: 70px;
    font-weight: 700;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    &:focus {
      outline: 0;
    }
    cursor: pointer;
    :first-of-type {
      color: ${props => (props.activeleads ? '#c30144;' : '#d4d4d4;')};
    }
    :nth-of-type(2) {
      color: ${props => (props.activeleads ? '#d4d4d4;' : '#c30144;')};
    }
  }

  @media (min-width: 575px) {
    > button {
      min-width: 112px;
    }
  }
`;
const DivFreeLead = styled.div`
  margin-bottom: 2rem;

  > h3 {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #32546d;
    margin-top: 0.8rem;
  }

  > ul {
    list-style: none;
    padding: 0;

    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.4rem;

      > span {
        :first-of-type {
          color: #778d9b;
          font-size: 14px;
          font-weight: bold;
        }

        :nth-of-type(2) {
          color: #405a6b;
        }

        @media (min-width: 1200px) {
          :nth-of-type(2) {
            margin: 0 2rem 0 auto;
          }
        }
      }
    }
  }
`;

const DivCoupons = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const DivCouponItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-right: 0.4rem;
  margin-bottom: 1rem;
  min-width: 217px;
  flex-basis: 32.3%;
  height: 80px;
  border: 1px solid #d8dae0;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  ${props =>
    props.expired &&
    css`
      background-color: #e1e1e1;
      opacity: 0.5;
      cursor: default;
    `}
  @media (max-width: 870px) {
    flex-basis: 48%;
  }
  @media (max-width: 625px) {
    flex-basis: 80%;
    margin: 0 auto 1rem;
  }
`;
const DivCouponCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50px;
  border: solid 2px #af1e3c;
  border-radius: 50%;
  color: #af1e3c;
  font-size: 18pt;
  font-family: BelfiusNormal, sans-serif;
  font-weight: 700;
  ${props =>
    props.expired &&
    css`
      border: solid 2px #999999;
      color: #999999;
    `}
`;

const DivCouponDetails = styled.div`
  display: flex;
  flex-basis: 64%;
  flex-flow: row wrap;
`;

const DivCouponTitle = styled.div`
  flex-basis: 100%;
  font-family: 'BelfiusNormal', sans-serif;
  font-weight: 700;
  font-size: 12pt;
  color: #32546d;
`;

const DivCouponExpiresIn = styled.div`
  flex-basis: 100%;
  font-family: 'Lato', sans-serif;
  font-size: 11pt;
  color: ${props => (props.expired ? '#777777;' : '#af1e3c;')};
`;

const ExpiresMessage = styled.span`
  color: ${props => (props.expired ? '#777777;' : '#af1e3c;')};
`;

const FreeLeadsUsedMessage = styled.p`
  font-family: 'Lato';
  font-style: italic;
  color: #778d9b;
`;
const validIbanRegex = {
  BE: /^BE[0-9]{2}[0-9]{3}[0-9]{7}[0-9]{2}$/,
  NL: /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/,
  FR: /^FR[0-9]{2}[0-9]{5}[0-9]{5}[A-Z0-9]{11}[0-9]{2}$/,
  DE: /^DE[0-9]{2}[0-9]{8}[0-9]{10}$/,
  LU: /^LU[0-9]{2}[0-9]{3}[A-Z0-9]{13}$/
};

const Profile = ({
  user,
  matchingLocales,
  mobilePhone,
  isLoading,
  updateProfile,
  locale,
  error,
  serviceRadius,
  categories,
  catLoading,
  catError,
  getCategories,
  getAllVacations,
  vacations
}) => {
  const [form, setForm] = useState(null);
  const [emailConfirmOpen, setEmailConfirmOpen] = useState(false);
  // switch between active leads and history (used & expired)
  const [activeLeads, setActiveLeads] = useState(true);
  const [otherPostcodeField, setOtherPostcodeField] = useState(
    user.radius_zip === user.zip ? 'same_as_billing' : 'other'
  );
  const intl = useIntl();
  const [ibanIsValid, setIbanIsValid] = useState(true);

  const [zipIsValid, setZipIsValid] = useState(true);
  const [matchingLanguages, setMatchingLanguages] = useState(matchingLocales);
  const [mobilePhoneValid, setMobilePhoneValid] = useState(true);
  const utilService = new UtilService();
  // set default value for country code based on saved mobile phone, if present
  const countryCodeDefault = mobilePhone ? mobilePhone.substr(0, 3) : '';
  // regex for mobile belgian numbers
  const phoneValidationRegexBE = /^\+32[0]?(?:4[5-9][0-9])\d{6}$/;
  const phoneValidationRegexNL = /^\+31[0]?(\d{9})$/;
  const phoneValidationRegexFR = /^\+33[1-9]([0-9]{2}){4}$/;
  const phoneValidationRegexDE = /^\+49([0-9]{1,6})?([0-9]{3,20})([0-9]{1,4})?$/;
  const metaTitle = intl.messages['profilepage.title'];

  const [openVacationPlanning, setOpenVacationPlanning] = useState(false);
  const [openVacationCancel, setOpenVacationCancel] = useState(false);
  const [vacationToCancel, setVacationToCancel] = useState(undefined);

  const handleVacationPlanning = () => {
    setOpenVacationPlanning(currentValue => !currentValue);
  };
  const handleToggleVacationCancel = vacation => {
    setVacationToCancel(vacation);
    setOpenVacationCancel(true);
  };

  const handleCloseVacationCancel = () => {
    setOpenVacationCancel(false);
  };
  useEffect(() => {
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!catError && !catLoading) {
      getCategories(user.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.locale]);

  const getValue = (field, defaultValue) => (form && form[field] ? form[field] : defaultValue);
  const onFieldChanges =
    field =>
    ({ target: { value } }) =>
      setForm({ ...form, [field]: value });

  const validatePhoneFormat = number => {
    if (
      !phoneValidationRegexBE.test(number) &&
      !phoneValidationRegexNL.test(number) &&
      !phoneValidationRegexFR.test(number) &&
      !phoneValidationRegexDE.test(number)
    ) {
      setMobilePhoneValid(false);
      return false;
    }
    setMobilePhoneValid(true);
    return true;
  };

  const validatePhone = form => {
    if (form.country_code && !form.mobile_phone) {
      // user modified country code but not mobile phone field
      if (mobilePhone) {
        // logic to reconstruct mobile phone field as one value ex. +32xxxxxxxx
        form.mobile_phone = form.country_code + mobilePhone.substr(3);
        return validatePhoneFormat(form.mobile_phone);
      }
      setMobilePhoneValid(false);
      return false;
    }
    if (form.country_code && form.mobile_phone) {
      // user modified both country code and mobile phone field
      form.mobile_phone = form.country_code + form.mobile_phone;
      return validatePhoneFormat(form.mobile_phone);
    }
    if (mobilePhone) {
      // user modified mobile phone field but not country code
      form.mobile_phone = mobilePhone.substr(0, 3) + form.mobile_phone;
    }
    return validatePhoneFormat(form.mobile_phone);
  };

  const validateIbanFormat = iban => {
    const ibanFormatted = String(iban)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');

    const countryCode = ibanFormatted.substring(0, 2);
    const isValid = validIbanRegex[countryCode] && validIbanRegex[countryCode].test(ibanFormatted);

    return isValid;
  };

  const validateIban = iban => {
    if (iban) {
      const isValid = validateIbanFormat(iban);
      if (!isValid) {
        setIbanIsValid(false);
        return false;
      }

      setForm({ ...form, iban: form.iban });
      setIbanIsValid(true);
      return true;
    }
  };

  const onSave = e => {
    e.preventDefault();
    e.stopPropagation();
    if (form) {
      if (form.email) {
        setEmailConfirmOpen(true);
      } else {
        if ((form.country_code || form.mobile_phone) && !validatePhone(form)) {
          return;
        }
        if (form && form.vat_number) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'VAT filled in'
            },
            dataLayerName: 'ProDataLayer'
          });
        }
        if (form.iban) {
          const isValidIban = validateIban(form.iban);
          if (!isValidIban) {
            setIbanIsValid(false);
            return false;
          }
          updateProfile(form, intl.messages['profile.form.update.success']);
        }
        if (otherPostcodeField === 'same_as_billing') {
          setForm({ ...form, radius_zip: form.zip });
        }
        if (form.zip) {
          utilService.validatePostcode(form.zip).then(val => {
            setZipIsValid(val);
            if (val) {
              updateProfile(form, intl.messages['profile.form.update.success']);
              setForm(null);
            }
          });
        } else {
          setZipIsValid(true);
          updateProfile(form, intl.messages['profile.form.update.success']);
          setForm(null);
        }
      }
    }
  };

  const onLanguageChange = e => {
    const { value } = e.target;
    let languages = matchingLanguages;
    if (!matchingLanguages) {
      languages = [value];
    } else if (matchingLanguages.includes(value)) {
      languages = matchingLanguages.filter(x => x !== value);
      onFieldChanges('matching_locales')({ target: { value: languages } });
    } else {
      languages = [...matchingLanguages, value];
      onFieldChanges('matching_locales')({ target: { value: languages } });
    }
    setMatchingLanguages(languages);
  };

  const handleUseOtherPostcode = e => {
    const { value } = e.target;
    setOtherPostcodeField(value);
    if (value === 'same_as_billing') {
      onFieldChanges('radius_zip')({ target: { value: user.zip } });
    }
  };

  useEffect(() => {
    getAllVacations(user.id);
  }, [getAllVacations, user.id]);

  const hasCoupons = user.traderCoupons && user.traderCoupons.filter(e => !e.used).length;
  const filteredCoupons = activeLeads
    ? user.traderCoupons && user.traderCoupons.filter(e => !(e.used || e.expired))
    : user.traderCoupons && user.traderCoupons.filter(e => e.used || e.expired);
  const msPerDay = 24 * 60 * 60 * 1000;

  const coupons =
    filteredCoupons &&
    filteredCoupons.map(e => (
      <Link
        to={!(e.used || e.expired) ? ROUTES.JOBS.handler() : ROUTES.PROFILE.handler()}
        key={e.id}
        style={{ textDecoration: 'none' }}
      >
        <DivCouponItem expired={e.used || e.expired}>
          <DivCouponCircle expired={e.used || e.expired}>1</DivCouponCircle>
          <DivCouponDetails>
            <DivCouponTitle>
              {e.name && e.name.toLowerCase().startsWith('coupon')
                ? intl.messages['profile.form.free_lead.coupon']
                : intl.messages['profile.form.free_lead.name']}
            </DivCouponTitle>
            <DivCouponExpiresIn expired={e.used || e.expired}>
              {!e.used && !e.expired && (
                <ExpiresMessage>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.free_lead.credits.expires_in_days"
                    values={{
                      expires: (
                        <span style={{ color: '#32546d' }}>
                          {intl.messages['profile.form.free_lead.credits.expires']}
                        </span>
                      ),
                      count: Math.round(
                        (new Date(e.expiresAt).getTime() - new Date().getTime()) / msPerDay
                      )
                    }}
                    defaultMessage=""
                  />
                </ExpiresMessage>
              )}
              {e.expired && (
                <ExpiresMessage expired={e.used || e.expired}>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.free_lead.credits.expired_days_ago"
                    values={{
                      count: Math.abs(
                        Math.round(
                          (new Date(e.expiresAt).getTime() - new Date().getTime()) / msPerDay
                        )
                      )
                    }}
                    defaultMessage="Expired"
                  />
                </ExpiresMessage>
              )}
              {e.used && (
                <ExpiresMessage expired={e.used || e.expired}>
                  <FormattedMessage tagName="span" id="profile.form.free_lead.credits.used" />
                </ExpiresMessage>
              )}
            </DivCouponExpiresIn>
          </DivCouponDetails>
        </DivCouponItem>
      </Link>
    ));
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivHeader>
        <SectionTopHeader>
          {!isMobile && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.PROFILE.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.profile" />
              </Link>
            </Breadcrumbs>
          )}
          {isMobile && (
            <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          )}
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="profile.title" defaultMessage="My Profile" />
        </H1ProfileTitle>
      </DivHeader>
      <DivProfile>
        <Form onSubmit={onSave}>
          {hasCoupons > 0 && (
            <DivFreeLead data-cy-div="free-leads">
              <DivLabelSection>
                <LabelTitle>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.free_lead"
                    defaultMessage="Your free lead credits"
                  />
                </LabelTitle>
                <SpanFreeLeadActions activeleads={activeLeads}>
                  <button type="button" onClick={() => setActiveLeads(true)}>
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.free_lead.action.active"
                      defaultMessage="Active"
                    />
                  </button>
                  <button type="button" onClick={() => setActiveLeads(false)}>
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.free_lead.action.history"
                      defaultMessage="History"
                    />
                  </button>
                </SpanFreeLeadActions>
              </DivLabelSection>
              <DivCoupons>
                {activeLeads && coupons.length === 0 && (
                  <FreeLeadsUsedMessage>
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.free_lead.no_remaining"
                      defaultMessage="All your free leads have been used or expired"
                    />
                  </FreeLeadsUsedMessage>
                )}
                {coupons.length > 0 && coupons}
              </DivCoupons>
            </DivFreeLead>
          )}
          <DivLabelSection>
            <LabelTitle>
              <FormattedMessage
                tagName="span"
                id="profile.form.default_filters"
                defaultMessage="Default filter"
              />
            </LabelTitle>
          </DivLabelSection>
          <DivProfileSection>
            <DivFilters>
              <DivFormControl>
                <label>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.primary_trader"
                    defaultMessage="Primary trade"
                  />
                </label>
                <CategoriesTreeSelect
                  id="treetrades"
                  name="treetrades"
                  onSelectTrade={value => onFieldChanges('treetrades')({ target: { value } })}
                  categories={categories}
                  locale={locale}
                  selectedCategoryIdsDefault={user.treetrades}
                  tag="forms.signup.field.primary_trade"
                  fullWidth
                  required
                  useUserDefault
                  disableUnsubCategories
                />
              </DivFormControl>
              <DivFormControl>
                <label>
                  <FormattedMessage tagName="span" id="sectionfilter.label.languages" />
                </label>
                <FormGroup onChange={onLanguageChange} row>
                  <FormControlLabelStyled
                    className="languageCheckbox"
                    control={
                      <CheckboxStyled
                        color="default"
                        checked={matchingLanguages && matchingLanguages.includes('en')}
                        value="en"
                      />
                    }
                    label="EN"
                  />
                  <FormControlLabelStyled
                    className="languageCheckbox"
                    control={
                      <CheckboxStyled
                        color="default"
                        checked={matchingLanguages && matchingLanguages.includes('nl')}
                        value="nl"
                      />
                    }
                    label="NL"
                  />
                  <FormControlLabelStyled
                    className="languageCheckbox"
                    control={
                      <CheckboxStyled
                        color="default"
                        checked={matchingLanguages && matchingLanguages.includes('fr')}
                        value="fr"
                      />
                    }
                    label="FR"
                  />
                </FormGroup>
              </DivFormControl>
            </DivFilters>
            <DivServiceArea
              isSameAsBilling={otherPostcodeField === 'same_as_billing' && locale === 'fr'}
            >
              <DivFormControl>
                <label>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.radius"
                    defaultMessage="Service radius (km)"
                  />
                </label>
                <RadiusSelect
                  color="secondary"
                  defaultValue={serviceRadius}
                  value={(form && form.service_radius) || serviceRadius}
                  onChange={onFieldChanges('service_radius')}
                  name="service_radius"
                  userSubscription={user.subscription}
                  required
                />
              </DivFormControl>
              <DivFormControl>
                <label>
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.postcode.label"
                    defaultMessage="Postcode"
                  />
                </label>
                <Select
                  color="secondary"
                  value={otherPostcodeField}
                  onChange={handleUseOtherPostcode}
                  autoWidth
                >
                  <MenuItem data-cy-menuitem="same_as_billing" value="same_as_billing">
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.postcode.same_as_billing"
                      defaultMessage="Same as billing"
                      values={{ zip: user.zip || (form && (form.zip || 0)) }}
                    />
                  </MenuItem>
                  <MenuItem data-cy-menuitem="other" value="other">
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.postcode.other"
                      defaultMessage="Other"
                    />
                  </MenuItem>
                </Select>
              </DivFormControl>
              {otherPostcodeField === 'other' && (
                <DivFormControl>
                  <label>
                    <FormattedMessage
                      tagName="span"
                      id="profile.form.postcode.radius"
                      defaultMessage="Postcode"
                    />
                  </label>
                  <TextField
                    defaultValue={user.radius_zip}
                    onChange={onFieldChanges('radius_zip')}
                    name="radius_zip"
                    fullWidth
                    required
                  />
                </DivFormControl>
              )}
            </DivServiceArea>
          </DivProfileSection>
          <DivLabelSection>
            <LabelTitle>
              <FormattedMessage
                tagName="span"
                id="profile.form.preferences"
                defaultMessage="Preferences"
              />
            </LabelTitle>
          </DivLabelSection>
          <DivProfileSection>
            <DivFormControl style={{ flexBasis: isMobile ? '100%' : '40%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.email_choice.label"
                  defaultMessage="Email preference"
                />
              </label>
              <Select
                color="secondary"
                defaultValue={user.email_choice}
                onChange={onFieldChanges('email_choice')}
                fullWidth
              >
                <MenuItem data-cy-menuitem="all_emails" value="all_emails">
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.email_choice.all_emails"
                    defaultMessage="Immediate"
                  />
                </MenuItem>
                <MenuItem data-cy-menuitem="one_email_a_day" value="one_email_a_day">
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.email_choice.one_email_a_day"
                    defaultMessage="Once a day"
                  />
                </MenuItem>
                <MenuItem data-cy-menuitem="no_emails" value="no_emails">
                  <FormattedMessage
                    tagName="span"
                    id="profile.form.email_choice.no_emails"
                    defaultMessage="No emails"
                  />
                </MenuItem>
              </Select>
            </DivFormControl>
            <JaimyFlex flexDirection="column" margin="2rem 0 0" width={isMobile ? '100%' : '50%'}>
              <JaimyButton.Base
                margin="0 0 0 auto"
                height="2.5rem"
                onClick={handleVacationPlanning}
                maxWidth="15rem"
              >
                <FormattedMessage id="calendar.vacation" />
              </JaimyButton.Base>
              {vacations &&
                vacations.map(vacation => (
                  <JaimyFlex alignItems="center" margin=".75rem 0 0" key={vacation.id}>
                    <JaimyText fontWeight={600}>
                      <FormattedMessage id="calendar.vacation.pauseMessage" /> {vacation.startDate}{' '}
                      <FormattedMessage id="calendar.vacation.pauseTo" /> {vacation.endDate}
                    </JaimyText>
                    <JaimyButton.Base
                      backgroundColor={theme.colors.secondary.light}
                      type="button"
                      onClick={() => handleToggleVacationCancel(vacation)}
                    >
                      <FormattedMessage id="cancel" />
                    </JaimyButton.Base>
                  </JaimyFlex>
                ))}
            </JaimyFlex>
          </DivProfileSection>
          <DivLabelSection>
            <LabelTitle>
              <FormattedMessage
                tagName="span"
                id="profile.form.personal_information"
                defaultMessage="Personal information"
              />
            </LabelTitle>
          </DivLabelSection>
          <DivProfileSection>
            <DivFormControl style={{ flexBasis: isMobile ? '100%' : '44%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.name"
                  defaultMessage="First Name"
                />
              </label>
              <TextField
                defaultValue={user.firstname}
                onChange={onFieldChanges('firstname')}
                name="firstname"
                required
                fullWidth
              />
            </DivFormControl>
            <DivFormControl style={{ flexBasis: isMobile ? '100%' : '54%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.lastname"
                  defaultMessage="Last Name"
                />
              </label>
              <TextField
                defaultValue={user.lastname}
                onChange={onFieldChanges('lastname')}
                name="lastname"
                required
                fullWidth
              />
            </DivFormControl>
            <DivFormControl style={{ flexBasis: '100%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.mobile_number"
                  defaultMessage="Mobile number"
                />
              </label>
              <DivPhone>
                <Select
                  name="country_code"
                  defaultValue={user.mobile_phone ? user.mobile_phone.substr(0, 3) : ''}
                  value={getValue('country_code', countryCodeDefault)}
                  onChange={onFieldChanges('country_code')}
                  color="secondary"
                  displayEmpty
                  fullWidth
                >
                  <MenuItem data-cy-list="country_code" value="+31">
                    <NlFlag />
                    &nbsp;&nbsp;+31
                  </MenuItem>
                  <MenuItem value="+32">
                    <BelFlag />
                    &nbsp;&nbsp;+32
                  </MenuItem>
                  <MenuItem value="+33">
                    <FrFlag />
                    &nbsp;&nbsp;+33
                  </MenuItem>
                  <MenuItem value="+49">
                    <DeFlag />
                    &nbsp;&nbsp;+49
                  </MenuItem>
                </Select>
                <TextField
                  error={!mobilePhoneValid}
                  defaultValue={user.mobile_phone ? user.mobile_phone.substr(3) : ''}
                  onChange={onFieldChanges('mobile_phone')}
                  type="tel"
                  name="mobile_phone"
                  required
                  fullWidth
                />
              </DivPhone>
            </DivFormControl>
            <DivFormControl style={{ flexBasis: '100%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.phone_number"
                  defaultMessage="Landline number"
                />
              </label>
              <TextField
                defaultValue={user.phone}
                onChange={onFieldChanges('phone')}
                type="tel"
                name="phone"
                fullWidth
              />
            </DivFormControl>
            <DivFormControl style={{ flexBasis: '100%' }}>
              <label>
                <FormattedMessage tagName="span" id="profile.form.email" defaultMessage="Email" />
              </label>
              <TextField
                defaultValue={user.email}
                onChange={onFieldChanges('email')}
                type="email"
                name="email"
                error={error && error.email !== null}
                required
                fullWidth
              />
            </DivFormControl>
          </DivProfileSection>
          <DivLabelSection>
            <LabelTitle>
              <FormattedMessage
                tagName="span"
                id="profile.form.your_location"
                defaultMessage="Your location"
              />
            </LabelTitle>
          </DivLabelSection>
          <DivProfileSection>
            <DivFormControl style={{ flexBasis: isMobile ? '100%' : '30%' }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.postcode"
                  defaultMessage="Business postcode"
                />
              </label>
              <TextField
                error={!zipIsValid}
                defaultValue={user.zip}
                onChange={onFieldChanges('zip')}
                type="number"
                name="zip"
                required
                fullWidth
              />
            </DivFormControl>
            <DivFormControl style={{ flexBasis: isMobile ? '100%' : '68%' }}>
              <label>
                <FormattedMessage tagName="span" id="profile.form.city" defaultMessage="City" />
              </label>
              <TextField
                defaultValue={user.city}
                onChange={onFieldChanges('city')}
                name="city"
                required
                fullWidth
              />
            </DivFormControl>
            <DivFormControl style={{ flexBasis: '100%' /* flexBasis: '74%' */ }}>
              <label>
                <FormattedMessage
                  tagName="span"
                  id="profile.form.address"
                  defaultMessage="Address"
                />
              </label>
              <TextField
                defaultValue={user.address}
                onChange={onFieldChanges('address')}
                name="address"
                required
                fullWidth
              />
            </DivFormControl>
            {/*
            <DivFormControl style={{ flexBasis: '24%' }}>
              <label>
                <FormattedMessage tagName="span" id="profile.form.street_number"
                defaultMessage="Street Number" />
              </label>
              <TextField
                defaultValue={user.streetnumber}
                onChange={onFieldChanges('streetnumber')}
                type="number"
                name="streetnumber"
                required
                fullWidth
            </DivFormControl>
          />
          */}
          </DivProfileSection>
          <DivLabelSection>
            <LabelTitle>
              <FormattedMessage
                tagName="span"
                id="profile.form.financial"
                defaultMessage="Financial information"
              />
            </LabelTitle>
          </DivLabelSection>
          <DivFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="profile.form.company"
                defaultMessage="Company name"
              />
            </label>
            <TextField
              defaultValue={user.companyname}
              onChange={onFieldChanges('companyname')}
              name="companyname"
              required
              fullWidth
            />
          </DivFormControl>
          <DivFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="profile.form.vat_number"
                defaultMessage="VAT Number"
              />
            </label>
            <TextField
              defaultValue={user.vat_number}
              onChange={onFieldChanges('vat_number')}
              name="vat_number"
              required
              disabled={
                ![
                  'not_valid',
                  'belgian_vat_number_does_not_exist',
                  'company_closed',
                  'pending'
                ].includes(user.vatValidationState)
              }
              fullWidth
            />
          </DivFormControl>
          <DivFormControl>
            <label>
              <FormattedMessage
                tagName="span"
                id="profile.form.business_website"
                defaultMessage="Business website"
              />
            </label>
            <TextField
              defaultValue={user.website_url ? user.website_url : ''}
              onChange={onFieldChanges('website_url')}
              name="website_url"
              fullWidth
            />
          </DivFormControl>
          <DivFormControl isLast>
            <label>
              <FormattedMessage
                tagName="span"
                id="profile.form.iban_account"
                defaultMessage="IBAN account"
              />
            </label>
            <TextField
              defaultValue={user.iban}
              error={!ibanIsValid}
              onChange={onFieldChanges('iban')}
              name="iban"
              type="text"
              required={true}
              fullWidth
            />
          </DivFormControl>
          {(error || !zipIsValid || !mobilePhoneValid || !ibanIsValid) && (
            <DivError data-cy-error="form-error">
              {error && error.hasOwnProperty('email') && <p>Email {error.email[0]}</p>}
              {!ibanIsValid && <p>{intl.messages['forms.signup.error.iban.invalid']}</p>}
              {!zipIsValid && <p>{intl.messages['register.step.address.error.postcode']}</p>}
              {!mobilePhoneValid && <p>{intl.messages['forms.signup.error.phone.invalid']}</p>}
            </DivError>
          )}

          <DivActions>
            <ButtonPrimary
              type="submit"
              disabled={form === null || isLoading}
              style={{ width: !isMobile ? '30%' : '' }}
            >
              {!isLoading && (
                <FormattedMessage tagName="span" id="profile.form.save" defaultMessage="Save" />
              )}
              {isLoading && <CircularProgress size={20} color="inherit" disableShrink />}
            </ButtonPrimary>
          </DivActions>
        </Form>
      </DivProfile>
      <TimerDialog
        isOpen={emailConfirmOpen}
        title={intl.messages['profile.form.logout.dialog.title']}
        onConfirm={() => {
          if ((form.country_code || form.mobile_phone) && !validatePhone(form)) {
            return;
          }
          if (form && form.vat_number) {
            TagManager.dataLayer({
              dataLayer: {
                event: 'VAT filled in'
              },
              dataLayerName: 'ProDataLayer'
            });
          }
          if (form.zip) {
            utilService.validatePostcode().then(val => {
              setZipIsValid(val);
              if (val) {
                updateProfile(form, intl.messages['profile.form.update.success']);
                setForm(null);
              }
            });
          } else {
            setZipIsValid(true);
            updateProfile(form, intl.messages['profile.form.update.success']);
            setForm(null);
          }
          setEmailConfirmOpen(false);
        }}
        onClose={() => setEmailConfirmOpen(false)}
      >
        {intl.messages['profile.form.logout.dialog.text']}
      </TimerDialog>

      <VacationPlanningDialog open={openVacationPlanning} handleClose={handleVacationPlanning} />
      <VacationCancelDialog
        vacation={vacationToCancel}
        open={openVacationCancel}
        handleClose={handleCloseVacationCancel}
      />
    </>
  );
};

// Profile.defaultProps = {
//   user: null,
//   matchingLocales: null,
//   mobilePhone: null,
//   isLoading: null,
//   categories: null,
//   updateProfile: null,
//   locale: null,
//   error: null,
//   serviceRadius: null,
//   catError: null
// };

// Profile.propTypes = {
//   user: UserPropType,
//   matchingLocales: ArrayStringPropType,
//   mobilePhone: StringPropType,
//   isLoading: BoolPropType,
//   updateProfile: FuncPropType,
//   locale: StringPropType,
//   error: ObjectPropType,
//   serviceRadius: NumberPropType,
//   getCategories: FuncPropType.isRequired,
//   categories: ObjectPropType.isRequired,
//   catLoading: BoolPropType.isRequired,
//   catError: BoolPropType
// };

const mapStateToProps = ({ user: { user }, categories, vacation: { vacations } }) => {
  return {
    user,
    locale: user.locale,
    isLoading: user.isLoading,
    error: user.error,
    iban: user.iban,
    serviceRadius: user.service_radius,
    email: user.email,
    mobilePhone: user.mobile_phone,
    matchingLocales: user.matching_locales,
    categories: categories.tree,
    catLoading: categories.loading,
    catError: categories.error,
    vacations
  };
};
const mapDispatchToProps = dispatch => ({
  updateProfile: (payload, snackbarText) => dispatch(updateUserProfile(payload, snackbarText)),
  hasUpdatedProfile: () => dispatch(hasUpdatedUserProfile()),
  getCategories: payload => dispatch(requestCategories(payload)),
  getAllVacations: traderId => dispatch(getAllVacations(traderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
