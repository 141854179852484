import theme from '../../../../theme';

import { Dialog } from '@material-ui/core';

import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import { FormattedMessage } from 'react-intl';
import VacationPlanningForm from './Form/VacationPlanningForm';
import { DialogContent } from '@mui/material';

interface IVacationPlanningDialogProps {
  open: boolean;
  handleClose: () => void;
  openedFromPrebook?: boolean;
  calendarId?: string;
  prebookId?: string;
}

const VacationPlanningDialog = ({
  open,
  handleClose,
  calendarId,
  openedFromPrebook,
  prebookId
}: IVacationPlanningDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="plan-vacation" scroll="paper">
      <DialogContent>
        <JaimyFlex padding="2rem" flexDirection="column">
          <JaimyHeading.H3 color={theme.colors.secondary.base}>
            <FormattedMessage id={'calendar.vacation.title'} />
          </JaimyHeading.H3>

          <VacationPlanningForm
            prebookId={prebookId}
            calendarId={calendarId}
            openedFromPrebook={openedFromPrebook}
            handleClose={handleClose}
          />
        </JaimyFlex>
      </DialogContent>
    </Dialog>
  );
};

export default VacationPlanningDialog;
