import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as MenuIcon } from '../../assets/images/menu-hamb-icon.svg';
import { PropTypes } from 'prop-types';
import { ROUTES } from '../../routes';
import { connect } from 'react-redux';
import { ComponentIsVisible } from '../utils/isVisible';
import {
  allowedToAccessInvoice,
  allowedToAccessMyCalendar,
  allowedToAccessMyPrebook,
  allowedToAccessMyProfile,
  allowedToSeeJobList
} from '../../constants/verifiedStatus';
import { PremiumSub, proOrPremiumSub } from '../../constants/subscriptions';

export const LiLanguage = styled.li`
  opacity: ${props => (props.isCurrent ? '1' : '0.5')};
`;
const LanguageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: ${props => (props.isOpen ? 'block' : props.isClosing ? 'none' : 'none')};
  animation: ${props => (props.isOpen ? langAnim : props.isClosing ? langCloseAnim : '')} 0.8s
    linear;
`;
const UlLanguage = styled.ul`
  position: static;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0;

  > li {
    margin: 20px 6px;
    color: #32546d;
    font-size: 14pt;
    display: inline;
    cursor: pointer;
    padding: 1rem;
  }

  > li:not(:last-child) {
    border-right: 1px solid lightgrey;
    padding-right: 1rem;
    margin-right: 0px;
  }
`;
const Button = styled.button`
  background: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.317116);
  box-sizing: border-box;
  border-radius: 4px;
  color: #32546d;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  height: 40px;
  margin-left: 0.8rem;
  cursor: pointer;

  > svg {
    fill: #32546d;
    display: block;
  }
`;
const DivWrapped = styled.div`
  button > span {
    margin-left: 0.6rem;
  }
`;
const langAnim = keyframes`
   0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
`;
const langCloseAnim = keyframes`
   0% {
        display: block;
        opacity: 1;
    }

    1% {
        display: none;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
`;
const navAnim = keyframes`
  from {
    top: 100%;
  }

  to {
    top: 68px;
  }
`;
const navCloseAnim = keyframes`
  from {
    top: 68px;
  }
  
  to {
    top: 100%;
  }
`;
const NavMenu = styled.nav`
  display: block;
  background-color: #fff;
  position: fixed;
  top: ${props => (props.isOpen ? '68px' : '100%')};
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem 4rem;
  overflow: hidden;
  animation: ${props => (props.isOpen ? navAnim : props.isClosing ? navCloseAnim : '')} 0.4s linear;
  z-index: 3;
`;
const Anchor = styled(Link)`
  color: #32546d;
  cursor: pointer;
  display: block;
  text-align: left;
  margin: 1rem 0;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
`;
const AnchorA = styled.a`
  color: #32546d;
  cursor: pointer;
  display: block;
  text-align: left;
  margin: 1rem 0;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
`;
const AnchorCustomerSite = styled.a`
  color: #32546d;
  cursor: pointer;
  display: block;
  text-align: left;
  margin: 1rem 0;
  padding-top: 1rem;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  border-top: 1px solid #32546d;
`;

export const NavButton = ({
  isOpen,
  isLogged,
  locale,
  setLanguage,
  localeLocalStorage,
  logout,
  subType,
  userVerifiedStatus
}) => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(isOpen);
  const [closing, setClosing] = useState(false);
  const onToggle = () => {
    setMenuOpen(!isMenuOpen);
    setClosing(true);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMenuOpen]);

  return (
    <DivWrapped>
      <Button onClick={onToggle}>{isMenuOpen ? <CloseIcon /> : <MenuIcon />}</Button>
      <NavMenu isOpen={isMenuOpen} isClosing={closing}>
        {!isLogged && (
          <Anchor to={`${ROUTES.LOGIN.handler(location.search)}`} onClick={onToggle}>
            <FormattedMessage tagName="span" id="mainheader.link.login" />
          </Anchor>
        )}
        {isLogged && (
          <>
            <ComponentIsVisible when={allowedToSeeJobList.includes(userVerifiedStatus)}>
              <Anchor to={`${ROUTES.JOBS.handler()}`} onClick={onToggle}>
                <FormattedMessage tagName="span" id="navbutton.link.jobs" />
              </Anchor>
            </ComponentIsVisible>
            <Anchor to={`${ROUTES.PURCHASED.handler()}`} onClick={onToggle}>
              <FormattedMessage tagName="span" id="navbutton.link.purchased" />
            </Anchor>
            <ComponentIsVisible when={allowedToAccessInvoice.includes(userVerifiedStatus)}>
              <Anchor to={`${ROUTES.INVOICES.handler()}`} onClick={onToggle}>
                <FormattedMessage tagName="span" id="navbutton.link.invoices" />
              </Anchor>
            </ComponentIsVisible>
            <ComponentIsVisible when={allowedToAccessMyCalendar.includes(userVerifiedStatus)}>
              <ComponentIsVisible when={PremiumSub.includes(subType)}>
                <Anchor to={`${ROUTES.CALENDAR.handler()}`} onClick={onToggle}>
                  <FormattedMessage tagName="span" id="navbutton.link.calendar" />
                </Anchor>
              </ComponentIsVisible>
            </ComponentIsVisible>
            <ComponentIsVisible when={allowedToAccessMyPrebook.includes(userVerifiedStatus)}>
              <ComponentIsVisible when={proOrPremiumSub.includes(subType)}>
                <Anchor to={`${ROUTES.PREBOOK.handler()}`} onClick={onToggle}>
                  <FormattedMessage tagName="span" id="navbutton.link.prebook" />
                </Anchor>
              </ComponentIsVisible>
            </ComponentIsVisible>
            <ComponentIsVisible when={allowedToAccessMyProfile.includes(userVerifiedStatus)}>
              <Anchor to={`${ROUTES.PROFILE.handler()}`} onClick={onToggle}>
                <FormattedMessage tagName="span" id="navbutton.link.profile" />
              </Anchor>
            </ComponentIsVisible>
            <AnchorA onClick={logout}>
              <FormattedMessage tagName="span" id="navbutton.link.log_out" />
            </AnchorA>
          </>
        )}
        <Anchor to={`${ROUTES.HELP.handler()}`} onClick={onToggle}>
          <FormattedMessage tagName="span" id="mainheader.link.help" />
        </Anchor>
        <AnchorCustomerSite href={`https://jaimy.be${window.location.search}`}>
          <FormattedMessage tagName="span" id="mainheader.link.customer" />
        </AnchorCustomerSite>
        <LanguageWrapper isOpen={isMenuOpen} isClosing={closing}>
          <UlLanguage>
            <LiLanguage
              key="fr"
              role="button"
              isCurrent={isLogged ? locale === 'fr' : localeLocalStorage === 'fr'}
              onClick={() => setLanguage({ locale: 'fr' })}
            >
              <FormattedMessage tagName="span" id="navbutton.language.fr" />
            </LiLanguage>
            <LiLanguage
              key="nl"
              role="button"
              isCurrent={
                isLogged ? locale === 'nl' : localeLocalStorage === 'nl' || !localeLocalStorage
              }
              onClick={() => setLanguage({ locale: 'nl' })}
            >
              <FormattedMessage tagName="span" id="navbutton.language.nl" />
            </LiLanguage>
          </UlLanguage>
        </LanguageWrapper>
      </NavMenu>
    </DivWrapped>
  );
};

NavButton.defaultProps = {
  isOpen: false,
  locale: 'nl',
  localeLocalStorage: 'nl'
};
NavButton.propTypes = {
  isOpen: PropTypes.bool,
  setLanguage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isLogged: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  localeLocalStorage: PropTypes.string
};

const mapStateToProps = ({ user: { user } }) => ({ locale: user.locale });

export default connect(mapStateToProps, null)(NavButton);
