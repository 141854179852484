import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteExpired, loadExpired } from '../../store/reducers/cart/actions';
import { ArrayPropType, BoolPropType, StringPropType } from '../../util/PropTypeValues';

import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { centsToEuro } from '../../util/priceCents';

const DivExpiredList = styled.div`
  display: inline-block;
  width: 100%;
  background-color: #f3f4f6;
  border-radius: 4px;
  border: solid 1px #d2d2d7;
  font-family: 'Lato';
  color: #778d9b;
  margin: 1rem 0 4rem;
  > .title {
    align-items: center;
    padding: 1rem;
    display: flex;
    user-select: none;
    width: 100%;
    border: none;
    background-color: #f3f4f6;
    border-bottom: solid 1px #d2d2d7;
    color: #778d9b;
    outline: none;
    cursor: pointer;
    > h3 {
      margin-right: 1rem;
      font-family: BelfiusNormal;
      font-size: 24px;
      font-weight: bold;
      color: #182a35;
    }
    > span {
      font-size: 14px;
      > span {
        color: #182a35;
      }
    }
    > svg {
      margin-left: auto;
    }
  }
  > .content {
    > div {
      margin: 1.5rem;
    }
  }
`;
const ButtonMain = styled.button`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 9pt;
  min-width: 70px;
  font-weight: 700;
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: 0;
  }
  cursor: pointer;
  color: #c30144;
  &:hover {
    text-decoration: underline;
  }
`;
const DivCartItems = styled.div`
  margin-bottom: 2rem;
  > div {
    cursor: default;
    flex-wrap: ${props => props.isMobile && 'wrap'};
    display: flex;
    margin-top: 1rem;
    padding-bottom: 1rem;
    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d7;
    }
  }
`;
const DivItemInfo = styled.div`
  min-width: 50%;
  margin-right: ${props => (!props.isMobile ? '1rem' : '0')};
  width: 100%;
  > h2 {
    font-size: 20px;
    font-weight: 'bold';
    font-family: BelfiusNormal, sans-serif;
  }
  > span {
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
  }
`;
const DivLocation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  > div {
    max-width: 30%;
    > h3 {
      text-transform: uppercase;
      font-size: 10px;
    }
    > span {
      font-size: 14px;
      line-height: 100%;
      display: block;
    }
  }
`;
const DivItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50%;
  width: 100%;
  > p {
    margin-bottom: 1rem;
  }
  > div {
    display: flex;
    > span:last-child {
      margin-left: auto;
      font-family: 'BelfiusNormal', sans-serif;
      font-weight: bold;
      font-size: 20px;
    }
  }
`;
const DivItemAmount = styled.div`
  margin-right: 20px;
`;
const CartExpired = ({
  loadExpired,
  deleteExpired,
  expired,
  isLoadingExpired,
  hasErrorExpired,
  locale
}) => {
  const intl = useIntl();
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    loadExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDeleteExpired = () => {
    if (expired.length > 0) {
      deleteExpired();
    }
    setIsCollapsed(false);
  };

  if (expired.length === 0) return null;

  return (
    <DivExpiredList>
      <button type="button" onClick={() => setIsCollapsed(!isCollapsed)} className="title">
        <h3>{intl.messages['cart.expired']}</h3>
        <span>
          <FormattedMessage id="cart.length" values={{ count: expired.length }} />
          <FormattedMessage id="cart.removed_from_cart" values={{ count: expired.length }} />
        </span>
        {isCollapsed ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
      </button>
      <Collapse className="content" in={isCollapsed}>
        <DivCartItems isMobile={isMobile}>
          {expired &&
            expired.map(item => (
              <div key={item.id}>
                <DivItemInfo isMobile={isMobile}>
                  <span>
                    {!!item[`category${locale !== 'en' ? `_${locale}` : ''}`][1] &&
                      item[`category${locale !== 'en' ? `_${locale}` : ''}`][0]}
                  </span>
                  <h2>
                    {item[`category${locale !== 'en' ? `_${locale}` : ''}`][1] ||
                      item[`category${locale !== 'en' ? `_${locale}` : ''}`][0]}
                  </h2>
                  <DivLocation>
                    <div>
                      <h3>{intl.messages['servicecardlist.card.location']}</h3>
                      <span>
                        {`${item.postcode} ${
                          locale === 'fr' ? item.townNames.fr : item.townNames.nl
                        }`}
                      </span>
                    </div>
                    <div>
                      <h3>{intl.messages['servicecardlist.card.distance']}</h3>
                      <span>{`${item.distance_in_km} km`}</span>
                    </div>
                    <div>
                      <h3>{intl.messages['serviceestimated.jobdate.label']}</h3>
                      <FormattedMessage
                        tagName="span"
                        id={`serviceestimated.jobdate.${item.job_date}`}
                      />
                    </div>
                  </DivLocation>
                </DivItemInfo>
                <DivItemDetails>
                  <p>{item.description}</p>
                  <DivItemAmount>
                    {item.proposed_by && (
                      <span>
                        <InfoOutlinedIcon fontSize="inherit" />
                        {intl.messages['cart.added_by_jaimy']}
                      </span>
                    )}
                    <FormattedMessage
                      tagName="span"
                      id="cart.price"
                      values={{ price: centsToEuro(item.price_cents) }}
                    />
                  </DivItemAmount>
                </DivItemDetails>
              </div>
            ))}
        </DivCartItems>
        <ButtonMain onClick={onDeleteExpired}>
          <CloseIcon fontSize="inherit" />
          Clear All
        </ButtonMain>
      </Collapse>
    </DivExpiredList>
  );
};

CartExpired.defaultProps = {
  expired: [],
  isLoadingExpired: false,
  hasErrorExpired: false,
  isMobile: false,
  locale: 'nl'
};

CartExpired.propTypes = {
  expired: ArrayPropType,
  isLoadingExpired: BoolPropType,
  hasErrorExpired: BoolPropType,
  isMobile: BoolPropType,
  locale: StringPropType
};

const mapStateToProps = ({
  cart: { hasErrorExpired, isLoadingExpired, expired },
  user: {
    user: { locale }
  }
}) => ({
  hasErrorExpired,
  isLoadingExpired,
  expired,
  locale
});
const mapDispatchToProps = {
  loadExpired,
  deleteExpired
};
export default connect(mapStateToProps, mapDispatchToProps)(CartExpired);
