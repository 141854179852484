import React from 'react';
import styled, { keyframes } from 'styled-components';
import JaimyFlex from '../../../theme/components/layout/Flex';

const RelevantJobsLoading = () => (
  <JaimyFlex flexDirection="column" gap="1rem" width="80%" margin="0 auto">
    <RelevantJobsCard />
    <RelevantJobsCard />
    <RelevantJobsCard />
  </JaimyFlex>
);

export default RelevantJobsLoading;

const loading = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

const RelevantJobsCard = styled.div`
  background-color: ${props => props.theme.colors.white};
  animation: ${loading} 1.5s ease-in-out infinite;
  border: 1px solid ${props => props.theme.colors.background.dark};

  border-radius: 8px;
  width: 100%;
  height: 85px;
  margin: 0.5rem auto;
  padding: 0 2.4rem;
  max-width: 1200px;

  @media (max-width: 768px) {
    width: 300px;
    margin: 0 auto;
    padidng: 0;
  }
`;
