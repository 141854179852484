const {
  CART_LOAD,
  CART_LOAD_ERROR,
  CART_LOAD_SUCCESS,
  CART_ADD_ITEM,
  CART_ADD_ITEM_ERROR,
  CART_ADD_ITEM_SUCCESS,
  CART_MERGE_ALL,
  CART_REPLACE_ALL,
  CART_REMOVE_ITEM,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_ERROR,
  CART_CHECKOUT_REQUEST,
  CART_CHECKOUT_AWATING_APPROVAL,
  CART_CHECKOUT_REQUEST_ERROR,
  CART_RESET_HAS_ERROR,
  CART_INVOICE_REQUEST,
  CART_INVOICE_AWATING_APPROVAL,
  CART_INVOICE_REQUEST_ERROR,
  CART_EXPIRED_DELETE_ERROR,
  CART_EXPIRED_DELETE_REQUEST,
  CART_EXPIRED_DELETE_SUCCESS,
  CART_EXPIRED_ERROR,
  CART_EXPIRED_REQUEST,
  CART_EXPIRED_SUCCESS,
  REQUEST_PAYMENT_STATUS_START,
  REQUEST_PAYMENT_STATUS_SUCCESS,
  REQUEST_PAYMENT_STATUS_ERROR
} = require('./actionTypes');

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  hasError: false,
  cart_size: 0,

  hasErrorExpired: false,
  isLoadingExpired: false,
  expired: [],

  paymentStatus: null,
  paymentStatusLoading: false,
  paymentStatusError: null
};
export const reducer = (state = initialState, action) => {
  let { data } = state;
  switch (action.type) {
    case CART_LOAD:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };
    case CART_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        error: null,
        hasError: false
      };
    case CART_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
        hasError: false
      };
    case CART_ADD_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case CART_ADD_ITEM_SUCCESS:
      data = state.data;
      if (data.length > 0) {
        const item = data.find(e => e.id === action.payload.cart_id);
        const itemIndex = data.findIndex(e => e.id === action.payload.cart_id);
        if (itemIndex === -1) {
          const item = action.payload;
          data.push(item);
        } else {
          data[itemIndex] = item;
        }
      } else {
        const item = action.payload;
        data.push(item);
      }

      return {
        ...state,
        cart_size: state.cart_size ? state.cart_size + 1 : 1,
        data,
        isLoading: false,
        error: null,
        hasError: false
      };

    case CART_ADD_ITEM_ERROR:
      data = state.data;

      return {
        ...state,
        cart_size: data.length,
        isLoading: false,
        error: action.payload,
        hasError: false
      };

    case CART_MERGE_ALL: {
      let dataConcated = action.payload ? action.payload.concat(data) : data;
      const result = [];
      const map = new Map();
      if (dataConcated && dataConcated.length > 0) {
        dataConcated.forEach(e => {
          if (!map.has(e.id)) {
            map.set(e.id, true);
            result.push(e);
          }
        });
        dataConcated = result;
      }

      const priceList = result.map(e => e.price_cents);
      const priceCents = priceList.reduce((acc, cv) => parseFloat(acc) + parseFloat(cv), 0);
      const price = priceCents / 100;

      return {
        ...state,
        ...action.payload,
        data: dataConcated,
        cart_price: price,
        cart_size: dataConcated.length,
        isLoading: false,
        error: null,
        hasError: false
      };
    }

    case CART_REPLACE_ALL: {
      const priceList = action.payload.map(e => e.price_cents);
      const priceCents = priceList.reduce((acc, cv) => parseFloat(acc) + parseFloat(cv), 0);
      const price = priceCents / 100;
      return {
        ...state,
        data: [...action.payload],
        cart_price: price,
        cart_size: priceList.length,
        isLoading: false,
        error: null,
        hasError: false
      };
    }

    case CART_REMOVE_ITEM:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case CART_REMOVE_ITEM_SUCCESS:
      data = state.data;
      data = data.filter(e => e.id !== action.payload);
      const priceList = data.map(e => e.price_cents);
      const priceCents = priceList.reduce((acc, cv) => parseFloat(acc) + parseFloat(cv), 0);

      return {
        ...state,
        cart_size: state.cart_size - 1,
        cart_price: priceCents,
        data,
        isLoading: false,
        error: null,
        hasError: false
      };

    case CART_REMOVE_ITEM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        hasError: false
      };
    case CART_EXPIRED_REQUEST:
      return {
        ...state,
        isLoadingExpired: true,
        hasErrorExpired: false
      };
    case CART_EXPIRED_SUCCESS:
      return {
        ...state,
        isLoadingExpired: false,
        expired: action.payload
      };
    case CART_EXPIRED_ERROR:
      return {
        ...state,
        isLoadingExpired: false,
        hasErrorExpired: true
      };
    case CART_EXPIRED_DELETE_REQUEST:
      return {
        ...state,
        isLoadingExpired: true,
        hasErrorExpired: false
      };
    case CART_EXPIRED_DELETE_SUCCESS:
      return {
        ...state,
        isLoadingExpired: false,
        expired: []
      };
    case CART_EXPIRED_DELETE_ERROR:
      return {
        ...state,
        isLoadingExpired: false,
        hasErrorExpired: true
      };

    case CART_CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case CART_CHECKOUT_AWATING_APPROVAL:
      return {
        ...state,
        isLoading: true,
        redirect_url: action.payload,
        error: null,
        hasError: false
      };

    case CART_CHECKOUT_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        redirect_url: null,
        error: action.payload,
        hasError: true
      };

    case CART_RESET_HAS_ERROR:
      return {
        ...state
      };

    case CART_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case CART_INVOICE_AWATING_APPROVAL:
      return {
        ...state,
        isLoading: true,
        redirect_url: action.payload,
        error: null,
        hasError: false
      };

    case CART_INVOICE_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        redirect_url: null,
        error: action.payload,
        hasError: true
      };
    case REQUEST_PAYMENT_STATUS_START:
      return {
        ...state,
        paymentStatusLoading: true,
        paymentStatusError: null
      };
    case REQUEST_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatusLoading: action.payload.paymentStatus === 'pending',
        paymentStatus: action.payload.paymentStatus
      };
    case REQUEST_PAYMENT_STATUS_ERROR:
      return {
        ...state,
        paymentStatusLoading: false,
        paymentStatusError: action.payload.error
      };

    default:
      return state;
  }
};
