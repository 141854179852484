import theme from '../../../../theme';

import { Dialog } from '@material-ui/core';

import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import { FormattedMessage } from 'react-intl';
import JaimyText from '../../../../theme/components/Typography/Text';
import JaimyButton from '../../../../theme/components/Button';
import { connect } from 'react-redux';
import { FixMeLater } from '../../../../types/FixMeLater';
import { useCallback } from 'react';
import { AnyAction } from 'redux';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { CircularProgress } from '@mui/material';
import {
  IGetCalendarVacationsRequestAction,
  getAllVacationsOfCalendar,
  deleteVacation,
  getAllVacationsOfPrebook,
  IDeleteVacationRequestAction,
  IGetPrebookVacationsRequestAction,
  getAllVacations
} from '../../../../store/reducers/vacation/actions';
import { IVacationState } from '../../../../store/reducers/vacation/reducer';
import { ICalendarVacation, IPrebookVacation, IVacation } from '../../../../types/Vacation';

interface IVacationCancelDialogProps {
  calendarId?: string;
  prebookId?: string;
  open: boolean;
  handleClose: () => void;
  deleteVacation: (action: IDeleteVacationRequestAction) => AnyAction;
  deleteVacationLoading: boolean;
  userId: number;
  vacation?: ICalendarVacation | IPrebookVacation | IVacation;
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) => AnyAction;
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) => AnyAction;
  getAllVacations: (traderId: number) => AnyAction;
}

const VacationCancelDialog = ({
  calendarId,
  prebookId,
  open,
  handleClose,
  deleteVacation,
  deleteVacationLoading,
  userId,
  vacation,
  getAllVacationsOfCalendar,
  getAllVacationsOfPrebook,
  getAllVacations
}: IVacationCancelDialogProps) => {
  const handleCancelVacation = useCallback(() => {
    if (!userId || !vacation) {
      return;
    }

    deleteVacation({
      payload: {
        traderId: userId,
        status: 'cancelled',
        pauseId: vacation.id
      },
      functions: {
        reloadVacations: () => {
          if (calendarId) {
            getAllVacationsOfCalendar({
              payload: {
                calendarId: calendarId as string,
                traderId: userId
              }
            });
          }
          if (prebookId) {
            getAllVacationsOfPrebook({
              payload: {
                prebookId: prebookId as string,
                traderId: userId
              }
            });
          }
          if (!calendarId || !prebookId) {
            getAllVacations(userId);
          }
        },
        closeModal: () => handleClose()
      }
    });
  }, [
    userId,
    vacation,
    deleteVacation,
    calendarId,
    prebookId,
    getAllVacationsOfCalendar,
    getAllVacationsOfPrebook,
    getAllVacations,
    handleClose
  ]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={handleClose} aria-labelledby="plan-vacation">
      <JaimyFlex padding="2rem" flexDirection="column">
        <JaimyHeading.H3 color={theme.colors.secondary.base}>
          <FormattedMessage id="calendar.vacationCancel.title" />
        </JaimyHeading.H3>

        <JaimyText margin="1.5rem 0">
          <FormattedMessage id="calendar.vacationCancel.text" />
        </JaimyText>
        <JaimyFlex gap="8px" margin="1.5rem auto 0" width="fit-content">
          <JaimyButton.BaseSecondary type="button" onClick={handleClose}>
            <FormattedMessage id="calendar.cancel" />
          </JaimyButton.BaseSecondary>
          <JaimyButton.Base type="button" onClick={handleCancelVacation} width="10rem">
            <ComponentIsVisible when={!deleteVacationLoading}>
              <FormattedMessage id="calendar.submit" />
            </ComponentIsVisible>
            <ComponentIsVisible when={deleteVacationLoading}>
              <CircularProgress size={20} color="inherit" disableShrink />
            </ComponentIsVisible>
          </JaimyButton.Base>
        </JaimyFlex>
      </JaimyFlex>
    </Dialog>
  );
};

const mapStateToProps = ({
  user: {
    user: { id }
  },
  vacation: { deleteVacationLoading }
}: {
  user: FixMeLater;
  prebooks: FixMeLater;
  vacation: IVacationState;
}) => ({
  userId: id,
  deleteVacationLoading
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  deleteVacation: (action: IDeleteVacationRequestAction) => dispatch(deleteVacation(action)),
  getAllVacationsOfCalendar: (action: IGetCalendarVacationsRequestAction) =>
    dispatch(getAllVacationsOfCalendar(action)),
  getAllVacationsOfPrebook: (action: IGetPrebookVacationsRequestAction) =>
    dispatch(getAllVacationsOfPrebook(action)),
  getAllVacations: (traderId: number) => dispatch(getAllVacations(traderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(VacationCancelDialog);
