import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IJaimyLinkProps {
  alignItems?: string;
  color?: string;
  display?: string;
  fontSize?: string;
  cursor?: string;
  fontStyle?: string;
  fontWeight?: number | string;
  letterSpacing?: string;
  fontFamily?: string;
  lineHeight?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  textAlign?: string;
  textDecoration?: string;
  textTransform?: string;
  variant?: 'preHeader' | 'preHeaderBorder' | 'preHeaderBorderHeavierGray';
  width?: string;
}

const JaimyLink = styled(Link)<IJaimyLinkProps>`
  color: ${props => (props.color ? props.color : '#c30b30')};
  display: ${props => (props.display ? props.display : '14px')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'Lato, sans-serif')};
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
  text-decoration: ${props => (props.textDecoration ? props.textDecoration : 'none')};

  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-style: ${props => props.fontStyle && props.fontStyle};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 400)};
  letter-spacing: ${props => props.letterSpacing && props.letterSpacing};
  line-height: ${props => props.lineHeight && props.lineHeight};
  margin: ${props => props.margin && props.margin};
  padding: ${props => props.padding && props.padding};
  text-align: ${props => props.textAlign && props.textAlign};
  text-transform: ${props => props.textTransform && props.textTransform};
  width: ${props => props.width && props.width};

  &:hover {
    text-decoration: underline;
  }
`;

export default JaimyLink;
