import { API_URL } from '../../util/url';
import { handleResult } from '../../util/serviceHelpers';

class UtilService {
  /**
   * Validate zipcode
   */
  async validatePostcode(postcode) {
    return fetch(`${API_URL}/api/backoffice/v1/postcodes/${postcode}`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
      .then(handleResult)
      .then(res => res.length > 0)
      .catch(error => console.error(error.message));
  }
  /**
   * Get categories
   */
  async getCategories(locale) {
    return fetch(
      `${API_URL}/api/traders/v1/treetrade/tree${
        locale ? `?sort=alphabetical&locale=${locale}` : ''
      }`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      }
    ).then(handleResult);
  }
}

export default UtilService;
