export const centsToEuro = e => e / 100;
export const euroToCents = e => e * 100;
export const priceWithoutVAT = e => e - e * 0.21;

export const calculateJobPrice = (price, credits, discount_of = 0) => {
  const totalExclVat = price * (1 - discount_of / 100);
  const totalCreditsUsed = Math.min(credits, totalExclVat);
  const totalPaymentExclVat = totalExclVat - Math.min(totalCreditsUsed, totalExclVat);
  const totalPaymentInclVat = Math.round(totalPaymentExclVat * 1.21);
  const totalCreditsLeft = credits - totalCreditsUsed;

  return {
    totalExclVat,
    totalCreditsUsed,
    totalPaymentExclVat,
    totalPaymentInclVat,
    totalCreditsLeft
  };
};
