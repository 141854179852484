import { CATEGORIES_FAILED, CATEGORIES_STARTED, CATEGORIES_SUCCESS } from './actionTypes';
import UtilService from '../../../lib/services/utilService';

const utilService = new UtilService();

export const requestCategories = locale => {
  return async dispatch => {
    dispatch(requestCategoriesStarted());

    utilService
      .getCategories(locale)
      .then(result => {
        dispatch(requestCategoriesSuccess(result));
      })
      .catch(err => {
        dispatch(requestCategoriesFailure(err));
      });
  };
};

const requestCategoriesSuccess = tree => ({
  type: CATEGORIES_SUCCESS,
  payload: {
    ...tree
  }
});

const requestCategoriesStarted = () => ({
  type: CATEGORIES_STARTED
});

const requestCategoriesFailure = error => ({
  type: CATEGORIES_FAILED,
  payload: {
    error
  }
});
