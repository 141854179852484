import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../theme';

export const BannedPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 102;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannedPopupContent = styled.div`
  width: 100%;
  max-width: 500px;

  padding: 2rem;

  background-color: ${theme.colors.white};
  border: 1px solid '#dedede';
  border-radius: 1.5rem;
`;

export const BannedPopupTitleTag = styled.div`
  background-color: ${theme.colors.primary.base};

  margin-left: -2rem;
  padding: 0 2rem 0 1.5rem;

  height: 40px;

  display: flex;
  align-items: center;

  position: relative;

  &::after {
    content: ' ';
    height: 28px;
    width: 28px;

    background-color: ${theme.colors.primary.base};

    transform: rotateZ(135deg);

    position: absolute;
    left: calc(100% - 0.925rem);
  }
`;

export const BannedPopupLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const Logo = styled.img`
  height: auto;
  margin: 3rem auto 1rem;
  width: 80px;
`;
