export const formatTitle = (sr, locale) =>
  sr.category &&
  sr.category.length > 0 &&
  (sr.category_fr[0] && locale === 'fr'
    ? sr.category_fr[0]
    : sr.category_nl[0] && locale === 'nl'
    ? sr.category_nl[0]
    : sr.category[0]);

export const formatSubtitle = (sr, locale) =>
  sr.category &&
  sr.category.length > 0 &&
  (sr.category[1] && locale === 'fr'
    ? sr.category_fr[1]
    : sr.category_nl[1] && locale === 'nl'
    ? sr.category_nl[1]
    : sr.category[1]);

export const showSubtitle = sr =>
  sr.category &&
  sr.category.length > 1 &&
  !(sr.category[1] && sr.category[1].toLowerCase().trim() === 'other');
