import { IconButton } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LensIcon from '@material-ui/icons/Lens';
import Carousel from 'nuka-carousel';
import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import theme from '../../theme';

interface NukaCarouselProps {
  afterSlide?: () => void;
  items: any;
}

const NukaCarousel = ({ afterSlide, items }: NukaCarouselProps) => {
  const { width } = useWindowDimensions();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const responsive = useMemo(() => {
    if (width <= 768) {
      return 1;
    }
    if (width < 900) {
      return 2;
    }
    if (width < 1200) {
      return 3;
    }
    return undefined;
  }, [width]);

  return (
    <Carousel
      afterSlide={index => setActiveIndex(index)}
      slidesToShow={responsive || 1}
      autoplay
      adaptiveHeight
      autoplayInterval={3500}
      dragging
      wrapAround
      enableKeyboardControls
      defaultControlsConfig={{
        pagingDotsContainerClassName: 'customContainerDots',
        pagingDotsClassName: 'customDots',
        pagingDotsStyle: {
          fill: theme.colors.secondary.base
        }
      }}
      renderCenterLeftControls={({ previousDisabled, previousSlide }) =>
        previousDisabled ? (
          <button onClick={previousSlide} disabled={previousDisabled}>
            Previous
          </button>
        ) : null
      }
      renderCenterRightControls={({ nextSlide }) =>
        items.length !== 1 ? (
          <IconButton onClick={nextSlide} style={{ backgroundColor: 'lightgrey' }}>
            <KeyboardArrowRightIcon />
          </IconButton>
        ) : null
      }
      renderBottomCenterControls={({ currentSlide, goToSlide, slideCount }) => (
        <div
          style={{
            left: '50%',
            bottom: '-65px',
            position: 'absolute',
            transform: 'translateX(-50%)'
          }}
        >
          <ul
            style={{
              position: 'relative',
              top: '-10px',
              display: 'flex',
              margin: '0px',
              padding: '0px',
              listStyleType: 'none'
            }}
          >
            {[...Array(slideCount)].map((_e, key) => (
              <li key={uuidv4()}>
                <IconButton
                  aria-label={`slider ${key} button-control`}
                  onClick={() => goToSlide(key)}
                  color={currentSlide === key ? 'primary' : 'default'}
                >
                  <LensIcon />
                </IconButton>
              </li>
            ))}
          </ul>
        </div>
      )}
    >
      {items.map((item: any) => (
        <div key={uuidv4()}>{item}</div>
      ))}
    </Carousel>
  );
};

export default NukaCarousel;
