import { CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import theme from '../../../theme';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';
import { FixMeLater } from '../../../types/FixMeLater';
import DashboardCard from '../../cards/DashboardCard';
import { ReactComponent as FavoriteIcon } from '../../../assets/images/favorite-icon.svg';
import { ComponentIsVisible } from '../../utils/isVisible';
import { DashboardReviewItem } from './Item';

const DashboardReviews = () => {
  const compliment = useSelector((state: FixMeLater) => state.user.user.compliment);
  const gradeIR = useSelector((state: FixMeLater) => state.user.user.grade_ir);
  const gradeRFQ = useSelector((state: FixMeLater) => state.user.user.grade_rfq);
  const isLoading = useSelector((state: FixMeLater) => state.user.isLoading);

  return (
    <DashboardCard>
      <JaimyFlex padding="10px" alignItems="center" justifyContent="center">
        <JaimyHeading.H3 fontSize="1.2rem" color={theme.colors.blue.darkBlue} textAlign="center">
          <FavoriteIcon
            width={22}
            height={22}
            fill={theme.colors.primary.dark}
            style={{ marginRight: '10px' }}
          />
          <FormattedMessage id="dashboard.reviews" />
        </JaimyHeading.H3>
      </JaimyFlex>
      <ComponentIsVisible when={isLoading}>
        <JaimyFlex justifyContent="center" alignItems="center">
          <CircularProgress />
        </JaimyFlex>
      </ComponentIsVisible>
      <ComponentIsVisible when={!isLoading}>
        <JaimyFlex
          flexDirection="column"
          alignItems="center"
          color={theme.colors.secondary.base}
          gap=".5rem"
          margin="1rem .5rem"
        >
          {!gradeIR?.grade && !gradeRFQ?.grade ? (
            <JaimyText textAlign="center">
              <FormattedMessage id="dashboard.reviews.empty" />
            </JaimyText>
          ) : (
            <>
              {gradeIR.grade > 0 ? (
                <DashboardReviewItem
                  title="dashboard.reviews.ir.title"
                  message="dashboard.reviews.ir.message"
                  review={gradeIR}
                />
              ) : (
                <JaimyText textAlign="center">
                  <FormattedMessage id="dashboard.reviews.empty" />
                </JaimyText>
              )}
              {gradeRFQ.grade > 0 ? (
                <DashboardReviewItem
                  title="dashboard.reviews.rfq.title"
                  message="dashboard.reviews.rfq.message"
                  review={gradeRFQ}
                />
              ) : (
                <JaimyText textAlign="center">
                  <FormattedMessage id="dashboard.reviews.empty" />
                </JaimyText>
              )}
            </>
          )}
          <hr style={{ width: '80%', backgroundColor: theme.colors.secondary.base }} />
          {compliment ? (
            <>
              <JaimyHeading.H4 color={theme.colors.primary.dark}>
                <FormattedMessage id="dashboard.compliment" />
              </JaimyHeading.H4>
              <JaimyText textAlign="center" fontStyle="italic">
                "{compliment}"
              </JaimyText>
            </>
          ) : (
            <JaimyFlex justifyContent="center" alignItems="center" flexDirection="column">
              <JaimyHeading.H4 color={theme.colors.primary.dark}>
                <FormattedMessage id="dashboard.compliment" />
              </JaimyHeading.H4>
              <JaimyText textAlign="center" fontStyle="italic">
                <FormattedMessage id="dashboard.compliment.empty" />
              </JaimyText>
            </JaimyFlex>
          )}
        </JaimyFlex>
      </ComponentIsVisible>
    </DashboardCard>
  );
};

export default DashboardReviews;
