const theme = {
  colors: {
    primary: {
      lighter: '#cf336942',
      light: '#cf3369',
      base: '#c30144',
      dark: '#88002f'
    },
    secondary: {
      lighter: '#86bcd3',
      light: '#406C8C',
      base: '#32546d',
      dark: '#274154'
    },
    heading: '#274154',
    gray: '#ACB7BB',
    green: '#159962',
    blue: {
      darkBlue: '#32546D',
      lightBlue: '#79A7C3'
    },
    background: { base: '#F2F2F2', dark: '#eaeaea', lightGray: '#F4F4F4' },
    inputBackground: '#f3f4f6',
    white: '#fff',
    whiteOpacity: 'hsla(0, 0%, 100%, 0.9)'
  },
  gradients: {
    primary: `linear-gradient(90deg, rgba(207,51,105,1) 0%, rgba(195,1,68,1) 100%);`,
    primaryHover: 'linear-gradient(90deg, rgba(195,1,68,1) 0%, rgba(136,0,47,1) 100%);',
    secondary: 'linear-gradient(90deg, rgba(64,108,140,1) 0%, rgba(50,84,109,1) 100%);',
    secondaryHover: 'linear-gradient(90deg, rgba(57,96,125,1) 0%, rgba(39,65,84,1) 100%);'
  },
  typography: {
    fonts: {
      heading: 'Belfius, Belfius Fallback, system-ui, Helvetica, Arial, sans-serif',
      body: 'Lato, Helvetica, Arial, sans-serif'
    },
    fontSizes: {
      xxs: '0.625rem', // 10
      xs: '0.75rem', // 12
      sm: '0.875rem', // 14
      md: '1rem', // 16
      lg: '1.125rem', // 18
      xl: '1.25rem', // 20
      '2xl': '1.5rem', // 24
      '3xl': '2rem', // 32
      '4xl': '2.5rem', // 40
      '5xl': '3rem', // 48
      '6xl': '4rem' // 64
    }
  },
  breakpoints: {
    xs: '375px',
    sm: '768px',
    md: '1240px',
    lg: '1440px',
    xl: '1920px'
  },
  containers: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  },
  border: '1px solid #d8dae0',
  shadow: '0px 2px 4px rgba(216, 216, 216, 0.5)',
  borderRadius: '8px'
};

export default theme;
