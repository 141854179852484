import { AnyAction } from 'redux';
import { ICalendarVacation, IPrebookVacation, IVacation, IVacationWarnings } from '../../../types/Vacation';
import * as types from './types';

export interface IVacationState {
  vacations: IVacation[];
  getVacationPlanningLoading: boolean;
  getVacationPlanningError: null;

  vacationWarnings: IVacationWarnings[];
  vacationWarningsOfSelectedDay: IVacationWarnings[];
  getVacationWarningsLoading: boolean;
  getVacationWarningsError: null;

  calendarVacations: ICalendarVacation[];
  getCalendarVacationsLoading: boolean;
  getCalendarVacationsError: null;

  prebookVacations: IPrebookVacation[];
  getPrebookVacationsLoading: boolean;
  getPrebookVacationsError: null;

  createVacationPlanningLoading: boolean;
  createVacationPlanningError: null;

  updateVacationPlanningLoading: boolean;
  updateVacationPlanningError: null;

  deleteVacationLoading: boolean;
  deleteVacationError: null;
}


const initialState: IVacationState = {
  vacations: [],
  getVacationPlanningLoading: false,
  getVacationPlanningError: null,

  vacationWarnings: [],
  vacationWarningsOfSelectedDay: [],
  getVacationWarningsLoading: false,
  getVacationWarningsError: null,

  calendarVacations: [],
  getCalendarVacationsLoading: false,
  getCalendarVacationsError: null,

  prebookVacations: [],
  getPrebookVacationsLoading: false,
  getPrebookVacationsError: null,

  createVacationPlanningLoading: false,
  createVacationPlanningError: null,

  updateVacationPlanningLoading: false,
  updateVacationPlanningError: null,

  deleteVacationLoading: false,
  deleteVacationError: null
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.GET_ALL_REQUEST:
      return { ...state, getVacationPlanningLoading: true };

    case types.GET_ALL_SUCCESS:
      return {
        ...state,
        vacations: action.payload,
        getVacationPlanningLoading: false,
        getVacationPlanningError: false
      };
    case types.GET_ALL_ERROR:
      return {
        ...state,
        getVacationPlanningLoading: false,
        getVacationPlanningError: action.error
      };

    case types.GET_ALL_WARNINGS_REQUEST:
      return { ...state, getVacationWarningsLoading: true };

    case types.GET_ALL_WARNINGS_SUCCESS:
      return {
        ...state,
        vacationWarnings: action.payload,
        getVacationWarningsLoading: false,
        getVacationWarningsError: false
      };
    case types.GET_ALL_WARNINGS_ERROR:
      return {
        ...state,
        getVacationWarningsLoading: false,
        getVacationWarningsError: action.error
      };

    case types.GET_ALL_OF_CALENDAR_REQUEST:
      return { ...state, getCalendarVacationsLoading: true };

    case types.GET_ALL_OF_CALENDAR_SUCCESS:
      return {
        ...state,
        calendarVacations: action.payload,
        getCalendarVacationsLoading: false,
        getCalendarVacationsError: false
      };
    case types.GET_ALL_OF_CALENDAR_ERROR:
      return {
        ...state,
        getCalendarVacationsLoading: false,
        getCalendarVacationsError: action.error
      };

      case types.GET_ALL_OF_PREBOOK_REQUEST:
        return { ...state, getPrebookVacationsLoading: true };
  
      case types.GET_ALL_OF_PREBOOK_SUCCESS:
        return {
          ...state,
          prebookVacations: action.payload,
          getPrebookVacationsLoading: false,
          getPrebookVacationsError: false
        };
      case types.GET_ALL_OF_PREBOOK_ERROR:
        return {
          ...state,
          getPrebookVacationsLoading: false,
          getPrebookVacationsError: action.error
        };

    case types.CREATE_PLANNING_REQUEST:
      return { ...state, createVacationPlanningLoading: true };

    case types.CREATE_PLANNING_SUCCESS:
      return {
        ...state,
        createVacationPlanningLoading: false,
        createVacationPlanningError: false
      };
    case types.CREATE_PLANNING_ERROR:
      return {
        ...state,
        createVacationPlanningLoading: false,
        createVacationPlanningError: action.error
      };

    case types.UPDATE_PLANNING_REQUEST:
      return { ...state, updateVacationPlanningLoading: true };

    case types.UPDATE_PLANNING_SUCCESS:
      return {
        ...state,
        updateVacationPlanningLoading: false,
        updateVacationPlanningError: false
      };
    case types.UPDATE_PLANNING_ERROR:
      return {
        ...state,
        updateVacationPlanningLoading: false,
        updateVacationPlanningError: action.error
      };

    case types.DELETE_REQUEST:
      return { ...state, deleteVacationLoading: true };

    case types.DELETE_SUCCESS:
      return {
        ...state,
        deleteVacationLoading: false,
        deleteVacationError: false
      };
    case types.DELETE_ERROR:
      return {
        ...state,
        deleteVacationLoading: false,
        deleteVacationError: action.error
      };

    default:
      return state;
  }
};
