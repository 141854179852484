import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ButtonPrimary } from '../common/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import { FormattedMessage } from 'react-intl';
import { H4Title } from '../common/Header';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import SubscriptionPopover from '../subscription/Popover';
import TreeItem from './TreeItem';
import TreeView from './TreeView';
import Typography from '@material-ui/core/Typography';
import ValidatePermission from '../subscription/ValidatePermission';
import { connect } from 'react-redux';
import intersectionBy from 'lodash/intersectionBy';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'Plan Upgrade Requested'
  },
  dataLayerName: 'ProDataLayer'
};

const styledBy = (property, mapping) => props => mapping[props[property]];

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)'
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    '& $content': {
      paddingLeft: theme.spacing(1)
    }
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0)
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1
  }
}));

const SmallCheckbox = withStyles({
  root: {
    padding: '4px',
    marginRight: '4px'
  },
  checked: {
    color: '#32546D'
  }
})(props => <Checkbox color="default" {...props} />);

const ChipStyled = withStyles({
  root: {
    margin: '2px 4px',
    color: '#32546D',
    borderColor: '#32546D',
    '& svg': {
      fill: 'rgba(50, 84, 109, 0.8)'
    },
    '& svg:hover': {
      fill: 'rgba(50, 84, 109, 1)'
    }
  },
  deletable: {
    '&:focus': {
      backgroundColor: 'transparent !important'
    }
  }
})(Chip);

const PopoverStyled = withStyles({
  paper: {
    width: 'calc(100% - 30px)',
    maxWidth: '400px',
    padding: '1rem',
    maxHeight: 'calc(100% - 100px)'
  }
})(Popover);

const SelectStyled = withStyles({
  selectMenu: {
    whiteSpace: 'pre-line',
    color: 'rgba(50, 84, 109, 0.4)',
    '& div > p': {
      color: 'rgba(50, 84, 109, 1)'
    }
  }
})(Select);

const InputStyled = withStyles(theme => ({
  root: {
    minWidth: 76,
    width: '100%',
    color: '#32546D',
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&> svg': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #D8DAE0',
      borderRadius: '4px',
      marginRight: '8px'
    }
  },
  error: {
    '& div': {
      borderColor: 'red'
    }
  },
  input: {
    minHeight: 20,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: styledBy('color', {
      primary: theme.palette.background.paper,
      secondary: '#F3F4F6'
    }),
    border: '1px solid #ced4da',
    borderColor: props => (props.error ? 'red' : '#ced4da'),
    fontSize: 15,
    padding: styledBy('color', {
      primary: '9px 34px 10px 12px',
      secondary: '0.8rem 0.9rem'
    }),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4
    }
  }
}))(InputBase);
const CompetenceDomainContainer = styled.div`
  font-size: 12px;
  padding: 10px;
  background: #5dc9d2;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.74);
`;
const StyledTreeItem = props => {
  const classes = useTreeItemStyles();
  const {
    labelText,
    labelInfo,
    color,
    bgColor,
    isSelected,
    onSelectTrade,
    indeterminate,
    checkAnyChildSelected,
    checkAllChildrenUnselected,
    checkDisabled,
    category,
    hasChildren,
    noChildren,
    selectedTrades,
    ...other
  } = props;

  React.useEffect(() => {
    const checkChildSelected = children => {
      if (
        !isSelected &&
        selectedTrades.length > 0 &&
        hasChildren(category) &&
        checkAnyChildSelected(children)
      ) {
        onSelectTrade(category, true, true);
      }
    };
    const checkChildrenSelected = children => {
      if (isSelected && hasChildren(category) && checkAllChildrenUnselected(children)) {
        onSelectTrade(category, false, false);
      }
    };

    const children = hasChildren(category) ? category.children : [];

    checkChildrenSelected(children);
    checkChildSelected(children);
  }, [
    category,
    checkAllChildrenUnselected,
    checkAnyChildSelected,
    hasChildren,
    isSelected,
    onSelectTrade,
    selectedTrades
  ]);
  const disabled = checkDisabled({ id: category.id, isSelected });
  return (
    <TreeItem
      label={
        <div
          className={classes.labelRoot}
          onDoubleClick={() => onSelectTrade(category, !isSelected, false)}
          onClick={() => onSelectTrade(category, !isSelected, false)}
        >
          <SmallCheckbox
            style={{ opacity: disabled ? '0.5' : '1' }}
            checked={isSelected || indeterminate || false}
            onChange={e => onSelectTrade(category, e.target.checked, false, e)}
            value="checkedA"
            inputProps={{
              'aria-label': `${labelText} checkbox`
            }}
            indeterminate={indeterminate}
            data-cy="treeselect-checkbox"
          />
          <Typography
            style={{ opacity: disabled ? '0.5' : '1' }}
            variant="body2"
            className={classes.labelText}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired
};
const SubscriptionMessage = ({ limit }) => (
  <>
    <FormattedMessage
      tagName="strong"
      id="categories_tree.selected.amount.limit.popover.title"
      values={{ count: limit }}
    />
    <FormattedMessage
      tagName="p"
      id="categories_tree.selected.amount.limit.popover.start"
      values={{ count: limit }}
    />
    <div
      data-cy="subscription-limit-reached"
      style={{ display: 'inline-block', cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => {
        TagManager.dataLayer(tagManagerArgs);
        setTimeout(() => window.location.reload(), 500);
      }}
    >
      <FormattedMessage tagName="span" id="categories_tree.selected.amount.limit.popover.event" />
    </div>
    <FormattedMessage tagName="span" id="categories_tree.selected.amount.limit.popover.end" />
  </>
);
const CategoriesTreeSelect = ({
  categories,
  locale,
  onSelectTrade,
  defaultSelectedIdsProfile,
  defaultSelectedIdsFilter,
  error,
  required,
  fullWidth,
  primary,
  showChips,
  tag,
  noChildren,
  limit,
  useUserDefault,
  disableUnsubCategories = false,
  showCompetenceDomain = false
}) => {
  const defaultSelectedIds = useUserDefault ? defaultSelectedIdsProfile : defaultSelectedIdsFilter;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [oldDefaultSelectedIds, setOldDefaultSelectedIds] = useState([]);
  const [limitReached, setLimitReached] = useState(false);
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [visualSelectedTrades, setVisualSelectedTrades] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMaxCategories, setAnchorElMaxCategories] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (anchorEl !== null) {
      onSelectTrade(
        selectedTrades.filter(selectedTrade => selectedTrade.isChecked).map(cat => cat.id)
      );
    }
  };
  const handleClickMaxCategories = event => {
    if (event) setAnchorElMaxCategories(event.currentTarget);
  };
  const handleCloseMaxCategories = () => {
    setAnchorElMaxCategories(null);
  };
  const getTradeNameLocale = category =>
    locale === 'fr' ? category.fr_name : locale === 'nl' ? category.nl_name : category.name;

  const hasChildren = useCallback(
    parent => !noChildren && parent && parent.children && parent.children.length > 0,
    [noChildren]
  );

  const isCategoryChecked = useCallback(
    catId => selectedTrades && selectedTrades.some(cat => cat.id === catId && cat.isChecked),
    [selectedTrades]
  );

  const checkAllChildrenUnselected = useCallback(
    children => {
      if (hasChildren(children) && children.every(cat => !isCategoryChecked(cat.id))) {
        children.forEach(childCat => {
          if (hasChildren(childCat)) checkAllChildrenUnselected(childCat.children);
        });
      } else {
        return children.every(cat => !isCategoryChecked(cat.id));
      }
    },
    [hasChildren, isCategoryChecked]
  );

  const checkAllChildrenSelected = useCallback(
    children => {
      if (hasChildren(children) && children.every(cat => isCategoryChecked(cat.id))) {
        children.forEach(childCat => {
          if (hasChildren(childCat)) checkAllChildrenSelected(childCat.children);
        });
      } else {
        return children.every(cat => isCategoryChecked(cat.id));
      }
    },
    [hasChildren, isCategoryChecked]
  );

  const checkAnyChildSelected = useCallback(
    children => {
      const hasDirectChildSelected = children.some(cat => isCategoryChecked(cat.id));
      if (hasDirectChildSelected) {
        return true;
      }
      if (hasChildren(children)) {
        children.forEach(childCat => {
          if (hasChildren(childCat)) checkAnyChildSelected(childCat.children);
        });
      } else {
        return false;
      }
    },
    [hasChildren, isCategoryChecked]
  );
  const checkDisabled = category => {
    if (typeof limit !== 'number') {
      return false;
    }
    const { isSelected, id } = category;
    if (disableUnsubCategories) {
      return !defaultSelectedIdsProfile.includes(id);
    }
    return !isSelected && selectedCategories.length >= limit;
  };
  /**
   * Count Main categories
   */
  useEffect(() => {
    const selectedCategories = intersectionBy(selectedTrades, categories.children, 'id').filter(
      cat => cat.isChecked
    );
    setSelectedCategories(selectedCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrades]);
  const checkLimit = useCallback(
    (category, event) => {
      if (disableUnsubCategories) {
        handleClickMaxCategories(event);
        setLimitReached(true);
        return true;
        /**
         * TODO this behavior is buggy becuase without defaultSelectedIdsProfile(treetrades)
         * are overriden when each time a user makes a change.
         * Since we are not storing initial treetrades we don't have a way to know which
         * categories user initially selected
         * The outcome results in the next bug:
         * if users disabled all categories and saves the profile
         * he won't be able to select ANY category
         */
        // const limit = !defaultSelectedIdsProfile.includes(category.id);
        // if (limit) {
        //   handleClickMaxCategories(event);
        //   setLimitReached(true);
        //   return limit;
        // }
      }
      // recursive function to find root category of any deeply nested subcategory
      const findParent = ({ children = [], ...object }, category) => {
        let result;
        if (object.id === category.id) return object;
        return (
          children.some(o => (result = findParent(o, category))) &&
          Object.assign({}, object, { children: [result] })
        );
      };
      const rootCategory = findParent(categories, category).children[0];
      const selectedCategories = intersectionBy(selectedTrades, categories.children, 'id').filter(
        cat => cat.isChecked
      );
      // Looks like this call is redundant because of previous useEffect
      // setSelectedCategories(selectedCategories);
      // ignore click on already selected cat
      if (selectedCategories.find(cat => rootCategory.id === cat.id)) {
        setLimitReached(false);
        return false;
      }
      if (selectedCategories.length === limit) {
        setLimitReached(true);
        handleClickMaxCategories(event);
        return true;
      }
      setLimitReached(false);

      return false;
    },
    [categories, selectedTrades, limit, disableUnsubCategories]
  );

  const handleSelectTrade = useCallback(
    (category, isChecked, isFromChild, event) => {
      // apply limits only for trades whose permissions are limiting
      if (typeof limit === 'number') {
        if (checkLimit(category, event)) {
          return;
        }
      }

      const newSelectedTrades = [...selectedTrades];
      const updateChecked = category => {
        const categoryIndex = newSelectedTrades.findIndex(cat => cat.id === category.id);
        if (categoryIndex === -1) {
          newSelectedTrades.push({ ...category, isChecked });
        } else {
          newSelectedTrades[categoryIndex].isChecked = isChecked;
        }
      };

      updateChecked(category);

      if (hasChildren(category) && !isFromChild) {
        const toggleChildrenSelect = catChildren => {
          catChildren.forEach(childCat => {
            updateChecked(childCat);
            if (hasChildren(childCat)) toggleChildrenSelect(childCat.children);
          });
        };
        toggleChildrenSelect(category.children);
      }

      setSelectedTrades([...newSelectedTrades]);
      onSelectTrade(
        newSelectedTrades.filter(selectedTrade => selectedTrade.isChecked).map(cat => cat.id)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasChildren, selectedTrades, categories]
  );
  const getTreeItems = children =>
    children.map(category => (
      <StyledTreeItem
        key={category.id}
        nodeId={category.id.toString()}
        labelText={getTradeNameLocale(category)}
        onSelectTrade={(category, isChecked, isFromChild, event) =>
          handleSelectTrade(category, isChecked, isFromChild, event)
        }
        checkAnyChildSelected={checkAnyChildSelected}
        checkAllChildrenUnselected={checkAllChildrenUnselected}
        category={category}
        selectedTrades={selectedTrades}
        hasChildren={hasChildren}
        noChildren={noChildren}
        isSelected={isCategoryChecked(category.id)}
        checkDisabled={checkDisabled}
        indeterminate={
          selectedTrades.length > 0 &&
          hasChildren(category) &&
          checkAnyChildSelected(category.children) &&
          !checkAllChildrenSelected(category.children)
        }
      >
        {hasChildren(category) && getTreeItems(category.children)}
      </StyledTreeItem>
    ));

  useEffect(() => {
    const newSelectedTrades = [];
    const setSelectedCategory = children => {
      children.forEach(cat => {
        if (defaultSelectedIds && defaultSelectedIds.includes(cat.id)) {
          newSelectedTrades.push({ ...cat, isChecked: true });
        }
        if (hasChildren(cat)) {
          setSelectedCategory(cat.children);
        }
      });
    };

    if (selectedTrades.length === 0 || defaultSelectedIds !== oldDefaultSelectedIds) {
      setOldDefaultSelectedIds(defaultSelectedIds);
      if (hasChildren(categories)) setSelectedCategory(categories.children);
      if (newSelectedTrades.length > 0) {
        setSelectedTrades([...newSelectedTrades]);
      }
    }

    const hideDisplay = [];
    selectedTrades.forEach(selectedTrade => {
      if (
        selectedTrade.isChecked &&
        hasChildren(selectedTrade) &&
        checkAllChildrenSelected(selectedTrade.children)
      ) {
        const toggleChildrenSelect = catChildren => {
          catChildren.forEach(childCat => {
            selectedTrade.children.map(cat => hideDisplay.push(cat.id));
            if (hasChildren(childCat)) toggleChildrenSelect(childCat.children);
          });
        };
        toggleChildrenSelect(selectedTrade.children);
      }
    });
    setVisualSelectedTrades(
      selectedTrades.filter(
        selectedTrade => selectedTrade.isChecked && !hideDisplay.includes(selectedTrade.id)
      )
    );
  }, [
    defaultSelectedIds,
    handleSelectTrade,
    categories,
    hasChildren,
    selectedTrades,
    checkAnyChildSelected,
    onSelectTrade,
    checkAllChildrenSelected,
    oldDefaultSelectedIds
  ]);

  const treeItems = categories && categories.children ? getTreeItems(categories.children) : [];

  const open = anchorEl !== null;
  const id = open ? 'categories-popover' : undefined;
  const openMaxCategories = anchorElMaxCategories !== null;
  return (
    <>
      <SubscriptionPopover
        id={id}
        open={openMaxCategories}
        anchorEl={anchorElMaxCategories}
        onClose={handleCloseMaxCategories}
      >
        <SubscriptionMessage limit={limit} />
      </SubscriptionPopover>
      <FormControl required={required} error={error} fullWidth={fullWidth}>
        <SelectStyled
          value={false}
          open={false}
          onClick={handleClick}
          renderValue={() => (
            <div>
              {visualSelectedTrades.length === 0 && tag && (
                <FormattedMessage tagName="span" id={tag} />
              )}
              {visualSelectedTrades && !showChips && (
                <FormattedMessage
                  tagName="p"
                  id="categories_tree.selected.amount"
                  values={{ count: selectedCategories.length }}
                  defaultMessage="{count, plural, =0 {No selected categories} one {1 selected category} other {# selected categories}}"
                />
              )}
              {showChips &&
                visualSelectedTrades.map(category => (
                  <ChipStyled
                    key={category.id}
                    label={getTradeNameLocale(category)}
                    onDelete={() => {
                      handleSelectTrade(category, false, false);
                    }}
                    variant="outlined"
                    size="small"
                    color="primary"
                  />
                ))}
            </div>
          )}
          input={
            <InputStyled
              color={primary ? 'primary' : 'secondary'}
              name="categories"
              id="categories"
              required
            />
          }
          fullWidth={fullWidth}
        >
          <MenuItem value={false} />
        </SelectStyled>
      </FormControl>
      <PopoverStyled
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: anchorEl ? anchorEl.getBoundingClientRect().right - 170 : 0,
          top: 120
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <H4Title>
          {tag ? (
            <FormattedMessage tagName="span" id={tag} />
          ) : (
            <FormattedMessage
              tagName="span"
              id="categories_tree.selected.amount"
              values={{ count: selectedCategories.length }}
              defaultMessage="{count, plural, =0 {No selected categories} one {1 selected category} other {# selected categories}}"
            />
          )}
        </H4Title>
        {limitReached && showCompetenceDomain && (
          <CompetenceDomainContainer>
            <SubscriptionMessage limit={limit} />
          </CompetenceDomainContainer>
        )}

        <TreeView
          defaultExpanded={visualSelectedTrades
            .filter(
              selectedTrade =>
                selectedTrade.isChecked &&
                (hasChildren(selectedTrade)
                  ? !checkAllChildrenSelected(selectedTrade.children)
                  : true)
            )
            .map(cat => cat.id.toString())}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >
          {treeItems}
        </TreeView>
        <ButtonPrimary
          data-cy-button="close_tree_view"
          center
          onClick={handleClose}
          data-cy="treeselect-submit"
        >
          <FormattedMessage tagName="span" id="categories_tree.submit" />
        </ButtonPrimary>
      </PopoverStyled>
    </>
  );
};

const mapStateToProps = ({ services, user: { user } }) => ({
  defaultSelectedIdsFilter: services.filters.category_list,
  defaultSelectedIdsProfile: user.treetrades
});

// export default connect(mapStateToProps)(CategoriesTreeSelect);
const CategoriesTreeSelectDecorated = connect(mapStateToProps)(CategoriesTreeSelect);
const CategoriesTreeSelectSubscription = props => (
  <ValidatePermission permission="category_limit">
    <CategoriesTreeSelectDecorated {...props} />
  </ValidatePermission>
);
export default CategoriesTreeSelectSubscription;
