import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { FixMeLater } from '../../types/FixMeLater';

interface TextFieldProps {
  label?: any;
  id?: string;
  inputRef?: any;
  fullWidth?: boolean;
  error?: boolean;
  mask?: any;
  name?: string;
  startAdornment?: any;
  endAdornment?: any;
  type?: string;
  required?: boolean;
  defaultValue?: string;
  onChange?: any;
  value?: any;
  min?: number;
  max?: number;
}

const JaimyInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    backgroundColor: '#F3F4F6',
    borderRadius: 4,
    border: '1px solid #D8DAE0',
    color: '#32546D',
    fontFamily: "'Lato', sans-serif",
    padding: '0.8rem 0.9rem'
  },
  error: {
    '& input': {
      borderColor: 'red'
    }
  }
}))(InputBase);

const TextMaskCustom = ({ inputRef, mask, ...props }: FixMeLater) => (
  <MaskedInput
    {...props}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    placeholderChar={'\u2000'}
    mask={mask}
    showMask
  />
);

const TextField = ({ label, id, fullWidth, error, mask, min, max, ...props }: TextFieldProps) => {
  const [values, setValues] = React.useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };
  const handleMouseDownPassword = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
  };

  const hasMask = mask ? { inputComponent: TextMaskCustom } : {};
  const endAdornment =
    props.type === 'password' ? (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {values.showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ) : undefined;

  const customStartAdornment = props.startAdornment ? (
    <InputAdornment position="start">
      <IconButton aria-label="startAdornment" edge="end">
        {props.startAdornment}
      </IconButton>
    </InputAdornment>
  ) : undefined;

  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {label && (
        <InputLabel shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <JaimyInput
        {...props}
        {...hasMask}
        id={id}
        startAdornment={customStartAdornment}
        endAdornment={endAdornment}
        type={values.showPassword ? 'text' : props.type}
        inputProps={{ min, max }}
      />
    </FormControl>
  );
};

export default TextField;
