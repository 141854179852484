import { FormattedMessage, useIntl } from 'react-intl';
import { FuncPropType, ObjectPropType } from '../../util/PropTypeValues';
import React, { useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import styled from 'styled-components';
import { ButtonDarkRed } from '../../components/common/Button';

const DivPasswordRequirements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem !important;
`;
const SpanRequirement = styled.div`
  border-radius: 14px;
  background-color: #e8ebed;
  padding: 4px 9px;
  margin: 3px !important;
  display: flex;
  align-items: center;
  ${props => props.valid && 'background-color: #79ce9573;'}
  ${props => props.valid && 'color: #10702f;'}
    > svg {
    font-size: 15px;
  }
`;
const LabelPassword = styled.label`
  display: flex;
`;
const InputPassword = styled.input`
  width: 100%;
  background-color: #f3f4f6;
  border: 1px solid #d8dae0;
  font-size: 18px;
  color: #32546d;
  padding: 0.8rem 0.9rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-family: 'Lato', sans-serif;
  outline: none;
  border-right: none;
  ${props => !props.valid && 'border-color: #c30b30;'}
`;
const ButtonVisibility = styled.button`
  display: flex;
  background-color: #f3f4f6;
  border: 1px solid #d8dae0;
  font-size: 18px;
  color: #32546d;
  padding: 0.8rem 0.9rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: none;
  border-left: none;
  cursor: pointer;
  ${props => !props.valid && 'border-color: #c30b30;'}
`;
const StepPassword = ({ onClickContinue, query }) => {
  const [password, setPassword] = useState('');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const intl = useIntl();
  const registerToken = query && query.id;
  const authTokens = {
    token: query['access-token'],
    client: query.client,
    uid: query.uid
  };
  const isPasswordLongEnough = !!password && password.length > 7;
  const validateAndContinue = () => {
    if (isPasswordLongEnough) {
      onClickContinue(password, registerToken, authTokens);
    } else {
      setPasswordValid(false);
    }
  };

  return (
    <>
      <h2 data-cy="page-password">{intl.messages['register.step.password.title']}</h2>
      <FormattedMessage tagName="p" id="register.step.password.almost" />
      <h3>{intl.messages['register.step.password.choose']}</h3>
      <FormattedMessage tagName="p" id="register.step.password.contain" />
      <DivPasswordRequirements>
        <SpanRequirement valid={isPasswordLongEnough} data-cy="password-length">
          <CheckIcon />
          {intl.messages['register.step.password.8_letters']}
        </SpanRequirement>
      </DivPasswordRequirements>
      <LabelPassword
        htmlFor="passwordField"
        valid={(passwordValid && password.length === 0) || isPasswordLongEnough}
      >
        <InputPassword
          id="passwordField"
          type={passwordHidden ? 'password' : 'text'}
          value={password}
          valid={(passwordValid && password.length === 0) || isPasswordLongEnough}
          onChange={e => {
            setPassword(e.target.value);
          }}
          data-cy="password"
        />
        <ButtonVisibility
          data-cy="visibility"
          type="button"
          onClick={() => {
            setPasswordHidden(!passwordHidden);
          }}
          valid={(passwordValid && password.length === 0) || isPasswordLongEnough}
        >
          {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </ButtonVisibility>
      </LabelPassword>
      <ButtonDarkRed data-cy="submit" onClick={validateAndContinue} secondary>
        {intl.messages['register.steps.continue']}
      </ButtonDarkRed>
    </>
  );
};

StepPassword.defaultProps = { onClickContinue: {}, router: {} };
StepPassword.propTypes = { onClickContinue: FuncPropType, router: ObjectPropType };

export default StepPassword;
