import { PrismicRichText as RichText } from '@prismicio/react';
import * as prismicT from '@prismicio/types';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Link, List, ListItem } from '@mui/material';
import theme from '../../../theme';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';

interface Props {
  render: prismicT.RichTextField | null | undefined;
}

export const PrismicRichText = (props: Props): JSX.Element => {
  return (
    <RichText
      field={props.render}
      components={{
        heading1: ({ children }) => <JaimyHeading.H1 margin="10px 0px">{children}</JaimyHeading.H1>,
        heading2: ({ children }) => <JaimyHeading.H2 margin="10px 0px">{children}</JaimyHeading.H2>,
        heading3: ({ children }) => <JaimyHeading.H3 margin="10px 0px">{children}</JaimyHeading.H3>,
        heading4: ({ children }) => <JaimyHeading.H4 margin="10px 0px">{children}</JaimyHeading.H4>,
        heading5: ({ children }) => <JaimyHeading.H5 margin="10px 0px">{children}</JaimyHeading.H5>,
        heading6: ({ children }) => <JaimyHeading.H6 margin="10px 0px">{children}</JaimyHeading.H6>,
        paragraph: ({ children }) => <JaimyText>{children}</JaimyText>,
        strong: ({ children }) => <strong>{children}</strong>,
        em: ({ children }) => <em>{children}</em>,
        list: ({ children }) => <List>{children}</List>,
        listItem: ({ children }) => (
          <ListItem style={{ alignItems: 'start' }}>
            <CheckCircleIcon style={{ color: theme.colors.secondary.base, marginRight: '7.5px' }} />
            <div>{children}</div>
          </ListItem>
        ),
        oList: ({ children }) => <List>{children}</List>,
        oListItem: ({ children }) => (
          <ListItem>
            <CheckCircleIcon style={{ color: theme.colors.secondary.base, marginRight: '7.5px' }} />
            {children}
          </ListItem>
        ),
        hyperlink: ({ node, children }) => {
          if (node.data.link_type === 'Web') {
            return (
              <Link
                href={node.data.url}
                target={node.data.target}
                color={theme.colors.primary.dark}
              >
                {children}
              </Link>
            );
          }
          return null;
        }
      }}
    />
  );
};
