import { format, getDay, isBefore, isEqual, isSameDay, isSameMonth, isToday } from 'date-fns';
import { IEvent } from '../../../../types/Calendar';
import { ICalendarVacation } from '../../../../types/Vacation';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { Appointment, AppointmentsContainer, Day, MonthDay, MonthDayButton } from './styles';

interface IAppointmentsFilteredByStatus {
  personal: IEvent[];
  jaimy: IEvent[];
}

interface IMyCalendarMonthDayProps {
  index: number;
  monthDay: Date;
  setIsSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  isSelectedDay: Date;
  firstDayOfSelectedMonth: Date;
  appointmentsFilteredByStatus: IAppointmentsFilteredByStatus;
  calendarVacations: ICalendarVacation[];
}

export function MyCalendarMonthDay({
  index,
  monthDay,
  isSelectedDay,
  setIsSelectedDay,
  firstDayOfSelectedMonth,
  appointmentsFilteredByStatus,
  calendarVacations
}: IMyCalendarMonthDayProps) {
  const columnStartValue = index === 0 && getDay(monthDay);

  return (
    <MonthDay
      columnStart={columnStartValue ? columnStartValue : 0}
      borderTop={index > 6}
      key={`${monthDay} - ${index}`}
    >
      <MonthDayButton
        onClick={() => setIsSelectedDay(monthDay)}
        isEqual={isEqual(monthDay, isSelectedDay)}
        isToday={isToday(monthDay)}
        isSameMonth={isSameMonth(monthDay, firstDayOfSelectedMonth)}
      >
        <Day isEqual={isEqual(monthDay, isSelectedDay)} isToday={isToday(monthDay)}>
          {format(monthDay, 'dd')}
        </Day>
        <ComponentIsVisible
          when={
            appointmentsFilteredByStatus.jaimy.length > 0 ||
            appointmentsFilteredByStatus.personal.length > 0
          }
        >
          <AppointmentsContainer>
            <ComponentIsVisible
              when={appointmentsFilteredByStatus.jaimy.some(
                jaimyAppointment =>
                  isSameDay(jaimyAppointment.start, monthDay) ||
                  isSameDay(jaimyAppointment.end, monthDay)
              )}
            >
              <Appointment>
                {
                  appointmentsFilteredByStatus.jaimy.filter(
                    jaimyAppointment =>
                      isSameDay(jaimyAppointment.start, monthDay) ||
                      isSameDay(jaimyAppointment.end, monthDay)
                  ).length
                }
              </Appointment>
            </ComponentIsVisible>
            <ComponentIsVisible
              when={appointmentsFilteredByStatus.personal.some(
                personalAppointment =>
                  isSameDay(personalAppointment.start, monthDay) ||
                  isSameDay(personalAppointment.end, monthDay)
              )}
            >
              <Appointment personal>
                {
                  appointmentsFilteredByStatus.personal.filter(
                    personalAppointment =>
                      isSameDay(personalAppointment.start, monthDay) ||
                      isSameDay(personalAppointment.end, monthDay)
                  ).length
                }
              </Appointment>
            </ComponentIsVisible>
          </AppointmentsContainer>
        </ComponentIsVisible>

        <ComponentIsVisible when={calendarVacations.length > 0}>
          <ComponentIsVisible
            when={calendarVacations.some(
              calendarVacation =>
                isSameDay(calendarVacation.startDate, monthDay) ||
                isSameDay(calendarVacation.endDate, monthDay) ||
                (isBefore(calendarVacation.startDate, monthDay) &&
                  isBefore(monthDay, calendarVacation.endDate))
            )}
          >
            <Appointment vacation />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </MonthDayButton>
    </MonthDay>
  );
}
