import styled, { css } from 'styled-components';

interface IJaimyFlexProps {
  alignItems?: string;
  backgroundColor?: string;
  borderRadius?: number;
  borderRight?: string;
  borderTop?: string;
  color?: string;
  cursor?: string;
  columnGap?: string;
  flexDirection?: string;
  flexWrap?: string;
  gap?: string;
  gridColumnSm?: string;
  height?: string;
  justifyContent?: string;
  margin?: string;
  marginLeft?: string;
  maxHeight?: string;
  maxWidth?: string;
  minHeight?: string;
  padding?: string;
  position?: string;
  px?: number;
  py?: number;
  mx?: number;
  my?: number;
  textAlign?: string;
  width?: string;
  zIndex?: string | number;
  border?: string;
  overflow?: string;
  boxShadow?: string;
  directionSm?: string;
  justifyContentSm?: string;
  marginSm?: string;
  paddingSm?: string;
  flex?: string; // flex grow
  flexSm?: string;
  flexMd?: string;
  flexLg?: string;
  widthSm?: string;
  hideScrollbar?: boolean;
  transition?: string;
  _hover?: {
    border?: string;
    backgroundColor?: string;
  }
}

const JaimyFlex = styled.div<IJaimyFlexProps>`
  align-items: ${props => props.alignItems && props.alignItems};
  background-color: ${props => props.backgroundColor && props.backgroundColor};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 0.5)}rem;
  border: ${props => props.border && props.border};
  border-top: ${props => props.borderTop && props.borderTop};
  border-right: ${props => props.borderRight && props.borderRight};
  color: ${props => props.color && props.color};
  cursor: ${props => props.cursor && props.cursor};
  column-gap: ${props => props.columnGap && props.columnGap};
  overflow: ${props => props.overflow && props.overflow};
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'nowrap')};
  flex: ${props => props.flex && props.flex};
  gap: ${props => props.gap && props.gap};
  height: ${props => props.height && props.height};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  margin: ${props => props.margin && props.margin};
  margin-left: ${props => props.marginLeft && props.marginLeft};
  max-height: ${props => props.maxHeight && props.maxHeight};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  min-height: ${props => props.minHeight && props.minHeight};
  padding: ${props => props.padding && props.padding};
  position: ${props => props.position && props.position};
  text-align: ${props => props.textAlign && props.textAlign};
  width: ${props => (props.width ? props.width : '100%')};
  z-index: ${props => props.zIndex && props.zIndex};
  box-shadow: ${props => props.boxShadow && props.boxShadow};

  ${props =>
    props.px &&
    css`
      padding-left: ${props.px}rem;
      padding-right: ${props.px}rem;
    `}

  ${props =>
    props.py &&
    css`
      padding-bottom: ${props.py}rem;
      padding-top: ${props.py}rem;
    `}

    ${props =>
    props.mx &&
    css`
      margin-left: ${props.mx}rem;
      margin-right: ${props.mx}rem;
    `}
    ${props =>
    props.my &&
    css`
      margin-bottom: ${props.my}rem;
      margin-top: ${props.my}rem;
    `}

    ${props =>
    props.hideScrollbar &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }
    `}

    ${props =>
    props.my &&
    css`
      margin-bottom: ${props.my}rem;
      margin-top: ${props.my}rem;
    `}

    &:hover {
    ${(props) =>
      props._hover &&
      css`
        border: ${props._hover.border || '1px solid #000'};
        transition: ${props.transition || '0.15s ease-in-out'};
        background-color: ${props._hover.backgroundColor || '#d8dae0'};
      `}
  }

  @media (max-width: 768px) {
    flex-direction: ${props => props.directionSm && props.directionSm};
    flex-direction: ${props => props.flexSm && props.flexSm};
    justify-content: ${props => props.justifyContentSm && props.justifyContentSm};
    margin: ${props => props.marginSm && props.marginSm};
    padding: ${props => props.paddingSm && props.paddingSm};
    width: ${props => props.widthSm && props.widthSm};
  }

  @media screen and (max-width: 767px) {
    grid-column: ${props => props.gridColumnSm && props.gridColumnSm};
  }
  @media screen and (min-width: 1200px) {
    flex-direction: ${props => props.flexMd && props.flexMd};
  }
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    flex-direction: ${props => props.flexLg && props.flexLg};
  }
`;

export default JaimyFlex;
