import { Dialog, DialogContent } from '@material-ui/core';
import CarouselSlide from '../CarouselSlide';
import { StyledCarousel } from '../ui/Carousel';

const Carousel = ({ mediaItems, selectedIndex, closeMediaModal, classes }) => {
  return (
    <Dialog
      classes={{
        paper: classes.paper,
        paperWidthSm: classes.paperWidthSm,
        paperScrollPaper: classes.scrollPaper
      }}
      open={mediaItems && mediaItems.length > 0 && selectedIndex >= 0}
      onClose={() => closeMediaModal()}
    >
      {mediaItems && (
        <DialogContent classes={{ root: classes.root }}>
          <StyledCarousel
            dynamicHeight
            selectedItem={selectedIndex}
            useKeyboardArrows
            showThumbs={false}
            style={{ height: 'fit-content' }}
          >
            {mediaItems.map((item, idx) => (
              <CarouselSlide item={item} key={idx} idx={idx} />
            ))}
          </StyledCarousel>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default Carousel;
