import { getToken } from '../../util/tokenProvider';
import { API_URL } from '../../util/url';
/* eslint-disable class-methods-use-this */
import { handleResult } from '../../util/serviceHelpers';

class PurchasedService {
  /**
   * Get purchased jobs
   */
  async getFilteredList(filters) {
    const formData = new FormData();

    if (filters.pagination && filters.pagination.page) {
      formData.append('page', filters.pagination.page);
    }
    if (filters.languages && filters.languages.length > 0) {
      filters.languages.forEach(e => formData.append('filter[locales][]', e));
    }
    if (filters.order_direction) {
      formData.append('sorted', true);
      formData.append('order_direction', filters.order_direction);
    }
    if (filters.status) {
      if (filters.status === 'all') {
        formData.append('filter[status][]', ['active']);
        formData.append('filter[status][]', ['archived']);
      } else {
        formData.append('filter[status][]', filters.status);
      }
    }
    if (filters.request_type) {
      formData.append('filter[request_type]', filters.request_type);
    }
    if (filters.category_list) {
      filters.category_list.forEach(e => formData.append('filter[treetrades][]', e));
    }

    if (filters.search_value) {
      formData.append('search_value', filters.search_value);
    }

    const body = new URLSearchParams(formData);

    const request = await fetch(`${API_URL}/api/traders/v1/service_requests/purchased`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${await getToken()}`
      },
      body
    });

    const data = await handleResult(request);

    if (request.ok) {
      const pagination = {
        total: parseInt(request.headers.get('total'), 10),
        perPage: parseInt(request.headers.get('per-page') || 12, 10)
      };

      return {
        data,
        pagination
      };
    }

    return { error: true, info: data };
  }

  /**
   * Archive toggle
   */
  async toggleArchived(id) {
    const response = await fetch(
      `${API_URL}/api/traders/v1/service_requests/${id}/purchased_archived`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        }
      }
    );
    const result = await handleResult(response);
    return result;
  }
}

export default PurchasedService;
