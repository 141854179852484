import { useEffect } from 'react';
import { hideAlert, showAlert } from '../../../store/reducers/alerts/actions';

import { ButtonActions } from '../../common/Button';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { EVerifiedStatus } from '../../../constants/verifiedStatus';
import { ComponentIsVisible } from '../../utils/isVisible';
import { FixMeLater } from '../../../types/FixMeLater';
import { AnyAction } from 'redux';

const DivWrapper = styled.div`
  padding: 0 1.2rem 1.2rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const DivFreeLead = styled.div`
  background-color: #86bcd3;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0.4rem 1rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto 1rem;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-right: 0.6rem;
  }
`;
const ButtonClose = styled(ButtonActions)`
  :nth-of-type(1) {
    margin-bottom: 0;
  }
`;

interface IWaitingForInfoAlertProps {
  userVerifiedStatus: EVerifiedStatus;
  userFreeLeadsRemaining: number;
  waitingForInfo: boolean;
  userWaitForInfoPurchasesLeft: number;
  hideAlert: (value: any) => AnyAction;
  showAlert: (value: any) => AnyAction;
}

const WaitingForInfoAlert = ({
  waitingForInfo,
  hideAlert,
  showAlert,
  userWaitForInfoPurchasesLeft,
  userFreeLeadsRemaining,
  userVerifiedStatus
}: IWaitingForInfoAlertProps) => {
  useEffect(() => {
    if (
      userVerifiedStatus === EVerifiedStatus.RestrictedWaitForInfo &&
      userFreeLeadsRemaining === 0
    ) {
      showAlert({ waitingForInfo: true });
    }
  }, [userFreeLeadsRemaining, showAlert, userVerifiedStatus]);

  const onClose = () => {
    hideAlert({ waitingForInfo: false });
  };
  return (
    <ComponentIsVisible when={waitingForInfo}>
      <DivWrapper data-cy-div="free-lead-alert">
        <DivFreeLead>
          <p>
            <strong>
              <FormattedMessage tagName="span" id="general.error.missingInfo.get_started.pre" />
            </strong>
            <FormattedMessage
              tagName="span"
              id="general.error.missingInfo.get_started.description"
              values={{
                count: userWaitForInfoPurchasesLeft,
                link: <FormattedMessage tagName="span" id="general.error.missingInfo.verify_link" />
              }}
            />
          </p>
          <ButtonClose onClick={onClose}>
            <CloseIcon width={24} height={24} />
          </ButtonClose>
        </DivFreeLead>
      </DivWrapper>
    </ComponentIsVisible>
  );
};

const mapStateToProps = ({
  alerts: { waitingForInfo },
  user: {
    user: { free_leads_remaining, wait_for_info_purchases_left, verified_status }
  }
}: {
  alerts: FixMeLater;
  user: FixMeLater;
}) => ({
  waitingForInfo,
  userFreeLeadsRemaining: free_leads_remaining,
  userWaitForInfoPurchasesLeft: wait_for_info_purchases_left,
  userVerifiedStatus: verified_status
});
const mapDispatchToProps = (dispatch: FixMeLater) => ({
  hideAlert: (value: any) => dispatch(hideAlert(value)),
  showAlert: (value: any) => dispatch(showAlert(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingForInfoAlert);
