import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import {
  cartCheckout,
  cartInvoiceLater,
  checkoutStart,
  loadCart,
  removeFromCart
} from '../../store/reducers/cart/actions';
import { CHECKOUT_ERRORS, VAT_VALIDATION_ERRORS } from '../../util/constants';
import { calculateJobPrice, centsToEuro } from '../../util/priceCents';
import { CartPropType, FuncPropType, UserPropType } from '../../util/PropTypeValues';
import { productParser, raisePageEvent } from '../../logging/analytics';

import CloseIcon from '@material-ui/icons/Close';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CartSVG } from '../../assets/images/cart-icon.svg';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import CheckoutDialog from '../../components/CheckoutDialog';
import { H1TitleBelfius } from '../../components/common/Card';
import JaimyTooltip from '../../components/Tooltip';
import { ROUTES } from '../../routes';
import { validateCheckout } from '../../util/businessLogicHelpers';
import Checkout from './cartCheckout';
import CartExpired from './cartExpired';
import TagManager from 'react-gtm-module';
import { ComponentIsVisible } from '../../components/utils/isVisible';
import { allowedToSeeAddCartButton, EVerifiedStatus } from '../../constants/verifiedStatus';
import JaimyText from '../../theme/components/Typography/Text';
import theme from '../../theme';

const DivCartWrapper = styled.div`
  color: #405a6b;
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  max-width: 1200px;
  margin: auto;
  margin-bottom: ${props => props.isMobile && '8rem'};
  min-height: ${props => !props.isMobile && '750px'};
  padding: 1rem 1.2rem;
`;
const DivPageTitle = styled.div`
  display: flex;
  align-items: flex-end;
  flex-flow: wrap;
  > h1 {
    font-size: 48px;
    margin-right: 1rem;
  }
  > span {
    color: #182a35;
    font-size: 14px;
    margin: 0.3rem 0.7rem;
    margin-left: ${props => props.isMobile && 'auto'};
  }
`;
const DivPageContent = styled.div`
  max-width: 850px;
`;
const DivCartItems = styled.div`
  margin-bottom: 2rem;
  > button {
    flex-wrap: ${props => props.isMobile && 'wrap'};
    display: flex;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border: none;
    font-family: 'Lato';
    &:not(:last-child) {
      border-bottom: 1px solid #d2d2d7;
    }
  }
  > div {
    cursor: pointer;
  }
`;
const ACartItem = styled.div`
  flex-wrap: ${props => props.isMobile && 'wrap'};
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: inherit;
  color: inherit;
  &:not(:last-child) {
    border-bottom: 1px solid #d2d2d7;
  }
  > div {
    flex-basis: ${props => (props.isMobile ? '100%' : '50%')};
  }
`;
const DivItemInfo = styled.div`
  margin-right: ${props => (!props.isMobile ? '1rem' : '0')};
  width: 100%;
  > span {
    font-weight: bold;
    font-size: 10px;
    color: #405a6b;
    text-transform: uppercase;
  }
`;
const ContainerH2 = styled.div`
  display: flex;
`;
const H2 = styled.h2`
  margin-right: 1rem;
  color: #405a6b;
  font-size: 20px;
  font-weight: 'bold';
  font-family: BelfiusNormal, sans-serif;
`;
const DivLocation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  > div {
    &:first-child {
      flex-basis: 40%;
    }
    &:nth-child(2) {
      flex-basis: 20%;
    }
    &:last-child {
      flex-basis: 40%;
    }
    > h3 {
      text-transform: uppercase;
      color: #778d9b;
      font-size: 10px;
    }
    > span {
      color: #32546d;
      font-size: 14px;
      line-height: 100%;
      display: block;
    }
  }
`;
const DivItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  > p {
    color: #637780;
    margin-bottom: 1rem;
    margin-top: ${props => props.isMobile && '1rem'};
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.5rem;
    max-height: 3rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  > div {
    display: flex;
    > span:last-child {
      margin: auto 0 0 auto;
      font-family: 'BelfiusNormal', sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.5rem;
      height: 1.375rem;
    }
  }
`;
const ButtonMain = styled.button`
  padding: 0.5rem 0.5rem 0.5rem 0;
  top: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  min-width: 70px;
  font-weight: 700;
  border: none;
  width: auto;
  overflow: visible;
  background: transparent;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  > svg {
    margin-right: 0.5rem;
    font-size: 10pt;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: 0;
  }
  cursor: pointer;
  color: #c30144;
  &:hover {
    text-decoration: underline;
  }
`;
const SpanAddedByJaimy = styled.span`
  height: min-content;
  margin-top: auto;
  position: relative;
  top: 2px;
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.isMobile ? '0.5rem' : 'inherit')};
  margin-left: ${props => (!props.isMobile ? '1rem' : 'inherit')};

  > svg {
    margin-right: 0.5rem;
  }
`;
const DivCartEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: ${props => (!props.isMobile ? 'solid 1px #d8dae0' : 'none')};
  background-color: #ffffff;
  padding: 5rem 0;
  > * {
    margin: 0.5rem 0;
  }
`;
const AnchorButton = styled(Link)`
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #af1e3c;
  text-decoration: none;
  background-color: #ffffff;
  :hover {
    text-decoration: underline;
  }
`;
const DivErrorMessage = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  background-color: #c30144;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  padding: 1rem 2rem;
  margin: 1rem 1rem 1rem 0rem;
  border: 1px #c30144 solid;

  > svg {
    fill: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 4px;
  }
  > svg:hover {
    fill: #fff;
    border-color: #fff;
  }

  @media (min-width: 1200px) {
    display: flex;
    flex-wrap: nowrap;
    max-width: 1200px;
    width: 100%;
    margin: 1rem auto;
  }
`;
const DivWarningMessage = styled.div`
  border-radius: 30px;
  background-color: rgba(255, 195, 77, 0.25);
  font-size: 14px;
  padding: 1rem 2rem;
  display: flex;
  margin: 2rem 0;
  > span {
    font-weight: bold;
    color: #b37700;
    min-width: fit-content;
    height: fit-content;
    margin: auto 1rem auto 0;
  }
  > span:first-child {
    margin: 0 1rem 0 0;
  }
  > p {
    color: #182a35;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const AnchorErrorLink = styled(Link)`
  text-decoration: underline;
  color: inherit;
`;
const ErrorMessage = ({ user, cart, setShowMessage, showMessage }) => {
  const renderErrorMessage = () => {
    if (cart.error && VAT_VALIDATION_ERRORS.includes(user.vatValidationState)) {
      return (
        <FormattedMessage
          tagName="p"
          id="cart.error.invalid_vat"
          values={{
            profile: (
              <AnchorErrorLink to={ROUTES.PROFILE.handler()}>
                <FormattedMessage tagName="span" id="navbutton.link.profile" />
              </AnchorErrorLink>
            )
          }}
        />
      );
    } else if (CHECKOUT_ERRORS.includes(cart.error)) {
      return <FormattedMessage tagName="p" id={`cart.error.${cart.error}`} />;
    } else {
      return <FormattedMessage tagName="p" id="question.error.unexpected" />;
    }
  };
  if (showMessage || cart.non_available_anymore_sr > 0) {
    return (
      <DivErrorMessage>
        {renderErrorMessage()}
        <CloseIcon
          width={24}
          height={24}
          viewBox="0 0 24 24"
          onClick={() => setShowMessage(false)}
        />
      </DivErrorMessage>
    );
  }
  return null;
};
export const Cart = ({
  user,
  cart,
  loadCart,
  removeFromCart,
  cartCheckout,
  checkoutStart,
  cartInvoiceLater
}) => {
  const intl = useIntl();
  const metaTitle = intl.messages['cartpage.title'];
  const history = useHistory();
  const {
    totalExclVat,
    totalCreditsUsed,
    totalPaymentExclVat,
    totalPaymentInclVat,
    totalCreditsLeft
  } = calculateJobPrice(cart.cart_price, user.credits_remaining, cart.discount_of);

  const [showCheckoutDialog, setShowCheckoutDialog] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    loadCart();
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (cart && cart.redirect_url) {
      window.location.href = cart.redirect_url;
    }
  }, [cart]);
  const { disableCheckout, checkoutAlerts } = validateCheckout(null, user, cart);

  const checkoutEvent = (name, timestamp) =>
    checkoutStart({
      name,
      products: cart.data.map(job => ({ ...job, id: job.service_request_id })),
      totalPaymentInclVat,
      totalPaymentExclVat,
      discount: totalCreditsUsed,
      timestamp
    });

  const onClickPayWithCard = async () => {
    const timestamp = new Date().getTime();
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Checkout Started',
          timestamp: timestamp
        },
        dataLayerName: 'ProDataLayer'
      });
      await cartCheckout({
        ids: cart.data.map(e => e.service_request_id),
        credits: user.credits_remaining,
        timestamp,
        discount_of: cart.discount_of
      });
    } catch (error) {
      setShowMessage(true);
      setShowCheckoutDialog(false);
      checkoutEvent('Order Cancelled', timestamp);
      setTimeout(() => {
        loadCart({ id: user.id });
        setShowMessage(false);
      }, 3000);
    }
  };
  const onClickInvoiceLater = async () => {
    const timestamp = new Date().getTime();
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Checkout Started',
          timestamp: timestamp
        },
        dataLayerName: 'ProDataLayer'
      });
      await cartInvoiceLater({
        ids: cart.data.map(e => e.service_request_id),
        credits: user.credits_remaining,
        timestamp,
        discount_of: cart.discount_of
      });
    } catch (error) {
      setShowMessage(true);
      setShowCheckoutDialog(false);
      checkoutEvent('Order Cancelled', timestamp);
      setTimeout(() => {
        loadCart({ id: user.id });
        setShowMessage(false);
      }, 3000);
    }
  };

  const AddedByJaimy = (
    <JaimyTooltip title={intl.messages['cart.added_by_jaimy']} placement="bottom">
      <SpanAddedByJaimy isMobile={isMobile}>
        <ShoppingBasketOutlinedIcon fontSize="inherit" />
        {intl.messages['cart.added_by_jaimy.short']}
      </SpanAddedByJaimy>
    </JaimyTooltip>
  );

  const isAllowedToSeeTheCartButton = useMemo(() => {
    const isAllowedBasedOnVerifiedStatus = allowedToSeeAddCartButton.includes(user.verified_status);
    if (isAllowedBasedOnVerifiedStatus) {
      return isAllowedBasedOnVerifiedStatus;
    }

    const isVerifiedStatusCheckedAndHasPurchacesLeft =
      user.verified_status === EVerifiedStatus.Checked && user.checked_purchases_left > 0;
    if (isVerifiedStatusCheckedAndHasPurchacesLeft) {
      return isVerifiedStatusCheckedAndHasPurchacesLeft;
    }

    const isVerifiedStatusWaitForInfoHasPurchasesLeft =
      user.verified_status === EVerifiedStatus.RestrictedWaitForInfo &&
      user.wait_for_info_purchases_left > 0;
    if (isVerifiedStatusWaitForInfoHasPurchasesLeft) {
      return isVerifiedStatusWaitForInfoHasPurchasesLeft;
    }

    return false;
  }, [user.checked_purchases_left, user.verified_status, user.wait_for_info_purchases_left]);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivCartWrapper isMobile={isMobile}>
        {!isMobile && (
          <Breadcrumbs>
            <Link to={`${ROUTES.JOBS.handler()}`}>
              <FormattedMessage id="breadcrumbs.link.home" />
            </Link>
            <Link to={`${ROUTES.CART.handler()}`}>
              <FormattedMessage tagName="span" id="breadcrumbs.link.job_shoppingcart" />
            </Link>
          </Breadcrumbs>
        )}
        <DivPageTitle isMobile={isMobile}>
          <H1TitleBelfius>
            <FormattedMessage tagName="span" id="cart.title" />
          </H1TitleBelfius>
        </DivPageTitle>

        <ComponentIsVisible when={isAllowedToSeeTheCartButton}>
          <Checkout
            vatDuplicated={user.vat_validation_state === 'duplicated'}
            userCredits={user.credits_remaining}
            discount={cart.discount_of}
            totalExclVat={totalExclVat}
            cartPrice={cart.cart_price}
            totalCreditsUsed={totalCreditsUsed}
            totalPaymentExclVat={totalPaymentExclVat}
            totalPaymentInclVat={totalPaymentInclVat}
            totalCreditsLeft={totalCreditsLeft}
            itemCount={cart.data.length}
            isMobile={isMobile}
            disableCheckout={disableCheckout || cart.isLoading}
            isLoading={cart.isLoading}
            onClickBuy={() =>
              user.invoice_later_authorization && user.credits_remaining < cart.cart_price
                ? setShowCheckoutDialog(true)
                : onClickPayWithCard()
            }
          />
        </ComponentIsVisible>
        <DivPageContent>
          <ErrorMessage
            user={user}
            cart={cart}
            setShowMessage={setShowMessage}
            showMessage={showMessage}
          />
          <ComponentIsVisible when={checkoutAlerts[EVerifiedStatus.Checked]}>
            <DivWarningMessage>
              <FormattedMessage tagName="span" id="general.error.checked.not_so_fast.pre" />
              <p>
                <FormattedMessage
                  id="general.error.checked.not_so_fast.description"
                  values={{
                    link: (
                      <FormattedMessage tagName="span" id="general.error.checked.verify_link" />
                    ),
                    count: user.checked_purchases_left
                  }}
                  s
                />
              </p>
            </DivWarningMessage>
          </ComponentIsVisible>
          <ComponentIsVisible when={checkoutAlerts[EVerifiedStatus.RestrictedWaitForInfo]}>
            <DivWarningMessage>
              <FormattedMessage tagName="span" id="general.error.missingInfo.not_so_fast.pre" />
              <p>
                <FormattedMessage
                  id="general.error.missingInfo.not_so_fast.description"
                  values={{
                    link: (
                      <FormattedMessage tagName="span" id="general.error.missingInfo.verify_link" />
                    ),
                    count: user.wait_for_info_purchases_left
                  }}
                  s
                />
              </p>
            </DivWarningMessage>
          </ComponentIsVisible>
          <ComponentIsVisible when={isAllowedToSeeTheCartButton}>
            <DivCartItems isMobile={isMobile}>
              {cart &&
                cart.data &&
                cart.data.length > 0 &&
                cart.data.map(item => (
                  <ACartItem
                    onClick={() => history.push(ROUTES.JOBS_ID.handler(item.service_request_id))}
                    key={item.service_request_id}
                    isMobile={isMobile}
                  >
                    <DivItemInfo isMobile={isMobile}>
                      <span>
                        {!!item[`category${user.locale !== 'en' ? `_${user.locale}` : ''}`][1] &&
                          item[`category${user.locale !== 'en' ? `_${user.locale}` : ''}`][0]}
                      </span>
                      <ContainerH2>
                        <H2>
                          {item[`category${user.locale !== 'en' ? `_${user.locale}` : ''}`][1] ||
                            item[`category${user.locale !== 'en' ? `_${user.locale}` : ''}`][0]}
                        </H2>
                      </ContainerH2>
                      <DivLocation>
                        <div>
                          <h3>{intl.messages['servicecardlist.card.location']}</h3>
                          <span>
                            {`${item.postcode} ${
                              user.locale === 'fr' ? item.townNames.fr : item.townNames.nl
                            }`}
                          </span>
                        </div>
                        <div>
                          <h3>{intl.messages['servicecardlist.card.distance']}</h3>
                          <span>{`${item.distance_in_km} km`}</span>
                        </div>
                        <div>
                          <h3>{intl.messages['serviceestimated.jobdate.label']}</h3>
                          <span>
                            <FormattedMessage
                              tagName="span"
                              id={`serviceestimated.jobdate.${item.job_date}`}
                            />
                          </span>
                        </div>
                      </DivLocation>
                    </DivItemInfo>
                    <DivItemDetails isMobile={isMobile}>
                      <p>{item.additionalInfo}</p>
                      {item.proposed_by && isMobile && AddedByJaimy}
                      <div>
                        <ButtonMain
                          type="button"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();

                            TagManager.dataLayer({
                              dataLayer: {
                                event: 'Product Removed',
                                data: productParser(item)
                              },
                              dataLayerName: 'ProDataLayer'
                            });
                            removeFromCart(item.id);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                          {intl.messages['remove.item']}
                        </ButtonMain>
                        {item.proposed_by && !isMobile && AddedByJaimy}
                        <FormattedMessage
                          tagName="span"
                          id="cart.checkout.excl_vat"
                          values={{ price: centsToEuro(item.price_cents) }}
                        />
                      </div>
                    </DivItemDetails>
                  </ACartItem>
                ))}
            </DivCartItems>
          </ComponentIsVisible>
          <ComponentIsVisible when={isAllowedToSeeTheCartButton && cart && cart.cart_size === 0}>
            <DivCartEmpty isMobile={isMobile}>
              <CartSVG />
              <ComponentIsVisible when={cart.isLoading}>
                <FormattedMessage tagName="span" id="cart.loading" />
              </ComponentIsVisible>
              <ComponentIsVisible when={!cart.isLoading}>
                <FormattedMessage tagName="span" id="cart.empty.title" />
                <AnchorButton to={`${ROUTES.JOBS.handler()}`}>
                  <FormattedMessage id="purchased.empty.link" />
                </AnchorButton>
              </ComponentIsVisible>
            </DivCartEmpty>
          </ComponentIsVisible>

          <ComponentIsVisible when={!isAllowedToSeeTheCartButton}>
            <DivCartEmpty isMobile={isMobile}>
              <CartSVG />
              <ComponentIsVisible
                when={user.verified_status === EVerifiedStatus.RestrictedLatePayment60}
              >
                <JaimyText
                  fontSize={theme.typography.fontSizes.lg}
                  fontWeight={600}
                  color={theme.colors.primary.base}
                  textAlign="center"
                >
                  <FormattedMessage id="cart.restriction.late60.message" />
                </JaimyText>
              </ComponentIsVisible>
              <ComponentIsVisible
                when={
                  user.verified_status === EVerifiedStatus.Checked ||
                  user.verified_status === EVerifiedStatus.RestrictedWaitForInfo
                }
              >
                <JaimyText
                  fontSize={theme.typography.fontSizes.lg}
                  fontWeight={600}
                  color={theme.colors.primary.base}
                >
                  <FormattedMessage id="cart.restriction.checkedOrMissingInfo.message" />
                </JaimyText>
              </ComponentIsVisible>
            </DivCartEmpty>
          </ComponentIsVisible>
          <CartExpired />
        </DivPageContent>
        {user.invoice_later_authorization && (
          <CheckoutDialog
            open={showCheckoutDialog}
            handleClose={() => setShowCheckoutDialog(false)}
            payWithCard={onClickPayWithCard}
            invoiceLater={onClickInvoiceLater}
            isLoading={cart.isLoading}
            userVerifiedStatus={user.verified_status}
          />
        )}
      </DivCartWrapper>
    </>
  );
};

Cart.defaultProps = {
  cart: {},
  user: {},
  loadCart: null,
  removeFromCart: null,
  cartCheckout: null,
  cartInvoiceLater: null
};
Cart.propTypes = {
  cart: CartPropType,
  user: UserPropType,
  loadCart: FuncPropType,
  removeFromCart: FuncPropType,
  cartCheckout: FuncPropType,
  cartInvoiceLater: FuncPropType
};

const mapStateToProps = ({ cart, user: { user } }) => ({
  cart,
  user
});
const mapDispatchToProps = {
  loadCart,
  removeFromCart,
  cartCheckout,
  cartInvoiceLater,
  checkoutStart
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
