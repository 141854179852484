import {
  ICreateAppointmentPayloadData,
  IUpdateAppointmentPayloadData
} from '../../types/Appointment';
import { IGetOnlyJaimyAppointmentPayloadData } from '../../types/Calendar';

import { handleResult } from '../../util/serviceHelpers';
import { getToken } from '../../util/tokenProvider';
import { API_URL } from '../../util/url';

class CalendarService {
  async getOnlyJaimyAppointments(payload: IGetOnlyJaimyAppointmentPayloadData) {
    const query = `?start_date=${payload.range.start}&end_date=${payload.range.end}`;

    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.calendarId}/appointments${query}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        }
      }
    ).then(handleResult);

    return response;
  }

  async createAppointment(payload: ICreateAppointmentPayloadData) {
    const requestBody = {
      start_time: payload.startDate,
      end_time: payload.endDate,
      description: payload.description,
      title: payload.title,
      address: payload.address && payload.address.label,
      phone: payload.phone && payload.phone,
      email: payload.email && payload.email
    };

    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.interventionPartnershipId}/create_appointment`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        },
        body: JSON.stringify(requestBody)
      }
    ).then(handleResult);

    return response;
  }

  async updateAppointment(payload: IUpdateAppointmentPayloadData) {
    const requestBody = {
      start_time: payload.startDate,
      end_time: payload.endDate,
      description: payload.description,
      title: payload.title,
      appointment_id: payload.appointmentId,
      phone: payload.phone && payload.phone,
      email: payload.email && payload.email,
      address: payload.address && payload.address
    };

    const response = await fetch(
      `${API_URL}/api/traders/v1/traders/${payload.interventionPartnershipId}/update_appointment`,
      {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${await getToken()}`
        },
        body: JSON.stringify(requestBody)
      }
    ).then(handleResult);

    return response;
  }
}

export default CalendarService;
