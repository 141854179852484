import { useMemo } from 'react';
import JaimyText from '../../../../theme/components/Typography/Text';
import { FormattedMessage } from 'react-intl';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import theme from '../../../../theme';
import { Tooltip } from '@mui/material';

interface IUserGrade {
  grade: number;
  nb_of_rates: number;
  satisfied: number;
  unsatisfied: number;
  very_satisfied: number;
  very_unsatisfied: number;
}

interface IDashboardReviewItemProps {
  review: IUserGrade;
  title: string;
  message: string;
}

export const DashboardReviewItem = ({ title, message, review }: IDashboardReviewItemProps) => {
  const gradeFormatted = useMemo(() => {
    let gradeText = 'very_unsatisfied';

    if (review.grade > 3) {
      gradeText = 'very_satisfied';
    } else if (review.grade > 2) {
      gradeText = 'satisfied';
    } else if (review.grade > 1) {
      gradeText = 'unsatisfied';
    }

    return gradeText;
  }, [review.grade]);

  return (
    <JaimyText>
      <b style={{ color: theme.colors.primary.dark }}>
        <FormattedMessage id={title} />
      </b>
      <FormattedMessage id={message} />
      <Tooltip
        title={
          <JaimyFlex flexDirection="column" gap=".5rem">
            <JaimyText color={theme.colors.white}>
              <FormattedMessage id="dashboard.reviews.very_satisfied" />:{' '}
              <b>
                {review.very_satisfied} / {review.nb_of_rates}
              </b>
            </JaimyText>
            <JaimyText color={theme.colors.white}>
              <FormattedMessage id="dashboard.reviews.satisfied" />:{' '}
              <b>
                {review.satisfied} / {review.nb_of_rates}
              </b>
            </JaimyText>
            <JaimyText color={theme.colors.white}>
              <FormattedMessage id="dashboard.reviews.unsatisfied" />:{' '}
              <b>
                {review.unsatisfied} / {review.nb_of_rates}
              </b>
            </JaimyText>
            <JaimyText color={theme.colors.white}>
              <FormattedMessage id="dashboard.reviews.very_unsatisfied" />:{' '}
              <b>
                {review.very_unsatisfied} / {review.nb_of_rates}
              </b>
            </JaimyText>
          </JaimyFlex>
        }
      >
        <b>
          <FormattedMessage id={`dashboard.reviews.${gradeFormatted}`} />
        </b>
      </Tooltip>
    </JaimyText>
  );
};
