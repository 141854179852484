import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  DivCard,
  DivCardHeader,
  DivCardHeaderTitle,
  DivCardMain
} from '../../../components/common/Card';
import { DivHeader, DivTitleWrapper, SectionTopHeader } from '../../../components/common/Header';
import { raisePageEvent } from '../../../logging/analytics';
import { requestJobById, resetJob } from '../../../store/reducers/services/actions';
import { formatSubtitle, formatTitle, showSubtitle } from '../../../util/cardHelpers';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { PictureAsPdf, Videocam } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/images/location-icon.svg';
import { ReactComponent as MailIcon } from '../../../assets/images/mail.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/person-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/phone.svg';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import CarouselSlide from '../../../components/CarouselSlide';
import ContactCard from '../../../components/ContactCard';
import JaimyTooltip from '../../../components/Tooltip';
import { AnchorBack } from '../../../components/common/Link';
import { StyledCarousel } from '../../../components/ui/Carousel';
import { ComponentIsVisible } from '../../../components/utils/isVisible';
import { ROUTES } from '../../../routes';
import JaimyText from '../../../theme/components/Typography/Text';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';
import { centsToEuro } from '../../../util/priceCents';

const useStyles = makeStyles({
  dialogRoot: {
    padding: '8px 8px',
    '&:first-child': {
      paddingTop: '8px'
    }
  },
  paper: {
    margin: '24px'
  },
  paperWidthSm: {
    // reduce the size of dialog container on mobile landscape view for the image to fit better
    '@media (max-height: 600px)': {
      maxWidth: '450px'
    }
  },
  scrollPaper: {
    maxHeight: 'calc(100% - 80px)'
  }
});

const H2Title = styled.h2`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 32px;
    line-height: 32px;
  }
`;
const DivContent = styled.div`
  padding: 1rem;

  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: self-end;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem 1.2rem;
  }
`;
const DivCardBottom = styled.div`
  @media (max-width: 1200px) {
    padding-top: 1rem;
  }
`;
const DivCardContent = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    div:first-child {
      flex-basis: 40%;
    }
    div:last-child {
      width: 100%;
      flex-basis: 60%;
      padding-right: 1rem;
    }
  }
`;
const DivContentRight = styled.div`
  margin-top: 3rem;

  @media (min-width: 1200px) {
    margin-left: 1rem;

    div:first-child {
      max-width: 350px;
      display: block;
      margin: -7rem auto 2rem;
    }
  }
`;
const DivContentLeft = styled.div`
  @media (min-width: 1200px) {
    flex-basis: 60%;
    div:first-child {
      margin-bottom: 1rem;
    }
  }
`;
const DivDescription = styled.div`
  border: 1px solid #d8dae0;
  background-color: #fff;
  white-space: pre-wrap;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 1rem 1rem;
  font-family: 'Lato', sans-serif;

  > p {
    color: #32546d;
  }

  > dl {
    display: flex;

    dt {
      align-self: center;
      color: #c30b30;
      font-style: normal;
      white-space: nowrap;
      font-weight: 900;
      font-size: 12px;
    }

    dd {
      color: #32546d;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
    }
  }
`;
const DivImages = styled.div`
  border: 1px solid #d8dae0;
  background-color: #fff;
  padding: 1rem;
  margin-top: 1rem;

  > p {
    color: #32546d;
  }

  > span {
    color: #c30b30;
    font-style: normal;
    white-space: nowrap;
    font-weight: 900;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 1rem;
  }
`;
const DivMap = styled.div`
  margin-bottom: 2rem;

  > div {
    margin-top: 1rem;
    width: 100%;
    border-radius: 6px;
    height: 310px;
    background-color: #f2f2f2;
  }
  @media (min-width: 1200px) {
    margin-left: 1rem;
  }
`;

export const DivCardItem = styled.div`
  padding: ${props => (props.first ? '1rem 1rem 0 1rem' : '0 1rem')};
  margin-bottom: 1rem;
  margin-left: 1rem;
  display: flex;

  & > div:first-child {
    display: flex;
    font-family: 'Lato', sans-serif;
    color: #c30144;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0.2rem;
    text-transform: uppercase;

    @media (max-width: 1200px) {
      margin-right: 1rem;
    }

    > span:first-child {
      display: inline-block;
      width: 1rem;
      margin-right: 1rem;

      & > svg {
        fill: #b7c0c4;
      }
    }
  }

  & > div:last-child {
    color: #32546d;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 14px;
    word-break: break-all;
    @media (min-width: 1200px) {
      margin-left: 2rem;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: column;
  }
`;

const createCarouselSlides = (item, idx) => {
  return <CarouselSlide item={item} key={idx} />;
};

const Carousel = ({ classes, mediaItems, selectedIndex, closeMediaModal }) => (
  <Dialog
    classes={{
      paper: classes.paper,
      paperWidthSm: classes.paperWidthSm,
      paperScrollPaper: classes.scrollPaper
    }}
    open={mediaItems && mediaItems.length > 0 && selectedIndex >= 0}
    onClose={() => closeMediaModal()}
  >
    {mediaItems && (
      <DialogContent classes={{ root: classes.root }}>
        <StyledCarousel
          dynamicHeight
          selectedItem={selectedIndex}
          useKeyboardArrows
          showThumbs={false}
        >
          {mediaItems.map((item, idx) => createCarouselSlides(item, idx))}
        </StyledCarousel>
      </DialogContent>
    )}
  </Dialog>
);

export const ServiceEstimated = ({ locale, data = {}, requestJobById, resetJob }) => {
  const intl = useIntl();
  const metaTitle = intl.messages['purchasedpage.title'];

  useEffect(() => {
    const fetchData = async () => {
      const { id } = params;
      const data = await requestJobById({ id });
      if (!data?.customer || !data?.customer.name) {
        history.push(ROUTES.JOBS_ID.handler(id));
      }
    };
    fetchData();
    raisePageEvent({ title: metaTitle });
    return () => {
      resetJob();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    id,
    customer = {},
    postcode,
    townNames,
    additionalInfo,
    job_date: jobDate,
    purchase_price,
    purchase_date
  } = data;

  const town = townNames && (locale === 'fr' ? townNames.fr : townNames.nl);
  const title = formatTitle(data, locale);
  const subtitle = formatSubtitle(data, locale);
  const classes = useStyles();
  const mapRef = useRef(null);
  const [showMediaIndex, setShowMediaIndex] = useState(-1);
  const mediaItems = [...(data.photos || [])];
  let map;
  const history = useHistory();
  const params = useParams();
  //CDM

  useEffect(() => {
    try {
      const googleMaps = async () => {
        try {
          const request = {
            query: `${town}, Belgium`,
            fields: ['geometry']
          };
          const detailedRequest = {
            query: `${customer.street || ''} ${
              customer.number || ''
            } ${postcode} - ${town}, Belgium`,
            fields: ['geometry']
          };
          // eslint-disable-next-line react-hooks/exhaustive-deps
          map = new window.google.maps.Map(mapRef.current, {
            zoom: 14,
            center: { lat: 50.8550625, lng: 4.3053506 },
            mapTypeId: 'terrain',
            disableDefaultUI: true
          });
          const service = new window.google.maps.places.PlacesService(map);
          service.findPlaceFromQuery(detailedRequest, (detailedResults, detailedStatus) => {
            if (detailedStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              new window.google.maps.Marker({
                map,
                position: detailedResults[0].geometry.location
              });
              map.setCenter(detailedResults[0].geometry.location);
            } else {
              service.findPlaceFromQuery(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  new window.google.maps.Circle({
                    strokeColor: '#C30B30',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#C30B30',
                    fillOpacity: 0.35,
                    map,
                    center: results[0].geometry.location,
                    radius: 750
                  });
                  map.setCenter(results[0].geometry.location);
                }
              });
            }
          });
        } catch (err) {
          console.error("Couldn't load google maps, probably due to bad or no network connection");
        }
      };
      if (postcode) {
        googleMaps();
      }
    } catch (err) {
      console.error({ err });
    }
  }, [postcode]);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivHeader>
        <SectionTopHeader>
          {!isMobile && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.PURCHASED.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.job_purchased" />
              </Link>
            </Breadcrumbs>
          )}
          {isMobile && (
            <AnchorBack to={`${ROUTES.PURCHASED.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          )}
        </SectionTopHeader>
        <DivTitleWrapper>
          <H2Title>
            <FormattedMessage
              tagName="span"
              id="serviceestimated.title"
              defaultMessage="Here is all the info you need to get started…"
            />
          </H2Title>
        </DivTitleWrapper>
      </DivHeader>
      <DivContent>
        <DivContentLeft>
          <DivCard>
            <DivCardHeader>
              <DivCardHeaderTitle>
                <hgroup>
                  {showSubtitle(data) ? (
                    <>
                      <h5>{title}</h5>
                      <h2>{subtitle}</h2>
                    </>
                  ) : (
                    <h2>{title}</h2>
                  )}
                </hgroup>
              </DivCardHeaderTitle>
            </DivCardHeader>
            <DivCardContent>
              <DivCardMain>
                <DivCardItem first>
                  <div>
                    <span>
                      <PersonIcon width={12} height={12} fill="#ACB7BB" />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.name" />
                    </span>
                  </div>
                  <div>{customer.name}</div>
                </DivCardItem>
                <DivCardItem>
                  <div>
                    <span>
                      <MailIcon width={12} height={12} fill="#ACB7BB" />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.email" />
                    </span>
                  </div>
                  <div>{customer.email}</div>
                </DivCardItem>
                <DivCardItem>
                  <div>
                    <span>
                      <PhoneIcon width={12} height={12} fill="#ACB7BB" />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.phone" />
                    </span>
                  </div>
                  <div>{customer.phone}</div>
                </DivCardItem>
                <DivCardItem>
                  <div>
                    <span>
                      <LocationIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="servicecardlist.card.location" />
                    </span>
                  </div>
                  <div>
                    {!isEmpty(customer) && (
                      <>
                        <p>{`${customer.street || ''} ${customer.number || ''}`}</p>
                        <p>{`${postcode} ${town}`}</p>
                      </>
                    )}
                  </div>
                </DivCardItem>
                <DivCardItem>
                  <div>
                    <span>
                      <ClockIcon />
                    </span>
                    <span>
                      <FormattedMessage tagName="span" id="serviceestimated.jobdate.label" />
                    </span>
                  </div>
                  <div>
                    {jobDate && (
                      <FormattedMessage tagName="span" id={`serviceestimated.jobdate.${jobDate}`} />
                    )}
                  </div>
                </DivCardItem>
              </DivCardMain>
              {!isMobile && (
                <DivMap id="map">
                  <div ref={mapRef} />
                </DivMap>
              )}
            </DivCardContent>

            <DivCardBottom />
          </DivCard>
          {isMobile && (
            <DivMap id="map">
              <div ref={mapRef} />
            </DivMap>
          )}
          <DivDescription>
            <dl>
              <dt>
                <FormattedMessage
                  tagName="span"
                  id="serviceestimated.reference"
                  defaultMessage="REFERENCE"
                />
              </dt>
              <dd>{id}</dd>
            </dl>
            <p>{additionalInfo}</p>
          </DivDescription>
          {mediaItems && mediaItems.length > 0 && (
            <DivImages>
              <FormattedMessage
                tagName="span"
                id="serviceestimated.images"
                defaultMessage="Images"
              />
              <JaimyFlex flexDirection="column" directionSm="column">
                {mediaItems.map((asset, idx) => {
                  return (
                    <JaimyFlex
                      directionSm="column"
                      alignItems="center"
                      justifyContent="center"
                      margin="10px 0px"
                      padding="10px"
                      paddingSm="5px"
                      border="1px solid #d8dae0"
                      cursor="pointer"
                      key={asset.url}
                      onClick={() => setShowMediaIndex(idx)}
                      _hover={{ backgroundColor: '#d8dae0' }}
                    >
                      <ComponentIsVisible when={asset.asset_type === 'video'}>
                        <JaimyFlex
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          flexDirection="column"
                        >
                          <Videocam onClick={() => setShowMediaIndex(idx)} />
                          <p>{asset.name}</p>
                        </JaimyFlex>
                      </ComponentIsVisible>
                      <ComponentIsVisible when={asset.asset_type === 'document'}>
                        <JaimyTooltip title={isMobile ? '' : asset.name}>
                          <JaimyFlex
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            flexDirection="column"
                            // onClick={() => setShowMediaIndex(idx)}
                          >
                            <a href={asset.url} target="_blank" rel="noopener noreferrer">
                              <PictureAsPdf />
                            </a>
                            <p>{asset.name}</p>
                          </JaimyFlex>
                        </JaimyTooltip>
                      </ComponentIsVisible>
                      <ComponentIsVisible when={asset.asset_type === 'image'}>
                        <JaimyTooltip title={isMobile ? '' : asset.name}>
                          <JaimyFlex alignItems="center" justifyContent="center" width="100%">
                            <img
                              width={'300px'}
                              src={asset.url}
                              alt={asset.asset_type}
                              onClick={() => setShowMediaIndex(idx)}
                            />
                          </JaimyFlex>
                        </JaimyTooltip>
                      </ComponentIsVisible>
                      <ComponentIsVisible when={asset.comment}>
                        <JaimyFlex
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          width="100%"
                          height="100%"
                        >
                          <FormattedMessage tagName="span" id="serviceestimated.purchase.comment" />
                          <JaimyText fontStyle="italic">{asset.comment}</JaimyText>
                        </JaimyFlex>
                      </ComponentIsVisible>
                      <ComponentIsVisible when={!asset.comment}>
                        <JaimyFlex
                          alignItems="center"
                          justifyContent="center"
                          width="100%"
                          height="100%"
                        >
                          <FormattedMessage
                            tagName="span"
                            id="serviceestimated.purchase.no_comment"
                          />
                        </JaimyFlex>
                      </ComponentIsVisible>
                    </JaimyFlex>
                  );
                })}
              </JaimyFlex>
            </DivImages>
          )}
          {purchase_date && typeof purchase_price !== 'undefined' && purchase_price !== null && (
            <DivImages>
              <FormattedMessage
                tagName="span"
                id="serviceestimated.purchase"
                defaultMessage="Purchase"
              />
              <p>
                <FormattedMessage
                  tagName="span"
                  id="serviceestimated.purchase.description"
                  defaultMessage="Job purchase date and price couldn't be found. Please contact us."
                  values={{
                    date: (
                      <strong>
                        {new Intl.DateTimeFormat(locale, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }).format(new Date(purchase_date))}
                      </strong>
                    ),
                    price: (
                      <strong>
                        <FormattedMessage
                          tagName="span"
                          id="cart.price"
                          values={{ price: centsToEuro(purchase_price) }}
                        />
                      </strong>
                    )
                  }}
                />
              </p>
            </DivImages>
          )}
        </DivContentLeft>
        <DivContentRight>
          <ContactCard
            id={id}
            title={<FormattedMessage tagName="span" id="servicespage.contact_us" />}
          >
            <FormattedMessage tagName="span" id="servicespage.contact_us.description" />
          </ContactCard>
        </DivContentRight>
      </DivContent>
      {/* <MediaModal mediaObject={showMediaUrl} closeMediaModal={() => setShowMediaUrl(null)} /> */}
      {mediaItems.length > 0 && (
        <Carousel
          mediaItems={mediaItems}
          selectedIndex={showMediaIndex}
          isMobile={isMobile}
          classes={{
            root: classes.dialogRoot,
            paper: classes.paper,
            paperWidthSm: classes.paperWidthSm,
            scrollPaper: classes.scrollPaper
          }}
          closeMediaModal={() => setShowMediaIndex(-1)}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user: { user }, services }) => ({
  locale: user.locale,
  data: services.jobData
});
const mapDispatchToProps = { requestJobById, resetJob };

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEstimated);
