import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { format } from 'date-fns';
import { fr, nlBE } from 'date-fns/locale';
import getLocale from '../../../../getLocale';
import theme from '../../../../theme';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import { ChangeMonthButton, Header } from './styles';

interface IMyCalendarHeaderProps {
  firstDayOfSelectedMonth: Date;
  handleChangeCurrentMonth: (value: number) => void;
}

export function MyCalendarHeader({
  firstDayOfSelectedMonth,
  handleChangeCurrentMonth
}: IMyCalendarHeaderProps) {
  return (
    <Header>
      <JaimyHeading.H3
        color={theme.colors.secondary.base}
        margin="0 auto 0 0"
        textTransform="capitalize"
      >
        {format(firstDayOfSelectedMonth, 'MMMM yyyy', {
          locale: getLocale() === 'fr' ? fr : nlBE
        })}
      </JaimyHeading.H3>
      <ChangeMonthButton onClick={() => handleChangeCurrentMonth(-1)}>
        <ChevronLeft />
      </ChangeMonthButton>
      <ChangeMonthButton onClick={() => handleChangeCurrentMonth(1)}>
        <ChevronRight />
      </ChangeMonthButton>
    </Header>
  );
}
