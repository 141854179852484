/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import AccountService from '../../lib/services/accountService';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { ROUTES } from '../../routes';
import { ResetPasswordForm } from '../../components/ResetPasswordForm';
import TraderService from '../../lib/services/traderService';
import { connect } from 'react-redux';
import { login } from '../../store/reducers/user/actions';
import { raisePageEvent } from '../../logging/analytics';
import { useHistory } from 'react-router-dom';

const traderService = new TraderService();
const accountService = new AccountService();

const ResetPassword = ({ login }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [message, setMessage] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const metaTitle = 'jaimy.be - reset password';
  useEffect(() => {
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async (username, password) => {
    setIsLoginLoading(true);
    if (!isLoginLoading) {
      try {
        const user = await login({ username, password });
        if (user.leadSourceId && user.leadSourceId === 'teamleader') {
          traderService
            .getTeamleaderUrl()
            .then(({ redirect_url }) => {
              window.location = `${redirect_url}${window.location.search}`;
            })
            .catch(error => console.error("Couldn't get Teamleader URL", error.message));
        } else {
          history.push(ROUTES.PROFILE.handler());
        }
      } catch (error) {
      } finally {
        setIsLoginLoading(false);
      }
    }
  };
  const handleResetPassword = async (resetPasswordToken, password, passwordConfirm, userData) => {
    setIsLoading(true);
    if (!isLoading) {
      accountService
        .resetPassword({
          resetPasswordToken,
          password,
          passwordConfirm,
          userData
        })
        .then(response => {
          setIsLoading(false);
          if (response.error) {
            setErrorMessage(response.error);
          } else if (response.success) {
            setMessage(response.message);
            handleLogin(response.data.uid, password);
            setErrorMessage(null);
          }
        })
        .catch(err => {
          console.error({ err });
          setErrorMessage(err.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <ResetPasswordForm
        onSubmit={handleResetPassword}
        isLoading={isLoading || isLoginLoading}
        errorMessage={errorMessage}
        message={message}
      />
    </>
  );
};

ResetPassword.defaultProps = {};

ResetPassword.propTypes = {
  setUserData: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  login
};

export default connect(null, mapDispatchToProps)(ResetPassword);
