import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import theme from '../../../theme';
import JaimyButton from '../../../theme/components/Button';
import JaimyBox from '../../../theme/components/layout/Box';
import JaimyFlex from '../../../theme/components/layout/Flex';

import JaimyHeading from '../../../theme/components/Typography/Heading';
import JaimyText from '../../../theme/components/Typography/Text';

import formatDate from '../../../util/formatDate';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import DashboardCard from '../../cards/DashboardCard';
import IInvoice from '../../../types/Invoice';
interface DashboardInvoicesProps {
  invoices: IInvoice[];
}

const DashboardInvoices = ({ invoices }: DashboardInvoicesProps) => {
  const [lastThreeInvoices, setLastThreeInvoices] = useState<IInvoice[]>([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (!invoices) return;
    if (invoices.find((invoice) => invoice.invoice === null)) return

    // For privacy reasons we dont display the invoices in staging
    if (process.env.REACT_APP_ENV !== 'production') {
      setLastThreeInvoices([])
      return
    }
    const sortedInvoices = invoices.sort((a, b) => {
      if (!a.invoice || !b.invoice || !a.invoice.payment_status || !a.invoice.creation_date || !b.invoice.creation_date || b.invoice.payment_status) return 0;

      if (a.invoice.payment_status === 'not_paid' && b.invoice.payment_status !== 'not_paid') {
        return -1;
      }
      if (b.invoice.payment_status === 'not_paid' && a.invoice.payment_status !== 'not_paid') {
        return 1;
      }
      if (new Date(a.invoice.creation_date) > new Date(b.invoice.creation_date)) {
        return -1;
      } else if (new Date(a.invoice.creation_date) < new Date(b.invoice.creation_date)) {
        return 1;
      }
      return 0;
    });
    const latestThreeInvoices = sortedInvoices.slice(0, 3);
    setLastThreeInvoices(latestThreeInvoices);
    return () => {
      setLastThreeInvoices([]);
    };
  }, [invoices]);

  return (
    <DashboardCard>
      <JaimyBox padding="10px">
        <JaimyHeading.H3 fontSize="1.2rem" color={theme.colors.blue.darkBlue}>
          <FormattedMessage id="dashboard.invoices" />
        </JaimyHeading.H3>
      </JaimyBox>
      <JaimyFlex
        flexDirection="column"
        padding="10px"
        alignItems="center"
        justifyContent="space-around"
      >
        {lastThreeInvoices.length > 0 ? (
          lastThreeInvoices.map(invoice => {
            return (
              <JaimyFlex
                key={invoice.id}
                justifyContent="space-around"
                alignItems="center"
                boxShadow={theme.shadow}
                padding={width <= 768 ? '20px' : '20px 30px'}
                margin="10px 0px"
                style={{
                  borderTop: `2px solid ${theme.colors.blue.darkBlue}`,
                  borderLeft: `2px solid ${theme.colors.blue.darkBlue}`
                }}
              >
                <JaimyFlex justifyContent="space-around" flexDirection={'column'}>
                  <JaimyText fontWeight={500}>
                    {formatDate(invoice.invoice.creation_date)}
                  </JaimyText>
                  <JaimyText fontWeight={500}>{`Nº: ${invoice.id}`}</JaimyText>
                </JaimyFlex>

                {invoice.invoice.payment_status === 'not_paid' ? (
                  <a href={invoice.invoice.payment_link} style={{ textDecoration: 'none' }}>
                    <JaimyButton.Base>
                      <FormattedMessage tagName="span" id="pay" />
                    </JaimyButton.Base>
                  </a>
                ) : (
                  <JaimyFlex alignItems="center" justifyContent="center">
                    <JaimyText
                      fontWeight={700}
                      fontStyle="italic"
                      color={theme.colors.blue.darkBlue}
                    >
                      <FormattedMessage tagName="span" id="paid" />
                    </JaimyText>
                  </JaimyFlex>
                )}
              </JaimyFlex>
            );
          })
        ) : (
          <JaimyFlex justifyContent="center" alignItems="center" padding="20px">
            <JaimyText fontStyle="italic">
              <FormattedMessage tagName="span" id="dashboard.invoices.empty" />
            </JaimyText>
          </JaimyFlex>
        )}
      </JaimyFlex>
    </DashboardCard>
  );
};

export default DashboardInvoices;
