import styled from 'styled-components';

interface IJaimyDividerProps {
  color?: string;
  variant?: 'small' | 'dashed' | 'solid';
  margin?: string;
}

const JaimyDivider = styled.hr<IJaimyDividerProps>`
  border: 0;
  border-bottom: 1px ${props => props.variant || 'solid'};
  border-bottom-color: ${props => (props.color ? props.color : props.theme.colors.white)};
  height: 1px;
  margin: ${props => (props.margin ? props.margin : '0.75rem 0')};
`;

export default JaimyDivider;
