import { centsToEuro } from '../util/priceCents';
import { v4 as uuidv4 } from 'uuid';
import { formatPrice } from '../formatters/price';

export const addToDataLayer = item => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(item);
};

export const segmentIdentify = (id, properties) => {
  if (process.env.REACT_APP_CYPRESS) {
    return;
  }
  if (window.analytics) {
    window.analytics.identify(id, properties);
  }
};

export const raisePageEvent = properties => {
  if (process.env.REACT_APP_CYPRESS) {
    return;
  }
  if (window.analytics) {
    window.analytics.page(properties);
  }
};

// ** Old segment event
// export const raiseSegmentEvent = (event, properties) => {
//   if (process.env.REACT_APP_CYPRESS) {
//     return;
//   }
//   if (window.analytics) {
//     window.analytics.track(event, {
//       ...properties
//     });
//   }
// };

/**
 * https://segment.com/docs/connections/spec/ecommerce/v2/#product-list-viewed
 */
export const productParser = job => ({
  product_id: job.id,
  sr_id: job.id,
  name: job.description,
  price: formatPrice(job.price_cents ? job.price_cents : job.price),
  category: job.category ? job.category.join(' -> ') : job.treetrade.name,
  sr_location: `${job.postcode} - ${job.townNames ? job.townNames.nl : job.city}, Belgium`
});
/**
 * https://segment.com/docs/connections/spec/ecommerce/v2/#checkout-started
 */
export const orderParser = ({
  products,
  totalPaymentExclVat,
  totalPaymentInclVat,
  discount,
  order_id
}) => ({
  currency: 'EUR',
  products: products.map(productParser),
  quantity: products.length,
  tax: centsToEuro(totalPaymentInclVat - totalPaymentExclVat),
  revenue: centsToEuro(totalPaymentExclVat),
  value: centsToEuro(totalPaymentInclVat),
  discount: centsToEuro(discount),
  order_id
});

export const getQueryVariable = (variable, search = '') => {
  const query = search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
};

export const cookieDomain =
  process.env.REACT_APP_ENV === 'staging'
    ? '.jaimystaging.be'
    : process.env.REACT_APP_ENV === 'production'
    ? '.jaimy.be'
    : '.jaimy.be';

export const getJaimyCookie = traderId => {
  const gclidLocalStorage = window.sessionStorage.getItem('gclid');

  const utmParams = window.sessionStorage.getItem('utm-parameters') || `{}`;

  const utmParametersLocalStorage = utmParams && JSON.parse(utmParams);
  const utmSourceLocalStorage = utmParametersLocalStorage?.utmSource;
  const utmMediumLocalStorage = utmParametersLocalStorage?.utmMedium;
  const utmCampaignLocalStorage = utmParametersLocalStorage?.utmCampaign;

  const utm_campaign =
    getQueryVariable('utm_campaign', window.location.search) || utmCampaignLocalStorage;
  const utm_medium =
    getQueryVariable('utm_medium', window.location.search) || utmMediumLocalStorage;
  const utm_source =
    getQueryVariable('utm_source', window.location.search) || utmSourceLocalStorage;

  const gclid = gclidLocalStorage || getQueryVariable('gclid', window.location.search);
  const fbclid = gclidLocalStorage || getQueryVariable('fbclid', window.location.search);
  const msclkid = gclidLocalStorage || getQueryVariable('msclkid', window.location.search);

  let clid_type;

  const gclidHandler = () => {
    if (gclid) {
      clid_type = 'google';
      return gclid;
    }
    if (fbclid) {
      clid_type = 'facebook';
      return fbclid;
    }
    if (msclkid) {
      clid_type = 'bing';
      return msclkid;
    }
    return undefined;
  };

  const jaimyCookieValues = {
    uuid: uuidv4(),
    clid_id: gclidHandler(),
    clid_type,
    utm_campaign,
    utm_medium,
    utm_source,
    trader_id: traderId
  };
  return jaimyCookieValues;
};
