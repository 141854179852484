import styled, { css } from 'styled-components';

interface IJaimyLabelProps {
  textAlign?: string;
}

const JaimyLabel = styled.label<IJaimyLabelProps>`
  display: block;
  color: #32546d;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
`;

export default JaimyLabel;
