import JaimyGrid from '../../../../theme/components/layout/Grid';
import { IUser } from '../../../../types/User';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { EVerifiedStatus } from '../../../../constants/verifiedStatus';
import { FormattedMessage } from 'react-intl';
import theme from '../../../../theme';
import JaimyText from '../../../../theme/components/Typography/Text';

interface IBannerRestrictionWaitForInfoProps {
  user: IUser;
}

export function BannerRestrictionWaitForInfo({ user }: IBannerRestrictionWaitForInfoProps) {
  return (
    <ComponentIsVisible when={user.verified_status === EVerifiedStatus.RestrictedWaitForInfo}>
      <JaimyGrid backgroundColor={theme.colors.secondary.lighter}>
        <JaimyGrid
          alignItems="center"
          padding=".75rem"
          columnGap=".5rem"
          margin="0 auto"
          maxWidth="1200px"
        >
          <JaimyText textAlign="center" color={theme.colors.white}>
            <FormattedMessage id="mainheader.banner.missingInfo" />
          </JaimyText>
        </JaimyGrid>
      </JaimyGrid>
    </ComponentIsVisible>
  );
}
