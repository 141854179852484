import React from 'react';
import { injectIntl } from 'react-intl';

const CookieLink = ({ intl }) => {
  let href = '';
  switch (intl.locale) {
    case 'en':
      href =
        'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Politique+de+cookies.pdf';
      break;
    case 'fr':
      href =
        'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Politique+de+cookies.pdf';
      break;
    case 'nl':
      href = 'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Cookiebeleid.pdf';
      break;
    default:
      href =
        'https://d1ngac5455aayn.cloudfront.net/pdf/Jaimy+-+after+2024+-+Politique+de+cookies.pdf';
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {intl.messages['footer.contact.cookie-policy.link']}
    </a>
  );
};
export default injectIntl(CookieLink);
