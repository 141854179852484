import Cookies from 'js-cookie';
import AuthService from '../lib/services/authService';
import { store } from '../store/configureStore';
import { USER_LOG_OUT } from '../store/reducers/user/actionTypes';

var Mutex = require('async-mutex').Mutex;
const mutex = new Mutex();
const authService = new AuthService();
let _accessToken = JSON.parse(localStorage.getItem('accessToken'));
export const getToken = async () => {
  const release = await mutex.acquire();
  if (isExpired(getExpirationDate(_accessToken))) {
    try {
      const updatedToken = await authService.refresh(_accessToken);
      setToken(updatedToken);
    } catch (error) {
      logout();
      release();
      throw error;
    }
  }
  release();
  return _accessToken;
};
export const setToken = async accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
  } else {
    localStorage.removeItem('accessToken');
  }
  _accessToken = accessToken;
};

function getExpirationDate(jwtToken) {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split('.')[1]));
  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
}
function isExpired(exp) {
  if (!exp) {
    return false;
  }
  return Date.now() > exp;
}

// User logout with hard refresh and removal of cookie
export const logout = () => {
  // localStorage.removeItem('accessToken');
  // localStorage.removeItem('user');
  Cookies.remove('jaimyCookie');
  store.dispatch({
    type: USER_LOG_OUT
  });
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem('logout', Date.now());
  window.location.href = '/';
};
