const {
  FORMS_ERROR,
  FORMS_ERROR_SET_MULTIPLES,
  FORMS_ERROR_MULTIPLES,
  FORMS_UPDATE,
  SIGN_UP_TRADER_ERROR,
  SIGN_UP_TRADER_SUCCESS,
  SIGN_UP_TRADER_STARTED,
  SIGN_UP_FORM_SUBMITTED,
  RESEND_ONBOARDING_MAIL_STARTED,
  RESEND_ONBOARDING_MAIL_SUCCESS,
  RESEND_ONBOARDING_MAIL_ERROR
} = require('./actionTypes');

const initialState = {
  values: {
    country_code: '+32'
  },
  trader: {},
  isLoading: false,
  error: null,
  hasError: {},
  submitted: false,
  resent: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORMS_UPDATE:
      const error = state.hasError[action.payload.key] ? state.hasError[action.payload.key] : [];

      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.key]: action.payload.value
        },
        hasError: {
          ...state.hasError,
          [action.payload.key]: [...error].filter(e => e !== 'REQUIRED')
        }
      };

    case FORMS_ERROR:
      return {
        ...state,
        hasError: {
          ...state.hasError,
          [action.payload.key]: [...state.hasError[action.payload.key], ...action.payload.error]
        }
      };

    case FORMS_ERROR_MULTIPLES:
      return {
        ...state,
        hasError: {
          ...state.hasError,
          ...action.payload
        }
      };

    case FORMS_ERROR_SET_MULTIPLES:
      return {
        ...state,
        hasError: {
          ...action.payload
        }
      };

    case SIGN_UP_FORM_SUBMITTED:
      return {
        ...state,
        submitted: false
      };

    case SIGN_UP_TRADER_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: {},
        submitted: false
      };

    case SIGN_UP_TRADER_SUCCESS:
      return {
        ...state,
        trader: action.payload,
        isLoading: false,
        error: null,
        hasError: {},
        submitted: true
      };

    case SIGN_UP_TRADER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        hasError: action.payload.hasError,
        submitted: false
      };
    case RESEND_ONBOARDING_MAIL_STARTED:
      return { ...state, isLoading: true, resent: false };
    case RESEND_ONBOARDING_MAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resent: true
      };
    case RESEND_ONBOARDING_MAIL_ERROR:
      return {
        ...state,
        isLoading: false,
        resent: false,
        error: action.payload
      };

    default:
      return state;
  }
};
