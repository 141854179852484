export const setUtmSource = value => sessionStorage.setItem('utm_source', value);

export const getUtmSource = query => {
  if (query.utm_source) {
    return query.utm_source;
  }

  if (sessionStorage.getItem('utm_source')) {
    return sessionStorage.getItem('utm_source');
  }

  return 'organic';
};

export const setUpUTMSource = query => {
  if (query.utm_source) {
    setUtmSource(query.utm_source);
  }
};

export const setUtmMedium = value => sessionStorage.setItem('utm_medium', value);

export const getUtmMedium = query => {
  if (query.utm_medium) {
    return query.utm_medium;
  }

  if (sessionStorage.getItem('utm_medium')) {
    return sessionStorage.getItem('utm_medium');
  }

  return 'organic';
};

export const setUpUTMMedium = query => {
  if (query.utm_medium) {
    setUtmMedium(query.utm_medium);
  }
};
