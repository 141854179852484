import JaimyGrid from '../../../../theme/components/layout/Grid';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import { ComponentIsVisible } from '../../../utils/isVisible';
import theme from '../../../../theme';
import { IUser } from '../../../../types/User';
import { EVerifiedStatus } from '../../../../constants/verifiedStatus';
import JaimyText from '../../../../theme/components/Typography/Text';
import { FormattedMessage } from 'react-intl';

interface IBannerRestrictionBannedProps {
  user: IUser;
}

export function BannerRestrictionBanned({ user }: IBannerRestrictionBannedProps) {
  return (
    <ComponentIsVisible when={user.verified_status === EVerifiedStatus.Banned}>
      <JaimyGrid backgroundColor={theme.colors.primary.dark}>
        <JaimyGrid
          alignItems="center"
          columns="3rem 1fr "
          padding=".75rem"
          columnGap=".5rem"
          margin="0 auto"
          maxWidth="1200px"
        >
          <WarningOutlined htmlColor={theme.colors.white} fontSize="large" />

          <JaimyText color={theme.colors.white}>
            <FormattedMessage id="mainheader.banner.banned" />
          </JaimyText>
        </JaimyGrid>
      </JaimyGrid>
    </ComponentIsVisible>
  );
}
