import Popover from '@material-ui/core/Popover';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStylesPopoverPaper = makeStyles({
  root: {
    background: 'black',
    color: 'white',
    borderRadius: '10px',
    padding: '5px',
    maxWidth: isMobile ? '200px' : '400px'
  }
});
const PopoverContentStyled = styled.div`
  color: white;
  border-radius: 10px;
  padding: 5px;
  background: black;
`;
const SubscriptionPopover = ({ id, open, anchorEl, onClose, children }) => {
  const popoverPaperClasses = useStylesPopoverPaper();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      PaperProps={{ classes: { root: popoverPaperClasses.root } }}
    >
      <PopoverContentStyled>{children}</PopoverContentStyled>
    </Popover>
  );
};
export default SubscriptionPopover;
