import { format } from 'date-fns';
import { fr, nl } from 'date-fns/locale';
import { isLocale } from '../getLocale';

const getMonthAndYear = () => {
  const activeMonthAndYear = format(new Date(), 'LLLL-yyyy', { locale: isLocale('fr') ? fr : nl });

  return activeMonthAndYear;
};

export default getMonthAndYear;
