import { DivHeader, SectionTopHeader } from '../../components/common/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { AnchorBack } from '../../components/common/Link';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { ROUTES } from '../../routes';
import TraderService from '../../lib/services/traderService';
import { centsToEuro } from '../../util/priceCents';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { raisePageEvent } from '../../logging/analytics';
import CircularProgress from '@material-ui/core/CircularProgress';

const H1ProfileTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1.2rem;
  text-align: center;
  margin: 0 auto 1rem;
  text-align: left;
  @media (min-width: 1200px) {
    max-width: 1200px;
    font-size: 32px;
    line-height: 32px;
  }
`;
const DivMonthTitle = styled.div`
  margin: 15px 0;
  font-family: BelfiusNormal;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
  color: #c30b30;
`;
const DivMonthSection = styled.div`
  border-radius: 4px;
  border: solid 1px #d8dae0;
  padding: 15px;
  > div:last-of-type {
    border: none;
  }
`;
const DivBody = styled.div`
  background-color: #fff;
  border: 1px solid #d8dae0;
  border-radius: 4px;
  max-width: 1200px;
  margin: 1rem auto 4rem auto;
  padding: 3rem;
  @media (max-width: 870px) {
    padding: 1rem;
  }
`;
const DivMonthRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 1px #d8dae0;
  justify-content: space-between;
`;
const DivSplitHeader = styled.div`
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 19.2px;
  ${props => !props.isMobile && 'padding: 0;'};
`;
const DivDataField = styled.div`
  margin: 10px;
`;
const DivDataFieldLefttMargin = styled.div`
  margin: 10px;
  margin-left: auto;
`;
const DivDataFieldPayment = styled.div`
  margin: auto 10px;
  min-width: 9.5rem;
  height: auto;
  text-align: right;
  > a {
    text-decoration: none;
  }
`;
const DivPayButton = styled.div`
  border-radius: 3px;
  border: solid 2px #c30b30;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: #ba1c44;
  text-decoration: none;
  padding: 12px;
  margin-left: auto;
  text-transform: uppercase;
  width: fit-content;
`;
const DivCenteredMessage = styled.div`
  text-align: center;
`;
const SpanFieldTitle = styled.span`
  color: #979faa;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  margin: 0.5rem;
`;
const SpanFieldText = styled.span`
  display: block;
  margin: 0.5rem;
  text-align: right;
  > a {
    color: #c30b30;
    text-decoration: none;
  }
`;
const DivOverallBalance = styled.div`
  border-radius: 4px;
  border: solid 1px #d8dae0;
  padding: 15px;
  margin: auto 0;
  display: flex;
  ${props => !props.isMobile && 'padding: 0;'};
`;
const SpanOverallBalanceTitle = styled.span`
  display: block;
  margin: 0.5rem;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  color: #405a6b;
  text-transform: uppercase;
`;
const SpanOverallBalanceField = styled.span`
  display: block;
  margin: 0.5rem;
  font-family: Lato;
  font-size: 24px;
  color: #32546d;
`;
const NoInvoicesMessage = styled.p`
  font-family: 'Lato';
  font-style: italic;
  color: #778d9b;
`;
const BasicErrorMessage = styled.p`
  font-family: 'Lato';
  font-style: italic;
  color: #ba1c44;
`;
const traderService = new TraderService();
export const Invoices = ({ user = {}, locale = 'en' }) => {
  const intl = useIntl();

  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const metaTitle = intl.messages['invoicespage.title'];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let invoices = [];
      let rawInvoices = [];
      try {
        rawInvoices = await traderService.getInvoices();
        invoices = rawInvoices
          .filter(badInvoice => badInvoice.invoice)
          .map(invoice => ({
            id: invoice.id,
            url: invoice.invoice.url,
            fileName: invoice.invoice.fileName,
            invoice_number: invoice.invoice.invoice_number,
            creation_date: invoice.invoice.creation_date,
            jobs_count: invoice.invoice.jobs_count,
            amount: invoice.invoice.amount,
            structured_communication: invoice.invoice.structured_communication,
            payment_status: invoice.invoice.payment_status,
            due_date: invoice.invoice.due_date,
            payment_link: invoice.invoice.payment_link,
            payment_date: invoice.invoice.payment_date,
            payment_method: invoice.invoice.payment_method
          }));
        // For privacy reasons we dont display the invoices in staging
        if (process.env.REACT_APP_ENV !== 'production') {
          setInvoices([]);
        } else {
          setInvoices(invoices);
        }
        setError(false);
      } catch (error) {
        setInvoices([]);
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    raisePageEvent({ title: metaTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const months = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map(m =>
    new Intl.DateTimeFormat(locale, { month: 'long' }).format(
      new Date(Date.UTC(2000, m, 1, 0, 0, 0))
    )
  );

  const getInvoicesMonthSection = invoicesByMonth =>
    invoicesByMonth.map(month => (
      <DivMonthRow key={month.id}>
        <DivDataField>
          <SpanFieldTitle>
            {month.payment_status === 'negative_invoice'
              ? intl.messages['invoices.creditnota']
              : intl.messages['invoices.invoice']}
          </SpanFieldTitle>
          <SpanFieldText>
            <a href={month.url} target="_blank" rel="noopener noreferrer">
              {`${month.invoice_number}.pdf`}
            </a>
          </SpanFieldText>
        </DivDataField>
        <DivDataField>
          <SpanFieldTitle>{intl.messages['invoices.date']}</SpanFieldTitle>
          <SpanFieldText>
            {new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(
              new Date(month.creation_date)
            )}
          </SpanFieldText>
        </DivDataField>
        {month.payment_status === 'not_paid' && (
          <>
            {!isMobile && (
              <DivDataField>
                <SpanFieldTitle>{intl.messages['invoices.due_date']}</SpanFieldTitle>
                <SpanFieldText>
                  {new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(
                    new Date(month.due_date)
                  )}
                </SpanFieldText>
              </DivDataField>
            )}
            {!isMobile && (
              <DivDataField>
                <SpanFieldTitle>{intl.messages['invoices.structured_message']}</SpanFieldTitle>
                <SpanFieldText>{`+++${month.structured_communication}+++`}</SpanFieldText>
              </DivDataField>
            )}
          </>
        )}
        {!isMobile && (
          <DivDataFieldLefttMargin>
            <SpanFieldTitle>{intl.messages['invoices.jobs']}</SpanFieldTitle>
            <SpanFieldText>{month.jobs_count}</SpanFieldText>
          </DivDataFieldLefttMargin>
        )}
        <DivDataField>
          <SpanFieldTitle>{intl.messages['invoices.amount']}</SpanFieldTitle>
          <SpanFieldText>
            <FormattedMessage
              id="cart.price.credit"
              values={{
                price: `${month.payment_status === 'negative_invoice' ? '-' : ''} ${centsToEuro(
                  month.amount
                )}`
              }}
            />
          </SpanFieldText>
        </DivDataField>
        {month.payment_status === 'not_paid' && (
          <DivDataFieldPayment>
            <a href={month.payment_link}>
              <DivPayButton>{intl.messages['invoices.pay_button']}</DivPayButton>
            </a>
          </DivDataFieldPayment>
        )}
        {month.payment_status === 'paid' && (
          <DivDataFieldPayment>
            <SpanFieldTitle>{intl.messages['invoices.payment_type']}</SpanFieldTitle>
            <SpanFieldText>
              {intl.messages[`invoices.payment_type.${month.payment_method}`]}
            </SpanFieldText>
          </DivDataFieldPayment>
        )}
        {month.payment_status === 'negative_invoice' && (
          <DivDataFieldPayment>
            <SpanFieldTitle>{intl.messages['invoices.payment_type']}</SpanFieldTitle>
            <SpanFieldText>{intl.messages['invoices.payment_type.compensation']}</SpanFieldText>
          </DivDataFieldPayment>
        )}
      </DivMonthRow>
    ));

  const getInvoicesStructured = () => {
    let invoicedYears = [];
    invoicedYears = invoices.map(i => new Date(i.creation_date).getFullYear());
    invoicedYears = invoicedYears.filter(
      (sandwich, index) => invoicedYears.indexOf(sandwich) === index
    );
    invoicedYears.sort().reverse();
    invoicedYears = invoicedYears.map(i => ({
      year: i,
      data: invoices.filter(e => new Date(e.creation_date).getFullYear() === i)
    }));
    invoicedYears = invoicedYears.map(year => {
      let invoicedMonths = [];
      invoicedMonths = year.data.map(i => new Date(i.creation_date).getMonth());
      invoicedMonths = invoicedMonths.filter(
        (month, index) => invoicedMonths.indexOf(month) === index
      );
      invoicedMonths = invoicedMonths.map(i => ({
        month: i,
        data: year.data.filter(e => new Date(e.creation_date).getMonth() === i)
      }));
      return { year: year.year, data: invoicedMonths };
    });
    return invoicedYears.map(yearly =>
      yearly.data.map(monthly => (
        <div key={`${yearly.year}-${monthly.month}`}>
          <DivMonthTitle>{`${months[11 - monthly.month]} ${yearly.year}`}</DivMonthTitle>
          <DivMonthSection>{getInvoicesMonthSection(monthly.data)}</DivMonthSection>
        </div>
      ))
    );
  };

  const getOutstandingBalance = () => {
    let total = 0;
    invoices.forEach(i => {
      if (i.payment_status === 'not_paid') {
        total += centsToEuro(i.amount);
      }
    });
    return total;
  };
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <DivHeader>
        <DivSplitHeader>
          <div>
            <SectionTopHeader>
              {!isMobile && (
                <Breadcrumbs>
                  <Link to={`${ROUTES.HOME.handler()}`}>
                    <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
                  </Link>
                  <Link to={`${ROUTES.INVOICES.handler()}`}>
                    <FormattedMessage tagName="span" id="breadcrumbs.link.invoices" />
                  </Link>
                </Breadcrumbs>
              )}
              {isMobile && (
                <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
                  <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
                </AnchorBack>
              )}
            </SectionTopHeader>
            <H1ProfileTitle>
              <FormattedMessage tagName="span" id="invoices.title" defaultMessage="Invoices" />
            </H1ProfileTitle>
          </div>
          {(user.credits_remaining !== 0 || getOutstandingBalance() > 0) && (
            <DivOverallBalance>
              {getOutstandingBalance() > 0 && (
                <div>
                  <SpanOverallBalanceTitle>
                    {intl.messages['invoices.outstanding_balance']}
                  </SpanOverallBalanceTitle>
                  <SpanOverallBalanceField>
                    <FormattedMessage
                      id="cart.price.credit"
                      values={{ price: getOutstandingBalance() }}
                    />
                  </SpanOverallBalanceField>
                </div>
              )}
            </DivOverallBalance>
          )}
        </DivSplitHeader>
      </DivHeader>
      <DivBody>
        {hasError && (
          <DivCenteredMessage>
            <BasicErrorMessage>{intl.messages['general.error.message']}</BasicErrorMessage>
          </DivCenteredMessage>
        )}
        {!hasError && invoices.length > 0 && getInvoicesStructured()}
        {loading && (
          <DivCenteredMessage>
            <CircularProgress />
          </DivCenteredMessage>
        )}
        {!loading && !hasError && invoices.length === 0 && (
          <DivCenteredMessage>
            <NoInvoicesMessage>{intl.messages['invoices.no_invoices_message']}</NoInvoicesMessage>
          </DivCenteredMessage>
        )}
      </DivBody>
    </>
  );
};

const mapStateToProps = ({ user: { user } }) => ({
  locale: user.locale,
  user
});

export default connect(mapStateToProps)(Invoices);
