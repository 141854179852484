import { PictureAsPdf } from '@material-ui/icons';
import JaimyText from '../../theme/components/Typography/Text';
import JaimyBox from '../../theme/components/layout/Box';
import JaimyFlex from '../../theme/components/layout/Flex';
import { ComponentIsVisible } from '../utils/isVisible';

const CarouselSlide = ({ item, idx }) => {
  return (
    <JaimyFlex key={idx} alignItems="center" justifyContent="center" flexDirection="column">
      <ComponentIsVisible when={item.asset_type === 'video'}>
        <video width="320" height="240" controls>
          <source src={item.url} type={`video/mp4`} />
        </video>
      </ComponentIsVisible>
      <ComponentIsVisible when={item.asset_type === 'document'}>
        <JaimyFlex
          height="100px"
          width="80px"
          alignItems="center"
          justifyContent="center"
          border="1px solid #d8dae0"
          _hover={{ backgroundColor: '#d8dae0' }}
        >
          <a href={item.url}>
            <PictureAsPdf style={{ cursor: 'pointer', fontSize: '50px' }} />
          </a>
        </JaimyFlex>
      </ComponentIsVisible>
      <ComponentIsVisible when={item.asset_type === 'image'}>
        <JaimyFlex flexDirection="column">
          <img alt={item.name} src={item.url} />
        </JaimyFlex>
      </ComponentIsVisible>
      <ComponentIsVisible when={!!item.comment}>
        <JaimyBox>
          <JaimyText>{item.comment}</JaimyText>
        </JaimyBox>
      </ComponentIsVisible>
    </JaimyFlex>
  );
};

export default CarouselSlide;
