import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_ERROR,
  CART_ADD_ITEM_SUCCESS,
  CART_CHECKOUT_AWATING_APPROVAL,
  CART_CHECKOUT_REQUEST,
  CART_CHECKOUT_REQUEST_ERROR,
  CART_EXPIRED_DELETE_ERROR,
  CART_EXPIRED_DELETE_REQUEST,
  CART_EXPIRED_DELETE_SUCCESS,
  CART_EXPIRED_ERROR,
  CART_EXPIRED_REQUEST,
  CART_EXPIRED_SUCCESS,
  CART_INVOICE_AWATING_APPROVAL,
  CART_INVOICE_REQUEST,
  CART_INVOICE_REQUEST_ERROR,
  CART_LOAD,
  CART_LOAD_ERROR,
  CART_LOAD_SUCCESS,
  CART_MERGE_ALL,
  CART_REMOVE_ITEM,
  CART_REMOVE_ITEM_ERROR,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REPLACE_ALL,
  CART_RESET_HAS_ERROR,
  REQUEST_PAYMENT_STATUS_ERROR,
  REQUEST_PAYMENT_STATUS_START,
  REQUEST_PAYMENT_STATUS_SUCCESS
} from './actionTypes';
import { orderParser, productParser } from '../../../logging/analytics';

import CartService from '../../../lib/services/cartService';
import { ROUTES } from '../../../routes';
import { SERVICE_UPDATE_CART_ID } from '../services/actionTypes';
import { requestUser } from '../user/actions';
import TagManager from 'react-gtm-module';

const generateCheckoutUrl = (credits, ids, timestamp, discount_of) => {
  const discountQuery = discount_of ? `&discount_of=${discount_of}` : '';
  const query = `credits=${credits}${discountQuery}&ids=${ids.join(
    ','
  )}&timestamp=${timestamp}&utm_nooverride=1`;
  return `${window.location.origin}${ROUTES.CART_CHECKOUT.handler()}?${query}`;
};

const cartService = new CartService();

export function loadCart(payload) {
  return async dispatch => {
    dispatch({
      type: CART_LOAD,
      payload
    });
    try {
      const details = await cartService.cartDetails();
      const data = await cartService.get();
      dispatch({
        type: CART_LOAD_SUCCESS,
        payload: {
          data,
          ...details.cart_details[0]
        }
      });
    } catch (error) {
      dispatch({
        type: CART_LOAD_ERROR,
        payload: error.message
      });
    }
  };
}
export function loadCartDetails(payload) {
  return async dispatch => {
    dispatch({
      type: CART_LOAD,
      payload
    });
    try {
      const details = await cartService.cartDetails();
      dispatch({
        type: CART_LOAD_SUCCESS,
        payload: {
          ...details.cart_details[0]
        }
      });
    } catch (error) {
      dispatch({
        type: CART_LOAD_ERROR,
        payload: error.message
      });
    }
  };
}
export function loadExpired() {
  return async dispatch => {
    dispatch({
      type: CART_EXPIRED_REQUEST
    });
    try {
      const data = await cartService.getExpired();
      dispatch({
        type: CART_EXPIRED_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: CART_EXPIRED_ERROR,
        payload: error.message
      });
    }
  };
}
export function deleteExpired() {
  return async dispatch => {
    dispatch({
      type: CART_EXPIRED_DELETE_REQUEST
    });
    try {
      await cartService.deleteExpired();
      dispatch({
        type: CART_EXPIRED_DELETE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: CART_EXPIRED_DELETE_ERROR,
        payload: error.message
      });
    }
  };
}
export function cartMergeAll(payload) {
  return {
    type: CART_MERGE_ALL,
    payload
  };
}

export function cartReplaceAll(payload) {
  return {
    type: CART_REPLACE_ALL,
    payload
  };
}

export function addServiceToCart(payload) {
  return async dispatch => {
    dispatch({
      type: CART_ADD_ITEM,
      payload
    });
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Product Added',
          data: productParser(payload)
        },
        dataLayerName: 'ProDataLayer'
      });
      const data = await cartService.add(payload.id);
      dispatch({
        type: CART_ADD_ITEM_SUCCESS,
        payload: data
      });
      dispatch({
        type: SERVICE_UPDATE_CART_ID,
        payload: data
      });
      return data;
    } catch (error) {
      dispatch({
        type: CART_ADD_ITEM_ERROR,
        payload: error.message
      });
    }
  };
}

export function checkoutStart({
  name = 'Checkout Started',
  products,
  totalPaymentInclVat,
  totalPaymentExclVat,
  discount,
  timestamp
}) {
  return async () => {
    TagManager.dataLayer({
      dataLayer: {
        event: name,
        order: orderParser({
          products,
          totalPaymentInclVat,
          totalPaymentExclVat,
          discount,
          order_id: timestamp
        })
      },
      dataLayerName: 'ProDataLayer'
    });
  };
}
/**
 * Used on the Service Request page. To buy only one job.
 * @param { id: Number } payload
 */
export function buyService(payload) {
  return async (dispatch, getState) => {
    dispatch({
      type: CART_CHECKOUT_REQUEST,
      payload
    });

    const { credits, id, timestamp, discount_of } = payload;
    try {
      const url = generateCheckoutUrl(credits, [id], timestamp, discount_of);
      await cartService.createMollieId();
      const data = await cartService.checkout([id], url);
      dispatch({
        type: CART_CHECKOUT_AWATING_APPROVAL,
        payload: data.redirect_url
      });
      const { user } = getState().user;
      dispatch(requestUser(user.id));
    } catch (error) {
      dispatch({
        type: CART_CHECKOUT_REQUEST_ERROR,
        payload: error.message
      });
      throw error;
    }
  };
}

/**
 * Used on the Service Request page. To invoice only one job.
 * @param { id: Number } payload
 */
export function invoiceService(payload) {
  return async dispatch => {
    dispatch({
      type: CART_INVOICE_REQUEST,
      payload
    });
    const { credits, id, timestamp, discount_of } = payload;
    const url = generateCheckoutUrl(credits, [id], timestamp, discount_of);
    try {
      await cartService.checkoutInvoiceLater([id]);
      dispatch({
        type: CART_INVOICE_AWATING_APPROVAL,
        payload: url
      });
    } catch (error) {
      dispatch({
        type: CART_INVOICE_REQUEST_ERROR,
        payload: error.message
      });
      throw error;
    }
  };
}

export function removeFromCart(payload) {
  return async dispatch => {
    dispatch({
      type: CART_REMOVE_ITEM,
      payload
    });
    try {
      const data = await cartService.remove(payload);
      dispatch({
        type: CART_REMOVE_ITEM_SUCCESS,
        payload
      });
      dispatch({
        type: SERVICE_UPDATE_CART_ID,
        payload: data
      });
      return data;
    } catch (error) {
      dispatch({
        type: CART_REMOVE_ITEM_ERROR,
        payload: error.message
      });
    }
  };
}

export function cartCheckout({ ids, credits, timestamp, discount_of }) {
  const url = generateCheckoutUrl(credits, ids, timestamp, discount_of);

  return async (dispatch, getState) => {
    dispatch({
      type: CART_CHECKOUT_REQUEST,
      ids
    });
    try {
      const data = await cartService.checkout(ids, url);
      dispatch({
        type: CART_CHECKOUT_AWATING_APPROVAL,
        payload: data.redirect_url
      });
      const { user } = getState().user;
      dispatch(requestUser(user.id));
    } catch (error) {
      dispatch({
        type: CART_CHECKOUT_REQUEST_ERROR,
        payload: error.message
      });
      throw error;
    }
  };
}

export function cartResetError() {
  return async dispatch => {
    dispatch({
      type: CART_RESET_HAS_ERROR
    });
  };
}

export function cartInvoiceLater({ ids, credits, timestamp, discount_of }) {
  return async dispatch => {
    dispatch({
      type: CART_INVOICE_REQUEST,
      ids
    });
    try {
      const url = generateCheckoutUrl(credits, ids, timestamp, discount_of);
      await cartService.checkoutInvoiceLater(ids);
      dispatch({
        type: CART_INVOICE_AWATING_APPROVAL,
        payload: url
      });
    } catch (error) {
      dispatch({
        type: CART_INVOICE_REQUEST_ERROR,
        payload: error.message
      });
      throw error;
    }
  };
}

const PAYMENT_STATUS_ERRORS = ['expired', 'failed', 'canceled'];
export function requestPaymentStatus() {
  return async dispatch => {
    dispatch({
      type: REQUEST_PAYMENT_STATUS_START
    });
    try {
      const { payment_status } = await cartService.getPaymentStatus();
      if (PAYMENT_STATUS_ERRORS.includes(payment_status)) {
        throw new Error(payment_status);
      }

      dispatch({
        type: REQUEST_PAYMENT_STATUS_SUCCESS,
        payload: { paymentStatus: payment_status }
      });
    } catch (error) {
      dispatch({
        type: REQUEST_PAYMENT_STATUS_ERROR,
        payload: { error: error.message }
      });
    }
  };
}
