import jwtDecode from 'jwt-decode';

import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_ERROR,
  USER_HAS_UPDATED_PROFILE,
  USER_LOCALE_CHANGE,
  USER_LOG_IN,
  USER_LOG_IN_ERROR,
  USER_LOG_IN_SUCCESS,
  USER_LOG_OUT,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_ERROR,
  USER_UPDATE_PROFILE_SUCCESS,
  TRADER_INVOICES,
  TRADER_INVOICES_ERROR,
  TRADER_INVOICES_SUCCESS
} from './actionTypes';

const initialUser = {};

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : initialUser,
  permissions: localStorage.getItem('accessToken')
    ? jwtDecode(localStorage.getItem('accessToken')).permissions
    : [],
  isLoading: false,
  invoicesLoading: false,
  error: null,
  hasError: false,
  userDataLoading: false,
  userDataError: null,
  invoicesData: [],
  invoicesError: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DATA_ERROR:
      return {
        ...state,
        userDataLoading: false,
        userDataError: action.payload
      };

    case USER_DATA_REQUEST:
      return {
        ...state,
        userDataLoading: true,
        userDataError: null
      };

    case USER_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        userDataLoading: false,
        userDataError: null
      };

    case USER_UPDATE_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
        hasError: false
      };

    case USER_HAS_UPDATED_PROFILE:
      return {
        ...state
      };

    case USER_LOCALE_CHANGE:
    case USER_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
        hasError: false
      };

    case USER_UPDATE_PROFILE_ERROR:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: action.payload.error,
        hasError: true
      };

    case USER_LOG_IN:
      return {
        ...state,
        isLoading: true,
        error: false,
        hasError: false
      };

    case USER_LOG_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: false,
        hasError: false
      };

    case USER_LOG_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        unauthorized: false,
        hasError: true
      };

    case USER_LOG_OUT:
      return {
        isLoading: false,
        user: initialUser
      };

    case TRADER_INVOICES:
      return {
        ...state,
        invoicesLoading: true,
        invoicesData: [],
        invoicesError: null
      };

    case TRADER_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoicesData: action.payload,
        invoicesError: null
      };

    case TRADER_INVOICES_ERROR:
      return {
        ...state,
        invoicesLoading: false,
        invoicesData: [],
        invoicesError: action.payload
      };

    default:
      return state;
  }
};
