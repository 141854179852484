import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  useMediaQuery,
  withStyles
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs';
import { DivHeader, SectionTopHeader } from '../../components/common/Header';
import { AnchorBack } from '../../components/common/Link';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../routes';
import Styles from './styles.module.css';

const H1ProfileTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: center;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 32px;
    line-height: 32px;
  }
`;

const DivContainer = styled.div`
  margin: 0 auto;
  padding: 1rem 1.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const DivHelpSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 4rem;

  @media (max-width: 768px) {
    margin: 0rem;
  }
`;

const H2GroupTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: left;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 32px;
    line-height: 32px;
  }
`;

const AccordionWrapper = styled.div`
  margin: 20px;
`;
const AccordionStyled = withStyles({
  root: {
    color: '#32546D',
    fontWeight: 'normal',
    fontFamily: 'Lato, sans-serif'
  }
})(Accordion);
const AccordionSummaryStyled = withStyles({
  root: {
    color: '#32546D',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Lato, sans-serif',
    padding: '7px',
    fontSize: '18px',
    margin: '5px'
  }
})(AccordionSummary);
const AccordionDetailsStyled = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Lato, sans-serif',
    lineHeight: '32px'
  }
})(AccordionDetails);

const PrismicImage = styled.img``;
const ImageContainerFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  a {
    color: #af1b3c;
  }
  p {
    font-size: 16px;
  }
`;

const DivPreFooterSection = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
  background-color: #687f8e;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  @media (max-width: 1200px) {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }
`;

export const H3PreFootertitle = styled.h3`
  color: white;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 20px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: left;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 700px;
    font-size: 26px;
    line-height: 32px;
  }
`;
export const H4PreFooterSubtitle = styled.h4`
  color: white;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 16px;
  font-style: italic;
  line-height: 28px;
  padding: 0 1rem;
  text-align: left;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 20px;
    line-height: 32px;
  }
`;

const DivIcon = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  margin: auto;
  border: 2.4px solid #405a6b;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Help = () => {
  const intl = useIntl();
  const { width } = useWindowDimensions();
  const metaTitle = intl.messages['help.title'];
  const windowWidth = useMediaQuery('(max-width:768px)');

  const [expanded, setExpanded] = useState(false);
  const [selectedGroupOfQuestions, setSelectedGroupOfQuestions] = useState();
  const locale = useSelector(state => state.user.user.locale);

  const [helpPage] = useSinglePrismicDocument('help_page', {
    lang: locale === 'fr' ? 'fr-be' : 'nl-be'
  });
  const [faqGroups, setFaqGroups] = useState();
  const [preFooterSlice, setPreFooterSlice] = useState();

  useEffect(() => {
    const prismicSlices = helpPage?.data?.body?.map(slice => {
      if (slice.slice_type === 'help_slice_faq') {
        const questions = slice.items.map(question => question);
        return {
          sectionTitle: slice.primary.section_title,
          questions,
          sliceType: slice.slice_type
        };
      } else if (slice.slice_type === 'prefooter') {
        return {
          preFooterTitle: slice.primary.prefooter_title,
          preFooterSubTitle: slice.primary.prefooter_subtitle,
          sliceType: slice.slice_type
        };
      }
      return slice;
    });
    if (!prismicSlices) return;
    setFaqGroups(prismicSlices.filter(slice => slice.sliceType === 'help_slice_faq'));
    setPreFooterSlice(prismicSlices.filter(slice => slice.sliceType === 'prefooter'));
  }, [helpPage]);

  const handleOpenAccordionQuestion = panel => (event, isExpanded) => {
    const element = event.target;
    setExpanded(isExpanded ? panel : false);
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  };
  const handleOpenGroupOfQuestions = section => {
    setSelectedGroupOfQuestions(section);
  };
  const handleBackIcon = () => {
    setSelectedGroupOfQuestions(null);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const AccordionGroupWrapper = withStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: !windowWidth ? '70px 100px 100px 100px' : '30px 10px 30px 10px',
      margin: !windowWidth ? '4rem' : '1rem',
      width: !windowWidth ? '400px' : '300px',
      height: '300px',
      transition: '0.5s transform ease',
      '&:hover': {
        background: '#c30144',
        transform: 'translateY(-15px)',
        cursor: 'pointer'
      }
    }
  })(Paper);

  const AccordionGroupWrapperQuestions = withStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: !windowWidth ? '70px 100px 100px 100px' : '30px 10px 30px 10px',
      width: !windowWidth ? '80vw' : '100vw'
    }
  })(Paper);

  const renderQuestionGroups = () => {
    if (!faqGroups) return;
    return faqGroups.map((questionsGroup, index) => {
      const SectionTitle = questionsGroup.sectionTitle;
      return (
        <AccordionGroupWrapper
          key={SectionTitle}
          elevation={12}
          className={index % 2 === 0 ? Styles.questionGroupPair : Styles.questionGroupOdd}
          onClick={() => handleOpenGroupOfQuestions(SectionTitle)}
          _hover={{ backgroundColor: 'yellow' }}
        >
          <H2GroupTitle style={{ color: 'white' }}>{SectionTitle}</H2GroupTitle>
        </AccordionGroupWrapper>
      );
    });
  };
  const renderQuestionGroup = () => {
    if (!faqGroups) return;
    return faqGroups.map((questionsGroup, index) => {
      const SectionTitle = questionsGroup.sectionTitle;
      if (SectionTitle === selectedGroupOfQuestions) {
        return (
          <AccordionGroupWrapperQuestions
            key={SectionTitle}
            elevation={12}
            className={index % 2 === 0 ? Styles.questionGroupPair : Styles.questionGroupOdd}
          >
            <H2GroupTitle style={{ color: 'white' }}>{SectionTitle}</H2GroupTitle>
            <TransitionGroup>
              {questionsGroup.questions.map(question => (
                <AccordionWrapper key={question.question_title}>
                  <AccordionStyled
                    expanded={expanded === question.question_title}
                    onChange={handleOpenAccordionQuestion(question.question_title)}
                    elevation={16}
                  >
                    <AccordionSummaryStyled
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${SectionTitle}-content`}
                      id="panel1bh-header"
                    >
                      {question.question_title}
                    </AccordionSummaryStyled>
                    <AccordionDetailsStyled>
                      <TextContainerFlex>
                        <PrismicRichText field={question.question_content} />
                      </TextContainerFlex>
                      {question.question_image.url && (
                        <ImageContainerFlex>
                          <PrismicImage
                            src={question.question_image.url}
                            style={{
                              width: !windowWidth
                                ? question.question_image.dimensions.width
                                : '240px',
                              height: !windowWidth
                                ? question.question_image.dimensions.height
                                : '200px'
                            }}
                          />
                        </ImageContainerFlex>
                      )}
                    </AccordionDetailsStyled>
                  </AccordionStyled>
                </AccordionWrapper>
              ))}
            </TransitionGroup>
          </AccordionGroupWrapperQuestions>
        );
      } else return <></>;
    });
  };

  return (
    <>
      <>
        <Helmet>
          <title>{metaTitle}</title>
        </Helmet>
        <DivHeader>
          <SectionTopHeader>
            {width > 768 && (
              <Breadcrumbs>
                <Link to={`${ROUTES.HOME.handler()}`}>
                  <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
                </Link>
                <Link to={`${ROUTES.HELP.handler()}`}>
                  <FormattedMessage tagName="span" id="breadcrumbs.link.help" />
                </Link>
              </Breadcrumbs>
            )}
            {width > 768 && (
              <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
                <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
              </AnchorBack>
            )}
          </SectionTopHeader>
          <H1ProfileTitle>
            <FormattedMessage tagName="span" id="help.title" defaultMessage="My Profile" />
          </H1ProfileTitle>
        </DivHeader>
      </>
      <DivContainer>
        {selectedGroupOfQuestions ? (
          <div>
            <DivIcon onClick={() => handleBackIcon()}>
              <ArrowBackIosIcon sx={{ height: '50px', width: '100px' }} />
            </DivIcon>
            <DivHelpSection>{faqGroups && renderQuestionGroup()}</DivHelpSection>
            <DivIcon onClick={() => handleBackIcon()}>
              <ArrowBackIosIcon sx={{ height: '50px', width: '100px' }} />
            </DivIcon>
          </div>
        ) : (
          <DivHelpSection>{faqGroups && renderQuestionGroups()}</DivHelpSection>
        )}
        {preFooterSlice && (
          <DivPreFooterSection key={preFooterSlice}>
            <H3PreFootertitle>{preFooterSlice[0].preFooterTitle}</H3PreFootertitle>
            <H4PreFooterSubtitle>{preFooterSlice[0].preFooterSubTitle}</H4PreFooterSubtitle>
          </DivPreFooterSection>
        )}
      </DivContainer>
    </>
  );
};

export default Help;
