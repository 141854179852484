import { ERROR_COUPON_INVALID, ERROR_EMAIL_INVALID } from '../../../util/serviceHelpers';
import {
  FORMS_ERROR,
  FORMS_ERROR_MULTIPLES,
  FORMS_ERROR_SET_MULTIPLES,
  FORMS_UPDATE,
  RESEND_ONBOARDING_MAIL_ERROR,
  RESEND_ONBOARDING_MAIL_STARTED,
  RESEND_ONBOARDING_MAIL_SUCCESS,
  SIGN_UP_FORM_SUBMITTED,
  SIGN_UP_TRADER_ERROR,
  SIGN_UP_TRADER_STARTED,
  SIGN_UP_TRADER_SUCCESS
} from './actionTypes';

import TraderService from '../../../lib/services/traderService';

const traderService = new TraderService();

export function updateForm(payload) {
  return {
    type: FORMS_UPDATE,
    payload
  };
}

export function setErrorForm({ key, error }) {
  return {
    type: FORMS_ERROR,
    payload: { key, error }
  };
}

export function setErrorsForm(payload) {
  return {
    type: FORMS_ERROR_MULTIPLES,
    payload
  };
}

export function setMultipleErrorsForm(payload) {
  return {
    type: FORMS_ERROR_SET_MULTIPLES,
    payload
  };
}

export function formSubmitted() {
  return {
    type: SIGN_UP_FORM_SUBMITTED
  };
}

export function sendOnboardingMail(payload) {
  return async dispatch => {
    dispatch({ type: RESEND_ONBOARDING_MAIL_STARTED });
    traderService
      .resendOnboardingMail(payload)
      .then(res =>
        dispatch({
          type: RESEND_ONBOARDING_MAIL_SUCCESS,
          payload: res
        })
      )
      .catch(err =>
        dispatch({
          type: RESEND_ONBOARDING_MAIL_ERROR,
          payload: err
        })
      );
  };
}

export function createTrader(payload) {
  return async dispatch => {
    dispatch(createTraderStarted());

    traderService
      .create(payload)
      .then(res => dispatch(signUpTraderSuccess(res)))
      .catch(err => {
        if (err.message === ERROR_EMAIL_INVALID) {
          dispatch(signUpTraderError(err.data, { email: ['INVALID'] }));
        } else if (err.message === ERROR_COUPON_INVALID) {
          dispatch(signUpTraderError('promocode', { promocode: ['INVALID'] }));
        } else {
          dispatch(signUpTraderError(err.data, {}));
        }
      });
  };
}

const createTraderStarted = () => ({
  type: SIGN_UP_TRADER_STARTED
});
const signUpTraderSuccess = data => ({
  type: SIGN_UP_TRADER_SUCCESS,
  payload: data
});
const signUpTraderError = (error, hasError) => ({
  type: SIGN_UP_TRADER_ERROR,
  payload: {
    error: error,
    hasError: hasError
  }
});
