module.exports = {
  SERVICE_FAVORITE: 'SERVICE_FAVORITE',
  SERVICE_FAVORITE_SUCCESS: 'SERVICE_FAVORITE_SUCCESS',
  SERVICE_FAVORITE_ERROR: 'SERVICE_FAVORITE_ERROR',

  SERVICE_TRASH: 'SERVICE_TRASH',
  SERVICE_TRASH_SUCCESS: 'SERVICE_TRASH_SUCCESS',
  SERVICE_TRASH_ERROR: 'SERVICE_TRASH_ERROR',

  SERVICE_FILTER_UPDATE: 'SERVICE_FILTER_UPDATE',
  SERVICE_FILTER_UPDATE_NO_FETCH: 'SERVICE_FILTER_UPDATE_NO_FETCH',
  SERVICE_FILTER_SUCCESS: 'SERVICE_FILTER_SUCCESS',
  SERVICE_FILTER_ERROR: 'SERVICE_FILTER_ERROR',
  SERVICE_FILTER_RESET: 'SERVICE_FILTER_RESET',

  SERVICE_UPDATE_ID: 'SERVICE_UPDATE_ID',
  SERVICE_UPDATE_CART_ID: 'SERVICE_UPDATE_CART_ID',

  SERVICE_USE_FREE_LEAD: 'SERVICE_USE_FREE_LEAD',
  SERVICE_USE_FREE_LEAD_ERROR: 'SERVICE_USE_FREE_LEAD_ERROR',
  SERVICE_USE_FREE_LEAD_SUCCESS: 'SERVICE_USE_FREE_LEAD_SUCCESS',

  SERVICE_LOAD_MORE_ERROR: 'SERVICE_LOAD_MORE_ERROR',
  SERVICE_LOAD_MORE_SUCCESS: 'SERVICE_LOAD_MORE_SUCCESS',

  JOB_LOADING: 'JOB_LOADING',
  JOB_SUCCESS: 'JOB_SUCCESS',
  JOB_ERROR: 'JOB_ERROR',
  JOB_UNAVAILABLE: 'JOB_UNAVAILABLE',
  JOB_RESET: 'JOB_RESET',

  RELEVANT_JOBS: 'RELEVANT_JOBS',
  RELEVANT_JOBS_SUCCESS: 'RELEVANT_JOBS_SUCCESS',
  RELEVANT_JOBS_ERROR: 'RELEVANT_JOBS_ERROR'
};
