const { SHOW_ALERT, HIDE_ALERT, SHOW_SNACK, HIDE_SNACK } = require('./actionTypes');

const initialState = {
  freeLead: null,
  checked: null,
  waitingForInfo: null,
  snackText: null,
  snackStack: 0
};
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        ...action.payload
      };

    case HIDE_ALERT:
      return {
        ...state,
        ...action.payload
      };
    case SHOW_SNACK:
      return {
        ...state,
        ...action.payload,
        snackStack: state.snackStack ? state.snackStack + 1 : 1
      };

    case HIDE_SNACK:
      if (action.payload) {
        return {
          ...state,
          snackStack: action.payload
        };
      }
      return {
        ...state,
        snackStack: state.snackStack > 0 ? state.snackStack - 1 : 0
      };

    default:
      return state;
  }
};
