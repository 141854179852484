import { Dialog } from '@material-ui/core';
import { format } from 'date-fns';
import theme from '../../../../theme';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';
import JaimyText from '../../../../theme/components/Typography/Text';
import { IEvent } from '../../../../types/Calendar';

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import { formatPrice } from '../../../../formatters/price';
import JaimyButton from '../../../../theme/components/Button';
import { AssignmentInd, Close, LocationOn } from '@material-ui/icons';
import { ComponentIsVisible } from '../../../utils/isVisible';
import { FormattedMessage } from 'react-intl';
import JaimyDivider from '../../../../theme/components/layout/Divider';
import JaimyGrid from '../../../../theme/components/layout/Grid';
import { useMemo } from 'react';

interface IAppointmentDetailsDialog {
  open: boolean;
  handleClose: () => void;
  appointment: IEvent;
}

const AppointmentDetailsDialog = ({
  open,
  handleClose,
  appointment
}: IAppointmentDetailsDialog) => {
  const appointmentTitle = useMemo(() => {
    const title = appointment.serviceRequestId
      ? `${appointment.serviceRequestId} - ${appointment.title}`
      : appointment.title;

    return title;
  }, [appointment.serviceRequestId, appointment.title]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="appointment-details">
      <JaimyFlex justifyContent="flex-end">
        <JaimyButton.WithIcon
          border={`1px solid ${theme.colors.secondary.base}`}
          color={theme.colors.secondary.base}
          padding="0"
          margin=".75rem"
          onClick={handleClose}
          width="2rem"
          height="2rem"
          borderRadius={8}
        >
          <Close fontSize="small" />
        </JaimyButton.WithIcon>
      </JaimyFlex>
      <ComponentIsVisible when={!!appointment}>
        <JaimyFlex
          flexDirection="column"
          padding="0 2rem 1.5rem 2rem"
          height="fit-content"
          gap="1rem"
          color={theme.colors.secondary.base}
        >
          <JaimyGrid columns="1fr auto" columnGap="1rem">
            <JaimyHeading.H3
              color={theme.colors.primary.base}
              fontSizeSm={theme.typography.fontSizes['2xl']}
            >
              {appointmentTitle}
            </JaimyHeading.H3>
            <JaimyGrid>
              <ComponentIsVisible when={!!appointment.category}>
                <JaimyText
                  fontSize={theme.typography.fontSizes.lg}
                  fontWeight="600"
                  textAlign="right"
                >
                  {appointment.category}
                </JaimyText>
              </ComponentIsVisible>
              <ComponentIsVisible when={!!appointment.price}>
                <JaimyText
                  fontSize={theme.typography.fontSizes.lg}
                  fontWeight="600"
                  textAlign="right"
                >
                  {formatPrice(appointment.price as number)}
                </JaimyText>
              </ComponentIsVisible>
            </JaimyGrid>
          </JaimyGrid>
          <JaimyFlex columnGap="1.5rem">
            <JaimyFlex flexDirection="column">
              <FormattedMessage id="calendar.from" />
              <JaimyText fontWeight={600}>
                {format(new Date(appointment.start), 'MM/dd/yyy - kk:mm')}
              </JaimyText>
            </JaimyFlex>
            <JaimyFlex flexDirection="column">
              <FormattedMessage id="calendar.to" />
              <JaimyText fontWeight={600}>
                {format(new Date(appointment.end), 'MM/dd/yyy - kk:mm')}
              </JaimyText>
            </JaimyFlex>
          </JaimyFlex>
          <JaimyDivider margin="0" color="#ccc" />
          <ComponentIsVisible when={!!appointment.name}>
            <JaimyFlex flexDirection="column" gap=".5rem">
              <JaimyHeading.H4 color={theme.colors.secondary.lighter}>
                <FormattedMessage id="calendar.contactInfo" />
              </JaimyHeading.H4>
              <JaimyFlex alignItems="center" gap=".5rem">
                <AssignmentInd htmlColor={theme.colors.primary.base} fontSize="small" />
                <JaimyText>{appointment.name}</JaimyText>
              </JaimyFlex>
              <JaimyFlex alignItems="center" gap=".5rem">
                <LocationOn htmlColor={theme.colors.primary.base} fontSize="small" />
                <JaimyText>{appointment.address || '...'}</JaimyText>
              </JaimyFlex>
              <JaimyFlex alignItems="center" gap=".5rem">
                <PhoneIcon htmlColor={theme.colors.primary.base} fontSize="small" />
                <JaimyText>{appointment.phone || '...'}</JaimyText>
              </JaimyFlex>
              <JaimyFlex alignItems="center" gap=".5rem">
                <EmailIcon htmlColor={theme.colors.primary.base} fontSize="small" />
                <JaimyText>{appointment.email || '...'}</JaimyText>
              </JaimyFlex>
            </JaimyFlex>
          </ComponentIsVisible>
          <ComponentIsVisible when={!appointment.name}>
            <JaimyText>{appointment.address}</JaimyText>
          </ComponentIsVisible>

          <ComponentIsVisible when={!!appointment.description}>
            <JaimyHeading.H4 color={theme.colors.secondary.lighter} margin=".5rem 0 -0.5rem">
              <FormattedMessage id="calendar.appointmentDescription" />
            </JaimyHeading.H4>
            <JaimyText>{appointment.description}</JaimyText>
          </ComponentIsVisible>
        </JaimyFlex>
      </ComponentIsVisible>
    </Dialog>
  );
};

export default AppointmentDetailsDialog;
