import React from 'react';
import { connect } from 'react-redux';

/**
 * This HOC should be able to behave as such:
 * 1. Receive a list of permissions of a user.
 * 2. Conditionally render certain components (ex buttons):
 *  2.1 Verify if a user has requested permission
 *  2.2 Grand or deny the access
 * 3. In case a permission provides additional information
 *  (ex. a number for categories or radius select)
 *  the following steps should be executed:
 *  3.1 Verify if a user has a requested permission.
 *  3.2 Get the user's permission object
 *  3.3 Spread to children additional property called: "limit"
 *      with the extracted value
 *  3.4 Render a children component
 *  Typical usage:
 *  <ValidatePermission permission="category_limit">
    {children}
    </ValidatePermission>  
 */
// const permissions = {
//   old: [],
//   standard: [
//     { name: 'service_radius_limit', can: true, limit: 20 },
//     { name: 'category_limit', can: true, limit: 2 },
//     { name: 'show_invoice_later', can: false }
//   ],
//   pro: [],
//   premium: []
// };

const check = (permissions, permission) => {
  return permissions.find(p => p.name === permission);
};

const ValidatePermission = ({ permission, children, permissions }) => {
  const found = check(permissions, permission);
  if (!found) {
    return children;
  }
  const { can, limit } = found;

  if (limit) {
    const decoratedChildren = React.cloneElement(children, {
      limit
    });
    return decoratedChildren;
  }
  if (can) {
    return children;
  }
  return null;
};

const mapStateToProps = ({ user: { permissions } }) => ({
  permissions
});

export default connect(mapStateToProps)(ValidatePermission);
