import ErrorMessage from './ErrorMessage';
import { logout } from './tokenProvider';
export const ERROR_EMAIL_INVALID = 'ERROR_EMAIL_INVALID';
export const ERROR_COUPON_INVALID = 'ERROR_COUPON_INVALID';
export const ERROR_VAT_ALREADY_USED = 'ERROR_VAT_ALREADY_USED';
export const handleResult = async result => {
  if (result.ok) {
    return result.json();
  }
  /* Error handling */
  if (result.status === 401) {
    logout();
    throw new Error(result.status);
  } else {
    let errorData;
    try {
      // Get error data
      errorData = await result.json();
    } catch (error) {
      throw new Error(result.status);
    }
    if (errorData.grouped_errors && errorData.grouped_errors.email) {
      throw new ErrorMessage(ERROR_EMAIL_INVALID, errorData.grouped_errors.email[0]);
    } else if (errorData.error === 'invalid-coupon-in-create-new-trader') {
      throw new Error(ERROR_COUPON_INVALID);
    } else if (errorData.error && errorData.error === 'vat-already-used') {
      throw new ErrorMessage(ERROR_VAT_ALREADY_USED, errorData.error);
    } else if (result.status === 422) {
      throw new Error(errorData.error);
    } else {
      throw new Error(result.status);
    }
  }
};
export const getTraderId = () => {
  return JSON.parse(localStorage.getItem('user')).id;
};
