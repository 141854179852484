export const NOT_SATISFIED_REASONS = [
  {
    id: 'communication.form.not_satisfied.reason.demand_does_not_correspond_to_real_need',
    value: 'demand_does_not_correspond_to_real_need'
  },
  {
    id: 'communication.form.not_satisfied.reason.technical_difficulties_with_platform',
    value: 'technical_difficulties_with_platform'
  },
  {
    id: 'communication.form.not_satisfied.reason.clients_absent_from_appointment',
    value: 'clients_absent_from_appointment'
  },
  {
    id: 'communication.form.not_satisfied.reason.making_offers_but_few_or_none_accepted',
    value: 'making_offers_but_few_or_none_accepted'
  },
  {
    id: 'communication.form.not_satisfied.reason.other',
    value: 'other'
  }
];
