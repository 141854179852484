import { FormattedMessage, useIntl } from 'react-intl';
import { FuncPropType, StringPropType } from '../../../util/PropTypeValues';
import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { createTrader, sendOnboardingMail } from '../../../store/reducers/forms/actions';
import { getUtmMedium, getUtmSource } from '../../../util/utmSourceHandler';
import {
  phoneValidationRegexBE,
  phoneValidationRegexDE,
  phoneValidationRegexFR,
  phoneValidationRegexNL
} from './phoneValidationRegex';
import styled, { css } from 'styled-components';

import { ButtonPrimary } from '../../common/Button';
import Checkbox from '../../Checkbox';
import { ReactComponent as EnvelopeSVG } from '../../../assets/images/envelope-icon.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { ROUTES } from '../../../routes';
import Select from '../../Select';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import querystring from 'query-string';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'Trader Form Sent'
  },
  dataLayerName: 'ProDataLayer'
};
const DivSignUp = styled.div`
  @media (min-width: 1200px) {
    bottom: -30px;
    position: relative;
  }
  z-index: 1;
`;
const DivFormWrapper = styled.div`
  padding: 2.4rem 1rem 1rem;
  background-color: #fff;
  box-shadow: 0px 2px 18px rgba(173, 173, 173, 0.27087);
  border-radius: 4px;
  > form {
    > div {
      margin: 0.4rem 0;
    }
    > label {
      color: #32546d;
      margin-top: 1rem;
      > span {
        :nth-of-type(2) {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    > button {
      width: 100%;
      justify-content: center;
      margin-top: 2rem;
    }
  }
`;
const PPrimaryTitle = styled.p`
  font-family: 'BelfiusNormal', sans-serif;
  font-weight: bold;
  font-size: 32px;
  margin-top: 1rem;
  line-height: 38px;
  text-align: left;
  color: #c30144;
  ${props =>
    props.white &&
    css`
      color: #fff;
    `}
`;
const PPrimaryText = styled.p`
  color: #32546d;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  margin: 1rem auto;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;
const DivPhone = styled.div`
  display: flex;
  > div {
    :first-of-type {
      flex-basis: 35%;
      margin-right: 0.6rem;
      > div > div {
        height: 38px;
        display: flex;
        padding: 0;
        > span {
          margin: auto 0.5rem;
        }
      }
    }

    :nth-of-type(2) {
      flex-basis: 60%;
    }
  }
  > input {
    width: auto;
  }
`;
const ATermsLink = styled.a`
  text-decoration: underline;
  color: inherit;
`;
const DivPromo = styled.div`
  @media (min-width: 1200px) {
    background-color: #b6d7e5;
    font-family: Lato;
    font-weight: bold;
    font-size: 14px;
    color: #32546d;
    text-align: center;
    position: relative;
    height: 38px;
    width: 475px;
    left: -68px;
    > span {
      line-height: 38px;
      width: 100%;
      display: inline-block;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0px;
      border-top: 20px solid #2e3a43;
      border-left: 23px solid transparent;
    }

    &:after {
      content: '';
      position: relative;
      top: -58px;
      right: -227px;
      display: inline-block;
      background: transparent;
      border-top: 20px solid transparent;
      border-left: 22px solid #2e3a43;
    }
  }
`;
const InputText = styled.input`
  color: #32546d;
  border: 1px solid #d8dae0;
  font-size: 18px;
  padding-left: 0.8rem;
  font-family: 'Lato', sans-serif;
  border-radius: 4px;
  background-color: #f3f4f6;
  outline: none;
  height: 40px;
  width: 100%;
  ${props => !props.valid && 'border-color: #c30b30;'}
  &:focus {
    ${props => !props.valid && 'border-color: #c30b3064;'}
  }
`;
const SpanInputTitle = styled.span`
  display: inline-block;
  margin-top: 1.5rem;
  margin-bottom: 0.4rem;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  color: #405a6b;
`;
const DivSuccess = styled.div`
  font-family: Lato;
  height: 100%;
  display: flex;
  flex-direction: column;
  > p {
    font-size: 14px;
    text-align: center;
    color: #405a6b;
    > button {
      font-size: 14px;
      border: none;
      color: #af1e3c;
      background: none;
      cursor: pointer;
      outline: none;
      &:hover {
        text-decoration: underline;
        text-decoration-color: #af1e3c;
      }
    }
  }
  > h2 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #405a6b;
    margin-bottom: 0.5rem;
  }
  .email {
    font-weight: 600;
    color: #182a35;
  }
  .marginTop {
    margin-top: auto;
  }
`;
const DivIcon = styled.div`
  width: 100px;
  height: 100px;
  padding: 1rem;
  margin: 1rem auto;
  border: 2.4px solid #405a6b;
  border-radius: 100%;
`;
const SpanDuplicateLoginLink = styled(Link)`
  color: red;
`;
const DivError = styled.div`
  padding: 1rem 1rem 0 1rem;
  color: red;
  text-align: center;
  > span {
    display: block;
    font-size: 13pt;
    font-family: Lato, sans-serif;
  }
`;
const SignUp = ({
  submit,
  resendOnboardingMail,
  locale,
  error,
  submitted,
  resent,
  isLoading,
  trader
}) => {
  const location = useLocation();
  const query = querystring.parse(location.search);
  const { voucher } = query;
  const [firstname, setFirstname] = useState({ value: '', touched: false });
  const [lastname, setLastname] = useState({ value: '', touched: false });
  const [promocode, setPromocode] = useState({ value: voucher, touched: false });
  const [email, setEmail] = useState({ value: '', touched: false });
  const [phoneNumber, setPhoneNumber] = useState({ value: '', touched: false });
  const [countryCode, setCountryCode] = useState('+32');
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [promoChecked, setPromoChecked] = useState(false);
  const intl = useIntl();
  const validateFields = () => {
    setFirstname({ value: firstname.value, touched: true });
    setLastname({ value: lastname.value, touched: true });
    setEmail({ value: email.value, touched: true });
    setPhoneNumber({ value: phoneNumber.value, touched: true });
  };

  const isFirstnameValid = firstname.value !== '';
  const isLastNameValid = lastname.value !== '';
  const isEmailValid = email.value !== '';
  const phone = countryCode + phoneNumber.value;
  const isPhoneNumberValid = !(
    !phoneValidationRegexBE.test(phone) &&
    !phoneValidationRegexNL.test(phone) &&
    !phoneValidationRegexFR.test(phone) &&
    !phoneValidationRegexDE.test(phone)
  );

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isFirstnameValid && isLastNameValid && isEmailValid && isPhoneNumberValid) {
      TagManager.dataLayer(tagManagerArgs);
      submit({
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        promocode: promocode.value === '' ? null : promocode.value,
        phone,
        utmSource: getUtmSource(query),
        utmMedium: getUtmMedium(query),
        locale
      });
    }
  };

  const renderPhoneErrors = () => {
    if (!isPhoneNumberValid && phoneNumber.touched) {
      return (
        <span data-cy="fields_missing">{intl.messages['forms.signup.error.phone.invalid']}</span>
      );
    }
  };

  return (
    <DivSignUp>
      <DivFormWrapper>
        {!submitted && (
          <>
            <PPrimaryTitle>
              <FormattedMessage
                tagName="span"
                id="forms.signup.title"
                defaultMessage="Sign up and gain clients"
              />
            </PPrimaryTitle>
            <PPrimaryText>
              <FormattedMessage
                tagName="span"
                id="forms.signup.subtitle"
                defaultMessage="Fill in this form and find out how many jobs leads are in your area today"
              />
            </PPrimaryText>
            {!isMobile && (
              <DivPromo>
                <FormattedMessage tagName="span" id="forms.signup.promo_message" />
              </DivPromo>
            )}
            <form onSubmit={onSubmit} data-cy="signinform">
              <SpanInputTitle>{intl.messages['forms.signup.field.name']}</SpanInputTitle>
              <InputText
                type="text"
                value={firstname.value || ''}
                onChange={e => setFirstname({ value: e.target.value, touched: true })}
                valid={isFirstnameValid || !firstname.touched}
                name="firstname"
                id="firstname"
                required
              />
              <SpanInputTitle>{intl.messages['forms.signup.field.lastname']}</SpanInputTitle>
              <InputText
                type="text"
                value={lastname.value || ''}
                onChange={e => setLastname({ value: e.target.value, touched: true })}
                valid={isLastNameValid || !lastname.touched}
                name="lastname"
                id="lastname"
                required
              />
              <SpanInputTitle>{intl.messages['forms.signup.field.email']}</SpanInputTitle>
              <InputText
                type="email"
                value={email.value || ''}
                onChange={e => setEmail({ value: e.target.value, touched: true })}
                valid={isEmailValid || !email.touched}
                name="email"
                id="email"
                required
              />
              <SpanInputTitle>{intl.messages['forms.signup.field.phone']}</SpanInputTitle>
              <DivPhone>
                <Select
                  name="country_code"
                  data-cy="test"
                  id="country_code"
                  value={countryCode}
                  onChange={e => setCountryCode(e.target.value)}
                  color="secondary"
                  displayEmpty
                >
                  {[
                    { flag: '🇳🇱', country: 'Dutch', code: '+31' },
                    { flag: '🇧🇪', country: 'Belgium', code: '+32' },
                    { flag: '🇫🇷', country: 'French', code: '+33' },
                    { flag: '🇩🇪', country: 'German', code: '+49' }
                  ].map(e => (
                    <MenuItem data-cy-list="country_code" value={e.code} key={e.code}>
                      <span role="img" aria-label={`${e.country} Dutch flag`}>
                        {`${e.flag} ${e.code}`}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
                <div>
                  <InputText
                    type="text"
                    value={phoneNumber.value || ''}
                    onChange={e => setPhoneNumber({ value: e.target.value, touched: true })}
                    valid={isPhoneNumberValid || !phoneNumber.touched}
                    name="phone"
                    id="phone"
                    required
                  />
                </div>
              </DivPhone>
              {voucher && (
                <>
                  <SpanInputTitle>{intl.messages['forms.signup.field.promocode']}</SpanInputTitle>
                  <InputText
                    type="promocode"
                    value={promocode.value || ''}
                    onChange={e => setPromocode({ value: e.target.value, touched: true })}
                    valid={true}
                    name="promocode"
                    id="promocode"
                  />
                </>
              )}
              <FormControlLabel
                data-cy="has_accepted"
                onChange={() => setPrivacyChecked(!privacyChecked)}
                checked={privacyChecked}
                control={<Checkbox color="default" value={privacyChecked} required />}
                label={
                  <FormattedMessage
                    tagName="span"
                    id="forms.signup.label.terms"
                    values={{
                      terms: (
                        <ATermsLink href={`https://jaimy.be/${locale}/terms`} target="_blank">
                          {intl.messages['forms.signup.label.terms.terms_of_service']}
                        </ATermsLink>
                      ),
                      privacy: (
                        <ATermsLink href={`https://jaimy.be/${locale}/privacy`} target="_blank">
                          {intl.messages['forms.signup.label.terms.privacy_policy']}
                        </ATermsLink>
                      )
                    }}
                  />
                }
              />
              <FormControlLabel
                onChange={() => setPromoChecked(!promoChecked)}
                data-cy="has_accepted_marketing"
                checked={promoChecked}
                control={<Checkbox color="default" value={promoChecked} />}
                label={intl.messages['forms.signup.label.promotions']}
              />
              <DivError data-cy="signup_error">
                {error === 'has already been taken' && (
                  <span data-cy="duplicate_email">
                    <FormattedMessage
                      id="forms.signup.error.duplicate_email"
                      defaultMessage="We already have an account associated to this email address, please login."
                      values={{
                        login: (
                          <SpanDuplicateLoginLink to={`${ROUTES.LOGIN.handler()}`}>
                            {intl.messages['forms.signup.error.duplicate_email.login']}
                          </SpanDuplicateLoginLink>
                        )
                      }}
                    />
                  </span>
                )}
                {error === 'promocode' && (
                  <span data-cy="invalid_coupon">
                    <FormattedMessage
                      id="forms.signup.error.wrong_promocode"
                      defaultMessage="The given promo code is not a valid coupon"
                    />
                  </span>
                )}

                {((!isFirstnameValid && firstname.touched) ||
                  (!isLastNameValid && lastname.touched) ||
                  (!isEmailValid && email.touched) ||
                  (phoneNumber.value.length <= 0 && phoneNumber.touched)) && (
                  <span data-cy="fields_missing">
                    {intl.messages['forms.signup.error.missing.fields']}
                  </span>
                )}
                {renderPhoneErrors()}
              </DivError>
              <ButtonPrimary
                type="submit"
                onClick={validateFields}
                secondary
                disabled={isLoading}
                data-cy="submit"
              >
                <FormattedMessage
                  tagName="span"
                  id="forms.signup.submit"
                  defaultMessage="Inscription gratuite"
                />
              </ButtonPrimary>
            </form>
          </>
        )}
        {submitted && (
          <DivSuccess data-cy="success">
            <DivIcon>
              <EnvelopeSVG />
            </DivIcon>
            <FormattedMessage tagName="h2" id="register.form.complete.thanks" />
            <FormattedMessage tagName="p" id="register.form.complete.email_to" />
            <p className="email">{email.value || 'your email'}</p>
            <br />
            <FormattedMessage tagName="h2" id="register.form.complete.confirm_email" />
            <FormattedMessage tagName="p" id="register.form.complete.check_email" />
            {!resent && (
              <>
                <p className="marginTop">{intl.messages['register.form.complete.no_mail']}</p>
                <p>
                  {intl.messages['register.form.complete.check_spam']}
                  <button
                    onClick={() => resendOnboardingMail(trader)}
                    type="button"
                    data-cy="resend"
                  >
                    {intl.messages['register.form.complete.try_again']}
                  </button>
                </p>
              </>
            )}
            {resent && (
              <p className="marginTop" data-cy="resent">
                {intl.messages['register.form.complete.sent_again']}
              </p>
            )}

            {trader.id && (
              <input id="t_id" type="hidden" value={trader.id || 'Unable to receive trader id'} />
            )}
          </DivSuccess>
        )}
      </DivFormWrapper>
    </DivSignUp>
  );
};
SignUp.propTypes = {
  submit: FuncPropType,
  locale: StringPropType
};
SignUp.defaultProps = {
  submit: {},
  locale: 'nl'
};
const mapStateToProps = ({ user: { user }, forms }) => ({
  locale: user.locale,
  error: forms.error,
  trader: forms.trader,
  submitted: !!forms.submitted,
  resent: !!forms.resent,
  isLoading: forms.isLoading
});
const mapDispatchToProps = {
  submit: createTrader,
  resendOnboardingMail: sendOnboardingMail
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
