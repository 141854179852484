import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import SelectUI from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../routes';
import { requestCategories } from '../../store/reducers/categories/actions';
import { serviceFilter } from '../../store/reducers/services/actions';
import theme from '../../theme';
import JaimyButton from '../../theme/components/Button';
import JaimyBox from '../../theme/components/layout/Box';
import JaimyFlex from '../../theme/components/layout/Flex';
import JaimyHeading from '../../theme/components/Typography/Heading';
import { Breadcrumbs } from '../Breadcrumbs';
import ModalFilter from '../ModalFilter/purchased';
import SectionFilter from '../SectionFilter/purchased';

const DivServiceFilter = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #d8dae0;
  position: relative;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: 1rem 0.5rem;

  @media (min-width: 1200px) {
    padding: 2rem;
  }
`;

const SectionTitle = styled.section`
  color: #c30b30;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 1rem;
  @media (min-width: 1200px) {
    max-width: 1200px;
    font-size: 22px;
    margin: 0 auto;
    padding: 0 1.2rem;
  }
`;

const SectionResetFilter = styled.section`
  margin-top: 2rem;
  padding: 0 1.2rem;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 1rem auto;
    margin-top: 2rem;
  }
`;

const ServiceFilter = ({
  itemCount,
  setFilter,
  categories,
  locale,
  services,
  getCategories,
  catLoading,
  user,
  catError,
  resetFilters
}) => {
  const { width } = useWindowDimensions();
  const [isModalOpen, setModalOpen] = useState(false);
  const intl = useIntl();

  const checkIfFiltersMatchInitialState = () => {
    const { filters } = services;
    if (filters.originalState === true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!catError && !catLoading) {
      getCategories(user.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const requestType =
    services.filters && services.filters.request_type ? services.filters.request_type : '';

  const handleRequestType = value => {
    value !== requestType &&
      setFilter({ ...services.filters, request_type: value, originalState: false });
  };

  const orderBy =
    services.filters && services.filters.order_direction ? services.filters.order_direction : '';
  const onOrderBy = ({ target: { value } }) =>
    // only set filter when the value actually changes
    value !== orderBy && setFilter({ ...services.filters, order_direction: value });

  const debouncedResults = useMemo(() => {
    const onSearchValueChange = ({ target: { value } }) => {
      const state = { ...services.filters, search_value: value, originalState: false };
      setFilter(state);
    };
    return debounce(onSearchValueChange, 1000);
  }, [services.filters, setFilter]);
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const resetFiltersWithSameCategories = () => {
    const state = {
      ...services.filters,
      radius: user.service_radius,
      category_list: user.treetrades,
      request_type: '',
      search_value: '',
      status: 'all',
      originalState: true,
      languages: user.matching_locales ? user.matching_locales : [user.locale]
    };
    setFilter(state);
  };

  return (
    <>
      <DivServiceFilter>
        <SectionTitle>
          {width > 768 && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.PURCHASED.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.job_purchased" />
              </Link>
            </Breadcrumbs>
          )}
          <JaimyHeading.H1 style={{ textAlign: width <= 768 ? 'center' : 'start' }}>
            <FormattedMessage tagName="span" id="servicefilter.purchased.title" />
          </JaimyHeading.H1>
          {width <= 768 && (
            <JaimyFlex justifyContent="space-around" margin="2rem 0rem">
              <JaimyButton.Base
                fontWeight="400"
                fontsize="12px"
                padding="20px"
                backgroundColor={theme.colors.secondary.base}
                onClick={() => setModalOpen(!isModalOpen)}
              >
                <FormattedMessage tagName="span" id="servicefilter.show_filters" />
              </JaimyButton.Base>
              <JaimyButton.Base
                fontWeight="400"
                fontsize="12px"
                padding="20px"
                backgroundColor={
                  checkIfFiltersMatchInitialState() === true
                    ? theme.colors.secondary.light
                    : theme.colors.primary.base
                }
                onClick={resetFiltersWithSameCategories}
                disabled={checkIfFiltersMatchInitialState()}
              >
                <FormattedMessage tagName="span" id="servicefilter.reset_filter" />
              </JaimyButton.Base>
            </JaimyFlex>
          )}
          {width > 768 && (
            <SectionFilter
              setFilter={setFilter}
              categories={categories}
              locale={locale}
              debouncedResults={debouncedResults}
            />
          )}
        </SectionTitle>
        <JaimyFlex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          width="300px"
          style={{ bottom: '-1.5px', left: '50%', transform: 'translateX(-50%)', zIndex: '0' }}
          border={`2px solid ${theme.colors.secondary.light}`}
        >
          {''}
        </JaimyFlex>
        <JaimyFlex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          style={{ bottom: '-15px', left: '50%', transform: 'translateX(-50%)', zIndex: '1' }}
        >
          {['request_for_quote', 'intervention_request'].map(name => (
            <JaimyButton.Base
              key={name}
              onClick={() => handleRequestType(name)}
              color={requestType === name ? 'white' : theme.colors.secondary.light}
              backgroundColor={
                requestType === name && name === 'request_for_quote'
                  ? theme.colors.secondary.base
                  : requestType === name && name === 'intervention_request'
                  ? theme.colors.primary.base
                  : theme.colors.background.base
              }
              margin="0px 5px"
              width="125px"
              padding={requestType === name ? '20px' : ''}
              opacity={1}
              fontWeight={requestType === name ? 600 : 400}
              transition="0.4s all ease-out"
              style={{
                border: `2px solid ${theme.colors.secondary.base}`,
                borderBottom:
                  requestType !== name ? `2px solid ${theme.colors.secondary.base}` : '',
                borderRadius: '5px 5px 0px 0px'
              }}
            >
              <FormattedMessage tagName="span" id={`servicecardlist.${name}`} />
            </JaimyButton.Base>
          ))}
        </JaimyFlex>
      </DivServiceFilter>
      <SectionResetFilter>
        <FormattedMessage
          tagName="span"
          id="servicefilter.showing"
          values={{ count: itemCount }}
          defaultMessage="{count, plural, =0 {No jobs} one {# job} other {# jobs}}"
        />
        {width > 768 && checkIfFiltersMatchInitialState() !== true && (
          <JaimyButton.Base
            backgroundColor={
              checkIfFiltersMatchInitialState() === true
                ? theme.colors.secondary.light
                : theme.colors.primary.base
            }
            onClick={resetFiltersWithSameCategories}
            disabled={checkIfFiltersMatchInitialState()}
          >
            <FormattedMessage tagName="span" id="servicefilter.reset_filter" />
          </JaimyButton.Base>
        )}
        <SelectUI value={orderBy} onChange={onOrderBy} displayEmpty>
          <MenuItem value="">
            <FormattedMessage tagName="span" id="servicefilter.default" />
          </MenuItem>
          <MenuItem value="DESC">
            <FormattedMessage tagName="span" id="servicefilter.purchased.sort_high" />
          </MenuItem>
          <MenuItem value="ASC">
            <FormattedMessage tagName="span" id="servicefilter.purchased.sort_low" />
          </MenuItem>
        </SelectUI>
      </SectionResetFilter>
      <ModalFilter
        categories={categories}
        isLoading={catLoading}
        open={isModalOpen}
        setFilter={setFilter}
        onClose={() => setModalOpen(false)}
        onSave={() => setModalOpen(false)}
        isMobile={width <= 768}
        locale={locale}
        total={itemCount}
      />
      {width <= 768 && (
        <JaimyBox
          position="fixed"
          bottom="0"
          width="100%"
          opacity={1}
          backgroundColor={theme.colors.background.dark}
          zIndex={10}
        >
          <JaimyFlex alignItems="center" justifyContent="center" padding="20px">
            <TextField
              name="search_value"
              onChange={debouncedResults}
              placeholder={intl.messages['sectionfilter.search']}
              variant="outlined"
              type="search"
              key={locale}
              style={{ backgroundColor: 'white' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: { height: '50px' }
              }}
            />
          </JaimyFlex>
        </JaimyBox>
      )}
    </>
  );
};
const mapStateToProps = ({ categories, services, user: { user } }) => ({
  services,
  user,
  locale: user.locale,
  categories: categories.tree,
  catLoading: categories.loading,
  catError: categories.error
});

const mapDispatchToProps = dispatch => ({
  setFilter: payload => dispatch(serviceFilter(payload)),
  getCategories: payload => dispatch(requestCategories(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceFilter);
