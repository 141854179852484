import React, { useEffect } from 'react';
import { hideAlert, showAlert } from '../../../store/reducers/alerts/actions';

import { ButtonActions } from '../../common/Button';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
const DivWrapper = styled.div`
  padding: 0 1.2rem 1.2rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const DivFreeLead = styled.div`
  background-color: #86bcd3;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 1.2rem;
  margin-top: 1rem;

  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 0 auto 1rem;
  }

  span {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    margin-right: 0.6rem;
  }
`;
const ButtonClose = styled(ButtonActions)`
  :nth-of-type(1) {
    margin-bottom: 0;
  }
`;

const FreeLeadAlert = ({ freeLead, free_leads_remaining, isCoupon, showAlert, hideAlert }) => {
  useEffect(() => {
    if (free_leads_remaining > 0) {
      showAlert({ freeLead: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClose = () => {
    hideAlert({ freeLead: false });
  };
  if (freeLead) {
    return (
      <DivWrapper data-cy-div="free-lead-alert">
        <DivFreeLead>
          <FormattedMessage
            tagName="span"
            id={`alerts.freelead.${isCoupon ? 'coupon.' : ''}label`}
            values={{ number: free_leads_remaining }}
          />
          <ButtonClose onClick={onClose}>
            <CloseIcon width={24} height={24} />
          </ButtonClose>
        </DivFreeLead>
      </DivWrapper>
    );
  }
  return null;
};

const mapStateToProps = ({
  alerts: { freeLead },
  user: {
    user: { free_leads_remaining, isCoupon }
  }
}) => ({
  freeLead,
  free_leads_remaining,
  isCoupon
});
const mapDispatchToProps = {
  hideAlert,
  showAlert
};
export default connect(mapStateToProps, mapDispatchToProps)(FreeLeadAlert);
