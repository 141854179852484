import React, { useEffect, useState } from 'react';

import CookieLink from '../CookieLink';

import styled from 'styled-components';
import { useIntl } from 'react-intl';

const BannerDiv = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 20px;
  padding: 20px;
  background-color: #f3f5f9;
  justify-content: space-between;
  align-items: center;
  color: var(--jaimy-blue-grey);
  box-shadow: 0 0 20px rgba(50, 50, 50, 0.25);
  z-index: 20;
`;
const BannerP = styled.p`
  margin-right: 15px;
  line-height: 1.2em;
  max-width: 85%;
`;
const Button = styled.button`
  border-radius: 22px;
  background-color: #32546d;
  color: white;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
`;
const ButtonSecondary = styled.button`
  padding: 8px 12px;
  font-size: 12px;
  line-height: 12px;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  border: solid 1px #323232;
  &:hover {
    color: #32546d;
  }
`;
export const COOKIE_STORAGE_ID = 'nester-cookies-accept';

const CookieBanner = () => {
  const intl = useIntl();
  const [openCookieBanner, setOpenCookieBanner] = useState(true);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem(COOKIE_STORAGE_ID);
    setOpenCookieBanner(!hasAcceptedCookies);
  }, []);

  const onCookieClose = () => {
    setOpenCookieBanner(false);
    localStorage.setItem(COOKIE_STORAGE_ID, true);
  };
  const onCookieDeny = () => {
    localStorage.setItem(COOKIE_STORAGE_ID, false);
    onCookieClose();
  };
  if (openCookieBanner) {
    return (
      <BannerDiv>
        <BannerP>
          {intl.messages['cookie-banner.text']} <CookieLink />.
        </BannerP>
        <div>
          <ButtonSecondary style={{ marginRight: '5px' }} type="button" onClick={onCookieDeny}>
            {intl.messages['cookie-banner.button.deny']}
          </ButtonSecondary>
          <Button id="cookie-ok" data-cy="cookie-ok" type="button" onClick={onCookieClose}>
            {intl.messages['cookie-banner.button.ok']}
          </Button>
        </div>
      </BannerDiv>
    );
  }
  return null;
};

export default CookieBanner;
