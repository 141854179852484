import { MenuItem } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import CategoriesTreeSelect from '../CategoriesTreeSelect';
import { LinkButton } from '../common/Button';
import Select from '../Select';

const DialogStyled = withStyles({
  paper: {
    backgroundColor: '#F2F2F2'
  }
})(Dialog);
const Summary = withStyles({
  root: {
    color: '#32546D',
    fontFamily: "'Lato', sans-serif",
    textTransform: 'uppercase',
    fontWeight: 'normal'
  }
})(AccordionSummary);
const FormControlLabelStyled = withStyles({
  label: {
    color: '#32546D'
  }
})(FormControlLabel);
const CheckboxStyled = withStyles({
  checked: {
    color: '#c30144'
  }
})(Checkbox);

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DivHeader = styled.div`
  background: #ffffff;
  border: 1px solid #d8dae0;
  padding: 2rem 1.2rem 1.2rem;
`;
const DivContent = styled.div`
  padding: 1rem;
`;
const DivHeaderActions = styled.div`
  margin-top: 1.6rem;

  & > button {
    &:nth-of-type(1) {
      padding-right: 1rem;
      border-right: 1px solid #979797;
    }

    &:nth-of-type(2) {
      padding-left: 1rem;
    }
  }
`;
const SaveButton = styled.button`
  bottom: 0;
  background: linear-gradient(0deg, #c30144, #c30144);
  border: none;
  color: #ffffff;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 28px;
  width: 100%;
  height: 78px;
  position: absolute;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    background: linear-gradient(0deg, #950f3d, #950f3d);
  }

  :disabled {
    background: linear-gradient(0deg, #b7b3b3, #b7b3b3);
  }
`;
const DivHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;

  & > h2 {
    color: #32546d;
    font-size: 28px;
    line-height: 21px;
    font-family: 'Lato', sans-serif;
  }
`;
const ButtonClose = styled.button`
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  padding: 0.4rem;
  cursor: pointer;
`;
const DivSlider = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d8dae0;
  box-shadow: 0px 2px 4px rgba(216, 216, 216, 0.5);
  padding: 1rem;
  margin-top: 1rem;
`;

const ModalFilter = ({
  open,
  onClose,
  onSave,
  isMobile,
  filters,
  setFilter,
  categories,
  isLoading,
  locale,
  total
}) => {
  const langs = filters.languages ? filters.languages : [];
  const onLanguageChange = e => {
    const { value } = e.target;
    if (langs.includes(value)) {
      const languages = langs.filter(e => e !== value);
      const state = { ...filters, languages };
      setFilter(state);
    } else {
      const state = { ...filters, languages: [...langs, value] };
      setFilter(state);
    }
  };
  const onCategoriesChange = newCategories => {
    const selectedCategories = filters.category_list ? filters.category_list : '';
    if (
      newCategories &&
      selectedCategories &&
      newCategories.toString() !== selectedCategories.toString()
    ) {
      const state = { ...filters, category_list: newCategories };
      setFilter(state);
    }
  };
  const onStatusChange = ({ target: { value } }) => {
    const state = { ...filters, status: value };
    setFilter(state);
  };

  const onSearchValueChange = ({ target: { value } }) => {
    const state = { ...filters, search_value: value };
    setFilter(state);
  };
  const debouncedResults = useMemo(() => {
    return debounce(onSearchValueChange, 1000);
  }, []);
  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  return (
    <DialogStyled open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
      <DivHeader>
        <DivHeaderContent>
          <h2>
            <FormattedMessage tagName="span" id="modalfilter.title" />
          </h2>
          <ButtonClose onClick={onClose}>
            <CloseIcon width={32} height={32} />
          </ButtonClose>
        </DivHeaderContent>
        {!isMobile && (
          <DivHeaderActions>
            <LinkButton>
              <FormattedMessage tagName="span" id="modalfilter.save_filterset" />
            </LinkButton>
            <LinkButton>
              <FormattedMessage tagName="span" id="modalfilter.clear_filterset" />
            </LinkButton>
          </DivHeaderActions>
        )}
      </DivHeader>
      <DivContent>
        <DivSlider>
          <Summary>
            <FormattedMessage tagName="span" id="prebook.page.status" />
          </Summary>
          <Select name="status" value={filters.status} onChange={onStatusChange} fullWidth>
            <MenuItem data-cy-menuitem="all" value="all">
              <FormattedMessage id="servicecardlist.all" defaultMessage="All" />
            </MenuItem>
            <MenuItem data-cy-menuitem="active" value="active">
              <FormattedMessage id="servicecardlist.active" defaultMessage="Active" />
            </MenuItem>
            <MenuItem data-cy-menuitem="archived" value="archived">
              <FormattedMessage id="servicecardlist.archived" defaultMessage="Archived" />
            </MenuItem>
          </Select>
        </DivSlider>
        <DivSlider>
          <Summary>
            <FormattedMessage tagName="span" id="modalfilter.languages.label" />
          </Summary>
          <FormGroup row onChange={onLanguageChange}>
            <FormControlLabelStyled
              control={<CheckboxStyled color="default" checked={langs.includes('en')} value="en" />}
              label="EN"
            />
            <FormControlLabelStyled
              control={<CheckboxStyled color="default" checked={langs.includes('nl')} value="nl" />}
              label="NL"
            />
            <FormControlLabelStyled
              control={<CheckboxStyled color="default" checked={langs.includes('fr')} value="fr" />}
              label="FR"
            />
          </FormGroup>
        </DivSlider>
        <DivSlider>
          <Summary>
            <FormattedMessage tagName="span" id="sectionfilter.category.default" />
          </Summary>
          <CategoriesTreeSelect
            id="treetrades"
            name="treetrades"
            categories={categories}
            locale={locale}
            onSelectTrade={onCategoriesChange}
            disableUnsubCategories
            required
            fullWidth
            primary
          />
        </DivSlider>
      </DivContent>
      <SaveButton disabled={isLoading} onClick={onSave}>
        {isLoading && <CircularProgress color="primary" disableShrink />}
        {!isLoading && (
          <FormattedMessage
            tagName="span"
            id="modalfilter.save.button"
            values={{ number: total }}
          />
        )}
      </SaveButton>
    </DialogStyled>
  );
};

const mapStateToProps = ({ services }) => ({
  filters: services.filters
});

ModalFilter.defaultProps = {
  items: []
};

export default connect(mapStateToProps)(ModalFilter);
