import { format } from 'date-fns';
import { AnyAction, Dispatch } from 'redux';
import VacationService from '../../../lib/services/vacationService';
import {
  IVacationPlanningPayloadData,
  IVacationResponse,
  IVacation,
  IVacationWarningsResponse,
  IVacationWarnings,
  ICalendarVacationsPayloadData,
  ICalendarVacation,
  ICalendarVacationsResponse,
  IDeleteVacationPayloadData,
  IPrebookVacationsPayloadData,
  IPrebookVacationsResponse,
  IPrebookVacation
} from '../../../types/Vacation';
import * as types from './types';

const vacationService = new VacationService();


export const getAllVacations = (traderId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.GET_ALL_REQUEST
      });

      const response: IVacationResponse[] = await vacationService.getAll(traderId);

      const vacationFormatted: IVacation[] = response.map(vacation => ({
        endDate: format(new Date(vacation.end_date), 'dd/MM/yyyy'),
        id: vacation.id,
        pauseCalendar: vacation.pause_calendar,
        pauseCommunication: vacation.pause_communication,
        pausePrebook: vacation.pause_prebook,
        startDate: format(new Date(vacation.start_date), 'dd/MM/yyyy'),
        status: vacation.status
      }));

      dispatch({
        type: types.GET_ALL_SUCCESS,
        payload: vacationFormatted
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ALL_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IGetVacationWarningsRequestAction {
  payload: IVacationPlanningPayloadData;
}

export const getVacationWarnings = ({ payload }: IGetVacationWarningsRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.GET_ALL_WARNINGS_REQUEST
      });

      const response: IVacationWarningsResponse = await vacationService.getAllWarnings(
        payload
      );

      const warningsFormatted: IVacationWarnings[] = response.warnings.map(warning => ({
        id: warning.id,
        name: warning.name,
        warning: warning.warning,
        endDate: new Date(warning.end_date),
        startDate: new Date(warning.start_date),
        type: warning.type,
        endDateFormatted: format(new Date(warning.end_date), 'dd/MM/yyyy'),
        startDateFormatted: format(new Date(warning.start_date), 'dd/MM/yyyy')
      }));

      dispatch({
        type: types.GET_ALL_WARNINGS_SUCCESS,
        payload: warningsFormatted
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ALL_WARNINGS_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IGetCalendarVacationsRequestAction {
  payload: ICalendarVacationsPayloadData;
}

export const getAllVacationsOfCalendar = ({ payload }: IGetCalendarVacationsRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.GET_ALL_OF_CALENDAR_REQUEST
      });

      const response: ICalendarVacationsResponse = await vacationService.getAllOfCalendar(
        payload
      );

      const calendarVacationsFormatted: ICalendarVacation[] = response.vacations.map(
        (calendarVacation) => ({
          endDate: new Date(calendarVacation.end_date),
          id: calendarVacation.id,
          startDate: new Date(calendarVacation.start_date)
        })
      );

      dispatch({
        type: types.GET_ALL_OF_CALENDAR_SUCCESS,
        payload: calendarVacationsFormatted
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ALL_OF_CALENDAR_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IGetPrebookVacationsRequestAction {
  payload: IPrebookVacationsPayloadData;
}

export const getAllVacationsOfPrebook = ({ payload }: IGetPrebookVacationsRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.GET_ALL_OF_PREBOOK_REQUEST
      });

      const response: IPrebookVacationsResponse = await vacationService.getAllOfPrebook(
        payload
      );

      const prebookVacationsFormatted: IPrebookVacation[] = response.vacations.map(
        (prebookVacation) => ({
          endDate: format(new Date(prebookVacation.end_date), 'dd/MM/yyyy'),
          id: prebookVacation.id,
          startDate: format(new Date(prebookVacation.start_date), 'dd/MM/yyyy') 
        })
      );

      dispatch({
        type: types.GET_ALL_OF_PREBOOK_SUCCESS,
        payload: prebookVacationsFormatted
      });
    } catch (error: any) {
      dispatch({
        type: types.GET_ALL_OF_PREBOOK_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IVacationPlaninngRequestAction {
  functions: {
    closeModal: () => void;
    reloadVacations: () => void;
  };
  payload: IVacationPlanningPayloadData;
}

export const createVacationPlanning = ({ payload, functions }: IVacationPlaninngRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response: IVacationWarningsResponse = await vacationService.getAllWarnings(
        payload
      );

      if (!response.success) {
        if (response.warnings.length >= 0) {
          const warningsFormatted: IVacationWarnings[] = response.warnings.map(warning => ({
            ...warning,
            id: warning.id,
            name: warning.name,
            warning: warning.warning,
            endDate: new Date(warning.end_date),
            startDate: new Date(warning.start_date),
            type: warning.type,
            endDateFormatted: format(new Date(warning.end_date), 'dd/MM/yyyy'),
            startDateFormatted: format(new Date(warning.start_date), 'dd/MM/yyyy')
          }));

          dispatch({
            type: types.GET_ALL_WARNINGS_SUCCESS,
            payload: warningsFormatted
          });

          return;
        }
      }

      dispatch({
        type: types.CREATE_PLANNING_REQUEST
      });

      await vacationService.create(payload);

      dispatch({
        type: types.CREATE_PLANNING_SUCCESS
      });

      functions.reloadVacations()
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: types.CREATE_PLANNING_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export const updateVacationPlanning = ({ payload, functions }: IVacationPlaninngRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.UPDATE_PLANNING_REQUEST
      });

      await vacationService.update(payload);

      dispatch({
        type: types.UPDATE_PLANNING_SUCCESS
      });

      functions.reloadVacations();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: types.UPDATE_PLANNING_ERROR,
        error: error.message
      });
      return error;
    }
  };
};

export interface IDeleteVacationRequestAction {
  payload: IDeleteVacationPayloadData;
  functions: {
    reloadVacations: () => void;
    closeModal: () => void;
  };
}

export const deleteVacation = ({ payload, functions }: IDeleteVacationRequestAction) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      dispatch({
        type: types.DELETE_REQUEST
      });

      await vacationService.delete(payload);

      dispatch({
        type: types.DELETE_SUCCESS
      });

      functions.reloadVacations();
      functions.closeModal();
    } catch (error: any) {
      dispatch({
        type: types.DELETE_ERROR,
        error: error.message
      });
      return error;
    }
  };
};
