import { connect } from 'react-redux';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import { FormattedMessage } from 'react-intl';
import JaimyButton from '../../../theme/components/Button';
import JaimyGrid from '../../../theme/components/layout/Grid';
import JaimyText from '../../../theme/components/Typography/Text';
import JaimyFlex from '../../../theme/components/layout/Flex';
import { FixMeLater } from '../../../types/FixMeLater';
import theme from '../../../theme';
import { ComponentIsVisible } from '../../utils/isVisible';
import { EVerifiedStatus } from '../../../constants/verifiedStatus';
import {
  BannedPopupContainer,
  BannedPopupContent,
  BannedPopupTitleTag,
  BannedPopupLink,
  Logo
} from './styles';
import { logout } from '../../../util/tokenProvider';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../routes';

import jaimyLogo from '../../../assets/images/jaimy-logo-black.svg';

interface IBannedPopupProps {
  userFirstName: string;
  userLastName: string;
  userVerifiedStatus: string;
}

const BannedPopup = ({ userFirstName, userLastName, userVerifiedStatus }: IBannedPopupProps) => {
  const location = useLocation();

  return (
    <ComponentIsVisible
      when={
        userVerifiedStatus === EVerifiedStatus.Banned && !location.pathname.includes('invoices')
      }
    >
      <BannedPopupContainer>
        <BannedPopupContent>
          <JaimyFlex margin="0 0 2rem" justifyContent="space-between">
            <BannedPopupTitleTag>
              <JaimyHeading.H4 color={theme.colors.white}>
                <FormattedMessage id="popup.banned.title" />
              </JaimyHeading.H4>
            </BannedPopupTitleTag>
          </JaimyFlex>
          <JaimyGrid rowGap="1rem">
            <JaimyFlex columnGap=".25rem">
              <JaimyText>
                <FormattedMessage id="popup.banned.greeting" />
              </JaimyText>
              <JaimyText fontWeight={600}>{`${userFirstName} ${userLastName}`}</JaimyText>
            </JaimyFlex>

            <JaimyText>
              <FormattedMessage id="popup.banned.message" />
            </JaimyText>

            <JaimyFlex justifyContent="center" margin="3rem auto 0" columnGap="1rem" width="80%">
              <JaimyButton.Base onClick={logout} backgroundColor={theme.colors.secondary.base}>
                <FormattedMessage id="cancel" />
              </JaimyButton.Base>
              <JaimyButton.Base>
                <BannedPopupLink to={ROUTES.INVOICES.handler()}>
                  <FormattedMessage id="popup.banned.button" />
                </BannedPopupLink>
              </JaimyButton.Base>
            </JaimyFlex>

            <Logo src={jaimyLogo} />
          </JaimyGrid>
        </BannedPopupContent>
      </BannedPopupContainer>
    </ComponentIsVisible>
  );
};

const mapStateToProps = ({
  user: {
    user: { firstname, lastname, verified_status }
  }
}: {
  user: FixMeLater;
}) => ({
  userFirstName: firstname,
  userLastName: lastname,
  userVerifiedStatus: verified_status
});

export default connect(mapStateToProps, null)(BannedPopup);
