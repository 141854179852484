import CircularProgress from '@material-ui/core/CircularProgress';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Breadcrumbs from '../../components/Breadcrumbs';
import NukaCarousel from '../../components/Carousel/NukaCarousel';
import { DivHeader, SectionTopHeader } from '../../components/common/Header';
import { AnchorBack } from '../../components/common/Link';
import PrebookCard from '../../components/Prebook/PrebookCard/PrebookCard';
import { proOrPremiumSub, Subscriptions } from '../../constants/subscriptions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../routes';
import { requestTraderPrebooksData } from '../../store/reducers/prebooks/actions';
import theme from '../../theme';
import JaimyBox from '../../theme/components/layout/Box';
import JaimyFlex from '../../theme/components/layout/Flex';
import JaimyHeading from '../../theme/components/Typography/Heading';
import { FixMeLater } from '../../types/FixMeLater';
import getMonthAndYear from '../../util/getMonthAndYear';
import { centsToEuro } from '../../util/priceCents';

const Prebook = ({
  user,
  getTraderPrebooksData,
  traderPrebooks,
  preBooksIsLoading,
  preBooksMonthlyData
}: any) => {
  const { width } = useWindowDimensions();
  const activeMonthAndYear = getMonthAndYear();
  const intl = useIntl();
  const history = useHistory();
  const metaTitle = intl.messages['prebook.metaTitle'];

  const sub_type =
    user.subscription && user.subscription.length > 0 ? user.subscription[0].sub_type : 'local';

  useEffect(() => {
    if (!user) return;
    if (!sub_type) return;
    if (!proOrPremiumSub.includes(sub_type as Subscriptions)) {
      history.push(ROUTES.DASHBOARD.handler());
    }
  }, [history, sub_type, user]);

  useEffect(() => {
    getTraderPrebooksData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (preBooksIsLoading) return;
  }, [traderPrebooks, preBooksIsLoading]);

  const renderTraderPrebooks = () => {
    if (!traderPrebooks) return;
    const items = traderPrebooks.map((prebook: any) => {
      return <PrebookCard key={uuidv4()} prebook={{ ...prebook }} />;
    });
    if (traderPrebooks && traderPrebooks.length === 0) {
      return (
        <JaimyHeading.H4>
          <JaimyFlex flexDirection="column" maxWidth="375px">
            <JaimyBox backgroundColor="white" padding="1rem" borderRadius={1} margin="1rem">
              <FormattedMessage tagName="span" id="prebook.page.no_active_prebooks" />
            </JaimyBox>
          </JaimyFlex>
        </JaimyHeading.H4>
      );
    } else if (traderPrebooks && traderPrebooks.length > 0 && width <= 768) {
      return (
        <CarouselWrapper>
          <NukaCarousel items={items} />
        </CarouselWrapper>
      );
    } else {
      return <Prebooks>{items}</Prebooks>;
    }
  };
  return (
    <>
      <Helmet>
        <title>{`${metaTitle}`}</title>
      </Helmet>
      <DivHeader>
        <SectionTopHeader>
          {width > 768 && (
            <Breadcrumbs>
              <Link to={`${ROUTES.HOME.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.home" />
              </Link>
              <Link to={`${ROUTES.PREBOOK.handler()}`}>
                <FormattedMessage tagName="span" id="breadcrumbs.link.prebook" />
              </Link>
            </Breadcrumbs>
          )}
          {width <= 768 && (
            <AnchorBack to={`${ROUTES.JOBS.handler()}`}>
              <FormattedMessage tagName="span" id="servicespage.back_to_overview" />
            </AnchorBack>
          )}
        </SectionTopHeader>
        <H1ProfileTitle>
          <FormattedMessage tagName="span" id="prebook.pageTitle" defaultMessage="My Profile" />
        </H1ProfileTitle>
      </DivHeader>
      {preBooksIsLoading ? (
        <JaimyFlex alignItems="center" justifyContent="center">
          <CircularProgress color="inherit" disableShrink />
        </JaimyFlex>
      ) : (
        <PrebookDashboardLayout>
          {renderTraderPrebooks()}
          <JaimyBox margin="2rem 0rem">
            {activeMonthAndYear && (
              <JaimyHeading.H2 color={theme.colors.primary.base || '#c30144'}>
                {activeMonthAndYear.toUpperCase()}
              </JaimyHeading.H2>
            )}
            <JaimyFlex padding="1rem" flexDirection="column">
              <JaimyHeading.H2>
                <FormattedMessage id="prebook.total.missions" />
              </JaimyHeading.H2>
              <JaimyBox
                backgroundColor={theme.colors.white}
                borderRadius={100}
                padding="0px 15px 0px 15px"
                boxShadow="2px 2px 5px 5px rgba(0,0,0,0.2)"
                margin="10px 0px"
                style={{
                  borderBottom: `2px solid ${theme.colors.primary.base}`
                }}
              >
                <JaimyHeading.H2>
                  {preBooksMonthlyData && preBooksMonthlyData.total_sr_bought_in_this_month}
                </JaimyHeading.H2>
              </JaimyBox>
            </JaimyFlex>
            <JaimyFlex padding="1rem" flexDirection="column">
              <JaimyHeading.H2>
                <FormattedMessage id="prebook.total.budget" />
              </JaimyHeading.H2>
              <JaimyBox
                backgroundColor={theme.colors.white}
                borderRadius={100}
                padding="0px 15px 0px 15px"
                boxShadow="2px 2px 5px 5px rgba(0,0,0,0.2)"
                margin="10px 0px"
                style={{
                  borderBottom: `2px solid ${theme.colors.primary.base}`
                }}
              >
                <JaimyHeading.H2>
                  {preBooksMonthlyData &&
                    `${centsToEuro(preBooksMonthlyData.spent_during_this_month)}€`}
                </JaimyHeading.H2>
              </JaimyBox>
            </JaimyFlex>
          </JaimyBox>
          <JaimyBox>
            <JaimyHeading.H2 color="#79A6C3">
              <FormattedMessage id="prebook.new.prebook" />
            </JaimyHeading.H2>
            <JaimyHeading.H4 margin="10px 0px">
              <FormattedMessage id="prebook.new.contact" />
            </JaimyHeading.H4>
            {user.owner.email && (
              <JaimyHeading.H4 textDecoration={'underline'}>{user.owner.email}</JaimyHeading.H4>
            )}
          </JaimyBox>
        </PrebookDashboardLayout>
      )}
    </>
  );
};

const mapStateToProps = ({
  user: { user },
  prebooks: { prebooks, isLoading, error, monthlyData }
}: {
  user: FixMeLater;
  prebooks: FixMeLater;
}) => ({
  locale: user.locale,
  user,
  traderPrebooks: prebooks,
  preBooksIsLoading: isLoading,
  preBooksMonthlyData: monthlyData,
  preBooksError: error
});

const mapDispatchToProps = (dispatch: any) => ({
  getTraderPrebooksData: () => dispatch(requestTraderPrebooksData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Prebook);

// Styles
const H1ProfileTitle = styled.h1`
  color: #c30144;
  font-family: 'BelfiusNormal', sans-serif;
  font-size: 28px;
  line-height: 28px;
  padding: 0 1rem;
  text-align: center;
  margin: 0 auto 1rem;

  @media (min-width: 1200px) {
    max-width: 550px;
    font-size: 32px;
    line-height: 32px;
  }
`;
const PrebookDashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1.2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;
  @media (min-width: 768px) {
    gap: 1rem;
  }
`;

const Prebooks = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 3rem 0rem 3rem 0rem;
  gap: 2rem;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const CarouselWrapper = styled.div`
  padding: 2rem 0 2rem 0;
  border-radius: 10px;
  width: 350px;
  margin-bottom: 1rem;
`;
