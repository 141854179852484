import { EVerifiedStatus } from '../constants/verifiedStatus';
import { VERIFIED_STATUSES } from './constants';
import { centsToEuro } from './priceCents';

const moreThan40 = price_cents => centsToEuro(price_cents) > 40;

const validateCart = (cart, purchases_left) =>
  cart.cart_size > purchases_left ||
  (cart.data && cart.data.some(e => centsToEuro(e.price_cents) > 40));

/**
 * This function has 2 goals:
 * run validation to disable checkout and set alert text box
 * based on the user's current status.
 * Single function entry to validate a single job AND a list of jobs in a cart
 *
 * @param {*} price_cents price of a single job
 * @param {*} user a user object
 * @param {*} cart a cart object
 */
export const validateCheckout = (price_cents, user, cart) => {
  const { verified_status, checked_purchases_left, wait_for_info_purchases_left } = user;
  const result = {
    checkoutAlerts: {
      [VERIFIED_STATUSES.checked]: false,
      [EVerifiedStatus.RestrictedWaitForInfo]: false
    },
    disableCheckout: false
  };
  let purchases_left;
  // set purchases_left based on the user's status
  switch (verified_status) {
    case VERIFIED_STATUSES.checked:
      purchases_left = checked_purchases_left;
      break;

    case EVerifiedStatus.RestrictedWaitForInfo:
      purchases_left = wait_for_info_purchases_left;
      break;
    default:
      return result;
  }
  if (cart) {
    const outcome = validateCart(cart, purchases_left);
    result.disableCheckout = outcome;
    result.checkoutAlerts[verified_status] = outcome;
    return result;
  }
  const outcome = moreThan40(price_cents) || purchases_left < 1;
  result.disableCheckout = outcome;
  result.checkoutAlerts[verified_status] = outcome;
  return result;
};
