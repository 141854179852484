  export interface IVacationResponse {
  end_date: string;
  id: number;
  pause_calendar: boolean;
  pause_communication: boolean;
  pause_prebook: boolean;
  start_date: string;
  status: string;
}

export interface IVacationWarningsPayloadData {
  traderId: number;
  startDate: string;
  endDate: string;
  pauseCommunication?: boolean;
  pauseCalendar?: boolean;
  pausePreebook?: boolean;
  calendarIds?: number[];
  prebookIds?: number[];
}

export enum EVacationWarningType {
  calendar = 'calendar',
  prebook = 'prebook'
}

interface IVacationWarningsResponseWarnings {
  id: number;
  type: EVacationWarningType;
  warning: string;
  name: string;
  start_date: string;
  end_date: string;
}

export interface IVacationWarningsResponse {
  warnings: IVacationWarningsResponseWarnings[];
  success?: string;
}

export interface IVacation {
  endDate: string;
  id: number;
  pauseCalendar: boolean;
  pauseCommunication: boolean;
  pausePrebook: boolean;
  startDate: string;
  status: string;
}

export interface IVacationWarnings {
  id: number;
  type: EVacationWarningType;
  warning: string;
  name: string;
  startDate: Date;
  endDate: Date;
  startDateFormatted: string;
  endDateFormatted: string;
}

export interface ICalendarVacationsResponse {
  vacations: { end_date: string; id: number; start_date: string }[];
}

export interface ICalendarVacationsPayloadData {
  traderId: number;
  calendarId: string;
}

export interface ICalendarVacation {
  startDate: Date;
  id: number;
  endDate: Date;
}

export interface IPrebookVacationsResponse {
  vacations: { end_date: string; id: number; start_date: string }[];
}

export interface IPrebookVacationsPayloadData {
  traderId: number;
  prebookId: string;
}

export interface IPrebookVacation {
  startDate: string;
  id: number;
  endDate: string;
}

export interface IVacationPlanningPayloadData {
  traderId: number;
  startDate: string;
  endDate: string;
  pauseCommunication?: boolean;
  pauseCalendar?: boolean;
  pausePreebook?: boolean;
  calendarIds: number[];
  prebookIds: number[];
}

export interface IDeleteVacationPayloadData {
  traderId: number;
  status: 'cancelled';
  pauseId: number;
  calendarId?: number;
  prebookId?: number;
}
