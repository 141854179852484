import 'flatpickr/dist/flatpickr.css';
import '../../custom-flatpickr.css';
import './custom-dialog.css';

import { Checkbox, Dialog, FormControlLabel, TextField } from '@material-ui/core';
import Flatpickr from 'react-flatpickr';

import theme from '../../../../theme';
import JaimyButton from '../../../../theme/components/Button';
import JaimyLabel from '../../../../theme/components/form/Label';
import JaimyFlex from '../../../../theme/components/layout/Flex';
import JaimyHeading from '../../../../theme/components/Typography/Heading';

import { ErrorMessage } from '@hookform/error-message';
import { CircularProgress, DialogContent } from '@mui/material';
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfWeek } from 'date-fns';
import { useCallback } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import {
  createAppointment,
  getAppointments,
  ICreateAppointmentRequestAction
} from '../../../../store/reducers/calendar/actionCreators';
// import JaimyText from '../../../../theme/components/Typography/Text';
import { ICreateAppointmentFormData } from '../../../../types/Appointment';
import { IRange } from '../../../../types/Calendar';
import { FixMeLater } from '../../../../types/FixMeLater';
import { ComponentErrorMessage } from '../../../utils/ErrorMessage';
import { ComponentIsVisible } from '../../../utils/isVisible';

interface IAddAppointmentDialog {
  open: boolean;
  handleClose: () => void;
  createAppointment: (action: ICreateAppointmentRequestAction) => AnyAction;
  createAppointmentLoading: boolean;
  firstDayOfSelectedMonth: Date;
  getAppointments: (payload: { id: number; range: IRange }) => AnyAction;
  intervention_partnerships: FixMeLater[];
}

const AddAppointmentDialog = ({
  open,
  handleClose,
  createAppointment,
  createAppointmentLoading,
  firstDayOfSelectedMonth,
  getAppointments,
  intervention_partnerships
}: IAddAppointmentDialog) => {
  const { id } = useParams<{ id: string }>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<ICreateAppointmentFormData>({
    criteriaMode: 'all',
    mode: 'onBlur'
  });

  // const partnershipsWithCalendar = useMemo(() => {
  //   return intervention_partnerships.filter(iR => iR.jaimy_calendar);
  // }, [intervention_partnerships]);

  const watchedAllDay = watch('allDay');
  // const watchedAllCalendars = watch('allCalendars');

  const onSubmit = useCallback(
    (data: ICreateAppointmentFormData) => {
      let startDateSelected = data.startDate;
      let endDateSelected = data.endDate;

      if (data.allDay) {
        startDateSelected = startOfDay(data.allDayDate as Date).toISOString();
        endDateSelected = endOfDay(data.allDayDate as Date).toISOString();
      }

      // //Create private envent in all calendars
      // if (data.allCalendars) {
      //   partnershipsWithCalendar.forEach((partnership, index) => {
      //     createAppointment({
      //       payload: {
      //         ...data,
      //         interventionPartnershipId: partnership.id,
      //         endDate: endDateSelected,
      //         startDate: startDateSelected
      //       },
      //       functions: {
      //         reloadAppointments: () => {
      //           if (index === partnershipsWithCalendar.length - 1) {
      //             getAppointments({
      //               id: parseInt(id, 10),
      //               range: {
      //                 end: format(endOfWeek(endOfMonth(firstDayOfSelectedMonth)), 'yyyy/MM/dd'),
      //                 start: format(startOfWeek(firstDayOfSelectedMonth), 'yyyy/MM/dd')
      //               }
      //             });
      //           }
      //         },
      //         closeModal: () => {
      //           if (index === partnershipsWithCalendar.length - 1) {
      //             handleClose();
      //           }
      //         }
      //       }
      //     });
      //   });
      //   return;
      // }
      createAppointment({
        payload: {
          ...data,
          interventionPartnershipId: id,
          endDate: endDateSelected,
          startDate: startDateSelected
        },
        functions: {
          reloadAppointments: () =>
            getAppointments({
              id: parseInt(id, 10),
              range: {
                end: format(endOfWeek(endOfMonth(firstDayOfSelectedMonth)), 'yyyy/MM/dd'),
                start: format(startOfWeek(firstDayOfSelectedMonth), 'yyyy/MM/dd')
              }
            }),
          closeModal: () => {
            handleClose();
          }
        }
      });
    },
    [createAppointment, firstDayOfSelectedMonth, getAppointments, handleClose, id]
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="add-appointment"
      PaperProps={{
        className: 'customMuiDialogPaper'
      }}
      scroll="paper"
    >
      <DialogContent>
        <JaimyFlex flexDirection="column" width="100%" margin="0 auto" padding="2rem" gap="1rem">
          <JaimyHeading.H3 color={theme.colors.secondary.base} margin="0 auto">
            <FormattedMessage id="calendar.addAppointment" />
          </JaimyHeading.H3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <JaimyFlex flexDirection="column" gap="1rem">
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: 'missing-properties.message.required' }}
                  render={({ field: { onChange } }) => (
                    <>
                      <JaimyLabel htmlFor="title">
                        <FormattedMessage id="calendar.title" />
                      </JaimyLabel>
                      <TextField
                        autoFocus
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="title"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>
              <JaimyFlex px={2} flexDirection={watchedAllDay ? 'column' : 'row'} gap="1rem">
                <JaimyFlex>
                  <FormControlLabel
                    control={
                      <Controller
                        name="allDay"
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label={<FormattedMessage id="calendar.date.allDay" />}
                  />
                </JaimyFlex>
                <ComponentIsVisible when={watchedAllDay}>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="allDayDate"
                      control={control}
                      rules={{ required: watchedAllDay && 'missing-properties.message.required' }}
                      render={({ field: { onChange } }) => (
                        <>
                          <JaimyLabel htmlFor="allDayDate">
                            <FormattedMessage id="calendar.date" />
                          </JaimyLabel>
                          <Flatpickr
                            onChange={dates => onChange(dates[0])}
                            options={{
                              time_24hr: true,
                              static: true,
                              dateFormat: 'd-m-Y',
                              minDate: new Date().toISOString()
                            }}
                            aria-label="allDayDate"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="allDayDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </ComponentIsVisible>
              </JaimyFlex>

              <ComponentIsVisible when={!watchedAllDay}>
                <JaimyFlex px={2} flexDirection="column" gap="1rem">
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: !watchedAllDay && 'missing-properties.message.required' }}
                      render={({ field: { onChange } }) => (
                        <>
                          <JaimyLabel htmlFor="startDate">
                            <FormattedMessage id="calendar.date" />
                          </JaimyLabel>
                          <Flatpickr
                            onChange={dates => onChange(dates[0].toISOString())}
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              static: true,
                              minuteIncrement: 1,
                              dateFormat: 'd-m-Y H:i',
                              minDate: new Date().toISOString()
                            }}
                            aria-label="startDate"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="startDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                  <JaimyFlex flexDirection="column">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: !watchedAllDay && 'missing-properties.message.required' }}
                      render={({ field: { onChange } }) => (
                        <>
                          <JaimyLabel htmlFor="endDate">
                            <FormattedMessage id="calendar.date.end" />
                          </JaimyLabel>
                          <Flatpickr
                            onChange={dates => onChange(dates[0].toISOString())}
                            options={{
                              enableTime: true,
                              time_24hr: true,
                              static: true,
                              minuteIncrement: 1,
                              dateFormat: 'd-m-Y H:i',
                              minDate: watch('startDate') || new Date().toISOString()
                            }}
                            aria-label="endDate"
                            className="custom-flatpickr"
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      name="endDate"
                      errors={errors}
                      render={({ message }) => <ComponentErrorMessage message={message} />}
                    />
                  </JaimyFlex>
                </JaimyFlex>
              </ComponentIsVisible>

              {/* <ComponentIsVisible when={partnershipsWithCalendar.length > 1}>
                <JaimyFlex flexDirection="column" px={2}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="allCalendars"
                        control={control}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                    }
                    label={<FormattedMessage id="calendar.date.allCalendars" />}
                  />
                </JaimyFlex>
                <ComponentIsVisible when={watchedAllCalendars}>
                  <JaimyFlex flexDirection="column" px={2}>
                    <JaimyLabel htmlFor="title">
                      <FormattedMessage id="calendar.date.includedCalendars" />
                    </JaimyLabel>
                    {partnershipsWithCalendar.map(partnership => {
                      return (
                        <JaimyText>
                          {`${partnership.id}/${partnership.name && partnership.name}`}
                        </JaimyText>
                      );
                    })}
                  </JaimyFlex>
                </ComponentIsVisible>
              </ComponentIsVisible> */}

              <JaimyFlex flexDirection="column" px={2}>
                <JaimyLabel htmlFor="title">
                  <FormattedMessage id="calendar.address" />
                </JaimyLabel>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES}
                      selectProps={{
                        onChange,
                        isClearable: true,
                        placeholder: <FormattedMessage id="calendar.address.placeholder" />
                      }}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ['be', 'fr', 'nl']
                        }
                      }}
                    />
                  )}
                />
              </JaimyFlex>
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <>
                      <JaimyLabel htmlFor="phone">
                        <FormattedMessage id="calendar.phone" />
                      </JaimyLabel>
                      <TextField
                        autoFocus
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="title"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <>
                      <JaimyLabel htmlFor="email">
                        <FormattedMessage id="calendar.email" />
                      </JaimyLabel>
                      <TextField
                        autoFocus
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  name="title"
                  errors={errors}
                  render={({ message }) => <ComponentErrorMessage message={message} />}
                />
              </JaimyFlex>
              <JaimyFlex flexDirection="column" px={2}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <>
                      <JaimyLabel htmlFor="description">
                        <FormattedMessage id="calendar.description" />
                      </JaimyLabel>
                      <TextField
                        defaultValue=""
                        onChange={onChange}
                        size="small"
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={4}
                      />
                    </>
                  )}
                />
              </JaimyFlex>
              <JaimyFlex gap="16px" px={2} justifyContent="flex-end">
                <JaimyButton.Base
                  onClick={handleClose}
                  backgroundColor={theme.colors.secondary.base}
                  type="button"
                >
                  <FormattedMessage id="question.cancel" />
                </JaimyButton.Base>
                <JaimyButton.Base type="submit" disabled={createAppointmentLoading} width="10rem">
                  <ComponentIsVisible when={!createAppointmentLoading}>
                    <FormattedMessage id="profile.form.save" />
                  </ComponentIsVisible>
                  <ComponentIsVisible when={createAppointmentLoading}>
                    <CircularProgress size={20} color="inherit" disableShrink />
                  </ComponentIsVisible>
                </JaimyButton.Base>
              </JaimyFlex>
            </JaimyFlex>
          </form>
        </JaimyFlex>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({
  calendars: { createAppointmentLoading, firstDayOfSelectedMonth },
  user: {
    user: { intervention_partnerships }
  }
}: {
  calendars: FixMeLater;
  user: FixMeLater;
}) => ({
  intervention_partnerships,
  createAppointmentLoading,
  firstDayOfSelectedMonth
});

const mapDispatchToProps = (dispatch: FixMeLater) => ({
  createAppointment: (action: ICreateAppointmentRequestAction) =>
    dispatch(createAppointment(action)),
  getAppointments: (payload: { id: number; range: IRange }) =>
    dispatch(getAppointments({ range: payload.range, id: payload.id }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAppointmentDialog);
