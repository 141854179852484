import { useState } from 'react';
import { format } from 'date-fns';
import { formatPrice } from '../../../formatters/price';
import theme from '../../../theme';
import JaimyHeading from '../../../theme/components/Typography/Heading';
import { IEvent } from '../../../types/Calendar';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import Delete from '@material-ui/icons/Delete';
import { ComponentIsVisible } from '../../utils/isVisible';
import AppointmentDetailsDialog from '../../Dialog/Appointment/Details';
import UpdateAppointmentDialog from '../../Dialog/Appointment/Update';
import DeleteAppointmentDialog from '../../Dialog/Appointment/Delete';
import JaimyFlex from '../../../theme/components/layout/Flex';
import JaimyGrid from '../../../theme/components/layout/Grid';
import JaimyText from '../../../theme/components/Typography/Text';
import JaimyButton from '../../../theme/components/Button';

interface IAppointmentCard {
  appointment: IEvent;
}

const AppointmentCard = ({ appointment }: IAppointmentCard) => {
  const [updateAppointmentOpen, setUpdateAppointmentOpen] = useState<boolean>(false);
  const [appointmentDetailsOpen, setAppointmentDetailsOpen] = useState<boolean>(false);
  const [deleteAppointmentOpen, setDeleteAppointmentOpen] = useState<boolean>(false);

  const handleToggleUpdateAppointment = () => {
    setUpdateAppointmentOpen(currentValue => !currentValue);
  };
  const handleToggleDetails = () => {
    setAppointmentDetailsOpen(currentValue => !currentValue);
  };
  const handleToggleDelete = () => {
    setDeleteAppointmentOpen(currentValue => !currentValue);
  };

  return (
    <JaimyFlex
      backgroundColor={theme.colors.background.dark}
      borderRadius={0.5}
      flexDirection="column"
      padding="1rem"
    >
      <JaimyGrid columnGap=".5rem" columns="1fr auto">
        <JaimyGrid>
          <JaimyHeading.H4
            fontFamily={theme.typography.fonts.body}
            fontSize={theme.typography.fontSizes.md}
            color={theme.colors.primary.base}
          >
            {format(appointment.start, 'dd/MM - HH:mm')}
          </JaimyHeading.H4>
          <JaimyHeading.H4
            fontFamily={theme.typography.fonts.body}
            fontSize={theme.typography.fontSizes.md}
            color={theme.colors.primary.base}
          >
            {format(appointment.end, 'dd/MM - HH:mm')}
          </JaimyHeading.H4>
        </JaimyGrid>
        <JaimyGrid>
          <ComponentIsVisible when={!!appointment.category}>
            <JaimyText fontWeight="600" fontSize={theme.typography.fontSizes.md} textAlign="right">
              {appointment.category}
            </JaimyText>
          </ComponentIsVisible>
          <ComponentIsVisible when={!!appointment.price}>
            <JaimyText fontWeight="600" fontSize={theme.typography.fontSizes.md} textAlign="right">
              {formatPrice(appointment.price as number)}
            </JaimyText>
          </ComponentIsVisible>
        </JaimyGrid>
      </JaimyGrid>
      <JaimyGrid rowGap=".25rem" margin=".5rem 0">
        <ComponentIsVisible when={!!appointment.name}>
          <JaimyText fontWeight={600}>{appointment.name}</JaimyText>
        </ComponentIsVisible>
        <ComponentIsVisible when={!!appointment.address}>
          <JaimyText>{appointment.address}</JaimyText>
        </ComponentIsVisible>
        <ComponentIsVisible when={!!appointment.phone}>
          <JaimyText>{appointment.phone}</JaimyText>
        </ComponentIsVisible>
        <ComponentIsVisible when={!!appointment.email}>
          <JaimyText>{appointment.email}</JaimyText>
        </ComponentIsVisible>
      </JaimyGrid>
      <JaimyFlex justifyContent="space-between" alignItems="center">
        <JaimyFlex alignItems="center" columnGap=".5rem" width="fit-content">
          <JaimyButton.Base
            height="2.25rem"
            width="2.25rem"
            border={`1px solid ${theme.colors.secondary.base}`}
            color={theme.colors.secondary.base}
            backgroundColor="transparent"
            type="button"
            onClick={handleToggleUpdateAppointment}
          >
            <EditIcon fontSize="small" />
          </JaimyButton.Base>
          <ComponentIsVisible when={appointment.status === 'block_event'}>
            <JaimyButton.Base
              height="2.25rem"
              width="2.25rem"
              type="button"
              onClick={handleToggleDelete}
            >
              <Delete fontSize="small" />
            </JaimyButton.Base>
          </ComponentIsVisible>
        </JaimyFlex>
        <JaimyButton.Base
          height="2.25rem"
          width="2.25rem"
          backgroundColor={theme.colors.secondary.base}
          onClick={handleToggleDetails}
          type="button"
        >
          <Visibility fontSize="small" />
        </JaimyButton.Base>
      </JaimyFlex>

      <UpdateAppointmentDialog
        appointment={appointment}
        open={updateAppointmentOpen}
        handleClose={handleToggleUpdateAppointment}
      />
      <DeleteAppointmentDialog
        open={deleteAppointmentOpen}
        handleClose={handleToggleDelete}
        appointment={appointment}
      />
      <AppointmentDetailsDialog
        open={appointmentDetailsOpen}
        handleClose={handleToggleDetails}
        appointment={appointment}
      />
    </JaimyFlex>
  );
};

export default AppointmentCard;
