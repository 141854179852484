import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import theme from '../../../theme';
import { ComponentIsVisible } from '../isVisible';

interface IComponentAlertMessageProps {
  message: string;
  withoutTranslation?: boolean;
}

export function ComponentAlertMessage({
  message,
  withoutTranslation = false
}: IComponentAlertMessageProps) {
  return (
    <AlertMessage>
      <ComponentIsVisible when={withoutTranslation}>{message}</ComponentIsVisible>
      <ComponentIsVisible when={!withoutTranslation}>
        <FormattedMessage id={message} />
      </ComponentIsVisible>
    </AlertMessage>
  );
}

const AlertMessage = styled.span`
  color: ${theme.colors.secondary.light};
  font-size: 0.825rem;
  margin-top: 0.125rem;
`;
